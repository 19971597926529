import React, { useEffect, useState } from 'react';

import toast from 'react-hot-toast';
import CardProfile from './components/cardProfile';
import CardCertficate from './components/cardCertificate';
import CardProgressTrail from './components/CardProgressTrail';
import { useAuth } from '../../../hooks/Auth';
import { API } from '../../../services/api';
import {
  UserProfissionalProps,
  Dashboard,
  MyTrailsProps,
} from '../../../interfaces/Entidades';

import './style.scss';

export default function ProfileProfessional() {
  const { token, user } = useAuth();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [professional, setProfessional] = useState<UserProfissionalProps>(
    {} as UserProfissionalProps,
  );
  const [dashboard, setDashboard] = useState<Dashboard>({} as Dashboard);
  const [trilha, setTrilha] = useState<MyTrailsProps[]>([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadProfissional() {
      setLoading(true);
      try {
        const response = await API(token).get(`/api/usuario/perfil/${user.Id}`);
        setProfessional(response.data);

        const response_dashboard = await API(token).get(
          `/api/usuario/dashboard`,
        );
        setDashboard(response_dashboard.data);

        const response_trail = await API(token).get(
          `/api/trilha/minhas`,
        );
        setTrilha(response_trail.data);

        setLoading(false);
      } catch (err) {
        toast.error(
          'Houve um problema ao carregar seus dados, tente novamente! 😢',
        );
      }
    }

    loadProfissional();
  }, [token, user.Id]);

  return (
    <>
      <div className="row">
        <CardProfile
          loading={loading}
          professional={professional}
          editModalOpen={editModalOpen}
          setEditModalOpen={setEditModalOpen}
          dashboard={dashboard}
        />
      </div>
      <div className="row">
        <div className="carrosel">
          <CardProgressTrail trilha={trilha} loading={loading} />
        </div>
      </div>
      <div className="row">
        <CardCertficate loading={loading} trilha={professional.aprovadas} />
      </div>
    </>
  );
}
