import React, { useCallback, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import toast from 'react-hot-toast';

import { OptionTypeBase } from 'react-select';
import * as Yup from 'yup';
import { useAuth } from '../../../../../hooks/Auth';
import { API } from '../../../../../services/api';
import GetValidationErrors from '../../../../../utils/getValidationErros';

import ModalComponent from '../../../../../components/Modal/ModalComponent';
import Input from '../../../../components/Input';
import TextAreaInput from '../../../../components/TextAreaInput';
import { Button } from '../../../../../components/Button';
import { ModalProps } from '../../../../../interfaces/components';
import { DesafioProps } from '../../../../../interfaces/Entidades';

interface CreateDesafiosProps extends ModalProps {
  moduloId: number;
  pontuacaoDesafio: number;
}

export default function CreateDesafios({
  open,
  onClose,
  closeButton,
  itens,
  setItens,
  setModalOpen,
  moduloId,
  pontuacaoDesafio,
}: CreateDesafiosProps) {
  const { token } = useAuth();
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);

  const Submit = useCallback(
    async (data: DesafioProps) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          titulo: Yup.string().required('Campo Obrigatório'),
          conteudo: Yup.string().required('Campo Obrigatório'),
          pontuacao: Yup.number().required('Campo Obrigatório'),
        });

        data.urlVideo = undefined;

        data.ordem = 0;
        data.tipoVideo = 0;

        itens.forEach((item) => {
          if (item.ordem > data.ordem) {
            data.ordem = item.ordem;
          }
        });

        data.ordem += 1;
        data.unidadeAprendizagemId = moduloId;

        await schema.validate(data, {
          abortEarly: false,
        });

        const response = await API(token).post<DesafioProps>(
          '/api/desafio',
          data,
        );

        setLoading(false);

        data.id = response.data.id;

        setItens([...itens, data]);
        setModalOpen(false);

        toast.success('Desafio criado com sucesso!');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = GetValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          toast.error('Houve um erro ao criar Desafio, tente novamente!');
        }

        setLoading(false);
      }
    },

    [itens, moduloId, token],
  );

  return (
    <ModalComponent closeButton={closeButton} open={open} onClose={onClose}>
      <Form ref={formRef} placeholder="" className="createmodal-form-admin" onSubmit={Submit}>
        <h2 id="simple-modal-title">Desafio</h2>

        <Input
          name="titulo"
          maxLength={200}
          type="text"
          placeholder="Titulo do Desafio"
        />

        <TextAreaInput
          name="conteudo"
          maxLength={400}
          placeholder="Descrição do Desafio"
          style={{ marginBottom: 10 }}
        />

        <Input
          max={pontuacaoDesafio}
          min={0}
          name="pontuacao"
          type="number"
          placeholder={`Pontuação do Desafio: MÁX ${pontuacaoDesafio}`}
        />

        <Button
          color="#6F95FF"
          text="Confirmar"
          type="submit"
          loading={loading}
        />
      </Form>
    </ModalComponent>
  );
}
