import React, { useRef, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import { Skeleton } from '@material-ui/lab';

import { IoMdHelp } from 'react-icons/io';
import Medal1 from '../../../assets/images/medal/medal-1.svg';
import Medal2 from '../../../assets/images/medal/medal-2.svg';
import Medal3 from '../../../assets/images/medal/medal-3.svg';
import Medal4 from '../../../assets/images/medal/medal-4.svg';
import Medal5 from '../../../assets/images/medal/medal-5.svg';
import IconDiamond from '../../../assets/images/icon-diamond.svg';

import LevelMoadl from './levelModal';

import {
  UserProfissionalProps,
  Dashboard,
} from '../../../../interfaces/Entidades';

interface cardClassification {
  loading: boolean;
  professional: UserProfissionalProps;
  dashboard: Dashboard;
  levelModalOpen: boolean;
  setLevelModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Classification({
  loading,
  professional,
  dashboard,
  levelModalOpen,
  setLevelModalOpen,
}: cardClassification) {
  const medalRef = useRef<HTMLImageElement>(null);
  const [progressBar, setProgressBar] = useState<number>();

  const handleXP = () => {
    const XPnecessario = dashboard.proximoNivel;
    const XP = dashboard.pontuacaoTotal * 100;

    setProgressBar(XP / XPnecessario);
  };

  const handleMedalDashboard = () => {
    if (dashboard.nivel) {
      if (dashboard.nivel === 1) {
        if (medalRef.current) {
          medalRef.current.src = Medal1;
        }
      }
      if (dashboard.nivel === 2) {
        if (medalRef.current) {
          medalRef.current.src = Medal2;
        }
      }
      if (dashboard.nivel === 3) {
        if (medalRef.current) {
          medalRef.current.src = Medal3;
        }
      }
      if (dashboard.nivel === 4) {
        if (medalRef.current) {
          medalRef.current.src = Medal4;
        }
      }
      if (dashboard.nivel === 5) {
        if (medalRef.current) {
          medalRef.current.src = Medal5;
        }
      }
    } else if (medalRef.current) {
      medalRef.current.src = Medal1;
    }
  };
  useEffect(() => {
    async function loadData() {
      handleMedalDashboard();
      handleXP();
    }
    loadData();
  }, [handleMedalDashboard, handleXP]);
  return (
    <>
      <LevelMoadl
        id={professional.id}
        item={professional}
        open={levelModalOpen}
        setModalOpen={setLevelModalOpen}
        onClose={() => setLevelModalOpen(false)}
        closeButton={() => setLevelModalOpen(false)}
      />
      <div className="col-xl-4 responsive-card">
        <div className="card card-custom card-stretch gutter-b">
          <div className="card-header border-0 pt-5">
            <div className="card-title">
              <div className="card-label">
                {loading ? (
                  <Skeleton variant="text" width={200} height={40} />
                ) : (
                  <>
                    <div
                      style={{
                        width: '100%',
                        flexDirection: 'row',
                      }}
                    >
                      <div className="font-weight-bolder">
                        Sua Classificação
                        <span
                          className="label label-xl label-light label-inline my-lg-0 my-2 text-dark-50 font-weight-bolder"
                          style={{
                            marginLeft: 5,
                            borderRadius: '50%',
                            cursor: 'pointer',
                          }}
                          onClick={async () => {
                            await setLevelModalOpen(true);
                          }}
                        >
                          <IoMdHelp
                            size="10"
                            style={{
                              justifyContent: 'flex-end',
                            }}
                            color="#005F6B"
                          />
                        </span>
                      </div>
                    </div>

                    <div className="font-size-sm text-muted mt-2">
                      {professional.nome}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="card-body d-flex flex-column">
            {loading ? (
              <Skeleton
                variant="rect"
                width="98%"
                height={100}
                style={{ borderRadius: 6, marginTop: -15, marginBottom: 20 }}
              />
            ) : (
              <>
                <img
                  alt=""
                  ref={medalRef}
                  style={{
                    display: 'block',
                    height: '39%',
                    width: '100%',
                    marginTop: '-30px',
                  }}
                />
                <div
                  className="d-flex align-items-center justify-content-between mb-2"
                  style={{ marginTop: '-15px' }}
                >
                  <span className="text-muted font-size-sm font-weight-bold">
                    {dashboard.pontuacaoTotal}/{dashboard.proximoNivel} pontos
                  </span>
                  <span className="text-muted font-size-sm font-weight-bold">
                    {progressBar}%
                  </span>
                </div>
                <div
                  className="progress progress-xs w-100"
                  style={{ marginBottom: '15px' }}
                >
                  <div
                    className="progress-bar bg-success"
                    role="progressbar"
                    style={{ width: `${progressBar}%` }}
                    aria-valuenow={50}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </>
            )}

            <div className="card-body pt-1">
              <div className="d-flex align-items-center flex-wrap mb-4">
                <div className="symbol symbol-30 symbol-light mr-5">
                  {loading ? (
                    <Skeleton
                      variant="text"
                      width={45}
                      height={70}
                      style={{ borderRadius: 6, marginTop: -20 }}
                    />
                  ) : (
                    <>
                      <span className="symbol-label">
                        <img
                          src={IconDiamond}
                          className="h-50 align-self-center"
                          alt=""
                        />
                      </span>
                    </>
                  )}
                </div>
                {loading ? (
                  <Skeleton variant="text" className="responsive-skeleton" />
                ) : (
                  <div className="d-flex flex-column flex-grow-1 mr-2">
                    <Link
                      to="/"
                      className="font-weight-bold text-dark-75 text-hover-primary font-size-sm mb-1"
                    >
                      Trilhas Concluídas
                    </Link>
                  </div>
                )}

                {loading ? (
                  <Skeleton variant="text" width="0%" height={0} />
                ) : (
                  <span className="label label-xl label-light label-inline my-lg-0 my-2 text-dark-50 font-weight-bolder">
                    {dashboard.trilhasAssistidas}
                  </span>
                )}
              </div>

              <div className="d-flex align-items-center flex-wrap mb-4">
                <div className="symbol symbol-30 symbol-light mr-5">
                  {loading ? (
                    <Skeleton
                      variant="text"
                      width={45}
                      height={70}
                      style={{ borderRadius: 6, marginTop: -20 }}
                    />
                  ) : (
                    <>
                      <span className="symbol-label">
                        <img
                          src={IconDiamond}
                          className="h-50 align-self-center"
                          alt=""
                        />
                      </span>
                    </>
                  )}
                </div>
                {loading ? (
                  <Skeleton variant="text" className="responsive-skeleton" />
                ) : (
                  <div className="d-flex flex-column flex-grow-1 mr-2">
                    <Link
                      to="/"
                      className="font-weight-bold text-dark-75 text-hover-primary font-size-sm mb-1"
                    >
                      Cursos Concluídos
                    </Link>
                  </div>
                )}

                {loading ? (
                  <Skeleton variant="text" width="0%" height={0} />
                ) : (
                  <span className="label label-xl label-light label-inline my-lg-0 my-2 text-dark-50 font-weight-bolder">
                    {dashboard.cursosAssistidos}
                  </span>
                )}
              </div>

              <div className="d-flex align-items-center flex-wrap mb-4">
                <div className="symbol symbol-30 symbol-light mr-5">
                  {loading ? (
                    <Skeleton
                      variant="text"
                      width={45}
                      height={70}
                      style={{ borderRadius: 6, marginTop: -20 }}
                    />
                  ) : (
                    <>
                      <span className="symbol-label">
                        <img
                          src={IconDiamond}
                          className="h-50 align-self-center"
                          alt=""
                        />
                      </span>
                    </>
                  )}
                </div>
                {loading ? (
                  <Skeleton variant="text" className="responsive-skeleton" />
                ) : (
                  <div className="d-flex flex-column flex-grow-1 mr-2">
                    <Link
                      to="/"
                      className="font-weight-bold text-dark-75 text-hover-primary font-size-sm mb-1"
                    >
                      Módulos Concluídos
                    </Link>
                  </div>
                )}

                {loading ? (
                  <Skeleton variant="text" width="0%" height={0} />
                ) : (
                  <span className="label label-xl label-light label-inline my-lg-0 my-2 text-dark-50 font-weight-bolder">
                    {dashboard.unidadesAssistidas}
                  </span>
                )}
              </div>

              <div className="d-flex align-items-center flex-wrap mb-4">
                <div className="symbol symbol-30 symbol-light mr-5">
                  {loading ? (
                    <Skeleton
                      variant="text"
                      width={45}
                      height={70}
                      style={{ borderRadius: 6, marginTop: -20 }}
                    />
                  ) : (
                    <>
                      <span className="symbol-label">
                        <img
                          src={IconDiamond}
                          className="h-50 align-self-center"
                          alt=""
                        />
                      </span>
                    </>
                  )}
                </div>
                {loading ? (
                  <Skeleton variant="text" className="responsive-skeleton" />
                ) : (
                  <div className="d-flex flex-column flex-grow-1 mr-2">
                    <Link
                      to="/"
                      className="font-weight-bold text-dark-75 text-hover-primary font-size-sm mb-1"
                    >
                      Aulas Assistidas
                    </Link>
                  </div>
                )}

                {loading ? (
                  <Skeleton variant="text" width="0%" height={0} />
                ) : (
                  <span className="label label-xl label-light label-inline my-lg-0 my-2 text-dark-50 font-weight-bolder">
                    {dashboard.objetosAssistidos}
                  </span>
                )}
              </div>

              <div className="d-flex align-items-center flex-wrap mb-4">
                <div className="symbol symbol-30 symbol-light mr-5">
                  {loading ? (
                    <Skeleton
                      variant="text"
                      width={45}
                      height={70}
                      className="mobile"
                      style={{ borderRadius: 6, marginTop: -20 }}
                    />
                  ) : (
                    <>
                      <span className="symbol-label">
                        <img
                          src={IconDiamond}
                          className="h-50 align-self-center"
                          alt=""
                        />
                      </span>
                    </>
                  )}
                </div>
                {loading ? (
                  <Skeleton
                    variant="text"
                    className="responsive-skeleton mobile"
                  />
                ) : (
                  <div className="d-flex flex-column flex-grow-1 mr-2">
                    <Link
                      to="/"
                      className="font-weight-bold text-dark-75 text-hover-primary font-size-sm mb-1"
                    >
                      Avaliações Finalizadas
                    </Link>
                  </div>
                )}

                {loading ? (
                  <Skeleton variant="text" width="0%" height={0} />
                ) : (
                  <span className="label label-xl label-light label-inline my-lg-0 my-2 text-dark-50 font-weight-bolder">
                    {dashboard.avaliacoesRespondidas}
                  </span>
                )}
              </div>

              <div className="d-flex align-items-center flex-wrap mb-4">
                <div className="symbol symbol-30 symbol-light mr-5">
                  {loading ? (
                    <></>
                  ) : (
                    <>
                      <span className="symbol-label">
                        <img
                          src={IconDiamond}
                          className="h-50 align-self-center"
                          alt=""
                        />
                      </span>
                    </>
                  )}
                </div>
                {loading ? (
                  <></>
                ) : (
                  <div className="d-flex flex-column flex-grow-1 mr-2">
                    <Link
                      to="/"
                      className="font-weight-bold text-dark-75 text-hover-primary font-size-sm mb-1"
                    >
                      Desafios Concluídos
                    </Link>
                  </div>
                )}

                {loading ? (
                  <Skeleton variant="text" width="0%" height={0} />
                ) : (
                  <span className="label label-xl label-light label-inline my-lg-0 my-2 text-dark-50 font-weight-bolder">
                    {dashboard.desafiosRespondidos}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
