/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React, {
  useState,
  useCallback,
  BaseSyntheticEvent,
  useEffect,
} from 'react';
import toast from 'react-hot-toast';
import { API } from '../../../services/api';
import { useAuth } from '../../../hooks/Auth';
import DetailsPageComponent from '../../components/DetailsPage';
import { AvaliacaoProps } from '../../../interfaces/Entidades';
import AvaliacoesTable from './AvaliacoesTable';

const AvaliacoesPage = () => {
  const { token } = useAuth();
  const [loading, setLoading] = useState(false);
  const [avaliacoes, setAvaliacoes] = useState<AvaliacaoProps[]>([]);
  const [FilteredAvaliacoes, setFilteredAvaliacoes] = useState<
    AvaliacaoProps[]
  >([]);

  const handleFilter = useCallback(
    (text: string) => {
      const filterEvalutaions = avaliacoes.filter((evaluation) =>
        evaluation.unidadeAprendizagem.titulo
          .toUpperCase()
          .includes(text.toUpperCase()),
      );
      if (filterEvalutaions.length > 0) {
        setFilteredAvaliacoes(filterEvalutaions);
      } else if (text === '') {
        setFilteredAvaliacoes(avaliacoes);
      } else {
        setFilteredAvaliacoes([]);
      }
    },
    [avaliacoes],
  );

  useEffect(() => {
    async function reloadData() {
      setFilteredAvaliacoes(avaliacoes);
    }

    reloadData();
  }, [avaliacoes]);

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      try {
        const response = await API(token).get(
          '/api/avaliacao/odata?$expand=unidadeAprendizagem($select=id,titulo;$expand=curso($select=nome)),questoes($expand=alternativas)',
        );

        setAvaliacoes(response.data.value);
        setFilteredAvaliacoes(response.data.value);
        setLoading(false);
      } catch (err) {
        toast.error(
          'Houve um erro ao carregar as avaliações, tente novamente! 😢',
        );
      }
    }

    loadData();
  }, [token]);

  return (
    <>
      <DetailsPageComponent
        InputOnChange={(event: BaseSyntheticEvent) =>
          handleFilter(event.target.value)
        }
        hasCard={false}
        loading={loading}
        headerBack={false}
        hasHeaderButton={false}
        headerTitle="Avaliações"
      >
        <div className="cluster-table-container" style={{ marginTop: -30 }}>
          <div className="cluster-table-card" style={{ width: '100%' }}>
            <AvaliacoesTable
              setEvaluations={setAvaliacoes}
              evaluations={FilteredAvaliacoes}
              loading={loading}
            />
          </div>
        </div>
      </DetailsPageComponent>
    </>
  );
};

export default AvaliacoesPage;
