import { Form } from '@unform/web';
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { FiCheckCircle, FiMail, FiLock, FiKey } from 'react-icons/fi';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { FormHandles } from '@unform/core';
import { Link, useHistory, useLocation } from 'react-router-dom';

import Input from '../../components/Input';
import logoImg from '../../assets/images/icon-diamond.svg';

import './styles.scss';
import { Button } from '../../components/Button';

import GetValidationErrors from '../../../utils/getValidationErros';
import { API } from '../../../services/api';
import Wave from '../../assets/images/wave.svg';

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const location = useLocation().search;
  const [codePassword, setCodePassword] = useState<string | null>('');
  const formRef = useRef<FormHandles>(null);

  const Submit = useCallback(
    async (data) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          senha: Yup.string()
            .min(6, 'Mínimo 8 dígitos!')
            .max(20, 'Máximo 20 dígitos'),
          confirmarSenha: Yup.string()
            .oneOf([Yup.ref('senha'), null], 'Senhas não coincidem')
            .min(8, 'Mínimo 8 dígitos!')
            .max(20, 'Máximo 20 dígitos'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        data.codigo = codePassword;
        const response_reset = await API().post(
          '/api/usuario/redefinirSenha',
          data,
        );
        setLoading(false);
        toast.success('Senha Recuperada!');
        history.push('/');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = GetValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          toast.error('Ocorreu um erro, por favor tente novamente! 😥');
        }
        setLoading(false);
      }
    },
    [history, codePassword],
  );

  useEffect(() => {
    function searchCode() {
      const code = new URLSearchParams(location);
      setCodePassword(code.get('code'));
    }
    searchCode();
  }, [location]);

  return (
    <div className="forgotPasswordContainerReset">
      <main className="main-forgotpassword-email-reset">
        <Form
          ref={formRef}
          placeholder=""
          className="forgotPasswordFormReset"
          onSubmit={Submit}
        >
          <img src={logoImg} alt="" />
          <h1>Nova de Senha</h1>

          <Input
            name="senha"
            placeholder="Digite sua nova senha"
            icon={FiLock}
            maxLength={20}
            type="password"
          />
          <Input
            name="confirmarSenha"
            placeholder="Confirme sua nova senha"
            icon={FiLock}
            maxLength={20}
            type="password"
          />

          <Button
            text="Salvar"
            loading={loading}
            type="submit"
            style={{ width: '100%' }}
          />
        </Form>
      </main>
    </div>
  );
};

export default ResetPassword;
