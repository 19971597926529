import React, {
  InputHTMLAttributes,
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { IconType } from 'react-icons';
import { FiAlertCircle, FiSearch } from 'react-icons/fi';
import { useField } from '@unform/core';

import './style.scss';
import ReactInputMask, {
  InputState,
  Props as MaskInputProps,
} from 'react-input-mask';
import Tooltip from '../../../components/Tooltip';
import { API } from '../../../services/api';

interface maskInputProps extends MaskInputProps {
  name: string;
  icon?: IconType;
  hasIcon?: boolean;
  onPressIcon?: MouseEventHandler<SVGElement>;
}

const MaskInput = ({
  name,
  icon,
  hasIcon,
  onPressIcon,
  mask,
  onBlur,
  ...props
}: maskInputProps): JSX.Element => {
  const ContainerRef = useRef<HTMLDivElement>(null);
  const [iconActive, setIconActive] = useState('');
  const InputRef = useRef<any>(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const Icon = icon;

  const HandleFocus = useCallback(() => {
    ContainerRef.current?.classList.add('MaskInputContainerClusterActive');
    setIconActive('iconAdminClusterActive');
  }, []);

  const handleBlur = useCallback(() => {
    ContainerRef.current?.classList.remove('MaskInputContainerClusterActive');

    if (InputRef.current?.value) {
      setIconActive('iconAdminClusterActive');
    } else {
      setIconActive('');
    }
  }, []);

  if (error) {
    ContainerRef.current?.classList.add('MaskInputClusterError');
  } else {
    ContainerRef.current?.classList.remove('MaskInputClusterError');
  }

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: InputRef.current,
      path: 'value',
      setValue(ref: any, value: string) {
        ref.setInputValue(value);
      },
      clearValue(ref: any) {
        ref.setInputValue('');
      },
    });
  }, [fieldName, registerField]);

  return (
    <div ref={ContainerRef} className="MaskInputContainer">
      {Icon && <Icon className={iconActive} size={20} />}
      <ReactInputMask
        className="MaskInput"
        ref={InputRef}
        {...props}
        mask={mask}
        onFocus={HandleFocus}
        onBlur={onBlur || handleBlur}
      />
      {hasIcon && (
        <div className="SearchIconContainer">
          <FiSearch size={20} onClick={onPressIcon} />
        </div>
      )}
      {error && (
        <Tooltip title={error}>
          <FiAlertCircle color="#f53030" size={20} />
        </Tooltip>
      )}
    </div>
  );
};

MaskInput.defaultProps = {
  icon: undefined,
};

export default MaskInput;
