/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React, {
  useState,
  useCallback,
  BaseSyntheticEvent,
  useEffect,
} from 'react';
import { Pagination } from '@material-ui/lab';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { FiCheck, FiX } from 'react-icons/fi';
import { FaLock, FaLockOpen  } from 'react-icons/fa'
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import AvatarImg from '../../../assets/images/no-image.png';
import CreateTrailModal from './CreateModal';

import { API } from '../../../services/api';
import { useAuth } from '../../../hooks/Auth';
import DetailsPageComponent from '../../components/DetailsPage';
import { TrailProps } from '../../../interfaces/Entidades';

const BlueTooltip = withStyles({
  tooltip: {
    fontSize: 12,
    color: '#fff',
    backgroundColor: '#6F95FF',
  },
})(Tooltip);

const TrailsDetailPage = () => {
  const { token, user } = useAuth();
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [editId, setEditId] = useState(0);
  const [trails, setTrails] = useState<TrailProps[]>([]);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filteredTrails, setFilteredTrails] = useState<TrailProps[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(9);

  const handleFilter = useCallback(
    (text: string) => {
      const filterTrails = trails.filter((trail) =>
        trail.nome.toUpperCase().includes(text.toUpperCase()),
      );

      if (filterTrails.length > 0) {
        setFilteredTrails(filterTrails);
      } else if (text === '') {
        setFilteredTrails(trails);
      } else {
        setFilteredTrails([]);
      }

      const pagesCount = Math.ceil(filterTrails.length / rowsPerPage);
      setPageCount(pagesCount);
      if (pagesCount <= 1) {
        setPage(0);
      }
    },
    [trails, rowsPerPage],
  );

  useEffect(() => {
    function setNewTrails() {
      setFilteredTrails(trails);
      setPageCount(Math.ceil(trails.length / rowsPerPage));
    }

    setNewTrails();
  }, [trails, rowsPerPage]);

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      try {
        const response = await API(token).get(
          `/api/trilha/odata?$select=nome,descricao,ativa,id,urlImagem,exclusiva&$filter=organizacaoId eq ${user.organizacao}`,
        );
        setPageCount(Math.ceil(response.data.value.length / rowsPerPage));
        setTrails(response.data.value);
        setFilteredTrails(response.data.value);
        setLoading(false);
      } catch (err) {
        toast.error('Houve um erro ao carregar as trilhas, tente novamente!');
      }
    }

    loadData();
  }, [token, rowsPerPage]);

  return (
    <>
      <CreateTrailModal
        itens={trails}
        open={createModalOpen}
        setModalOpen={setCreateModalOpen}
        setItens={setTrails}
        onClose={() => setCreateModalOpen(false)}
        closeButton={() => setCreateModalOpen(false)}
      />

      <Breadcrumbs aria-label="breadcrumb">

        <Typography color="textPrimary">
        Listagem de Trilhas
        </Typography>

       </Breadcrumbs>

      <DetailsPageComponent
        InputOnChange={(event: BaseSyntheticEvent) =>
          handleFilter(event.target.value)
        }
        buttonHeaderText="Adicionar Trilha"
        hasCard={false}
        setCreateModalOpen={setCreateModalOpen}
        loading={loading}
        headerBack={false}
        headerTitle="Listagem de Trilhas"
      >
        {filteredTrails.length > 0 ? (
          filteredTrails
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((trail) => (
              <div
                key={trail.id}
                className="admin-trails-card"
                style={{ width: '31%' }}
              >
                <div className="admin-trails-card-header">
                  <div>
                    <img
                      src={trail.urlImagem ? trail.urlImagem : AvatarImg}
                      alt=""
                    />
                  </div>
                  <div
                    style={{
                      padding: 10,
                    }}
                  >
                    <h2>{trail.nome}</h2>
                  </div>
                </div>

                <div className="admin-trails-card-descriptions">
                  <span>{trail.descricao}</span>
                </div>

                <div className="admin-trails-card-footer">
                <BlueTooltip
                    title={trail.exclusiva === true ? 'Trilha exclusiva' : 'Trilha não exclusiva'}
                    placement="bottom"
                  >
                    <div>
                      {trail.exclusiva === true ? (
                        <FaLock
                          size={15}
                          style={{ marginRight: 10 }}
                        />
                      ) : (
                        <FaLockOpen
                          size={15}
                          style={{ marginRight: 10 }}
                        />
                      )}
                    </div>
                  </BlueTooltip>
                  <BlueTooltip
                    title={trail.ativa ? 'Trilha ativa' : 'Trilha desativada'}
                    placement="bottom"
                  >
                    <div>
                      {trail.ativa ? (
                        <FiCheck
                          size={20}
                          color="green"
                          style={{ marginRight: 10 }}
                        />
                      ) : (
                        <FiX
                          size={20}
                          color="red"
                          style={{ marginRight: 10 }}
                        />
                      )}
                    </div>
                  </BlueTooltip>
                  <Link to={`/trail/${trail.id}`}>
                    <span>Detalhes</span>
                  </Link>
                </div>
              </div>
            ))
        ) : (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <h2>Não foram encontrados itens</h2>
          </div>
        )}
        <div className="admin-trail-footer">
          <Pagination
            count={pageCount}
            page={page + 1}
            hideNextButton={pageCount < 2}
            hidePrevButton={pageCount < 2}
            onChange={(event: object, page_select: number) => {
              setPage(page_select - 1);
            }}
          />
        </div>
      </DetailsPageComponent>
    </>
  );
};

export default TrailsDetailPage;
