import React, { useCallback, useRef, useState, useEffect } from 'react';
import { Form } from '@unform/web';
import { FiUser } from 'react-icons/fi';
import { GiModernCity } from 'react-icons/gi';
import { FormHandles } from '@unform/core';
import toast from 'react-hot-toast';

import { OptionTypeBase } from 'react-select';
import * as Yup from 'yup';

import { API } from '../../../../services/api';
import SelectInput from '../../../components/SelectInput';
import GetValidationErrors from '../../../../utils/getValidationErros';
import { useAuth } from '../../../../hooks/Auth';
import Input from '../../../components/Input';
import { Button } from '../../../../components/Button';
import ModalComponent from '../../../../components/Modal/ModalComponent';
import {
  ClusterProps,
  TipoClusterProps,
} from '../../../../interfaces/Entidades';
import { ModalProps } from '../../../../interfaces/components';


export default function CreateModalCluster({
  open,
  itens,
  setItens,
  setModalOpen,
  onClose,
  closeButton,
}: ModalProps) {
  const { token } = useAuth();
  const [inputLoading, setInputLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<OptionTypeBase[]>();
  const formRef = useRef<FormHandles>(null);

  const Submit = useCallback(
    async (data) => {
      setLoading(true);

      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          descricao: Yup.string().required('Nome obrigatório!'),
          cidade: Yup.string().required('Cidade obrigatória!'),
          tipoClusterId: Yup.number().required(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const response = await API(token).post('/api/cluster', data);
        setLoading(false);

        const findItem = options?.find(
          (item) => item.value === data.tipoClusterId,
        );

        if (findItem) {
          data.tipoCluster = { descricao: findItem.label };
        } else {
          console.log('fail');
        }

        data.id = response.data.id;
        toast.success('Cluster criado com sucesso!');
        setItens([...itens, data]);
        setModalOpen(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = GetValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          toast.error('Houve um erro ao criar o cluster, tente novamente!');
        }

        setLoading(false);
      }
    },

    [token, itens, setItens, setModalOpen, options],
  );

  useEffect(() => {
    async function loadData() {
      setInputLoading(true);
      setOptions([]);

      try {
        const response = await API(token).get('/api/tipocluster/odata');

        const optionsFormat: OptionTypeBase[] = [];
        response.data.value.forEach((tipoCluster: TipoClusterProps) => {
          optionsFormat.push({
            value: tipoCluster.id,
            label: tipoCluster.descricao,
          });
        });

        setOptions(optionsFormat);

        setInputLoading(false);
      } catch (err) {
        toast.error('Houve um erro ao carregar os tipos de clusters! 😢');
        setInputLoading(false);
      }
    }

    loadData();
  }, [token]);

  return (
    <ModalComponent closeButton={closeButton} open={open} onClose={onClose}>
      <Form ref={formRef} placeholder="" onSubmit={Submit}>
        <h2 id="simple-modal-title">Criar novo cluster</h2>
        <Input
          name="descricao"
          maxLength={80}
          icon={FiUser}
          type="text"
          placeholder="Nome"
        />

        <Input
          name="cidade"
          maxLength={80}
          icon={GiModernCity}
          type="text"
          placeholder="Cidade"
        />

        <SelectInput
          loading
          options={options}
          isLoading={inputLoading}
          name="tipoClusterId"
          placeholder="Selecione o tipo do cluster..."
        />

        <Button
          color="#802A00"
          text="Confirmar"
          type="submit"
          loading={loading}
        />
      </Form>
    </ModalComponent>
  );
}
