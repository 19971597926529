import React, { Dispatch, SetStateAction } from 'react';
import CountUp from 'react-countup';
import { format } from 'date-fns';
import NoImagem from '../../../../assets/images/no-image.png';
import IconDiamond from '../../../../assets/images/icon-diamond.svg';
import { CursoProps } from '../../../../../interfaces/Entidades';
import { Button } from '../../../../../components/Button';

interface InfoCardProps {
  curso: CursoProps;
  setEditModalOpen: Dispatch<SetStateAction<boolean>> | undefined;
  setDeleteModalOpen?: Dispatch<SetStateAction<boolean>> | undefined;
  qtdeFormados?: number;
}

export const CourseCard = ({
  curso,
  setEditModalOpen,
  setDeleteModalOpen,
  qtdeFormados = 0,
}: InfoCardProps) => (
  <div className="col-xl-4" style={{ paddingLeft: 0, height: '45em' }}>
    {/* begin::Mixed Widget 16 */}
    <div className="card card-custom card-stretch gutter-b">
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">
        <div className="card-title">
          <div className="card-label">
            <div
              className="font-weight-bolder cardInfoClamp"
              style={{
                color: '#413B6B',
                textAlign: 'center',
                marginBottom: 15,
              }}
            >
              <span className="line-clamp">
                <span className="text-muted" style={{ fontSize: '16px' }}>
                  Dashboard do Curso:{' '}
                </span>
                {curso.nome}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="card-body d-flex flex-column">
        <img
          alt=""
          src={curso.urlImagem || NoImagem}
          style={{
            display: 'block',
            objectFit: 'cover',
            height: '40%',
            width: '100%',
            marginTop: -30,
            borderRadius: 6,
            marginBottom: 15,
          }}
        />

        <div className="card-body pt-1">
          <div className="d-flex align-items-center flex-wrap mb-4">
            <div className="symbol symbol-30 symbol-light mr-5">
              <span className="symbol-label">
                <img
                  src={IconDiamond}
                  className="h-50 align-self-center"
                  alt=""
                />
              </span>
            </div>
            <div className="d-flex flex-column flex-grow-1 mr-2">
              <span className="font-weight-bold text-dark-75  font-size-sm mb-1">
                Data de criação
              </span>
            </div>

            <span className="label label-xl label-light label-inline my-lg-0 my-2 text-dark-50 font-weight-bolder">
              {format(Date.parse(curso.createdAt), 'dd/MM/yyyy')}
            </span>
          </div>

          <div className="d-flex align-items-center flex-wrap mb-4">
            <div className="symbol symbol-30 symbol-light mr-5">
              <span className="symbol-label">
                <img
                  src={IconDiamond}
                  className="h-50 align-self-center"
                  alt=""
                />
              </span>
            </div>

            <div className="d-flex flex-column flex-grow-1 mr-2 my-2">
              <span className="font-weight-bold text-dark-75  font-size-sm mb-1">
                Profissionais formados
              </span>
            </div>

            <CountUp
              start={0}
              duration={2}
              end={qtdeFormados}
              className="label label-xl label-light label-inline my-lg-0 my-2 text-dark-50 font-weight-bolder"
            />
          </div>

          <div className="d-flex align-items-center flex-wrap mb-4">
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 30,
                marginTop: 30,
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <Button
                color="#6F95FF"
                text="Editar curso"
                onClick={() => {
                  setEditModalOpen ? setEditModalOpen(true) : undefined;
                }}
              />
              <Button
                color="#6F95FF"
                text="Deletar curso"
                onClick={() => {
                  setDeleteModalOpen ? setDeleteModalOpen(true) : undefined;
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
