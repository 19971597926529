/* eslint-disable no-param-reassign */
import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  ChangeEvent,
} from 'react';
import { Form } from '@unform/web';
import {
  FiImage,
  FiLock,
  FiMail,
  FiUser,
  FiUsers,
  FiCamera,
} from 'react-icons/fi';
import { AiOutlineIdcard } from 'react-icons/ai';
import { FormHandles } from '@unform/core';
import toast from 'react-hot-toast';

import { OptionTypeBase } from 'react-select';
import * as Yup from 'yup';

import { cpf } from 'cpf-cnpj-validator';
import { API } from '../../../../services/api';
import SelectInput from '../../../components/SelectInput';
import GetValidationErrors from '../../../../utils/getValidationErros';
import { useAuth } from '../../../../hooks/Auth';
import Input from '../../../components/Input';
import MaskInput from '../../../components/MaskInput';
import { Button } from '../../../../components/Button';
import ModalComponent from '../../../../components/Modal/ModalComponent';
import { ModalProps } from '../../../../interfaces/components';
import { CompanyProps } from '../../../../interfaces/Entidades';
import AvatarImg from '../../../assets/images/no-image.png';

export default function CreateModalAdminUserCompany({
  open,
  onClose,
  closeButton,
  itens,
  setItens,
  setModalOpen,
}: ModalProps) {
  const { token } = useAuth();
  const [inputLoading, setInputLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [OptionsOrg, setOptionsOrg] = useState<OptionTypeBase[]>();
  const formRef = useRef<FormHandles>(null);
  const avatarRef = useRef<HTMLImageElement>(null);
  const fileRef = useRef<HTMLInputElement>(null);

  const Submit = useCallback(
    async (data) => {
      setLoading(true);

      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          cargo: Yup.string().required('Campo Obrigatório'),
          organizacaoId: Yup.number().required('Campo Obrigatório'),
          nome: Yup.string().required('Campo Obrigatório'),
          email: Yup.string().required('Campo Obrigatório'),
          cpf: Yup.string()
            .required('Campo obrigatório')
            .test('validacao-cpf', 'CPF Inválido', async (Cpf) => {
              if (Cpf === undefined) {
                return false;
              }
              const validation = cpf.isValid(Cpf);
              if (validation) {
                return true;
              }
              return false;
            }),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (
          fileRef.current &&
          fileRef.current.files &&
          fileRef.current.files.length > 0
        ) {
          const formData = new FormData();
          formData.append('foto', fileRef.current.files[0]);

          const upload_response = await API(token).post(
            '/api/organizacao/imagem',
            formData,
          );

          data.foto = upload_response.data.url;
        }

        data.administrador = true;

        const response = await API(token).post(
          '/api/usuario/colaborador',
          data,
        );
        setLoading(false);

        const findOrg = OptionsOrg?.find(
          (item) => item.value === data.organizacaoId,
        );

        if (findOrg) {
          data.organizacao = { nomeFantasia: findOrg.label };
        } else {
          console.log('fail');
        }

        data.id = response.data.id;
        setItens([...itens, data]);
        setModalOpen(false);
        toast.success('Usuário criado com sucesso!');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = GetValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          toast.error('Houve um erro ao criar o usuário, tente novamente! 😢');
        }

        setLoading(false);
      }
    },

    [token, itens, setItens, setModalOpen, OptionsOrg],
  );

  useEffect(() => {
    async function loadData() {
      setInputLoading(true);
      setOptionsOrg([]);

      const response_Org = await API(token).get(
        '/api/organizacao/odata?$select=id,razaoSocial',
      );
      const optionsFormatOrg: OptionTypeBase[] = [];
      response_Org.data.value.forEach((Empresa: CompanyProps) => {
        optionsFormatOrg.push({
          value: Empresa.id,
          label: Empresa.razaoSocial,
        });
      });
      setOptionsOrg(optionsFormatOrg);

      setInputLoading(false);
    }

    loadData();
  }, [token]);

  const handleAvatarChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      if (avatarRef.current) {
        avatarRef.current.src = URL.createObjectURL(e.target.files[0]);
      }
    }
  }, []);

  return (
    <ModalComponent closeButton={closeButton} open={open} onClose={onClose}>
      <Form ref={formRef} placeholder="" className="createmodal-form-admin" onSubmit={Submit}>
        <h2 id="simple-modal-title">Criar novo Usuário</h2>
        <div className="AvatarInput">
          <img ref={avatarRef} src={AvatarImg} alt="" />
          <label htmlFor="avatar" style={{ backgroundColor: '#802A00' }}>
            <FiCamera />

            <input
              type="file"
              id="avatar"
              ref={fileRef}
              name="foto"
              accept="image/*"
              onChange={(event) => handleAvatarChange(event)}
            />
          </label>
        </div>
        <Input
          name="nome"
          maxLength={60}
          icon={FiUser}
          type="text"
          placeholder="Nome"
        />
        <MaskInput
          mask="999.999.999-99"
          name="cpf"
          icon={AiOutlineIdcard}
          type="text"
          placeholder="Cpf"
        />
        <Input
          name="cargo"
          maxLength={80}
          icon={FiUsers}
          type="text"
          placeholder="Cargo"
        />

        <Input
          name="email"
          maxLength={60}
          icon={FiMail}
          type="text"
          placeholder="Email"
        />

        <SelectInput
          loading
          options={OptionsOrg}
          isLoading={inputLoading}
          name="organizacaoId"
          placeholder="Selecione a empresa..."
        />

        <Button
          color="#802A00"
          text="Confirmar"
          type="submit"
          loading={loading}
        />
      </Form>
    </ModalComponent>
  );
}
