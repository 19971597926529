/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useState } from 'react';

import { AxiosResponse } from 'axios';
import toast from 'react-hot-toast';
import { useAuth } from '../../../hooks/Auth';
import {
  AllEvaluations,
  ClassProgress,
  CoursesProgress,
  Dashboard,
  RecommendationOrg,
  RecommendationTrailProfissional,
  RecommendationTrailProfissionalCompany,
  UserProfissionalProps,
} from '../../../interfaces/Entidades';
import { API } from '../../../services/api';

import CardRecommendedCompany from './components/cardRecommendedCompany';
import CardRecommendedTrail from './components/cardRecommendedtrail';
import Classification from './components/classification';
import ContinueAndTable from './components/continueAndTable';
import TableAssessment from './components/tableAssessment';

import CardRecommendedExclusive from './components/cardRecommendedtrailExclusive';
import './style.scss';

export default function HomeProfessional() {
  const { token, user } = useAuth();
  const [professional, setProfessional] = useState<UserProfissionalProps>(
    {} as UserProfissionalProps,
  );
  const [coursesProgress, setCoursesProgress] = useState<CoursesProgress[]>([]);
  const [evaluations, setEvaluations] = useState<AllEvaluations[]>([]);
  const [recommendationTrail, setRecommendationTrail] = useState<
    RecommendationTrailProfissional[]
  >([]);
  const [
    recommendationTrailProfessionalCompany,
    setRecommendationTrailProfessionalCompany,
  ] = useState<RecommendationTrailProfissionalCompany[]>([]);
  const [recommendationOrg, setRecommendationOrg] = useState<
    RecommendationOrg[]
  >([]);
  const [levelModalOpen, setLevelModalOpen] = useState(false);
  const [dashboard, setDashboard] = useState<Dashboard>({} as Dashboard);
  const [loading, setLoading] = useState(true);
  const [classProgress, setclassProgress] = useState<ClassProgress>(
    {} as ClassProgress,
  );

  const org = user.organizacao;

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      try {
        const promisesArray: Promise<AxiosResponse<any>>[] = [];
        const response_user = API(token).get(`/api/usuario/perfil/${user.Id}`);

        const response_classProgress = API(token).get(`/api/progresso/objetos`);

        const response_coursesProgress = API(token).get(
          `/api/progresso/cursos`,
        );

        const response_evaluations = API(token).get(
          `/api/resposta/odata?$expand=avaliacao($expand=unidadeAprendizagem)`,
        );

        const response_dashboard = API(token).get(`/api/usuario/dashboard`);

        const response_trilha = API(token).get(
          `/api/trilha/search?$filter=exclusiva eq false and ativa eq true&$select=id,nome,resumo,urlImagem,exclusiva,ativa`,
        );

        let response_trilha_exclusiva: any = { data: { value: [] } };

        if(org === '') {
          response_trilha_exclusiva = { data: { value: [] } };
        } else {
          response_trilha_exclusiva = API(token).get(
            `/api/trilha/search?$filter=exclusiva eq true and ativa eq true and organizacaoId eq ${org}&$select=id,nome,resumo,urlImagem,exclusiva,ativa`,
          );
        }


        const response_org = API(token).get(
          `/api/organizacao/search?$select=id, nomeFantasia, descricao,logotipo`,
        );

        promisesArray.push(
          response_user,
          response_classProgress,
          response_coursesProgress,
          response_evaluations,
          response_dashboard,
          response_trilha,
          response_org,
          response_trilha_exclusiva,
        );

        await Promise.all(promisesArray).then((results) => {
          setProfessional(results[0].data);
          setclassProgress(results[1].data[results[1].data.length - 1]);
          setCoursesProgress(results[2].data);
          setEvaluations(results[3].data.value);
          setDashboard(results[4].data);

          setRecommendationTrailProfessionalCompany(results[7].data.value);
          setRecommendationTrail(results[5].data.value);
          setRecommendationOrg(results[6].data.value);
        });

        setLoading(false);
      } catch (err) {
        toast.error(
          'Houve um problema ao carregar seus dados, tente novamente! 😢',
        );
      }
    }

    loadData();
  }, [token, user.Id, user.organizacao]);

  return (
    <>
      <div className="row">
        <Classification
          loading={loading}
          professional={professional}
          dashboard={dashboard}
          levelModalOpen={levelModalOpen}
          setLevelModalOpen={setLevelModalOpen}
        />
        <ContinueAndTable
          loading={loading}
          coursesProgress={coursesProgress}
          classProgress={classProgress}
        />
      </div>

      {user.organizacao !== '' ? (
        <div className="row">
          <div className="carrosel">
            <CardRecommendedExclusive
              loading={loading}
              recomendationTrailExclusive={
                recommendationTrailProfessionalCompany
              }
            />
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className="row">
        <div className="carrosel">
          <CardRecommendedTrail
            loading={loading}
            recommendationTrail={recommendationTrail}
          />
        </div>
      </div>

      <div className="row">
        <div className="carrosel">
          <CardRecommendedCompany
            loading={loading}
            recommendationOrg={recommendationOrg}
          />
        </div>
      </div>

      <div className="row">
        <TableAssessment loading={loading} evaluations={evaluations} />
      </div>
    </>
  );
}
