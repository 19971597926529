import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import CreateModal from './CreateModal';

import CategoriasTable from './CategoriaTable';
import { Button } from '../../../components/Button';

import { API } from '../../../services/api';
import { useAuth } from '../../../hooks/Auth';

interface CategoriaProps {
  id: number;
  descricao: string;
}

const CreateCategoriaPage = () => {
  const { token } = useAuth();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [categorias, setCategorias] = useState<CategoriaProps[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadCluster() {
      setLoading(true);
      setCategorias([]);
      try {
        const response = await API(token).get('/api/categoria/odata');

        setCategorias(response.data.value);
        setLoading(false);
      } catch (err) {
        toast.error(
          'Houve um problema ao listar as categorias, tente novamente! 😢',
        );
      }
    }

    loadCluster();
  }, [token]);

  return (
    <div className="cluster-table-container">
      <CreateModal
        setModalOpen={setCreateModalOpen}
        itens={categorias}
        setItens={setCategorias}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        closeButton={() => setCreateModalOpen(false)}
      />

      <div className="cluster-table-card">
        <div className="cluster-table-card-header">
          <h2 style={{ marginBottom: 30, color: '#802A00' }}>Categorias</h2>
          <Button
            color="#802A00"
            text="Adicionar categoria"
            onClick={() => setCreateModalOpen(true)}
          />
        </div>
        <CategoriasTable
          setCategorias={setCategorias}
          categorias={categorias}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default CreateCategoriaPage;
