import React, { InputHTMLAttributes, useEffect, useRef } from 'react';
import { useField } from '@unform/core';

import './style.scss';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  value?: string;
}

const SwitchInputAdmin = ({
  name,
  value,
  ...props
}: InputProps): JSX.Element => {
  const InputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const defaultChecked = defaultValue === value;

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: InputRef,
      getValue: (ref) => ref.current.checked,
      clearValue: (ref) => {
        ref.current.checked = defaultChecked;
      },
      setValue: (ref, Value) => {
        ref.current.checked = Value;
      },
    });
  }, [defaultValue, fieldName, registerField, defaultChecked]);

  return (
    <label className="SwitchInputAdmin">
      <input type="checkbox" ref={InputRef} {...props} />
      <span className="sliderRoundAdmin" />
    </label>
  );
};

export default SwitchInputAdmin;
