import React, { useCallback, useRef, useState, useEffect } from 'react';
import { Form } from '@unform/web';
import { FiUser } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import toast from 'react-hot-toast';

import { OptionTypeBase } from 'react-select';
import * as Yup from 'yup';

import { API } from '../../../../../services/api';
import SelectInput from '../../../../components/SelectInput';
import GetValidationErrors from '../../../../../utils/getValidationErros';
import { useAuth } from '../../../../../hooks/Auth';
import Input from '../../../../components/Input';
import { Button } from '../../../../../components/Button';
import ModalComponent from '../../../../../components/Modal/ModalComponent';
import { EditModalProps } from '../../../../../interfaces/components';
import TextAreaInput from '../../../../components/TextAreaInput';
import { DesafioProps } from '../../../../../interfaces/Entidades';

interface EditDesafioModal extends EditModalProps {
  pontuacaoDesafio: number;
}

export default function EditDesafioModal({
  closeButton,
  item,
  open,
  setModalOpen,
  onClose,
  pontuacaoDesafio,
  id,
}: EditDesafioModal) {
  const { token } = useAuth();
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const [inputLoading, setInputLoading] = useState(false);
  const [Options, setOptions] = useState<OptionTypeBase[]>();

  const Submit = useCallback(
    async (data: DesafioProps) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          titulo: Yup.string().required('Campo Obrigatório'),
          urlVideo: Yup.string(),
          conteudo: Yup.string().required('Campo Obrigatório'),
          pontuacao: Yup.number(),
        });

        data.id = id;
        data.ordem = item.ordem;
        data.tipoVideo = item.tipoVideo;
        data.unidadeAprendizagemId = item.unidadeAprendizagemId;

        await schema.validate(data, {
          abortEarly: false,
        });

        await API(token).put<DesafioProps>('/api/desafio', data);

        setLoading(false);

        setModalOpen(false);

        toast.success('Desafio Alterado com sucesso!');
        await setTimeout(() => {
          window.location.reload();
        }, 1000);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = GetValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          toast.error('Houve um erro ao criar Desafio, tente novamente!');
        }

        setLoading(false);
      }
    },

    [token, id],
  );

  return (
    <ModalComponent closeButton={closeButton} open={open} onClose={onClose}>
      <Form ref={formRef} placeholder="" className="createmodal-form-admin" onSubmit={Submit}>
        <h2 id="simple-modal-title">Editar Desafio</h2>

        <Input
          name="titulo"
          maxLength={80}
          loading={inputLoading}
          defaultValue={item ? item.titulo : ''}
          type="text"
          placeholder={inputLoading ? 'Carregando...' : 'Titulo do Desafio'}
        />

        <TextAreaInput
          name="conteudo"
          loading={inputLoading}
          defaultValue={item ? item.conteudo : ''}
          maxLength={300}
          placeholder={inputLoading ? 'Carregando...' : 'Descrição do Desafio'}
          style={{ marginBottom: 10 }}
        />

        <Input
          type="number"
          name="pontuacao"
          min={0}
          max={pontuacaoDesafio}
          loading={inputLoading}
          defaultValue={item ? item.pontuacao : ''}
          placeholder={inputLoading ? 'Carregando...' : 'Pontuação'}
        />

        <Button
          color="#6F95FF"
          text="Confirmar"
          type="submit"
          loading={loading}
        />
      </Form>
    </ModalComponent>
  );
}
