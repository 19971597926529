import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Button } from '../../../components/Button';
import { useAuth } from '../../../hooks/Auth';
import { AdminUserCompanyProps } from '../../../interfaces/Entidades';
import { API } from '../../../services/api';
import ColaboradoresTable from './ColaboradesTable';
import CreateColaboradorModal from './components/CreateModal';

const ColaboradoresPage = () => {
  const { token } = useAuth();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [colaboradores, setColaboradores] = useState<AdminUserCompanyProps[]>(
    [],
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadCompany() {
      setLoading(true);
      setColaboradores([]);
      try {
        const response = await API(token).get(
          '/api/colaborador/odata?$filter=administrador eq false',
        );

        setColaboradores(response.data.value);
        setLoading(false);
      } catch (err) {
        toast.error(
          'Houve um problema ao listar os Colaboradores, tente novamente!',
        );
      }
    }

    loadCompany();
  }, [token]);

  return (
    <div className="cluster-table-container">
      <CreateColaboradorModal
        setModalOpen={setCreateModalOpen}
        itens={colaboradores}
        open={createModalOpen}
        setItens={setColaboradores}
        onClose={() => setCreateModalOpen(false)}
        closeButton={() => setCreateModalOpen(false)}
      />

      <div className="cluster-table-card">
        <div className="cluster-table-card-header">
          <h2 style={{ marginBottom: 30, color: '#000' }}>Colaboradores Administradores</h2>
          <Button
            color="#6F95FF"
            text="Adicionar Colaborador"
            onClick={() => setCreateModalOpen(true)}
          />
        </div>

        <ColaboradoresTable
          loading={loading}
          users={colaboradores}
          setUsers={setColaboradores}
        />
      </div>
    </div>
  );
};

export default ColaboradoresPage;
