import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { BackHeaderComponent } from '../../../components/BackHeader';
import { TrailProps } from '../../../interfaces/Entidades';
import NoImage from '../../assets/images/no-image.png';

import { Error404 } from '../../../ErrorPages/404';

interface ListCourseTrail {
  trilha: TrailProps;
}

export default function ListCourseTrail() {
  const location = useLocation();
  const [trail, setTrail] = useState<TrailProps>({} as TrailProps);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    function getData() {
      try {
        const { trilha } = location.state as ListCourseTrail;
        setTrail(trilha);
        setIsLoading(false);
      } catch {
        setIsLoading(true);
      }
    }

    getData();
  }, [location.state]);

  if (isLoading) {
    return <Error404 />;
  }

  return (
    <>
      <div className="row">
        <BackHeaderComponent />
      </div>
      <div className="row">
        <div style={{ width: '100%', placeItems: 'center' }}>
          <h1 style={{ textAlign: 'center' }}>Cursos - {trail.nome}</h1>
        </div>
        {trail.cursos.map((course) => (
          <div className="col-xl-12">
            <div className="card card-custom card-stretch gutter-b">
              <div
                className="card-header border-0 py-5"
                style={{ marginBottom: -25, justifyContent: 'flex-start' }}
              >
                <img
                  src={
                    course.curso.urlImagem ? course.curso.urlImagem : NoImage
                  }
                  alt="Logo do Curso"
                  style={{
                    width: '96px',
                    height: '100px',
                    objectFit: 'cover',
                    borderRadius: 6,
                  }}
                />
                <div style={{ marginLeft: 10 }}>
                  <Link
                    to={{
                      pathname: `/learningUnit/${course.curso.id}`,
                      state: { trilha: trail.nome },
                    }}
                    style={{ display: 'block' }}
                    className="text-dark font-weight-bolder text-hover-primary font-size-h4 line-clamp-title"
                  >
                    {course.curso.nome}
                  </Link>

                  {/* <Rating name="disabled" value={3} disabled />
                  <p className="text-dark-75 font-size-sm font-size-base m-0 text-muted">
                      (5.000 classificação) 10.000 alunos inscritos
                    </p> */}

                  <p className="text-dark-75 font-size-sm  font-size-base m-0 text-muted">
                    Criado por{' '}
                    <Link
                      to={{
                        pathname: `/details_company/${trail.organizacaoId}`,
                      }}
                      className="font-weight-bolder"
                      style={{ color: '#005f6b' }}
                    >
                      {trail.organizacao.razaoSocial}
                    </Link>
                  </p>
                  <p className="text-dark-75 font-size-sm  font-size-base m-0 text-muted">
                    {trail.nome}
                    {/* <Link
                      to={{
                        pathname: `/details_company/${trail.organizacaoId}`,
                      }}
                      className="font-weight-bolder"
                      style={{ color: '#005f6b' }}
                    >
                      {trail.organizacao.razaoSocial}
                    </Link> */}
                  </p>
                </div>
              </div>

              <div className="card-body d-flex flex-column">
                <p className="text-dark-50 font-weight-normal font-size-lg line-clamp">
                  {course.curso.descricao}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
