/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Pagination } from '@material-ui/lab';
import React, {
  useState,
  useEffect,
  BaseSyntheticEvent,
  useCallback,
} from 'react';
import toast from 'react-hot-toast';
import { Link, useParams, useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import { Tooltip } from '@material-ui/core';
import { FiTrash } from 'react-icons/fi';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import AvatarImg from '../../../assets/images/no-image.png';
import { useAuth } from '../../../hooks/Auth';
import { API } from '../../../services/api';
import DetailsPageComponent from '../../components/DetailsPage';
import CreateCursoModal from './components/CreateCursoModal';
import EditTrailModal from './components/EditTrailModal';
import AddCursoModal from './components/AddCursoModal';
import {
  CursoProps,
  TrailProps,
  TrilhaInscricoes,
} from '../../../interfaces/Entidades';
import DeleteModal from '../../../components/Modal/DeleteModal';
import RemoverCursoModal from './components/RemoveCursoModal';

interface StateProps {
  id: number;
}

const BlueTooltip = withStyles({
  tooltip: {
    fontSize: 12,
    color: '#fff',
    backgroundColor: '#6F95FF',
  },
})(Tooltip);

const TrailsDetailPage = () => {
  const routeParams = useParams();
  const history = useHistory();
  const { token } = useAuth();
  const [trail, setTrail] = useState<TrailProps>({} as TrailProps);
  const [cursos, setCursos] = useState<CursoProps[]>([]);
  const [filteredCursos, setFilteredCursos] = useState<CursoProps[]>([]);
  const [loading, setLoading] = useState(false);
  const [createCursoModalOpen, setCreateCursoModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [removerCursoModalOpen, setRemoverCursoModalOpen] = useState(false);
  const [removerCursoId, setRemoverCursoId] = useState(0);
  const [addCursoModalOpen, setAddCursoModalOpen] = useState(false);
  const [editTrailModalOpen, setEditTrailModalOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [formados, setFormados] = useState(0);
  const [inscritos, setInscritos] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(4);

  const handleFilter = useCallback(
    (text: string) => {
      const filterCursos = cursos.filter((curso) =>
        curso.nome.toUpperCase().includes(text.toUpperCase()),
      );
      if (text === '') {
        setFilteredCursos(cursos);
      } else if (filterCursos.length > 0) {
        setFilteredCursos(filterCursos);
      } else {
        setFilteredCursos([]);
      }

      const pagesCount = Math.ceil(filterCursos.length / rowsPerPage);

      setPageCount(pagesCount);
      if (pagesCount <= 1) {
        setPage(0);
      }
    },
    [rowsPerPage, cursos],
  );

  useEffect(() => {
    function setNewCursos() {
      setFilteredCursos(cursos);
      setPageCount(Math.ceil(cursos.length / rowsPerPage));
    }

    setNewCursos();
  }, [cursos, rowsPerPage]);

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      setTrail({} as TrailProps);
      setFilteredCursos([]);
      try {
        const { id } = routeParams as StateProps;
        const response = await API(token).get<TrailProps>(`/api/trilha/${id}`);
        const response_dados = await API(token).get(
          `/api/trilha/inscricoes?$filter=trilhaId eq ${id}&$count=true&$select=progresso,finalizada`,
        );

        let formadoss = 0;

        // eslint-disable-next-line no-underscore-dangle
        const inscritoss = response_dados.data._count;

        const response_format = response_dados.data.value as TrilhaInscricoes[];

        response_format.forEach((item) => {
          if (item.finalizada) {
            formadoss++;
          }
        });
        setFormados(formadoss);
        setInscritos(inscritoss);
        setTrail(response.data);
        const cursosToSet = response.data.cursos.map((curso) => curso.curso);
        setCursos(cursosToSet);
        setFilteredCursos(cursosToSet);
        setPageCount(Math.ceil(response.data.cursos.length / rowsPerPage));
        setLoading(false);
      } catch (err) {
        toast.error(
          'Houve um erro ao carregar os dados da trilha, tente novamente! 😢',
        );
      }
    }

    loadData();
  }, [token, rowsPerPage, routeParams]);

  return (
    <>
      <CreateCursoModal
        open={createCursoModalOpen}
        itens={cursos}
        setItens={setCursos}
        setModalOpen={setCreateCursoModalOpen}
        trailId={trail.id}
        onClose={() => setCreateCursoModalOpen(false)}
        closeButton={() => setCreateCursoModalOpen(false)}
      />

      <AddCursoModal
        setModalOpen={setAddCursoModalOpen}
        open={addCursoModalOpen}
        setItens={setCursos}
        itens={cursos}
        trailId={trail.id}
        onClose={() => setAddCursoModalOpen(false)}
        closeButton={() => setAddCursoModalOpen(false)}
      />

      <DeleteModal
        open={deleteModalOpen}
        id={trail.id}
        color="#6F95FF"
        name="a trilha"
        routeName="trilha"
        onClose={() => setDeleteModalOpen(false)}
        closeButton={() => setDeleteModalOpen(false)}
      />

      <RemoverCursoModal
        open={removerCursoModalOpen}
        cursoId={removerCursoId}
        trilhaId={trail.id}
        itens={cursos}
        setItens={setCursos}
        onClose={() => setRemoverCursoModalOpen(false)}
        closeButton={() => setRemoverCursoModalOpen(false)}
      />

      <EditTrailModal
        id={trail.id}
        open={editTrailModalOpen}
        item={trail}
        setModalOpen={setEditTrailModalOpen}
        onClose={() => setEditTrailModalOpen(false)}
        closeButton={() => setEditTrailModalOpen(false)}
      />

        <Breadcrumbs aria-label="breadcrumb">
        <div color="inherit">
          Listagem de Trilhas
        </div>

        <Typography color="textPrimary">
          Cursos da Trilha
        </Typography>

       </Breadcrumbs>

      <DetailsPageComponent
        InputOnChange={(event: BaseSyntheticEvent) =>
          handleFilter(event.target.value)
        }
        buttonHeaderText="Opções"
        hasCard
        cardHasButtons
        hasMenuButton
        menuButtonOptions={[
          { option: 'Criar novo curso', setModalOpen: setCreateCursoModalOpen },
          {
            option: 'Adicionar curso já existente',
            setModalOpen: setAddCursoModalOpen,
          },
          {
            option: 'Visualizar profissionais inscritos',
            action: () => history.push(`/details_trail/${trail.id}`),
          },
        ]}
        cardNome={trail.nome}
        cardImage={trail.urlImagem}
        setCreateModalOpen={setCreateCursoModalOpen}
        cardFormados={formados}
        cardInscritos={inscritos}
        loading={loading}
        headerBack
        headerTitle="Trilha"
        setEditModalOpen={setEditTrailModalOpen}
        setDeleteModalOpen={setDeleteModalOpen}
        cardEditButtonText="Editar Trilha"
        cardDeleteButtonText="Excluir Trilha"

      >
        <div style={{ width: '100%', marginTop: -10 }}>
          <h4 style={{ textAlign: 'center' }}>
            <span className="text-muted" style={{ fontSize: '16px' }}>
              Cursos da Trilha:{' '}
            </span>
            {trail.nome}
          </h4>
        </div>
        {filteredCursos.length > 0 ? (
          filteredCursos
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((curso) => (
              <div key={curso.id} className="admin-trails-card">
                <div className="admin-trails-card-header">
                  <div>
                    <img
                      src={curso.urlImagem ? curso.urlImagem : AvatarImg}
                      alt=""
                    />
                  </div>
                  <div
                    style={{
                      padding: 10,
                    }}
                  >
                    <h2>{curso.nome}</h2>
                  </div>
                </div>

                <div className="admin-trails-card-descriptions">
                  <span>{curso.descricao}</span>
                </div>

                <div className="admin-trails-card-footer">
                  <BlueTooltip
                    title="Remover curso da Trilha"
                    placement="bottom"
                  >
                    <div>
                      <FiTrash
                        size={20}
                        color="#6f95ff"
                        onClick={() => {
                          setRemoverCursoId(curso.id);
                          setRemoverCursoModalOpen(true);
                        }}
                        style={{ cursor: 'pointer', marginRight: 15 }}
                      />
                    </div>
                  </BlueTooltip>
                  <Link to={`/course/${curso.id}`}>
                    <span>Detalhes</span>
                  </Link>
                </div>
              </div>
            ))
        ) : (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <h2>Não foram encontrados itens</h2>
          </div>
        )}
        <div className="admin-trail-footer">
          <Pagination
            count={pageCount}
            page={page + 1}
            hideNextButton={pageCount < 2}
            hidePrevButton={pageCount < 2}
            onChange={(event: object, page_select: number) => {
              setPage(page_select - 1);
            }}
          />
        </div>
      </DetailsPageComponent>
    </>
  );
};

export default TrailsDetailPage;
