import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import CreateModal from './CreateModal';

import CompanyTable from './CompanyTable';
import { Button } from '../../../components/Button';
import { CompanyProps } from '../../../interfaces/Entidades';
import { API } from '../../../services/api';
import { useAuth } from '../../../hooks/Auth';

const CompanyClusterPage = () => {
  const { token, user } = useAuth();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [empresas, setEmpresas] = useState<CompanyProps[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadCompany() {
      setLoading(true);
      setEmpresas([]);
      try {
        const response = await API(token).get(
          `/api/organizacao/odata?$filter=clusterId eq ${user.cluster}`,
        );

        setEmpresas(response.data.value);
        setLoading(false);
      } catch (err) {
        toast.error(
          'Houve um problema ao listar os Empresas, tente novamente! 😢',
        );
      }
    }

    loadCompany();
  }, [token, user.cluster]);

  return (
    <div className="cluster-table-container">
      <CreateModal
        itens={empresas}
        setItens={setEmpresas}
        setModalOpen={setCreateModalOpen}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        closeButton={() => setCreateModalOpen(false)}
      />

      <div className="cluster-table-card">
        <div className="cluster-table-card-header">
          <h2 style={{ marginBottom: 30, color: '#7A9940' }}>Organização</h2>
          <Button
            color="#7A9940"
            text="Adicionar Organização"
            onClick={() => setCreateModalOpen(true)}
          />
        </div>
        <CompanyTable
          empresas={empresas}
          setEmpresas={setEmpresas}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default CompanyClusterPage;
