/* eslint-disable jsx-a11y/label-has-associated-control */
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { Link } from 'react-router-dom';
import { CompanyProps } from '../../../../../interfaces/Entidades';

interface cardCompanyProps {
  empresas: CompanyProps[];
  loading: boolean;
}

export function CardSearchCompany({ empresas, loading }: cardCompanyProps) {
  return (
    <>
      <div className="continuar">
        {loading && (
          <div className="card card-custom card-stretch gutter-b">
            {/* begin::Header */}
            <div className="card-body">
              <div className="font-weight-bolder font-size-h5 color">
                <Skeleton variant="text" animation="wave" />
              </div>
              <div className="font-weight-bolder text-muted mt-2">
                <Skeleton variant="text" animation="wave" />
              </div>
              <p className="text-dark-75 font-size-sm  font-size-base m-0 text-muted line-clamp">
                <Skeleton variant="text" animation="wave" />
              </p>
            </div>
            {/* end::Body */}
          </div>
        )}
        {!loading &&
          empresas.length > 0 &&
          empresas.map((empresa) => (
            <div
              key={empresa.id}
              className="card card-custom card-stretch gutter-b"
              style={{ height: '66%' }}
            >
              <div className="card-body">
                <div className="font-weight-bolder font-size-h5 color">
                  {empresa.razaoSocial}
                </div>
                <div className="font-weight-bolder text-muted mt-2">
                  {empresa.cluster.descricao}
                </div>
                <div className="font-weight-bolder text-muted mt-2">
                  {empresa.cidade} - {empresa.uf}
                </div>
                <p className="text-dark-75 font-size-sm  font-size-base m-0 text-muted line-clamp">
                  {empresa.descricao}
                </p>

                <Link
                  to={{ pathname: `/details_company/${empresa.id}` }}
                  className="btn btn-primary font-weight-bolder mt-2 px-8"
                >
                  Saiba Mais
                </Link>
              </div>
            </div>
          ))}
        {!loading && empresas.length <= 0 && (
          <div className="card card-custom card-stretch gutter-b">
            {/* begin::Header */}
            <div className="card-body">
              <div className="font-weight-bolder font-size-h5 color">
                Nenhuma empresa econtrada
              </div>
            </div>
            {/* end::Body */}
          </div>
        )}
      </div>
    </>
  );
}
