import {
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { ObjetosAprendizagemProps } from '../../../../../interfaces/Entidades';
import './styles.scss';

interface PlaylistProps {
  titleModule: string;
  classes: ObjetosAprendizagemProps[];
  completedClasses: number[];
  activeClassId: number;
}

export default function PlaylistClass({
  titleModule,
  classes,
  completedClasses,
  activeClassId,
}: PlaylistProps) {
  const history = useHistory();

  const findActiveClass = useCallback(() => {
    const activeClass = classes.findIndex((aula) => aula.id === activeClassId);

    return activeClass;
  }, [activeClassId, classes]);

  return (
    <div className="col-xl-4">
      <div className="card card-custom card-stretch gutter-b">
        <div className="Container_Playlist">
          <div className="DetailsModule">
            <div>
              <span>{titleModule}</span>
              <p style={{ textAlign: 'center' }}>
                {classes?.length === 1
                  ? `${classes?.length} aula`
                  : `${classes?.length} aulas`}
              </p>
            </div>
          </div>

          <div className="DetailsClass">
            <Stepper
              activeStep={findActiveClass()}
              nonLinear
              orientation="vertical"
            >
              {classes &&
                classes.map((aula, index) => (
                  <Step
                    onClick={() => history.push(`/watchClass/${aula.id}`)}
                    completed={completedClasses.includes(aula.id)}
                    key={aula.id}
                  >
                    <StepLabel className="WatchClassLabel">
                      {aula.titulo}
                    </StepLabel>

                    <StepContent />
                  </Step>
                ))}
            </Stepper>
          </div>
        </div>
      </div>
    </div>
  );
}
