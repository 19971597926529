/* eslint-disable no-param-reassign */
import React, { ChangeEvent, useCallback, useRef, useState } from 'react';
import { Form } from '@unform/web';
import {
  FiCamera,
  FiImage,
  FiLock,
  FiMail,
  FiUser,
  FiUsers,
} from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { AiOutlineIdcard } from 'react-icons/ai';
import { cpf } from 'cpf-cnpj-validator';
import AvatarImg from '../../../../../assets/images/no-image.png';
import { useAuth } from '../../../../../hooks/Auth';
import Input from '../../../../components/Input';
import { API } from '../../../../../services/api';
import GetValidationErrors from '../../../../../utils/getValidationErros';
import MaskInput from '../../../../components/MaskInput';
import { EditModalProps } from '../../../../../interfaces/components';
import ModalComponent from '../../../../../components/Modal/ModalComponent';
import { Button } from '../../../../../components/Button';

export default function EditColaboradorModal({
  onClose,
  closeButton,
  item,
  id,
  open,
  setModalOpen,
}: EditModalProps) {
  const { token } = useAuth();
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const avatarRef = useRef<HTMLImageElement>(null);
  const fileRef = useRef<HTMLInputElement>(null);

  const Submit = useCallback(
    async (data) => {
      setLoading(true);

      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          nome: Yup.string().required('Campo Obrigatório'),
          email: Yup.string().required('Campo Obrigatório').email(),
          cpf: Yup.string()
            .required('Campo obrigatório')
            .test('validacao-cpf', 'CPF Inválido', async (Cpf) => {
              if (Cpf === undefined) {
                return false;
              }
              const validation = cpf.isValid(Cpf);
              if (validation) {
                return true;
              }
              return false;
            }),
          cargo: Yup.string().required('Campo Obrigatório'),
          foto: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        data.foto = item.foto;

        if (
          fileRef.current &&
          fileRef.current.files &&
          fileRef.current.files.length > 0
        ) {
          const formData = new FormData();
          formData.append('avatar', fileRef.current.files[0]);

          const upload_response = await API(token).post(
            '/api/trilha/UploadFiles/avatar',
            formData,
          );

          data.foto = upload_response.data.avatar;
        }

        data.id = id;
        data.administrador = false;

        await API(token).put('/api/usuario/colaborador', data);
        setLoading(false);
        await setTimeout(() => {
          window.location.reload();
        }, 1000);
        toast.success('Colaborador alterado com sucesso!');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = GetValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          toast.error(
            'Houve um erro ao alterar o colaborador, tente novamente!',
          );
        }

        setLoading(false);
      }
    },

    [token, id, item.foto],
  );

  const handleAvatarChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      if (avatarRef.current) {
        avatarRef.current.src = URL.createObjectURL(e.target.files[0]);
      }
    }
  }, []);

  return (
    <ModalComponent closeButton={closeButton} open={open} onClose={onClose}>
      <Form ref={formRef} placeholder="" className="createmodal-form-admin" onSubmit={Submit}>
        <h2 id="simple-modal-title">Editar colaborador</h2>

        <div className="AvatarInput">
          <img ref={avatarRef} src={item.foto || AvatarImg} alt="" />
          <label htmlFor="avatar">
            <FiCamera />

            <input
              type="file"
              id="avatar"
              ref={fileRef}
              name="urlImagem"
              accept="image/*"
              onChange={(event) => handleAvatarChange(event)}
            />
          </label>
        </div>

        <Input
          type="text"
          maxLength={80}
          name="nome"
          defaultValue={item.nome}
          icon={FiUser}
          placeholder="Nome"
        />

        <Input
          type="text"
          maxLength={80}
          name="email"
          defaultValue={item.email}
          icon={FiMail}
          placeholder="Email"
        />

        <Input
          type="text"
          maxLength={80}
          name="cargo"
          defaultValue={item.cargo}
          icon={FiUsers}
          placeholder="Cargo"
        />

        <MaskInput
          mask="999.999.999-99"
          type="text"
          name="cpf"
          defaultValue={item.cpf}
          placeholder="Cpf"
          icon={AiOutlineIdcard}
        />

        <Button
          color="#6F95FF"
          text="Confirmar"
          type="submit"
          loading={loading}
        />
      </Form>
    </ModalComponent>
  );
}
