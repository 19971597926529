/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import { Skeleton } from '@material-ui/lab';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import {
  CoursesProgress,
  UnidadeAprendizagemProps,
  MyDesafio,
  UserProfissionalProps,
  UnidadesCompletas,
  RespostaDesafioProps,
} from '../../../../interfaces/Entidades';

import AvatarImg from '../../../../assets/images/no-image.png';
import { Button } from '../../../components/Button';

interface CardInfo {
  cursoProgress: CoursesProgress;
  loading: boolean;
  hasEvaluation: boolean;
  modulos: UnidadeAprendizagemProps[];
  moduleId: number;
  desafios: RespostaDesafioProps[];
  profissional: UserProfissionalProps;
}
export default function CardInfo({
  loading,
  cursoProgress,
  hasEvaluation,
  modulos,
  moduleId,
  desafios,
  profissional,
}: CardInfo) {
  const history = useHistory();

  function qtdeModulos(unidadesCompletas: UnidadesCompletas[]) {
    let i = 0;
    unidadesCompletas.forEach((unidade) => {
      if (unidade.concluida) {
        i += 1;
      }
    });

    return i;
  }

  return (
    <>
      <div className="col-md-4" style={{ height: '100%' }}>
        <div className="card card-custom gutter-b">
          <div className="card-body">
            <div className="d-flex justify-content-between flex-column pt-4 h-100">
              <div className="pb-5">
                <div className="d-flex flex-column flex-center">
                  {loading ? (
                    <Skeleton
                      variant="rect"
                      width={120}
                      height={120}
                      style={{ borderRadius: 100 }}
                    />
                  ) : (
                    <div className="symbol symbol-120 symbol-circle symbol-success overflow-hidden">
                      <span className="symbol-label">
                        <div className="h-75 align-self-end">
                          <img
                            src={
                              profissional.foto ? profissional.foto : AvatarImg
                            }
                            alt=""
                            style={{
                              width: '120px',
                              height: '120px',
                              marginTop: '-30px',
                              borderRadius: '100px',
                              objectFit: 'cover',
                            }}
                          />
                        </div>
                      </span>
                    </div>
                  )}
                  {loading ? (
                    <Skeleton variant="text" width="0" height={0} />
                  ) : (
                    <label className="font-size-h3 font-weight-bolder text-dark mb-7 pt-4">
                      {profissional.nome}
                    </label>
                  )}
                </div>

                <div className="pt-1">
                  <div className="d-flex align-items-center pb-9">
                    <div className="symbol symbol-45 symbol-light mr-4">
                      {loading ? (
                        <Skeleton
                          variant="text"
                          width={45}
                          height={70}
                          style={{ borderRadius: 6 }}
                        />
                      ) : (
                        <span className="symbol-label">
                          <span className="svg-icon svg-icon-2x svg-icon-dark-50">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            >
                              <g
                                stroke="none"
                                strokeWidth={1}
                                fill="none"
                                fillRule="evenodd"
                              >
                                <rect x={0} y={0} width={24} height={24} />
                                <rect
                                  fill="#000000"
                                  opacity="0.3"
                                  x={13}
                                  y={4}
                                  width={3}
                                  height={16}
                                  rx="1.5"
                                />
                                <rect
                                  fill="#000000"
                                  x={8}
                                  y={9}
                                  width={3}
                                  height={11}
                                  rx="1.5"
                                />
                                <rect
                                  fill="#000000"
                                  x={18}
                                  y={11}
                                  width={3}
                                  height={9}
                                  rx="1.5"
                                />
                                <rect
                                  fill="#000000"
                                  x={3}
                                  y={13}
                                  width={3}
                                  height={7}
                                  rx="1.5"
                                />
                              </g>
                            </svg>
                          </span>
                        </span>
                      )}
                    </div>
                    {loading ? (
                      <Skeleton variant="text" width="100%" height={70} />
                    ) : (
                      <div className="d-flex flex-column flex-grow-1">
                        <span className="text-dark-75 text-hover-primary mb-1 font-size-lg font-weight-bolder">
                          Inicio do Curso
                        </span>
                      </div>
                    )}

                    {loading ? (
                      <Skeleton variant="text" width="0%" height={0} />
                    ) : (
                      <span
                        className="font-weight-bolder label label-xl  label-inline px-3 py-5 min-w-45px"
                        style={{ backgroundColor: '#f3f6f9', color: '#7e8299' }}
                      >
                        {cursoProgress
                          ? format(
                              Date.parse(cursoProgress.createdAt),
                              'dd/MM/yyyy',
                            )
                          : 'Curso não iniciado'}
                      </span>
                    )}
                  </div>
                  <div className="d-flex align-items-center pb-9">
                    <div className="symbol symbol-45 symbol-light mr-4">
                      {loading ? (
                        <Skeleton
                          variant="text"
                          width={45}
                          height={70}
                          style={{ borderRadius: 6, marginTop: -20 }}
                        />
                      ) : (
                        <span className="symbol-label">
                          <span className="svg-icon svg-icon-2x svg-icon-dark-50">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            >
                              <g
                                stroke="none"
                                strokeWidth={1}
                                fill="none"
                                fillRule="evenodd"
                              >
                                <rect x={0} y={0} width={24} height={24} />
                                <rect
                                  fill="#000000"
                                  x={4}
                                  y={4}
                                  width={7}
                                  height={7}
                                  rx="1.5"
                                />
                                <path
                                  d="M5.5,13 L9.5,13 C10.3284271,13 11,13.6715729 11,14.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,14.5 C4,13.6715729 4.67157288,13 5.5,13 Z M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,9.5 C20,10.3284271 19.3284271,11 18.5,11 L14.5,11 C13.6715729,11 13,10.3284271 13,9.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z M14.5,13 L18.5,13 C19.3284271,13 20,13.6715729 20,14.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,14.5 C13,13.6715729 13.6715729,13 14.5,13 Z"
                                  fill="#000000"
                                  opacity="0.3"
                                />
                              </g>
                            </svg>
                          </span>
                        </span>
                      )}
                    </div>

                    {loading ? (
                      <Skeleton
                        variant="text"
                        width="100%"
                        height={70}
                        style={{ marginTop: -20 }}
                      />
                    ) : (
                      <div className="d-flex flex-column flex-grow-1">
                        <span className="text-dark-75 text-hover-primary mb-1 font-size-lg font-weight-bolder">
                          Módulos Concluídos
                        </span>
                      </div>
                    )}

                    {loading ? (
                      <Skeleton variant="text" width="0%" height={0} />
                    ) : (
                      <span
                        className="font-weight-bolder label label-xl  label-inline px-3 py-5 min-w-45px"
                        style={{ backgroundColor: '#f3f6f9', color: '#7e8299' }}
                      >
                        {cursoProgress
                          ? `${qtdeModulos(cursoProgress.unidades)} de ${
                              modulos.length
                            }`
                          : `0 de ${modulos.length}`}
                      </span>
                    )}
                  </div>
                  <div className="d-flex align-items-center pb-9">
                    <div className="symbol symbol-45 symbol-light mr-4">
                      {loading ? (
                        <Skeleton
                          variant="text"
                          width={45}
                          height={70}
                          style={{ borderRadius: 6, marginTop: -20 }}
                        />
                      ) : (
                        <span className="symbol-label">
                          <span className="svg-icon svg-icon-2x svg-icon-dark-50">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            >
                              <g
                                stroke="none"
                                strokeWidth={1}
                                fill="none"
                                fillRule="evenodd"
                              >
                                <rect x={0} y={0} width={24} height={24} />
                                <path
                                  d="M13,18.9450712 L13,20 L14,20 C15.1045695,20 16,20.8954305 16,22 L8,22 C8,20.8954305 8.8954305,20 10,20 L11,20 L11,18.9448245 C9.02872877,18.7261967 7.20827378,17.866394 5.79372555,16.5182701 L4.73856106,17.6741866 C4.36621808,18.0820826 3.73370941,18.110904 3.32581341,17.7385611 C2.9179174,17.3662181 2.88909597,16.7337094 3.26143894,16.3258134 L5.04940685,14.367122 C5.46150313,13.9156769 6.17860937,13.9363085 6.56406875,14.4106998 C7.88623094,16.037907 9.86320756,17 12,17 C15.8659932,17 19,13.8659932 19,10 C19,7.73468744 17.9175842,5.65198725 16.1214335,4.34123851 C15.6753081,4.01567657 15.5775721,3.39010038 15.903134,2.94397499 C16.228696,2.49784959 16.8542722,2.4001136 17.3003976,2.72567554 C19.6071362,4.40902808 21,7.08906798 21,10 C21,14.6325537 17.4999505,18.4476269 13,18.9450712 Z"
                                  fill="#000000"
                                  fillRule="nonzero"
                                />
                                <circle
                                  fill="#000000"
                                  opacity="0.3"
                                  cx={12}
                                  cy={10}
                                  r={6}
                                />
                              </g>
                            </svg>
                          </span>
                        </span>
                      )}
                    </div>

                    {loading ? (
                      <Skeleton
                        variant="text"
                        width="100%"
                        height={70}
                        style={{ marginTop: -20 }}
                      />
                    ) : (
                      <div className="d-flex flex-column flex-grow-1">
                        <span className="text-dark-75 text-hover-primary mb-1 font-size-lg font-weight-bolder">
                          Desafios Concluídos
                        </span>
                      </div>
                    )}

                    {loading ? (
                      <Skeleton variant="text" width="0%" height={0} />
                    ) : (
                      <span
                        className="font-weight-bolder label label-xl  label-inline py-5 min-w-45px"
                        style={{ backgroundColor: '#f3f6f9', color: '#7e8299' }}
                      >
                        {desafios.length}
                      </span>
                    )}
                  </div>
                  <div className="d-flex align-items-center pb-9">
                    <div className="symbol symbol-45 symbol-light mr-4">
                      {loading ? (
                        <Skeleton
                          variant="text"
                          width={45}
                          height={70}
                          style={{ borderRadius: 6, marginTop: -20 }}
                        />
                      ) : (
                        <span className="symbol-label">
                          <span className="svg-icon svg-icon-2x svg-icon-dark-50">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            >
                              <g
                                stroke="none"
                                strokeWidth={1}
                                fill="none"
                                fillRule="evenodd"
                              >
                                <rect x={0} y={0} width={24} height={24} />
                                <rect
                                  fill="#000000"
                                  x={4}
                                  y={4}
                                  width={7}
                                  height={7}
                                  rx="1.5"
                                />
                                <path
                                  d="M5.5,13 L9.5,13 C10.3284271,13 11,13.6715729 11,14.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,14.5 C4,13.6715729 4.67157288,13 5.5,13 Z M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,9.5 C20,10.3284271 19.3284271,11 18.5,11 L14.5,11 C13.6715729,11 13,10.3284271 13,9.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z M14.5,13 L18.5,13 C19.3284271,13 20,13.6715729 20,14.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,14.5 C13,13.6715729 13.6715729,13 14.5,13 Z"
                                  fill="#000000"
                                  opacity="0.3"
                                />
                              </g>
                            </svg>
                          </span>
                        </span>
                      )}
                    </div>

                    {loading ? (
                      <Skeleton
                        variant="text"
                        width="100%"
                        height={70}
                        style={{ marginTop: -20 }}
                      />
                    ) : (
                      <div className="d-flex flex-column flex-grow-1">
                        <span className="text-dark-75 text-hover-primary mb-1 font-size-lg font-weight-bolder">
                          Prova do Módulo
                        </span>
                      </div>
                    )}
                    {loading ? (
                      <Skeleton variant="text" width="0%" height={0} />
                    ) : (
                      <Button
                        disabled={!hasEvaluation}
                        text="Realizar Prova"
                        onClick={() => history.push(`/evaluation/${moduleId}`)}
                        className="btn btn-primary font-weight-bolder label label-xl  label-inline py-5 min-w-45px"
                      />
                    )}
                  </div>

                  {loading ? (
                    <Skeleton variant="text" width="0%" height={0} />
                  ) : (
                    <div className="d-flex flex-column w-100 mr-2">
                      <div className="d-flex align-items-center justify-content-between mb-2">
                        <span className="text-muted mr-2 font-size-sm font-weight-bold">
                          {cursoProgress
                            ? (cursoProgress.progresso * 100).toFixed(2)
                            : '0'}
                          %
                        </span>
                        <span className="text-muted font-size-sm font-weight-bold">
                          Progresso
                        </span>
                      </div>
                      <div className="progress progress-xs w-100">
                        <div
                          className="progress-bar "
                          role="progressbar"
                          style={{
                            width: `${
                              cursoProgress
                                ? cursoProgress.progresso * 100
                                : '0'
                            }%`,
                            backgroundColor: '#005F6B',
                          }}
                          aria-valuenow={50}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
