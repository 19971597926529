/* eslint-disable no-param-reassign */
import React, { useCallback, useRef, useState } from 'react';
import { Form } from '@unform/web';
import {
  FiMail,
  FiUser,
} from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import toast from 'react-hot-toast';
import { cpf } from 'cpf-cnpj-validator';
import * as Yup from 'yup';
import { AiOutlineIdcard } from 'react-icons/ai';
import { MdOutlineLocationCity } from 'react-icons/md';
import { useAuth } from '../../../../../hooks/Auth';
import Input from '../../../../components/Input';
import { API } from '../../../../../services/api';
import GetValidationErrors from '../../../../../utils/getValidationErros';
import MaskInput from '../../../../components/MaskInput';
import { ModalProps } from '../../../../../interfaces/components';
import ModalComponent from '../../../../../components/Modal/ModalComponent';
import { Button } from '../../../../../components/Button';

export default function CreateColaboradorProfissionalModal({
  onClose,
  closeButton,
  itens,
  open,
  setModalOpen,
  setItens,
}: ModalProps) {
  const { token, user } = useAuth();
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);

  const id = user.organizacao;

  const Submit = useCallback(
    async (data) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          nome: Yup.string().required('Campo Obrigatório'),
          email: Yup.string().required('Campo Obrigatório').email(),
          cpf: Yup.string()
            .required('Campo obrigatório')
            .test('validacao-cpf', 'CPF Inválido', async (Cpf) => {
              if (Cpf === undefined) {
                return false;
              }
              const validation = cpf.isValid(Cpf);
              if (validation) {
                return true;
              }
              return false;
            }),
            bairro: Yup.string().required('Bairro obrigatório'),
            cidade: Yup.string().required('Cidade obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        data.aceitaColetaDados = true;
        data.organizacaoId = id;
        data.profissionalEmpresa = true;

        const response = await API(token).post('/api/usuario/registerProfissional', data);
        setLoading(false);
        setModalOpen(false);
        toast.success('Colaborador criado com sucesso!');
        data.id = response.data.id;
        setItens([...itens, data]);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = GetValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          toast.error(
            'Houve um erro ao criar o administrador, tente novamente!',
          );
        }

        setLoading(false);
      }
    },

    [token, itens, setItens, setModalOpen, id],
  );

  return (
    <ModalComponent closeButton={closeButton} open={open} onClose={onClose}>
      <Form ref={formRef} placeholder="" className="createmodal-form-admin" onSubmit={Submit}>
        <h2 id="simple-modal-title">Criar novo colaborador</h2>
        <Input
          type="text"
          maxLength={80}
          name="nome"
          icon={FiUser}
          placeholder="Nome"
        />

        <Input
          type="text"
          maxLength={80}
          name="email"
          icon={FiMail}
          placeholder="Email"
        />

        <MaskInput
          mask="999.999.999-99"
          type="text"
          name="cpf"
          placeholder="Cpf"
          icon={AiOutlineIdcard}
        />

        <Input
          type="text"
          maxLength={80}
          name="bairro"
          icon={MdOutlineLocationCity}
          placeholder="Bairro"
        />

        <Input
          type="text"
          maxLength={80}
          name="cidade"
          icon={MdOutlineLocationCity}
          placeholder="Cidade"
        />

        <Button
          color="#6F95FF"
          text="Confirmar"
          type="submit"
          loading={loading}
        />
      </Form>
    </ModalComponent>
  );
}
