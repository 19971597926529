import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import CreateModal from './CreateModal';

import TipoCompanyTable from './TipoCompanyTable';
import { Button } from '../../../components/Button';

import { API } from '../../../services/api';
import { useAuth } from '../../../hooks/Auth';

interface TipoCompanyProps {
  id: number;
  descricao: string;
}

const TipoCompanyPage = () => {
  const { token } = useAuth();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [tipoEmpresa, setTipoEmpresa] = useState<TipoCompanyProps[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadCluster() {
      setLoading(true);
      setTipoEmpresa([]);
      try {
        const response = await API(token).get('/api/tipoorganizacao/odata');

        setTipoEmpresa(response.data.value);
        setLoading(false);
      } catch (err) {
        toast.error(
          'Houve um problema ao listar os Tipos de Organização, tente novamente! 😢',
        );
      }
    }

    loadCluster();
  }, [token]);

  return (
    <div className="cluster-table-container">
      <CreateModal
        setModalOpen={setCreateModalOpen}
        itens={tipoEmpresa}
        setItens={setTipoEmpresa}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        closeButton={() => setCreateModalOpen(false)}
      />

      <div className="cluster-table-card">
        <div className="cluster-table-card-header">
          <h2 style={{ marginBottom: 30, color: '#802A00' }}>
            Tipos de Organização
          </h2>
          <Button
            color="#802A00"
            text="Adicionar Tipo Organização"
            onClick={() => setCreateModalOpen(true)}
          />
        </div>
        <TipoCompanyTable
          setTipoCompany={setTipoEmpresa}
          tipoCompany={tipoEmpresa}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default TipoCompanyPage;
