import React, { useState } from 'react';
import {
  DropDownOptionsProps,
  HeaderAdminProps,
  HeaderMobileProps,
  ItensProps,
  OptionsProps,
} from '../../interfaces/components';
import Footer from '../Footer';

interface ContainerProps {
  Header({ color, backgroundColor, options }: HeaderAdminProps): JSX.Element;
  HeaderMobile({ color, options, links }: HeaderMobileProps): JSX.Element;
  children: React.ReactNode;
  links: ItensProps[];
  options: ItensProps[];
  HeaderOptions?: OptionsProps[];
  color: string;
  backgroundColor: string;
  headerDropDownOptions?: DropDownOptionsProps[];
}

const PageContainer = ({
  children,
  Header,
  HeaderMobile,
  color,
  links,
  backgroundColor,
  options,
  HeaderOptions,
  headerDropDownOptions,
}: ContainerProps) => (
  <div>
    {/* <HeaderMobile /> */}
    <HeaderMobile color={color} links={links} options={options} />
    <div className="d-flex flex-column flex-root">
      {/* begin::Page */}
      <div className="d-flex flex-row flex-column-fluid page">
        {/* begin::Wrapper */}
        <div
          className="d-flex flex-column flex-row-fluid wrapper"
          id="kt_wrapper"
        >
          {/* begin::Header */}
          <Header
            backgroundColor={backgroundColor}
            color={color}
            options={HeaderOptions || []}
            dropdownOptions={headerDropDownOptions || []}
          />
          {/* begin::Content */}
          <div
            className="content d-flex flex-column flex-column-fluid"
            style={{ minHeight: '100vh' }}
            id="kt_content"
          >
            {/* begin::Entry */}
            <div className="d-flex flex-column-fluid">
              {/* begin::Container */}
              <div className="container">
                {/* begin::Dashboard */}
                {/* begin::Row */}
                {children}
              </div>
              {/* end::Container */}
            </div>
            {/* end::Entry */}
          </div>
          {/* end::Content */}
          {/* begin::Footer */}
          <Footer />
          {/* end::Footer */}
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Page */}
    </div>
    {/* end::Main */}
  </div>
);

export default PageContainer;
