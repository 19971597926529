/* eslint-disable jsx-a11y/label-has-associated-control */
import { Skeleton } from '@material-ui/lab';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../../hooks/Auth';
import { ObjetosAprendizagemProps } from '../../../../interfaces/Entidades';
import { API } from '../../../../services/api';

interface Props {
  aulas: ObjetosAprendizagemProps[];
  isLoading: boolean;
  moduleName: string | undefined;
}

export default function CardClass({ aulas, isLoading, moduleName }: Props) {
  const { token } = useAuth();

  if (!isLoading && aulas.length === 0) {
    return (
      <div style={{ flex: 1 }}>
        <h2 style={{ textAlign: 'center' }}>Não há aulas disponíveis</h2>
      </div>
    );
  }

  function hasUrlVideo(item: ObjetosAprendizagemProps) {
    if (item.urlVideo === null) {
      API(token).put('/api/progresso/AulaCompleta', {
        id: item.id,
      });
    }
  }

  return (
    <div className="continuar">
      {isLoading ? (
        <Skeleton
          variant="rect"
          height={100}
          width="100%"
          style={{ borderRadius: 3 }}
        />
      ) : (
        <>
          <h2 style={{ textAlign: 'center' }}>Aulas do módulo</h2>
          {aulas &&
            aulas.map((aula) => (
              <div
                key={aula.id}
                className="card card-custom card-stretch gutter-b"
              >
                <div className="card-body">
                  <Link
                    onClick={() => hasUrlVideo(aula)}
                    to={{
                      pathname: `/watchClass/${aula.id}`,
                    }}
                    className="font-weight-bolder font-size-h3 color"
                  >
                    {aula.titulo}
                  </Link>
                  <p className="text-dark-75 font-size-lg m-0 text-muted line-clamp">
                    Módulo - {moduleName}
                  </p>
                  <p className="text-dark-75 font-size-lg m-0 text-muted line-clamp">
                    {aula.conteudo}
                  </p>
                </div>
              </div>
            ))}
        </>
      )}
    </div>
  );
}
