import React, { useCallback, useEffect, useRef, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { FormHandles } from '@unform/core';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import './styles.scss';

import { useAuth } from '../../../../../hooks/Auth';
import { API } from '../../../../../services/api';
import GetValidationErrors from '../../../../../utils/getValidationErros';
import { Button } from '../../../../../components/Button';
import ModalComponent from '../../../../../components/Modal/ModalComponent';
import { ModalProps } from '../../../../../interfaces/components';
import { CursoProps } from '../../../../../interfaces/Entidades';

interface AddCourseModalProps extends ModalProps {
  trailId: number;
}

export default function AddCursoModal({
  onClose,
  closeButton,
  trailId,
  itens,
  open,
  setItens,
  setModalOpen,
}: AddCourseModalProps) {
  const { token, user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [inputLoading, setInputLoading] = useState(false);
  const [value, setValue] = useState<string | null>(null);
  const [options, setOptions] = useState<CursoProps[]>([]);
  const formRef = useRef<FormHandles>(null);

  const Submit = useCallback(async () => {
    setLoading(true);

    try {
      await API(token).put('/api/trilha/addCurso', {
        cursoId: value,
        trilhaId: trailId,
      });
      setLoading(false);

      const findCurso = options.find((curso) => curso.id.toString() === value);

      setItens([...itens, findCurso]);
      toast.success('Curso adicionado com sucesso!');
      setModalOpen(false);
    } catch (err) {
      toast.error('Houve um erro ao adicionar o curso, tente novamente!');

      setLoading(false);
    }
  }, [value, token, trailId, itens, options, setModalOpen, setItens]);

  useEffect(() => {
    async function loadCursos() {
      setOptions([]);
      setInputLoading(true);
      try {
        const response = await API(token).get(
          `/api/curso/odata?$select=nome,id,descricao,urlImagem&$filter=organizacaoId eq ${user.organizacao}`,
        );
        const CursosToFilter = response.data.value as CursoProps[];
        const CursosToSet: CursoProps[] = [];

        for (let i = 0; i < CursosToFilter.length; i++) {
          let found = false;
          for (let j = 0; j < itens.length; j++) {
            if (CursosToFilter[i].id === itens[j].id) {
              found = true;
              break;
            }
          }

          if (!found) {
            CursosToSet.push(CursosToFilter[i]);
          }
        }

        setOptions(CursosToSet);
        setInputLoading(false);
      } catch (err) {
        toast.error('Ocorreu um erro ao listar os cursos, tente novamente! 😢');
      }
    }

    loadCursos();
  }, [itens, token]);

  return (
    <ModalComponent open={open} onClose={onClose} closeButton={closeButton}>
      <h2 id="simple-modal-title">Adicionar Curso</h2>
      <div className="createmodal-form-admin">
        <Autocomplete
          loading={inputLoading}
          onChange={(event, newValue) => {
            if (newValue) {
              setValue(newValue.id.toString());
            }
          }}
          noOptionsText="Não há cursos disponíveis!"
          options={options}
          style={{
            width: '100%',
            backgroundColor: '#f2f3f7',
          }}
          getOptionLabel={(option) => option.nome}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Digite o nome do curso..."
            />
          )}
        />

        <Button
          color="#6F95FF"
          text="Confirmar"
          type="button"
          onClick={Submit}
          loading={loading}
        />
      </div>
    </ModalComponent>
  );
}
