import React, { useCallback, useRef, useState } from 'react';
import { Form } from '@unform/web';

import { useHistory, Link } from 'react-router-dom';

import { FiLock, FiMail, FiUser } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { AiOutlineIdcard } from 'react-icons/ai';
import { cpf } from 'cpf-cnpj-validator';
import { MdOutlineLocationCity } from 'react-icons/md';
import logoImg from '../../assets/images/apl.png';


import { Button } from '../../components/Button';
import Input from '../../components/Input';

import './styles.scss';
import GetValidationErrors from '../../../utils/getValidationErros';
import MaskInput from '../../components/MaskInput';
import { API } from '../../../services/api';

import TermsConditions from './termsConditions';

interface SignUpData {
  Email: string;
  Senha: string;
  Nome: string;
  Bairro: string;
  Cidade: string;
  ConfirmarSenha: string;
  Cpf: string;
  aceitaColetaDados: boolean;
}

export function SignUp() {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [termsConditionsModalOpen, setTermsConditionsModalOpen] =
    useState(false);
  const [accept, setAccept] = useState(false);

  const Submit = useCallback(
    async (data: SignUpData) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          Nome: Yup.string().required('Nome obrigatório'),
          Email: Yup.string()
            .required('Email obrigatório')
            .email('Digite um Email válido!'),
          Bairro: Yup.string().required('Bairro obrigatório'),
          Cidade: Yup.string().required('Cidade obrigatório'),
          Senha: Yup.string()
            .min(8, 'Mínimo 8 dígitos!')
            .max(20, 'Máximo 20 dígitos'),
          ConfirmarSenha: Yup.string()
            .oneOf([Yup.ref('Senha'), null], 'Senhas não coincidem')
            .min(8, 'Mínimo 8 dígitos!')
            .max(20, 'Máximo 20 dígitos'),
          Cpf: Yup.string()
            .required('Campo obrigatório')
            .test('validacao-cpf', 'CPF Inválido', async (Cpf) => {
              if (Cpf === undefined) {
                return false;
              }
              const validation = cpf.isValid(Cpf);
              if (validation) {
                return true;
              }
              return false;
            }),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        data.aceitaColetaDados = accept;

        if (data.aceitaColetaDados === true) {

          const response = await API().post('/api/usuario/register', data);
          setLoading(false);

          toast.success('Cadastro realizado!');
          history.push('/');
        } else {
          throw new Error('Error403');
        }
      } catch (err: any) {
        if (err.message === 'Error403') {
          toast.error('Necessário aceitar os Termos e Condições de Uso 😄');
        } else if (err instanceof Yup.ValidationError) {
          const errors = GetValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          toast.error('Ocorreu um erro ao efetuar o cadastro!');
        }

        setLoading(false);
      }
    },
    [history, accept],
  );

  return (
    <>
      <TermsConditions
        id={1}
        open={termsConditionsModalOpen}
        item=""
        setModalOpen={setTermsConditionsModalOpen}
        onClose={() => setTermsConditionsModalOpen(false)}
        closeButton={() => setTermsConditionsModalOpen(false)}
      />
      <div id="page-signUp">
        <main>
          <Form ref={formRef} placeholder="" onSubmit={Submit}>
            <img src={logoImg} alt="APL SP PLAY" style={{
              objectFit: 'cover',
              width: '100%',
              height: '120px',
            }}/>
            <h2>Faça seu cadastro</h2>
            <Input
              type="text"
              maxLength={80}
              name="Nome"
              icon={FiUser}
              placeholder="Nome"
            />

            <Input
              type="text"
              maxLength={80}
              name="Email"
              icon={FiMail}
              placeholder="Email"
            />

            <MaskInput
              mask="999.999.999-99"
              type="text"
              name="Cpf"
              placeholder="Cpf"
              icon={AiOutlineIdcard}
            />

            <Input
              type="text"
              maxLength={80}
              name="Bairro"
              icon={MdOutlineLocationCity}
              placeholder="Bairro"
            />

            <Input
              type="text"
              maxLength={80}
              name="Cidade"
              icon={MdOutlineLocationCity}
              placeholder="Cidade"
            />

            <Input
              type="password"
              maxLength={20}
              name="Senha"
              icon={FiLock}
              placeholder="Senha"
            />

            <Input
              maxLength={20}
              type="password"
              name="ConfirmarSenha"
              placeholder="Confirmar Senha"
              icon={FiLock}
            />

            <div
              style={{
                display: 'flex',
                width: '100%',
              }}
            >
              <label className="customSearchCheckBox">
                <input
                  type="checkbox"
                  name="termos"
                  id="1"
                  onChange={(event) => {
                    if (event.target.checked) {
                      setAccept(true);
                    } else {
                      setAccept(false);
                    }
                  }}
                />
                <span style={{ marginTop: 0 }} />
              </label>
              <div
                className="font-size-lg text-dark-75 font-weight-bold"
                style={{ marginTop: '2px', cursor: 'pointer' }}
              >
                Aceito os{' '}
                <strong
                  style={{ color: '#005F6B' }}
                  onClick={async () => {
                    await setTermsConditionsModalOpen(true);
                  }}
                >
                  <u>termos de uso e política de privacidade</u>{' '}
                </strong>
                da plataforma
              </div>
            </div>

            <Button
              style={{ marginTop: '8px' }}
              text="Cadastrar"
              type="submit"
              loading={loading}
            />
          </Form>

          <span>
            Já tem uma conta? <Link to="/">Faça Login</Link>
          </span>
        </main>
      </div>
    </>
  );
}
