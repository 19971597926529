import React from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { AiOutlinePlayCircle, AiOutlineLink } from 'react-icons/ai';
import { BsBook } from 'react-icons/bs';
import { Skeleton } from '@material-ui/lab';
import { CursoProps } from '../../../../../interfaces/Entidades';
import CollapseComponent from '../../../../components/CollapseComponent';

interface CardLearningUnitProps {
  curso: CursoProps;
  loading: boolean;
}

export default function CardLearningUnit({
  loading,
  curso,
}: CardLearningUnitProps) {
  return (
    <>
      <div className="cardLearningUnit">
        <div
          className="card card-custom card-stretch gutter-b"
          style={{ height: '66%' }}
        >
          <div className="card-header border-0 py-5">
            {loading ? (
              <Skeleton variant="text" width="70%" />
            ) : (
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label font-weight-bolder text-dark">
                  Conteúdo do curso
                </span>
              </h3>
            )}
          </div>

          <div className="card-body py-0">
            {loading ? (
              <>
                <Skeleton
                  variant="rect"
                  width="100%"
                  height={25}
                  style={{ marginBottom: 20 }}
                />
              </>
            ) : (
              <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                style={{ maxHeight: 1500, overflow: 'auto' }}
              >
                {curso.unidades.map((unidade) => (
                  <CollapseComponent
                    titulo={unidade.titulo}
                    itens={unidade.objetosAprendizagens}
                    type="video"
                  />
                ))}
              </List>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
