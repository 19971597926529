import { CircularProgress } from '@material-ui/core';
import React, { ButtonHTMLAttributes } from 'react';

import './styles.scss';

interface buttonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  loading?: boolean;
}

export function Button({
  type = 'button',
  text,
  loading,
  ...props
}: buttonProps) {
  if (loading) {
    return (
      <button type={type} disabled className="button" {...props}>
        <CircularProgress color="inherit" size={20} />
      </button>
    );
  }

  return (
    <button type={type} className="button" {...props}>
      {text}
    </button>
  );
}
