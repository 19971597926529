import React from 'react';

import '../../../styles/component.scss';

import { Route, Switch } from 'react-router-dom';

import ClusterPage from '../Cluster';
import AdminHome from '../Home';
import { Error404 } from '../../../ErrorPages/404';
import CompanyPage from '../Company';
import CompanyAdminUser from '../CompanyAdminUser';
import PageContainer from '../../../components/PageContainer';
import { HeaderAdmin } from '../../../components/HeaderAdmin';
import HeaderMobile from '../../../components/HeaderMobile';
import { ItensProps, DropDownOptionsProps, OptionsProps } from '../../../interfaces/components';
import ClusterAdminUser from '../ClusterAdminUser';
import TipoClusterPage from '../TipoCluster';
import TipoCompanyPage from '../TipoEmpresa';
import CreateCategoriaPage from '../CriarCategoria';
import ConfigAdminPage from '../Config';
import Tutorials from '../Tutorials';

const links: ItensProps[] = [
  { id: 1, link: '/', nome: 'Home' },
  { id: 2, link: '/cluster', nome: 'Clusters' },
  { id: 3, link: '/tipo_cluster', nome: 'Tipo Cluster' },
  { id: 4, link: '/cluster_users', nome: 'Admin Cluster' },
  { id: 5, link: '/organizacao', nome: 'Organizaçao' },
  { id: 6, link: '/tipo_organizacao', nome: 'Tipo Organizaçao' },
  { id: 7, link: '/organizacao_users', nome: 'Admin Organizaçao' },
  { id: 8, link: '/categoria', nome: 'Categorias' },
  { id: 9, link: '/config', nome: 'Configurações do sistema' },
];

const options: OptionsProps[] = [
  {
    id: 1,
    titulo: 'Cluster',
    itens: [
      { id: 2, link: '/cluster', nome: 'Clusters' },
      { id: 3, link: '/tipo_cluster', nome: ' Tipo Cluster' },
      { id: 4, link: '/cluster_users', nome: ' Admin Cluster' },
    ],
  },
  {
    id: 5,
    titulo: 'Organização',
    itens: [
      { id: 6, link: '/organizacao', nome: 'Organizaçao' },
      { id: 7, link: '/tipo_organizacao', nome: 'Tipo Organizaçao' },
      { id: 8, link: '/organizacao_users', nome: 'Admin Organizaçao' },
    ],
  },
  {
    id: 9,
    titulo: 'Categoria do Sistema',
    itens: [{ id: 10, link: '/categoria', nome: 'Categorias' }],
  },
  {
    id: 12,
    titulo: 'Configurações',
    itens: [{ id: 13, link: '/config', nome: 'Configurações do sistema' }],
  },
];

const dropdownOptions: DropDownOptionsProps[] = [
  {
    id: 1,
    titulo: 'Tutorial Organização',
    descricao: 'Tutoriais do Sistema',
    isAdmin: false,
    link: '/tutorials',
  },
];

const ContainerAdmin: React.FC = () => (
  <>
    <PageContainer
      HeaderOptions={options}
      backgroundColor="#802A00"
      Header={HeaderAdmin}
      HeaderMobile={HeaderMobile}
      headerDropDownOptions={dropdownOptions}
      color="#802A00"
      links={links}
      options={[]}
    >
      <Switch>
        <Route path="/" exact component={AdminHome} />
        <Route path="/cluster" exact component={ClusterPage} />
        <Route path="/tipo_cluster" exact component={TipoClusterPage} />
        <Route path="/cluster_users" exact component={ClusterAdminUser} />
        <Route path="/organizacao" exact component={CompanyPage} />
        <Route path="/tipo_organizacao" exact component={TipoCompanyPage} />
        <Route path="/organizacao_users" component={CompanyAdminUser} />
        <Route path="/tutorials" component={Tutorials} />
        <Route path="/categoria" component={CreateCategoriaPage} />
        <Route path="/config" component={ConfigAdminPage} />
        <Route component={Error404} />
      </Switch>
    </PageContainer>
  </>
);
export default ContainerAdmin;
