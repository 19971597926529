import React, { useCallback, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FiType } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import toast from 'react-hot-toast';

import * as Yup from 'yup';

import { API } from '../../../../services/api';
import GetValidationErrors from '../../../../utils/getValidationErros';
import { useAuth } from '../../../../hooks/Auth';
import Input from '../../../components/Input';
import { Button } from '../../../../components/Button';
import ModalComponent from '../../../../components/Modal/ModalComponent';
import { EditModalProps } from '../../../../interfaces/components';

export default function EditModalCluster({
  closeButton,
  item,
  open,
  setModalOpen,
  onClose,
  id,
}: EditModalProps) {
  const { token } = useAuth();
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);

  const Submit = useCallback(
    async (data) => {
      setLoading(true);

      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          descricao: Yup.string().required('Nome obrigatório!'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await API(token).put('/api/categoria', {
          descricao: data.descricao,
          id,
        });
        setLoading(false);

        toast.success('Categoria alterada com sucesso!');
        await setTimeout(() => {
          window.location.reload();
        }, 1000);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = GetValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          toast.error(
            'Houve um erro ao editar a categoria, tente novamente! 😢',
          );
        }

        setLoading(false);
      }
    },

    [token, id],
  );

  return (
    <ModalComponent closeButton={closeButton} open={open} onClose={onClose}>
      <Form ref={formRef} placeholder="" className="createmodal-form-admin" onSubmit={Submit}>
        <h2 id="simple-modal-title">Editar categoria</h2>
        <Input
          name="descricao"
          maxLength={80}
          defaultValue={item ? item.descricao : ''}
          icon={FiType}
          type="text"
          placeholder="Nome"
        />

        <Button
          color="#802A00"
          text="Confirmar"
          type="submit"
          loading={loading}
        />
      </Form>
    </ModalComponent>
  );
}
