import React, { useCallback, useState, MouseEventHandler } from 'react';
import Modal from '@material-ui/core/Modal';
import { FiX } from 'react-icons/fi';
import toast from 'react-hot-toast';
import { useAuth } from '../../../../../hooks/Auth';
import { API } from '../../../../../services/api';
import { Button } from '../../../../../components/Button';

interface DeleteModalProps {
  open: boolean;
  trilhaId: number;
  cursoId: number;
  itens?: any[];
  setItens?: React.Dispatch<React.SetStateAction<any[]>>;
  closeButton: MouseEventHandler;
  onClose?: {
    bivarianceHack(event: {}, reason: 'backdropClick' | 'escapeKeyDown'): void;
  }['bivarianceHack'];
}

export default function RemoverCursoModal({
  trilhaId,
  cursoId,
  open,
  itens,
  setItens,
  onClose,
  closeButton,
}: DeleteModalProps) {
  const { token } = useAuth();

  const [loading, setLoading] = useState(false);

  const Submit = useCallback(async () => {
    setLoading(true);

    try {
      await API(token).put('/api/trilha/removerCurso/', {
        cursoId,
        trilhaId,
      });
      setLoading(false);

      toast.success('Curso removido com sucesso!');

      if (itens && setItens) {
        const filteredItens = itens.filter((item) => item.id !== cursoId);
        setItens(filteredItens);
      }
    } catch (err) {
      toast.error(`Houve um erro ao remover o curso, tente novamente! 😢`);
      setLoading(false);
    }
  }, [token, itens, setItens, trilhaId, cursoId]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="deleteModal">
        <FiX
          color="#f53030"
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            margin: 10,
            cursor: 'pointer',
          }}
          size={20}
          onClick={closeButton}
        />

        <h2>Deseja remover o curso?</h2>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <Button
            color="#6F95FF"
            loading={loading}
            text="Confirmar"
            onClick={Submit}
          />
          <Button
            color="#6F95FF"
            text="Cancelar"
            style={{ marginLeft: 20, backgroundColor: '#6F95FF' }}
            onClick={closeButton}
          />
        </div>
      </div>
    </Modal>
  );
}
