/* eslint-disable no-nested-ternary */
import React from 'react';
import { Link } from 'react-router-dom';

import { Skeleton } from '@material-ui/lab';
import Play from '../../../assets/images/play.svg';
import {
  CoursesProgress,
  ClassProgress,
} from '../../../../interfaces/Entidades';

interface ContinueAndTable {
  loading: boolean;
  coursesProgress: CoursesProgress[];
  classProgress: ClassProgress;
}

export default function ContinueAndTable({
  loading,
  coursesProgress,
  classProgress,
}: ContinueAndTable) {
  if (!classProgress) {
    return (
      <div className="col-xl-8">
        <div className="card card-custom bgi-no-repeat gutter-b card-stretch responsive-card-continue">
          <div className="card-body">
            {loading ? (
              <Skeleton
                variant="text"
                width={200}
                height={40}
                style={{ marginTop: -10 }}
              />
            ) : (
              <div className="font-weight-bolder font-size-h5 color">
                Seja muito bem-vindo, 👋
              </div>
            )}

            <div className="play-continue">
              {loading ? (
                <Skeleton variant="text" width={0} height={0} />
              ) : (
                <div />
              )}
            </div>

            {loading ? (
              <div style={{ width: '100%' }}>
                <Skeleton
                  variant="text"
                  width="100%"
                  height={80}
                  style={{ marginTop: -8, borderRadius: 6 }}
                />
              </div>
            ) : (
              <p className="text-dark-75 font-size-sm  font-size-base m-0 text-muted line-clamp">
                O grande segredo de uma vida boa é descobrir qual é o seu
                destino. E realizá-lo. Que você possa encontrar o seu aqui.
                Procure uma trilha agora mesmo
              </p>
            )}
          </div>
        </div>

        <div
          className="card card-custom card-stretch gutter-b responsive-card-courses"
          style={{ height: '66%' }}
        >
          <div className="card-header border-0 py-5">
            <h3 className="card-title align-items-start flex-column">
              {loading ? (
                <Skeleton variant="text" width={200} height={40} />
              ) : (
                <span className="card-label font-weight-bolder text-dark">
                  Cursos em Andamento
                </span>
              )}
            </h3>
          </div>

          <div className="card-body py-0">
            <div className="table-responsive">
              {loading ? (
                <Skeleton
                  variant="text"
                  width="100%"
                  height={380}
                  style={{ marginTop: -120, borderRadius: 6 }}
                />
              ) : coursesProgress.length >= 4 ? (
                <table
                  className="table table-head-custom table-vertical-center"
                  id="kt_advance_table_widget_1"
                >
                  <thead>
                    <tr className="text-left">
                      <th style={{ minWidth: '150px' }}>Empresa</th>
                      <th style={{ minWidth: '150px' }}>Curso</th>
                      <th style={{ minWidth: '150px' }}>Progresso</th>
                      <th
                        className="pr-0 text-right"
                        style={{ minWidth: '30px' }}
                      />
                    </tr>
                  </thead>
                  <tbody>
                    {coursesProgress.slice(0, 4).map((progress) => (
                      <>
                        <tr>
                          <td className="pl-0">
                            <Link
                              to="/"
                              className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                            >
                              {progress.curso.organizacao.razaoSocial}
                            </Link>
                          </td>
                          <td className="pl-0">
                            <Link
                              to="/"
                              className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                            >
                              {progress.curso.nome}
                            </Link>
                          </td>
                          <td>
                            <div className="d-flex flex-column w-100 mr-2">
                              <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="text-muted mr-2 font-size-sm font-weight-bold">
                                  {(progress.progresso * 100).toFixed(2)}%
                                </span>
                                <span className="text-muted font-size-sm font-weight-bold">
                                  Progresso
                                </span>
                              </div>
                              <div className="progress progress-xs w-100">
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{
                                    width: `${progress.progresso * 100}%`,
                                    color: '#008C9E',
                                  }}
                                  aria-valuenow={50}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                />
                              </div>
                            </div>
                          </td>
                          <td className="text-right pr-0">
                            <Link
                              to={{
                                pathname: `/learningUnit/${progress.cursoId}`,
                              }}
                              className="btn btn-icon btn-light btn-sm"
                            >
                              <span className="svg-icon svg-icon-md svg-icon-success">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  width="24px"
                                  height="24px"
                                  viewBox="0 0 24 24"
                                  version="1.1"
                                >
                                  <g
                                    stroke="none"
                                    strokeWidth={1}
                                    fill="none"
                                    fillRule="evenodd"
                                  >
                                    <polygon points="0 0 24 0 24 24 0 24" />
                                    <rect
                                      fill="#000000"
                                      opacity="0.3"
                                      transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)"
                                      x={11}
                                      y={5}
                                      width={2}
                                      height={14}
                                      rx={1}
                                    />
                                    <path
                                      d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                                      fill="#000000"
                                      fillRule="nonzero"
                                      transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"
                                    />
                                  </g>
                                </svg>
                              </span>
                            </Link>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              ) : (
                <table
                  className="table table-head-custom table-vertical-center"
                  id="kt_advance_table_widget_1"
                >
                  <thead>
                    <tr className="text-left">
                      <th style={{ minWidth: '150px' }}>Empresa</th>
                      <th style={{ minWidth: '150px' }}>Curso</th>
                      <th style={{ minWidth: '150px' }}>Progresso</th>
                      <th
                        className="pr-0 text-right"
                        style={{ minWidth: '30px' }}
                      />
                    </tr>
                  </thead>
                  <tbody>
                    {coursesProgress.length > 0 &&
                      coursesProgress.map((progress) => (
                        <tr>
                          <td className="pl-0">
                            <Link
                              to="/"
                              className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                            >
                              {progress.curso.organizacao.razaoSocial}
                            </Link>
                          </td>

                          <td className="pl-0">
                            <Link
                              to="/"
                              className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                            >
                              {progress.curso.nome}
                            </Link>
                          </td>
                          <td>
                            <div className="d-flex flex-column w-100 mr-2">
                              <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="text-muted mr-2 font-size-sm font-weight-bold">
                                  {(progress.progresso * 100).toFixed(2)}%
                                </span>
                                <span className="text-muted font-size-sm font-weight-bold">
                                  Progresso
                                </span>
                              </div>
                              <div className="progress progress-xs w-100">
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{
                                    width: `${progress.progresso * 100}%`,
                                    color: '#008C9E',
                                  }}
                                  aria-valuenow={50}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                />
                              </div>
                            </div>
                          </td>
                          <td className="text-right pr-0">
                            <Link
                              to={{
                                pathname: `/learningUnit/${progress.cursoId}`,
                              }}
                              className="btn btn-icon btn-light btn-sm"
                            >
                              <span className="svg-icon svg-icon-md svg-icon-success">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  width="24px"
                                  height="24px"
                                  viewBox="0 0 24 24"
                                  version="1.1"
                                >
                                  <g
                                    stroke="none"
                                    strokeWidth={1}
                                    fill="none"
                                    fillRule="evenodd"
                                  >
                                    <polygon points="0 0 24 0 24 24 0 24" />
                                    <rect
                                      fill="#000000"
                                      opacity="0.3"
                                      transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)"
                                      x={11}
                                      y={5}
                                      width={2}
                                      height={14}
                                      rx={1}
                                    />
                                    <path
                                      d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                                      fill="#000000"
                                      fillRule="nonzero"
                                      transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"
                                    />
                                  </g>
                                </svg>
                              </span>
                            </Link>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="col-xl-8">
        <div
          className="card card-custom bgi-no-repeat gutter-b card-stretch"
          style={{
            backgroundPosition: 'right top',
            backgroundSize: '20% auto',
            height: '25%',
          }}
        >
          <div className="card-body">
            {loading ? (
              <Skeleton
                variant="text"
                width={200}
                height={40}
                style={{ marginTop: -10 }}
              />
            ) : (
              <Link
                to={{
                  pathname: `/watchClass/${classProgress.objetoAprendizagemId}`,
                }}
                className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
              >
                <div
                  className="font-weight-bolder font-size-h5 color"
                  style={{ width: '72%' }}
                >
                  {classProgress.objetoAprendizagem
                    ? classProgress.objetoAprendizagem.titulo
                    : ''}
                </div>
              </Link>
            )}

            <div className="play-continue mobile">
              {loading ? (
                <Skeleton variant="text" width={0} height={0} />
              ) : (
                <Link
                  to={{
                    pathname: `/watchClass/${classProgress.objetoAprendizagemId}`,
                  }}
                  className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                >
                  <div>
                    <div
                      className="font-weight-bolder font-size-h5 color"
                      style={{ display: 'inline-block' }}
                    >
                      Continuar Assistindo
                    </div>
                    <img
                      className="play"
                      style={{ display: 'inline-block' }}
                      src={Play}
                      alt="Play"
                    />
                  </div>
                </Link>
              )}
            </div>

            {loading ? (
              <div style={{ width: '100%' }}>
                <Skeleton
                  variant="text"
                  width="100%"
                  height={80}
                  style={{ marginTop: -8, borderRadius: 6 }}
                />
              </div>
            ) : (
              <p className="text-dark-75 font-size-sm  font-size-base m-0 text-muted line-clamp">
                {classProgress.objetoAprendizagem
                  ? classProgress.objetoAprendizagem.conteudo
                  : ''}
              </p>
            )}
          </div>
        </div>

        <div
          className="card card-custom card-stretch gutter-b"
          style={{ height: '66%' }}
        >
          <div className="card-header border-0 py-5">
            <h3 className="card-title align-items-start flex-column">
              {loading ? (
                <Skeleton variant="text" width={200} height={40} />
              ) : (
                <span className="card-label font-weight-bolder text-dark">
                  Cursos em Andamento
                </span>
              )}
            </h3>
          </div>

          <div className="card-body py-0">
            <div className="table-responsive">
              {loading ? (
                <Skeleton
                  variant="text"
                  width="100%"
                  height={380}
                  style={{ marginTop: -120, borderRadius: 6 }}
                />
              ) : coursesProgress.length >= 4 ? (
                <table
                  className="table table-head-custom table-vertical-center"
                  id="kt_advance_table_widget_1"
                >
                  <thead>
                    <tr className="text-left">
                      <th style={{ minWidth: '150px' }}>Empresa</th>
                      <th style={{ minWidth: '150px' }}>Curso</th>
                      <th style={{ minWidth: '150px' }}>Progresso</th>
                      <th
                        className="pr-0 text-right"
                        style={{ minWidth: '30px' }}
                      />
                    </tr>
                  </thead>
                  <tbody>
                    {coursesProgress.slice(0, 4).map((progress) => (
                      <>
                        <tr>
                          <td className="pl-0">
                            <Link
                              to="/"
                              className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                            >
                              {progress.curso.organizacao.razaoSocial}
                            </Link>
                          </td>
                          <td className="pl-0">
                            <Link
                              to="/"
                              className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                            >
                              {progress.curso.nome}
                            </Link>
                          </td>
                          <td>
                            <div className="d-flex flex-column w-100 mr-2">
                              <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="text-muted mr-2 font-size-sm font-weight-bold">
                                  {(progress.progresso * 100).toFixed(2)}%
                                </span>
                                <span className="text-muted font-size-sm font-weight-bold">
                                  Progresso
                                </span>
                              </div>
                              <div className="progress progress-xs w-100">
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{
                                    width: `${progress.progresso * 100}%`,
                                    color: '#008C9E',
                                  }}
                                  aria-valuenow={50}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                />
                              </div>
                            </div>
                          </td>
                          <td className="text-right pr-0">
                            <Link
                              to={{
                                pathname: `/learningUnit/${progress.cursoId}`,
                              }}
                              className="btn btn-icon btn-light btn-sm"
                            >
                              <span className="svg-icon svg-icon-md svg-icon-success">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  width="24px"
                                  height="24px"
                                  viewBox="0 0 24 24"
                                  version="1.1"
                                >
                                  <g
                                    stroke="none"
                                    strokeWidth={1}
                                    fill="none"
                                    fillRule="evenodd"
                                  >
                                    <polygon points="0 0 24 0 24 24 0 24" />
                                    <rect
                                      fill="#000000"
                                      opacity="0.3"
                                      transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)"
                                      x={11}
                                      y={5}
                                      width={2}
                                      height={14}
                                      rx={1}
                                    />
                                    <path
                                      d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                                      fill="#000000"
                                      fillRule="nonzero"
                                      transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"
                                    />
                                  </g>
                                </svg>
                              </span>
                            </Link>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              ) : (
                <table
                  className="table table-head-custom table-vertical-center"
                  id="kt_advance_table_widget_1"
                >
                  <thead>
                    <tr className="text-left">
                      <th style={{ minWidth: '150px' }}>Empresa</th>
                      <th style={{ minWidth: '150px' }}>Curso</th>
                      <th style={{ minWidth: '150px' }}>Progresso</th>
                      <th
                        className="pr-0 text-right"
                        style={{ minWidth: '30px' }}
                      />
                    </tr>
                  </thead>
                  <tbody>
                    {coursesProgress.length > 0 &&
                      coursesProgress.map((progress) => (
                        <tr>
                          <td className="pl-0">
                            <Link
                              to="/"
                              className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                            >
                              {progress.curso.organizacao.razaoSocial
                                ? progress.curso.organizacao.razaoSocial
                                : ''}
                            </Link>
                          </td>

                          <td className="pl-0">
                            <Link
                              to="/"
                              className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                            >
                              {progress.curso.nome}
                            </Link>
                          </td>
                          <td>
                            <div className="d-flex flex-column w-100 mr-2">
                              <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="text-muted mr-2 font-size-sm font-weight-bold">
                                  {(progress.progresso * 100).toFixed(2)}%
                                </span>
                                <span className="text-muted font-size-sm font-weight-bold">
                                  Progresso
                                </span>
                              </div>
                              <div className="progress progress-xs w-100">
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{
                                    width: `${progress.progresso * 100}%`,
                                    color: '#008C9E',
                                  }}
                                  aria-valuenow={50}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                />
                              </div>
                            </div>
                          </td>
                          <td className="text-right pr-0">
                            <Link
                              to={{
                                pathname: `/learningUnit/${progress.cursoId}`,
                              }}
                              className="btn btn-icon btn-light btn-sm"
                            >
                              <span className="svg-icon svg-icon-md svg-icon-success">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  width="24px"
                                  height="24px"
                                  viewBox="0 0 24 24"
                                  version="1.1"
                                >
                                  <g
                                    stroke="none"
                                    strokeWidth={1}
                                    fill="none"
                                    fillRule="evenodd"
                                  >
                                    <polygon points="0 0 24 0 24 24 0 24" />
                                    <rect
                                      fill="#000000"
                                      opacity="0.3"
                                      transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)"
                                      x={11}
                                      y={5}
                                      width={2}
                                      height={14}
                                      rx={1}
                                    />
                                    <path
                                      d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                                      fill="#000000"
                                      fillRule="nonzero"
                                      transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"
                                    />
                                  </g>
                                </svg>
                              </span>
                            </Link>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
