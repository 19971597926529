import React, { useState } from 'react';
import { CgMenuLeft } from 'react-icons/cg';
import { Link } from 'react-router-dom';
import Logo from '../../assets/images/apl.png';
import HeaderDropdown from '../HeaderDropdown';
import { useAuth } from '../../hooks/Auth';
import { SideMenu } from '../SideMenu';
import { HeaderAdminProps } from '../../interfaces/components';

export const HeaderAdmin = ({
  color,
  backgroundColor,
  options,
  dropdownOptions,
}: HeaderAdminProps) => {
  const { user } = useAuth();
  const [anchor, setAnchor] = useState(false);

  return (
    <>
      <div
        id="kt_header"
        className="header header-fixed hmobile"
        style={{ backgroundColor: color }}
      >
        <div className="container d-flex align-items-stretch justify-content-between">
          <div className="d-none d-lg-flex align-items-center mr-3">
            <button
              type="button"
              className="btn btn-icon aside-toggle ml-n3 mr-8"
              onClick={() => setAnchor(true)}
            >
              <span className="svg-icon svg-icon-xxl svg-icon-dark-75">
                <CgMenuLeft color="#fff" />
              </span>
            </button>

            <Link to="/">
              <img alt="Logo" src={Logo} className="max-h-80px" />
            </Link>
          </div>

          <div className="topbar">
            <div className="topbar" style={{ backgroundColor: color }}>
              <HeaderDropdown
                color={color}
                backgroundColor={backgroundColor}
                foto={user.foto}
                nome={user.username}
                options={dropdownOptions || []}
              />
            </div>
          </div>
        </div>
      </div>

      <SideMenu
        status={anchor}
        onClose={() => setAnchor(false)}
        options={options}
      />
    </>
  );
};
