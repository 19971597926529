/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React, {
  useState,
  useCallback,
  BaseSyntheticEvent,
  useEffect,
} from 'react';
import { Pagination } from '@material-ui/lab';
import toast from 'react-hot-toast';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { FiEdit } from 'react-icons/fi';
import { IoMdTrash } from 'react-icons/io';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import DeleteModal from '../../../components/Modal/DeleteModal';
import { API } from '../../../services/api';
import { useAuth } from '../../../hooks/Auth';
import DetailsPageComponent from '../../components/DetailsPage';

import {
  ObjetosAprendizagemProps,
  UnidadeAprendizagemProps,
} from '../../../interfaces/Entidades';
import CreateClassModal from './components/CreateClassModal';
import EditClassModal from './components/EditClassModal';

interface StateProps {
  id: number;
}

const ModuleDetailPage = () => {
  const location = useLocation();
  const history = useHistory();
  const { token } = useAuth();
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filteredClass, setFilteredClass] = useState<
    ObjetosAprendizagemProps[]
  >([]);
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [modulo, setModulo] = useState<UnidadeAprendizagemProps>(
    {} as UnidadeAprendizagemProps,
  );
  const [aulas, setAulas] = useState<ObjetosAprendizagemProps[]>([]);
  const [editItem, setEditItem] = useState<ObjetosAprendizagemProps>(
    {} as ObjetosAprendizagemProps,
  );
  const id = location.state as StateProps;

  const handleFilter = useCallback(
    (text: string) => {
      const filterClass = aulas.filter((aula) =>
        aula.titulo.toUpperCase().includes(text.toUpperCase()),
      );

      if (text === '') {
        setFilteredClass(aulas);
      } else if (filterClass.length > 0) {
        setFilteredClass(filterClass);
      } else {
        setFilteredClass([]);
      }

      const pagesCount = Math.ceil(filterClass.length / rowsPerPage);
      setPageCount(pagesCount);
      if (pagesCount <= 1) {
        setPage(0);
      }
    },
    [rowsPerPage, aulas],
  );

  useEffect(() => {
    function setNewCursos() {
      setFilteredClass(aulas);
      setPageCount(Math.ceil(aulas.length / rowsPerPage));
    }

    setNewCursos();
  }, [aulas, rowsPerPage]);

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      try {
        const response = await API(token).get<UnidadeAprendizagemProps>(
          `/api/unidadeAprendizagem/${id.id}`,
        );
        setModulo(response.data);
        setPageCount(
          Math.ceil(response.data.objetosAprendizagens.length / rowsPerPage),
        );
        response.data.objetosAprendizagens =
          response.data.objetosAprendizagens.sort((a, b) => a.ordem - b.ordem);
        setAulas(response.data.objetosAprendizagens);
        setFilteredClass(response.data.objetosAprendizagens);
        setLoading(false);
      } catch (err) {
        toast.error('Houve um erro ao carregar as aulas, tente novamente!');
        setLoading(false);
      }
    }

    loadData();
  }, [token, rowsPerPage, id.id]);

  return (
    <>
      {createModalOpen && (
        <CreateClassModal
          moduloId={id.id}
          itens={aulas}
          setItens={setAulas}
          setModalOpen={setCreateModalOpen}
          open={createModalOpen}
          onClose={() => setCreateModalOpen(false)}
          closeButton={() => setCreateModalOpen(false)}
        />
      )}

      <EditClassModal
        item={editItem}
        id={editItem.id}
        setModalOpen={setEditModalOpen}
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        closeButton={() => setEditModalOpen(false)}
      />

      <DeleteModal
        itens={aulas}
        setItens={setAulas}
        color="#6F95FF"
        name="a aula"
        routeName="objetoAprendizagem"
        id={editItem.id}
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        closeButton={() => setDeleteModalOpen(false)}
      />

      <Breadcrumbs aria-label="breadcrumb">
        <div color="inherit">
          Listagem de Trilhas
        </div>

        <div color="inherit">
          Cursos da Trilha
        </div>

        <div color="inherit">
          Módulos do Curso
        </div>

        <Typography color="textPrimary">
          Aulas do Módulo
        </Typography>

       </Breadcrumbs>

      <DetailsPageComponent
        InputOnChange={(event: BaseSyntheticEvent) =>
          handleFilter(event.target.value)
        }
        buttonHeaderText="Opções"
        hasCard={false}
        cardHasButtons
        hasMenuButton
        menuButtonOptions={[
          {
            option: 'Adicionar Aula',
            setModalOpen: setCreateModalOpen,
          },
          {
            option: 'Alterar ordem',
            action: () =>
              history.push('/order', {
                FatherEntity: modulo,
                HeaderTitle: 'Ordenar Aulas do Módulo',
                Itens: aulas,
                Type: 'ObjetoAprendizagem',
              }),
          },
        ]}
        loading={loading}
        headerBack
        headerTitle="Aulas"
      >
        <div style={{ width: '100%', marginTop: -10 }}>
          <h4 style={{ textAlign: 'center' }}>
            <span className="text-muted" style={{ fontSize: '16px' }}>
              Aulas do Módulo:{' '}
            </span>
            {modulo.titulo}
          </h4>
        </div>
        {filteredClass.length > 0 ? (
          filteredClass
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((aula) => (
              <div className="admin-trails-card" key={aula.titulo}>
                <div className="admin-trails-card-header">
                  <h2>{aula.titulo}</h2>
                </div>

                <div className="admin-trails-card-descriptions">
                  <span>{aula.conteudo}</span>
                </div>

                <div className="admin-trails-card-footer">
                  <Link
                    to={{
                      pathname: '/lessons_details',
                      state: { id: aula.id },
                    }}
                    style={{
                      height: 40,
                      width: '100%',
                      backgroundColor: '#6F95FF',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: 6,
                      color: '#f8f8f8',
                    }}
                  >
                    <span>Detalhes</span>
                  </Link>
                  <FiEdit
                    onClick={async () => {
                      await setEditItem(aula);
                      await setEditModalOpen(true);
                    }}
                    size={25}
                    color="#80808F"
                    style={{ cursor: 'pointer' }}
                    className="cluster-table-svg"
                    onMouseOver={({ currentTarget }) =>
                      (currentTarget.style.color = '#6F95FF')
                    }
                    onMouseOut={({ currentTarget }) =>
                      (currentTarget.style.color = '#80808F')
                    }
                  />
                  <IoMdTrash
                    onClick={async () => {
                      await setEditItem(aula);
                      await setDeleteModalOpen(true);
                    }}
                    onMouseOver={({ currentTarget }) =>
                      (currentTarget.style.color = '#6F95FF')
                    }
                    onMouseOut={({ currentTarget }) =>
                      (currentTarget.style.color = '#80808F')
                    }
                    size={25}
                    color="#80808F"
                    style={{ marginLeft: 15, cursor: 'pointer' }}
                    className="cluster-table-svg"
                  />
                </div>
              </div>
            ))
        ) : (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <h2>Não foram encontrados itens</h2>
          </div>
        )}
        <div className="admin-trail-footer">
          <Pagination
            count={pageCount}
            page={page + 1}
            hideNextButton={pageCount < 2}
            hidePrevButton={pageCount < 2}
            onChange={(event: object, page_select: number) => {
              setPage(page_select - 1);
            }}
          />
        </div>
      </DetailsPageComponent>
    </>
  );
};

export default ModuleDetailPage;
