import React from 'react';
import { Toaster } from 'react-hot-toast';
import { FiCheckCircle, FiInfo, FiXCircle } from 'react-icons/fi';

export const Toasts = () => (
  <Toaster
    toastOptions={{
      error: {
        icon: <FiXCircle color="#fff" size={35} />,
        position: 'top-right',
        style: {
          minWidth: 300,
          height: 50,
          color: '#fff',
          backgroundColor: '#f53030',
        },
      },
      success: {
        icon: <FiCheckCircle color="#fff" size={35} />,
        position: 'top-right',
        style: {
          minWidth: 300,
          height: 50,
          color: '#fff',
          backgroundColor: '#00cf00',
        },
      },
    }}
  />
);
