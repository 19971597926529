/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { LinearProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { FiArrowLeft } from 'react-icons/fi';
import { useParams, useHistory } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { useAuth } from '../../../hooks/Auth';
import { AvaliacaoProps } from '../../../interfaces/Entidades';
import { API } from '../../../services/api';
import TextAreaInput from '../../components/TextAreaInput';

import './styles.scss';

interface ParamsProps {
  id: number;
}

const AvaliacaoPage = () => {
  const routeParams = useParams();
  const history = useHistory();
  const { token } = useAuth();
  const [loading, setLoading] = useState(true);
  const [avaliacao, setAvaliacao] = useState<AvaliacaoProps>(
    {} as AvaliacaoProps,
  );

  useEffect(() => {
    async function loadAvaliacaoData() {
      setLoading(true);
      try {
        const { id } = routeParams as ParamsProps;
        const response = await API(token).get(`/api/avaliacao/${id}`);
        if (response.status !== 200) {
          throw new Error();
        }
        setAvaliacao(response.data);
        setLoading(false);
      } catch (err) {
        toast.error('Houve um erro ao carregar a prova, tente novamente! 😢');
      }
    }

    loadAvaliacaoData();
  }, [routeParams, token]);

  if (loading) {
    return (
      <>
        <div className="avaliacao-top">
          <div
            className="admin-trailheader-divider"
            onClick={() => history.goBack()}
          >
            <FiArrowLeft size={20} />
            <span>Voltar</span>
          </div>
        </div>
        <div>
          <LinearProgress />
        </div>
      </>
    );
  }

  return (
    <div className="row" style={{ padding: 10 }}>
      <div className="avaliacao-top">
        <div
          className="admin-trailheader-divider"
          onClick={() => history.goBack()}
        >
          <FiArrowLeft size={20} />
          <span>Voltar</span>
        </div>
      </div>
      <div className="avaliacao-header">
        <h1>Prova de Certificação</h1>

        <div style={{ marginTop: 12 }}>
          <h4>Descrição da Prova:</h4>
          <span>{avaliacao.descricao}</span>
        </div>
      </div>
      <div className="questions-container">
        <h2>Questões</h2>
        {avaliacao.questoes.map((questao, index) => (
          <>
            <div key={questao.conteudo} className="question-card">
              <div className="create-question-card-title">
                <span>
                  {index + 1} - {questao.conteudo}
                </span>
              </div>
              <div className="question-card-alternatives">
                {questao.alternativas.map((alternativa) => (
                  <div
                    key={alternativa.conteudo}
                    className={
                      alternativa.correta
                        ? 'question-input question-correct'
                        : 'question-input'
                    }
                  >
                    <input
                      type="radio"
                      name=""
                      id="a"
                      checked={alternativa.correta}
                      disabled
                    />
                    <label htmlFor="a">{alternativa.conteudo}</label>
                  </div>
                ))}
              </div>
            </div>
            <div className="question-card-divider" />
          </>
        ))}
      </div>
    </div>
  );
};

export default AvaliacaoPage;
