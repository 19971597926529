import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  ChangeEvent,
} from 'react';
import { Form } from '@unform/web';
import {
  FiHome,
  FiLink,
  FiMail,
  FiMap,
  FiMapPin,
  FiPhone,
  FiUser,
  FiCamera,
} from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import toast from 'react-hot-toast';

import { OptionTypeBase } from 'react-select';
import * as Yup from 'yup';

import { API } from '../../../../services/api';
import GetValidationErrors from '../../../../utils/getValidationErros';
import { useAuth } from '../../../../hooks/Auth';
import { API_PUBLIC } from '../../../../services/api_public';
import { Button } from '../../../../components/Button';
import { CepProps, ModalProps } from '../../../../interfaces/components';
import ModalComponent from '../../../../components/Modal/ModalComponent';
import { tipoOrganizacaoProps } from '../../../../interfaces/Entidades';
import Input from '../../../components/Input';
import MaskInput from '../../../components/MaskInput';
import SelectInput from '../../../components/SelectInput';
import AvatarImg from '../../../assets/images/no-image.png';

export default function CreateModalCompany({
  open,
  onClose,
  closeButton,
  itens,
  setItens,
  setModalOpen,
}: ModalProps) {
  const { token } = useAuth();
  const [inputLoading, setInputLoading] = useState(false);
  const [loadingCep, setLoadingCep] = useState(false);
  const [loading, setLoading] = useState(false);
  const [logradouro, setLogradouro] = useState('');
  const [cep, setCep] = useState('');
  const [bairro, setBairro] = useState('');
  const [estado, setEstado] = useState('');
  const [cidade, setCidade] = useState('');
  const [optionsTipoOrg, setOptionsTipoOrg] = useState<OptionTypeBase[]>();
  const formRef = useRef<FormHandles>(null);
  const avatarRef = useRef<HTMLImageElement>(null);
  const fileRef = useRef<HTMLInputElement>(null);

  const Submit = useCallback(
    async (data) => {
      setLoading(true);

      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          razaoSocial: Yup.string().required('Campo Obrigatório'),
          nomeFantasia: Yup.string().required('Campo Obrigatório'),
          tipoLogradouro: Yup.string().required('Campo Obrigatório'),
          logradouro: Yup.string().required('Campo Obrigatório'),
          numero: Yup.string().required('Campo Obrigatório'),
          complemento: Yup.string(),
          bairro: Yup.string().required('Campo Obrigatório'),
          cidade: Yup.string().required('Campo Obrigatório'),
          uf: Yup.string().required('Campo Obrigatório'),
          cep: Yup.string().required('Campo Obrigatório'),
          telefone: Yup.string().required('Campo Obrigatório'),
          email: Yup.string().required('Campo Obrigatório').email(),
          urlSite: Yup.string().required('Campo Obrigatório'),
          nomeResponsavel: Yup.string().required('Campo Obrigatório'),
          telefoneResponsavel: Yup.string().required('Campo Obrigatório'),
          tipoOrganizacaoId: Yup.number().required('Campo Obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (
          fileRef.current &&
          fileRef.current.files &&
          fileRef.current.files.length > 0
        ) {
          const formData = new FormData();
          formData.append('foto', fileRef.current.files[0]);

          const upload_response = await API(token).post(
            '/api/organizacao/imagem',
            formData,
          );

          data.logotipo = upload_response.data.url;
        }

        data.ativo = true;
        const response = await API(token).post('/api/organizacao', data);
        setLoading(false);

        const findTipoOrg = optionsTipoOrg?.find(
          (item) => item.value === data.tipoOrganizacaoId,
        );

        if (findTipoOrg) {
          data.tipoOrganizacao = { descricao: findTipoOrg.label };
        } else {
          console.log('fail');
        }

        data.id = response.data.id;
        setItens([...itens, data]);
        setModalOpen(false);
        toast.success('Organização criada com sucesso!');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = GetValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          toast.error('Houve um erro ao criar a organização, tente novamente!');
        }

        setLoading(false);
      }
    },
    [token, itens, setItens, setModalOpen, optionsTipoOrg],
  );

  const searchCep = useCallback(async () => {
    setLoadingCep(true);
    try {
      const response = await API_PUBLIC().get<CepProps>(
        `https://viacep.com.br/ws/${cep}/json/`,
      );

      setBairro(response.data.bairro);
      setLogradouro(response.data.logradouro);
      setCidade(response.data.localidade);
      setEstado(response.data.uf);
      setLoadingCep(false);
    } catch (err) {
      setLoadingCep(false);
    }
  }, [cep]);

  const handleAvatarChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      if (avatarRef.current) {
        avatarRef.current.src = URL.createObjectURL(e.target.files[0]);
      }
    }
  }, []);

  useEffect(() => {
    async function loadData() {
      setInputLoading(true);
      setOptionsTipoOrg([]);

      try {
        const response_tipoOrg = await API(token).get(
          '/api/tipoorganizacao/odata',
        );

        const optionsFormatTipoOrg: OptionTypeBase[] = [];

        response_tipoOrg.data.value.forEach(
          (tipoEmpresa: tipoOrganizacaoProps) => {
            optionsFormatTipoOrg.push({
              value: tipoEmpresa.id,
              label: tipoEmpresa.descricao,
            });
          },
        );

        setOptionsTipoOrg(optionsFormatTipoOrg);

        setInputLoading(false);
      } catch (err) {
        toast.error('Houve um erro ao carregar os tipos de organização! 😢');
        setInputLoading(false);
      }
    }

    loadData();
  }, [token]);

  return (
    <ModalComponent
      closeButton={closeButton}
      open={open}
      onClose={onClose}
      height="90%"
    >
      <Form ref={formRef} placeholder="" onSubmit={Submit}>
        <h2 id="simple-modal-title">Criar nova Organização</h2>
        <div className="AvatarInput">
          <img ref={avatarRef} src={AvatarImg} alt="" />
          <label htmlFor="avatar" style={{ backgroundColor: '#7A9940' }}>
            <FiCamera />

            <input
              type="file"
              id="avatar"
              ref={fileRef}
              name="urlImagem"
              accept="image/*"
              onChange={(event) => handleAvatarChange(event)}
            />
          </label>
        </div>
        <Input
          name="razaoSocial"
          maxLength={60}
          icon={FiUser}
          type="text"
          placeholder="Razão Social"
        />
        <Input
          name="nomeFantasia"
          maxLength={60}
          icon={FiUser}
          type="text"
          placeholder="Nome Fantasia"
        />
        <Input
          name="email"
          maxLength={80}
          icon={FiMail}
          type="text"
          placeholder="Email"
        />

        <Input
          name="nomeResponsavel"
          maxLength={80}
          icon={FiUser}
          type="text"
          placeholder="Nome Responsável"
        />
        <MaskInput
          mask="(99) 99999-9999"
          name="telefoneResponsavel"
          icon={FiPhone}
          type="text"
          placeholder="Telefone Responsável"
        />
        <MaskInput
          mask="(99) 9999-9999"
          name="telefone"
          icon={FiPhone}
          type="text"
          placeholder="Telefone Empresa"
        />
        <Input
          name="urlSite"
          maxLength={100}
          icon={FiLink}
          type="text"
          placeholder="Link do Site"
        />

        <MaskInput
          mask="99999-999"
          name="cep"
          hasIcon
          onPressIcon={searchCep}
          onChange={(text) => setCep(text.currentTarget.value)}
          icon={FiMap}
          type="text"
          placeholder={loadingCep ? 'Carregando...' : 'CEP'}
        />
        <Input
          name="logradouro"
          value={logradouro || undefined}
          maxLength={60}
          icon={FiHome}
          type="text"
          placeholder={loadingCep ? 'Carregando...' : 'Logradouro'}
          disabled
        />
        <Input
          name="numero"
          maxLength={20}
          icon={FiMapPin}
          type="text"
          placeholder="Numero"
        />
        <Input
          name="complemento"
          maxLength={20}
          icon={FiMapPin}
          type="text"
          placeholder="Complemento"
        />

        <Input
          value={bairro || undefined}
          name="bairro"
          maxLength={60}
          icon={FiHome}
          type="text"
          placeholder={loadingCep ? 'Carregando...' : 'Bairro'}
          disabled
        />
        <Input
          name="cidade"
          value={cidade || undefined}
          maxLength={60}
          icon={FiHome}
          type="text"
          placeholder={loadingCep ? 'Carregando...' : 'Cidade'}
          disabled
        />
        <Input
          name="uf"
          value={estado || undefined}
          maxLength={2}
          icon={FiMapPin}
          type="text"
          placeholder={loadingCep ? 'Carregando...' : 'Estado'}
          disabled
        />
        <Input
          name="tipoLogradouro"
          maxLength={20}
          icon={FiMapPin}
          type="text"
          placeholder="Tipo Logradouro"
        />

        <div className="createCompanyDivFormSelects">
          <SelectInput
            loading
            options={optionsTipoOrg}
            isLoading={inputLoading}
            name="tipoOrganizacaoId"
            placeholder="Selecione o tipo da empresa..."
          />
        </div>

        <div className="button-modal">
          <Button
            color="#7A9940"
            height={40}
            width="100%"
            text="Confirmar"
            type="submit"
            loading={loading}
          />
        </div>
      </Form>
    </ModalComponent>
  );
}
