/* eslint-disable no-return-assign */
import React from 'react';
import { Skeleton } from '@material-ui/lab';

import {
  AiFillInstagram,
  AiFillYoutube,
  AiFillFacebook,
  AiFillLinkedin,
  AiFillContainer,
} from 'react-icons/ai';

import { CompanyProps } from '../../../../../interfaces/Entidades';

interface CardSocial {
  company: CompanyProps;
  loading: boolean;
}

export default function CardSocial({ company, loading }: CardSocial) {
  return (
    <>
      <div className="cardLearningUnit">
        <div
          className="card card-custom card-stretch gutter-b"
          style={{ height: '66%' }}
        >
          <div className="card-header border-0 py-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label font-weight-bolder text-dark">
                {loading ? (
                  <Skeleton variant="text" width={150} height={30} />
                ) : (
                  'Saiba Mais'
                )}
              </span>
            </h3>
          </div>

          <div className="card-body py-5">
            <p className="font-weight-bold text-dark-75 font-size-lg">
              {loading ? (
                <Skeleton
                  variant="text"
                  width={370}
                  height={30}
                  style={{ marginTop: '-30px' }}
                />
              ) : (
                `Tel: ${company?.telefone}`
              )}
            </p>
            <p className="font-weight-bold text-dark-75 font-size-lg">
              {loading ? (
                <Skeleton
                  variant="text"
                  width={370}
                  height={30}
                  style={{ marginTop: '-15px' }}
                />
              ) : (
                `Email: ${company?.email}`
              )}
            </p>
            <p className="font-weight-bold text-dark-75 font-size-lg">
              {loading ? (
                <Skeleton
                  variant="text"
                  width={370}
                  height={30}
                  style={{ marginTop: '-15px' }}
                />
              ) : (
                `Endereço: ${company?.logradouro}, ${company?.numero} - ${company?.bairro}, ${company?.cidade} - ${company?.uf}`
              )}
            </p>

            <div className="company-social">
              {loading ? (
                <Skeleton
                  variant="text"
                  width={30}
                  height={30}
                  style={{ marginRight: 5 }}
                />
              ) : (
                <a href={company.urlFacebook} target="_blank" rel="noreferrer">
                  <AiFillFacebook
                    size={30}
                    onMouseOver={({ currentTarget }) =>
                      (currentTarget.style.color = '#1F727D')
                    }
                    onMouseOut={({ currentTarget }) =>
                      (currentTarget.style.color = '#005f6a')
                    }
                  />
                </a>
              )}
              {loading ? (
                <Skeleton
                  variant="text"
                  width={30}
                  height={30}
                  style={{ marginRight: 5 }}
                />
              ) : (
                <a href={company.urlInstagram} target="_blank" rel="noreferrer">
                  <AiFillInstagram
                    size={30}
                    onMouseOver={({ currentTarget }) =>
                      (currentTarget.style.color = '#1F727D')
                    }
                    onMouseOut={({ currentTarget }) =>
                      (currentTarget.style.color = '#005f6a')
                    }
                  />
                </a>
              )}
              {loading ? (
                <Skeleton
                  variant="text"
                  width={30}
                  height={30}
                  style={{ marginRight: 5 }}
                />
              ) : (
                <a href={company.urlLinkedIn} target="_blank" rel="noreferrer">
                  <AiFillLinkedin
                    size={30}
                    onMouseOver={({ currentTarget }) =>
                      (currentTarget.style.color = '#1F727D')
                    }
                    onMouseOut={({ currentTarget }) =>
                      (currentTarget.style.color = '#005f6a')
                    }
                  />
                </a>
              )}
              {loading ? (
                <Skeleton
                  variant="text"
                  width={30}
                  height={30}
                  style={{ marginRight: 5 }}
                />
              ) : (
                <a href={company.urlYoutube} target="_blank" rel="noreferrer">
                  <AiFillYoutube
                    size={30}
                    onMouseOver={({ currentTarget }) =>
                      (currentTarget.style.color = '#1F727D')
                    }
                    onMouseOut={({ currentTarget }) =>
                      (currentTarget.style.color = '#005f6a')
                    }
                  />
                </a>
              )}
              {loading ? (
                <Skeleton
                  variant="text"
                  width={30}
                  height={30}
                  style={{ marginRight: 5 }}
                />
              ) : (
                <a href={company.urlSite} target="_blank" rel="noreferrer">
                  <AiFillContainer
                    size={30}
                    onMouseOver={({ currentTarget }) =>
                      (currentTarget.style.color = '#1F727D')
                    }
                    onMouseOut={({ currentTarget }) =>
                      (currentTarget.style.color = '#005f6a')
                    }
                  />
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
