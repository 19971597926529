/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React, { useState } from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { FiEdit } from 'react-icons/fi';
import { IoMdTrash } from 'react-icons/io';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useAuth } from '../../../hooks/Auth';
import DeleteModal from '../../../components/Modal/DeleteModal';
import TableAdmin from '../../../components/Table';
import EditColaboradorModal from './components/EditModal';
import {
  AdminUserCompanyProps,
  ColaboradorProps,
} from '../../../interfaces/Entidades';

interface CompanyUserTableProps {
  users: AdminUserCompanyProps[];
  setUsers: React.Dispatch<React.SetStateAction<AdminUserCompanyProps[]>>;
  loading: boolean;
}

export default function ColaboradoresTable({
  users,
  setUsers,
  loading,
}: CompanyUserTableProps) {
  const { token } = useAuth();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [editColaborador, setEditColaborador] = useState<ColaboradorProps>(
    {} as ColaboradorProps,
  );
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <EditColaboradorModal
        id={editColaborador.id}
        item={editColaborador}
        open={editModalOpen}
        setModalOpen={setEditModalOpen}
        onClose={() => setEditModalOpen(false)}
        closeButton={() => setEditModalOpen(false)}
      />

      <DeleteModal
        color="#6F95FF"
        name="o colaborador"
        routeName="usuario/colaborador"
        id={editColaborador.id}
        itens={users}
        setItens={setUsers}
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        closeButton={() => setDeleteModalOpen(false)}
      />

      {loading ? (
        <div>
          <LinearProgress />
        </div>
      ) : (
        <TableAdmin
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          ItemLenght={users.length}
          TableHeadCells={['Nome', 'Email', 'Cargo', 'Ações']}
        >
          {users.length > 0 ? (
            users
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((user) => (
                <TableRow key={user.id}>
                  <TableCell
                    component="th"
                    scope="row"
                    align="left"
                    className="cluster-table-body-font"
                  >
                    {user.nome}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    align="left"
                    className="cluster-table-body-font"
                  >
                    {user.email}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    align="left"
                    className="cluster-table-body-font"
                  >
                    {user.cargo}
                  </TableCell>
                  <TableCell align="left" className="cluster-table-body-action">
                    <FiEdit
                      onClick={() => {
                        setEditColaborador(user);
                        setEditModalOpen(true);
                      }}
                      size={18}
                      color="#80808F"
                      style={{ cursor: 'pointer' }}
                      className="cluster-table-svg"
                      onMouseOver={({ currentTarget }) =>
                        (currentTarget.style.color = '#6F95FF')
                      }
                      onMouseOut={({ currentTarget }) =>
                        (currentTarget.style.color = '#80808F')
                      }
                    />
                    <IoMdTrash
                      onClick={() => {
                        setEditColaborador(user);
                        setDeleteModalOpen(true);
                      }}
                      onMouseOver={({ currentTarget }) =>
                        (currentTarget.style.color = '#6F95FF')
                      }
                      onMouseOut={({ currentTarget }) =>
                        (currentTarget.style.color = '#80808F')
                      }
                      size={18}
                      color="#80808F"
                      style={{ marginLeft: 15, cursor: 'pointer' }}
                    />
                  </TableCell>
                </TableRow>
              ))
          ) : (
            <h2 style={{ textAlign: 'center' }}>Não há itens disponíveis</h2>
          )}
        </TableAdmin>
      )}
    </>
  );
}
