import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import HeaderDropdown from '../../../components/HeaderDropdown';
import { useAuth } from '../../../hooks/Auth';
import { DropDownOptionsProps } from '../../../interfaces/components';

import Logo from '../../assets/images/apl.png';

interface HeaderProps {
  backgroundColor: string;
}

const Header = ({ backgroundColor }: HeaderProps) => {
  const { user } = useAuth();

  const links = [
    { id: 1, link: '/', name: 'Home' },
    { id: 2, link: '/mytrails', name: 'Minhas trilhas' },
    { id: 3, link: '/search_trail', name: 'Trilhas' },
    { id: 5, link: '/search_company', name: 'Empresas' },
  ];

  const options: DropDownOptionsProps[] = [
    {
      id: 1,
      link: '/profile',
      titulo: 'Meu Perfil',
      descricao: 'Configurações da conta',
      isAdmin: false,
    },
    {
      id: 2,
      link: '/mytrails',
      titulo: 'Minhas trilhas',
      descricao: 'Trilhas inscritas',
      isAdmin: false,
    },
  ];

  const [active, setActive] = useState(1);

  return (
    <>
      <div id="kt_header" className="header flex-column header-fixed">
        <div className="header-top" style={{backgroundColor: "#A9A9A9"}}>
          <div className="container" style={{backgroundColor: "#A9A9A9"}}>
            <div className="d-none d-lg-flex align-items-center mr-3">
              <Link to="/" className="mr-8">
                <img alt="Logo" src={Logo} className="max-h-80px" />
              </Link>

              <ul
                className="header-tabs nav align-self-end font-size-lg"
                role="tablist"
              >
                {links.map((link) => (
                  <li key={link.id} className="nav-item">
                    <Link
                      to={link.link}
                      className={
                        active === link.id
                          ? 'nav-link py-4 px-6 active'
                          : 'nav-link py-4 px-6'
                      }
                      onClick={() => setActive(link.id)}
                    >
                      {link.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            <div className="topbar" style={{backgroundColor: "#A9A9A9"}}>
              <HeaderDropdown
                backgroundColor={backgroundColor}
                color="##A9A9A9"
                foto={user.foto}
                nome={user.username}
                options={options}

              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Header;
