import React from 'react';

import '../../../styles/component.scss';

import { Switch } from 'react-router-dom';

import { Error404 } from '../../../ErrorPages/404';
import AdminCompanyHome from '../Home';
import TrailPage from '../Trail';
import TrailsDetailPage from '../TrailsDetail';
import CourseDetailPage from '../CourseDetails';
import ColaboradoresPage from '../Colaboradores';
import CompanyProfile from '../Profile';
import Tutorials from '../Tutorials';
import PageContainer from '../../../components/PageContainer';
import {
  DropDownOptionsProps,
  ItensProps,
  OptionsProps,
} from '../../../interfaces/components';
import { HeaderAdmin } from '../../../components/HeaderAdmin';
import HeaderMobile from '../../../components/HeaderMobile';
import { Route } from '../../../routes/OrgRoute';
import ModuleDetailPage from '../ModuloDetails';
import CoursesPage from '../Courses';
import AvaliacaoPage from '../AvaliacaoView';
import CreateAvaliacaoPage from '../AvaliacaoCreate';
import AvaliacoesPage from '../Evaluations';
import LessonsDetailPage from '../LessonsDetail';
import OrderPage from '../OrderPage';

import { TrailDashboardPage } from '../TrailDashboard';
import { ProfileProfessionalOrg } from '../ProfissionalProfile';
import CorrigirDesafios from '../CorrigirDesafios';
import ColaboradoresProfissionaisPage from '../ColaboradoresProfissionais';

const headerMobileOptions: ItensProps[] = [
  { id: 7, link: '/', nome: 'Home' },
  { id: 8, link: '/profile', nome: 'Empresa', isAdmin: true },
  { id: 9, link: '/company_users', nome: 'Administradores', isAdmin: true },
  { id: 13, link: '/company_users_professional', nome: 'Colaboradores', isAdmin: true },
  {
    id: 10,
    link: '/course',
    nome: 'Cursos',
  },
  {
    id: 11,
    nome: 'Trilhas',
    link: '/trail',
  },
  {
    id: 12,
    link: '/test',
    nome: 'Avaliações',
  },
];

const HeaderOptions: OptionsProps[] = [
  {
    id: 1,
    titulo: 'Trilha',
    isAdmin: false,
    itens: [{ id: 2, link: '/trail', nome: 'Trilhas' }],
  },
  {
    id: 3,
    titulo: 'Curso',
    isAdmin: false,
    itens: [
      { id: 4, link: '/course', nome: 'Cursos' },
      { id: 13, link: '/test', nome: 'Avaliações' },
    ],
  },
  {
    id: 5,
    titulo: 'Colaboradores',
    isAdmin: true,
    userRole: 'Organizacao',
    itens: [
      { id: 6, link: '/company_users', nome: 'Administradores' },
      { id: 7, link: '/company_users_professional', nome: 'Colaboradores' }
    ],
  },
];

const dropdownOptions: DropDownOptionsProps[] = [
  {
    id: 1,
    titulo: 'Perfil da empresa',
    descricao: 'Página com as informações da empresa',
    isAdmin: true,
    link: '/profile',
  },
  {
    id: 2,
    titulo: 'Tutorial Organização',
    descricao: 'Tutoriais do Sistema',
    isAdmin: false,
    link: '/tutorials',
  },
];

const ContainerAdminCompany: React.FC = () => (
  <>
    <PageContainer
      HeaderOptions={HeaderOptions}
      backgroundColor="#5C65C0"
      Header={HeaderAdmin}
      HeaderMobile={HeaderMobile}
      headerDropDownOptions={dropdownOptions}
      color="#A9A9A9"
      links={headerMobileOptions}
      options={[]}
    >
      <Switch>
        <Route path="/" exact component={TrailPage} />
        <Route path="/trail" exact component={TrailPage} />
        <Route path="/trail/:id" component={TrailsDetailPage} />
        <Route path="/details_trail/:id" component={TrailDashboardPage} />
        <Route path="/profissional/:id" component={ProfileProfessionalOrg} />
        <Route path="/profile" component={CompanyProfile} isAdmin />
        <Route path="/tutorials" component={Tutorials} />
        <Route path="/company_users" component={ColaboradoresPage} isAdmin />
        <Route path="/company_users_professional" component={ColaboradoresProfissionaisPage} />
        <Route path="/module_details" component={ModuleDetailPage} />
        <Route path="/lessons_details" component={LessonsDetailPage} />
        <Route path="/course" exact component={CoursesPage} />
        <Route path="/course/:id" component={CourseDetailPage} />
        <Route path="/test/:id" component={AvaliacaoPage} />
        <Route path="/test" exact component={AvaliacoesPage} />
        <Route path="/order" exact component={OrderPage} />
        <Route path="/create/test" exact component={CreateAvaliacaoPage} />
        <Route path="/corrigirDesafio" exact component={CorrigirDesafios} />
        <Route component={Error404} />
      </Switch>
    </PageContainer>
  </>
);
export default ContainerAdminCompany;
