/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-param-reassign */
import React from "react";
import { EditModalProps } from "../../../../interfaces/components";
import ModalComponent from "../../../../components/Modal/ModalComponent";

export default function EditModalTermsConditions({
  id,
  onClose,
  item,
  closeButton,
  open,
  setModalOpen,
}: EditModalProps) {
  return (
    <ModalComponent closeButton={closeButton} open={open} onClose={onClose}>
      <h2 id="simple-modal-title">
        Termos de Uso e Política de Privacidade do APL SP PLAY
      </h2>

        <p style={{ textAlign: "center", marginTop: "50px" }}>
          <b>Termos de Uso</b>
        </p>
        <p style={{ textAlign: "center", marginTop: "-10px", marginBottom: "50px" }}>
          Última Atualização: 21 de fevereiro de 2022
        </p>


        <p style={{ textAlign: "left" }}>
          Esta aplicação e seu conteúdo ("<b>APL SP Play</b>") são controlados e
          operados pela própria APL SP Play. <b>Todos os direitos reservados</b>
          .
          <br />
          Estes termos de uso têm por objeto definir as regras a serem seguidas
          para a utilização da APL SP Play ("<b>Termos de Uso</b>"), sem
          prejuízo da aplicação da legislação vigente.
        </p>

        <p style={{ textAlign: "left" }}>
          <b>
            AO UTILIZAR A <i>APL SP PLAY</i>, VOCÊ AUTOMATICAMENTE CONCORDA COM
            ESTES TERMOS DE USO, RESPONSABILIZANDO-SE INTEGRALMENTE POR TODOS E
            QUAISQUER ATOS PRATICADOS POR VOCÊ NA <i>APL SP PLAY</i> OU EM
            SERVIÇOS A ELA RELACIONADOS. CASO VOCÊ NÃO CONCORDE COM QUALQUER DOS
            TERMOS E CONDIÇÕES ABAIXO ESTABELECIDOS, VOCÊ NÃO DEVE UTILIZAR A{" "}
            <i>APL SP PLAY</i>. VOCÊ TAMBÉM CONCORDA COM OS TERMOS DESCRITOS EM
            NOSSA POLÍTICA DE PRIVACIDADE.
          </b>
        </p>

        <p style={{ textAlign: "left" }}>
          Caso queira nos dar algum <i>feedback</i> sobre a APL SP Play, tenha
          dúvidas ou precise tratar de qualquer assunto relacionado a estes
          Termos de Uso, entre em contato conosco através do e-mail{" "}
          <b>secretaria@apltimarilia.org.br</b>.
        </p>

        <p style={{ textAlign: "left" }}>
          <b>1. O QUE É A APL SP PLAY?</b>
        </p>

        <p style={{ textAlign: "left", marginLeft: '10px' }}>
          <b>1. Serviços.</b> a APL SP Play é uma plataforma digital aberta de
          educação disponibilizada à todas as empresas que participam dos
          Arranjos Produtivos Locais do Estado de São Paulo. As empresas
          cadastradas na plataforma podem criar suas trilhas de formação com
          possibilidade de disponibilizar conteúdos digitais para a capacitação
          dos profissionais.
          <br />
          <br />
          <b>2. Suspensão.</b> Nós nos reservamos o direito de suspender ou
          cancelar, a qualquer momento, o seu acesso à aplicação em caso de
          suspeita de fraude, obtenção de benefício ou vantagem de forma
          ilícita, ou pelo não cumprimento de quaisquer condições previstas
          nestes Termos de Uso, na Política de Privacidade ou na legislação
          aplicável. Nesses casos, não será devida qualquer indenização a você,
          e a APL SP Play poderá promover a competente ação de regresso, se
          necessário, bem como tomar quaisquer outras medidas necessárias para
          perseguir e resguardar seus interesses.
        </p>

        <p style={{ textAlign: "left" }}>
          <b>2. COMO ACESSO A APL SP PLAY?</b>
        </p>

        <p style={{ textAlign: "left", marginLeft: '10px' }}>
          <b>1. Acesso.</b> Para acessar a APL SP Play e utilizar suas
          funcionalidades é necessário efetuar um cadastro. Para cadastrar-se,
          você nos fornecerá informações pessoais, conforme descrito em nossa
          Política de Privacidade. Para saber mais sobre a privacidade de suas
          informações pessoais na APL SP Play, acesse nossa Política de
          Privacidade.
          <br />
          <br />
          <b>2. Idade de Utilização.</b> Para utilizar a APL SP Play, você deve
          ter pelo menos 18 (dezoito) anos.
          <br />
          <br />
          <b>3. Titularidade.</b> A partir do cadastro, você será titular de uma
          conta que somente poderá ser acessada por você. Caso a APL SP Play
          detecte alguma conta feita a partir de informações falsas, por
          usuários que, por exemplo, não possuem a idade mínima permitida, essa
          conta será automaticamente deletada.
          <br />
          <br />
          <b>4. Atualização das Informações.</b> Desde já, você se compromete a
          manter as suas informações pessoais atualizadas. Você também concorda
          que irá manter o seu login e senha seguros e fora do alcance de
          terceiros, e não permitirá que a sua conta na APL SP Play seja usada
          por outras pessoas. Dessa forma, o usuário responsabiliza-se por todas
          as ações realizadas em sua conta.
        </p>

        <p style={{ textAlign: "left" }}>
          <b>3. A RELAÇÃO CONTRATUAL ENTRE A APL SP PLAY E O USUÁRIO</b>
        </p>

        <p style={{ textAlign: "left", marginLeft: '10px' }}>
          <b>1. Relação Contratual.</b> Os serviços e o conteúdo oferecidos pela
          plataforma são propriedade da APL SP Play. Ao estabelecer o contrato
          que permite ao usuário o gozo das funcionalidades do sistema, a APL SP
          Play está oferecendo uma licença de uso, que é não-exclusiva,
          limitada, revogável e de uso pessoal. É da liberalidade do usuário
          subscrever a qualquer plano oferecido pela APL SP Play, sujeito às
          regras descritas nesses Termos de Uso.
        </p>

        <p style={{ textAlign: "left" }}>
          <b>4. QUAIS SÃO OS DIREITOS DA APL SP PLAY SOBRE A APLICAÇÃO? </b>
        </p>

        <p style={{ textAlign: "left", marginLeft: '10px' }}>
          <b>1. Nossos Direitos.</b> Todos os direitos relativos à APL SP Play e
          suas funcionalidades são de propriedade exclusiva da APL SP Play,
          inclusive no que diz respeito aos seus textos, imagens, layouts,
          software, códigos, bases de dados, gráficos, artigos, fotografias e
          demais conteúdos produzidos direta ou indiretamente pela APL SP Play
          (“Conteúdo da APL SP Play”). O Conteúdo da APL SP Play é protegido
          pela lei de direitos autorais e de propriedade intelectual. É proibido
          usar, copiar, reproduzir, modificar, traduzir, publicar, transmitir,
          distribuir, executar, fazer o upload, exibir, licenciar, vender ou
          explorar e fazer engenharia reversa do Conteúdo da APL SP Play, para
          qualquer finalidade, sem o consentimento prévio e expresso da APL SP
          Play. Qualquer uso não autorizado do Conteúdo da APL SP Play será
          considerado como violação dos direitos autorais e de propriedade
          intelectual da APL SP Play.
        </p>

        <p style={{ textAlign: "left" }}>
          <b>
            5. PROPRIEDADE INTELECTUAL SOBRE O SOFTWARE E OS MATERIAIS
            DISPONIBILIZADOS
          </b>
        </p>

        <p style={{ textAlign: "left", marginLeft: '10px' }}>
          <b>1. Propriedade Intelectual.</b> Para nós da APL SP Play, a
          qualidade dos materiais disponibilizados ao usuário é de suma
          importância. A criação deles é fruto de muito trabalho e dedicação de
          nossos desenvolvedores. Por isso, reafirmamos que a APL SP Play
          garante que todos os direitos, título e interesse (incluindo, mas não
          apenas, os direitos autorais, marcários e outros de propriedade
          intelectual) sobre o serviço disponibilizado por nós permanecerão sob
          a titularidade da APL SP Play.
          <br />
          <br />
          <b>2. Não-aquisição de Direitos.</b> O usuário não adquirirá nenhum
          direito de propriedade sobre os serviços e conteúdos da APL SP Play,
          exceto quando haja outorga expressa neste Termos de Uso.
        </p>

        <p style={{ textAlign: "left", marginLeft: '10px' }}>
          <b>
            3. Download de Conteúdo. É proibido que o usuário faça o download de
            nosso conteúdo com o intuito de armazená-lo em banco de dados para
            oferecer para terceiro que não seja o próprio usuário. Veda-se,
            também, que o conteúdo disponibilizado por nós seja usado para criar
            uma base de dados ou um serviço que possa concorrer de qualquer
            maneira com o nosso negócio.
          </b>
        </p>

        <p style={{ textAlign: "left" }}>
          <b>6. RECLAMAÇÕES SOBRE VIOLAÇÃO DE DIREITO AUTORAL</b>
        </p>

        <p style={{ textAlign: "left", marginLeft: '10px' }}>
          <b>1. Infringência de Direitos.</b> Alegações de infringência de
          direito autoral de qualquer conteúdo disponível na APL SP Play devem
          ser encaminhadas por meio do e-mail secretaria@apltimarilia.org.br.
        </p>

        <p style={{ textAlign: "left" }}>
          <b>7. RESPONSABILIDADES DO USUÁRIO E DA APL SP PLAY</b>
        </p>

        <p style={{ textAlign: "left", marginLeft: '10px' }}>
          <b>1. Responsabilidade pelo Uso.</b> Você é exclusivamente responsável
          pelo uso da APL SP Play e deverá respeitar as regras destes Termos de
          Uso, bem como a legislação aplicável à APL SP Play.
          <br />
          <br />
          <b>2. Responsabilização por Eventuais Danos.</b> a APL SP Play, seu
          controlador, suas afiliadas, parceiras ou funcionários não serão, em
          hipótese alguma, responsabilizados por danos diretos ou indiretos que
          resultem de, ou que tenham relação com o acesso, uso ou a incapacidade
          de acessar ou utilizar a APL SP Play.
          <br />
          <br />
          <b>
            3. Não-Responsabilização. TENDO EM VISTA AS CARACTERÍSTICAS
            INERENTES AO AMBIENTE DA INTERNET, A APL SP PLAY NÃO SE
            RESPONSABILIZA POR INTERRUPÇÕES OU SUSPENSÕES DE CONEXÃO,
            TRANSMISSÕES DE COMPUTADOR INCOMPLETAS OU QUE FALHEM, BEM COMO POR
            FALHA TÉCNICA DE QUALQUER TIPO, INCLUINDO, MAS NÃO SE LIMITANDO, AO
            MAU FUNCIONAMENTO ELETRÔNICO DE QUALQUER REDE, HARDWARE OU SOFTWARE.
            A INDISPONIBILIDADE DE ACESSO À INTERNET OU À APL SP PLAY, ASSIM
            COMO QUALQUER INFORMAÇÃO INCORRETA OU INCOMPLETA SOBRE A APL SP PLAY
            E QUALQUER FALHA HUMANA, TÉCNICA OU DE QUALQUER OUTRO TIPO NO
            PROCESSAMENTO DAS INFORMAÇÕES DA APL SP PLAY NÃO SERÃO CONSIDERADAS
            RESPONSABILIDADE DA APL SP PLAY. A APL SP PLAY SE EXIME DE QUALQUER
            RESPONSABILIDADE PROVENIENTE DE TAIS FATOS E/OU ATOS.
          </b>
          <br />
          <br />
          <b>4. Perda de Lucros.</b> Quando permitido por lei, a APL SP Play e
          os fornecedores ou distribuidores não serão responsáveis por perda de
          lucros, perda de receita, perda de dados, perdas financeiras ou por
          danos indiretos, especiais, consequenciais, exemplares ou punitivos.
          <br />
          <br />
          <b>5. Manutenção.</b> É de sua inteira responsabilidade manter o
          ambiente de seu dispositivo (computador, celular, tablet, entre
          outros) seguro, com o uso de ferramentas disponíveis, como antivírus,
          firewall, entre outras, de modo a contribuir na prevenção de riscos
          eletrônicos.
          <br />
          <br />
          <b>6. Links Externos.</b> É possível que a APL SP Play possa conter
          links para sites e aplicativos de terceiros, assim como ter
          tecnologias integradas. Isso não implica, de maneira alguma, que a APL
          SP Play endossa, verifica, garante ou possui qualquer ligação com os
          proprietários desses sites ou aplicativos, não sendo responsável pelo
          seu conteúdo, precisão, políticas, práticas ou opiniões. a APL SP Play
          recomenda que você leia os termos de uso e políticas de privacidade de
          cada site de terceiros ou serviço que o usuário vier a visitar ou
          utilizar.
        </p>

        <p style={{ textAlign: "left" }}>
          <b>
            8. COMO A APL SP PLAY LIDA COM O CONTEÚDO QUE VOCÊ E OUTROS USUÁRIOS
            PRODUZEM?
          </b>
        </p>

        <p style={{ textAlign: "left", marginLeft: '10px' }}>
          <b>1. Criação de Conteúdo.</b> a APL SP Play poderá, a seu exclusivo
          critério, permitir que você ou qualquer outro usuário apresente,
          carregue, publique ou disponibilize, na aplicação, conteúdo ou
          informações de texto, imagem, áudio ou vídeo (“Conteúdo de Usuário”).
          <br />
          <br />
          <b>2. Conteúdos Proibidos.</b> É proibido qualquer Conteúdo de Usuário
          de caráter difamatório, calunioso, injurioso, violento, pornográfico,
          obsceno, ofensivo ou ilícito, conforme apuração da APL SP Play a seu
          critério exclusivo, inclusive informações de propriedade exclusiva
          pertencentes a outras pessoas ou empresas (ex. direito de autor,
          marcas), sem a expressa autorização do titular desses direitos, cuja
          violação não será de responsabilidade da APL SP Play.
          <br />
          <br />
          <b>3. Titularidade do Conteúdo.</b> Qualquer Conteúdo de Usuário
          fornecido por você permanece de sua propriedade. Contudo, ao
          fornecê-lo para a APL SP Play, você nos outorga uma licença em nível
          mundial, por prazo indeterminado, gratuita e transferível, e com
          direito a sublicenciar, usar, copiar, modificar, criar obras
          derivadas, distribuir, publicar, exibir esse Conteúdo de Usuário em
          todos os formatos e canais de distribuição possíveis, sem necessidade
          de novo aviso a você, e sem necessidade de qualquer pagamento, desde
          que isso esteja relacionado ao funcionamento da plataforma.
          <br />
          <br />
          <b>4. Exclusão do Conteúdo.</b> A APL SP Play poderá, mas não se
          obriga a, analisar, monitorar e remover Conteúdo de Usuário, a
          critério exclusivo da APL SP Play, a qualquer momento e por qualquer
          motivo, sem nenhum aviso a você.
        </p>

        <p style={{ textAlign: "left" }}>
          <b>9. O QUE MAIS EU PRECISO SABER SOBRE ESTES TERMOS DE USO?</b>
        </p>

        <p style={{ textAlign: "left", marginLeft: '10px' }}>
          <b>1. Alterações.</b> Para melhorar sua experiência, a APL SP Play
          está sempre sendo atualizada. Por esse motivo, estes Termos de Uso
          podem ser alterados, a qualquer tempo, a fim de refletir os ajustes
          realizados. No entanto, sempre que ocorrer qualquer modificação, você
          será previamente informando pelo endereço de e-mail fornecido por você
          no momento do cadastro ou por um aviso em destaque na aplicação. Caso
          você não concorde com os novos Termos de Uso, você poderá rejeitá-los,
          mas, infelizmente, isso significa que você não poderá mais ter acesso
          e fazer uso da APL SP Play. Se de qualquer maneira você utilizar a APL
          SP Play mesmo após a alteração destes Termos de Uso, isso significa
          que você concorda com todas as modificações.
          <br />
          <br />
          <b>
            2. Conflito. entre Disposições. Em caso de conflito entre estes
            termos e os termos modificador, os termos posteriores prevalecerão
            com relação a esse conflito.
          </b>
          <br />
          <br />
          <b>3. Lei e Foro.</b> Estes Termos de Uso são regidos pelas leis da
          República Federativa do Brasil. Quaisquer dúvidas e situações não
          previstas nestes Termos de Uso serão primeiramente resolvidas pela APL
          SP Play e, caso persistam, deverão ser solucionadas pelo Foro da
          Comarca de Marília, São Paulo, com exclusão de qualquer outro, por
          mais privilegiado que seja ou venha a ser.
          <br />
          <br />
          <b>4. Dúvidas.</b> Caso você tenha alguma dúvida, comentário ou
          sugestão, por favor, entre em contato conosco por meio do e-mail{" "}
          <b>secretaria@apltimarilia.org.br</b>.
        </p>

        <p style={{ textAlign: "center", marginTop: "50px" }}>
          <b>Política de Privacidade do APL SP PLAY</b>
        </p>
        <p style={{ textAlign: "center", marginTop: "-10px", marginBottom: "50px" }}>
          Última Atualização: 21 de fevereiro de 2022
        </p>

        <p style={{ textAlign: "left" }}>
          Quando você utiliza o APL SP Play, você nos confia seus dados e
          informações. Nos comprometemos a manter essa confiança.
          <br />
          <br />
          Nesse sentido, a presente Política de Privacidade (“Política”) explica
          de maneira clara e acessível como as suas informações e dados serão
          coletados, usados, compartilhados e armazenados por meio dos nossos
          sistemas.
          <br />
          <br />
          <b>
            A aceitação da nossa Política será feita quando você acessar ou usar
            o site, aplicativo ou serviços do APL SP Play. Isso indicará que
            você está ciente e em total acordo com a forma como utilizaremos as
            suas informações e seus dados.
          </b>
          <br />
          <br />
          A presente Política está dividida da seguinte forma para facilitar a
          sua compreensão:
          <br />
          <br />
          <div style={{marginLeft: '10px'}}>
            ⦁ Quais informações o APL SP Play coleta ⦁ Como o APL SP Play usa as
            informações coletadas
            <br />
            <br />
            ⦁ Como, quando e com quem o APL SP Play
            compartilha suas informações
            <br />
            <br />
            ⦁ Quais direitos você tem sobre as
            informações que coletamos
            <br />
            <br />
            ⦁ Como o APL SP Play protege suas
            informações
            <br />
            <br />
            ⦁ Atualizações dessa política de privacidade
            <br />
            <br />
            ⦁ Lei aplicável
            <br />
          </div>

          <br />
          Este documento deve ser lido em conjunto com o nosso Termos de Uso,
          que contém uma visão geral da nossa plataforma. Caso tenha dúvidas ou
          precise tratar de qualquer assunto relacionado a esta Política, entre
          em contato conosco através do e-mail
          <b>secretaria@apltimarilia.org.br</b>.
        </p>

        <p style={{ textAlign: "left" }}>
          <b>INFORMAÇÕES QUE COLETAMOS</b>
          <br />
          Nós coletamos as informações que você nos fornece, que incluem:
        </p>


        <p style={{ textAlign: "left", marginLeft: '10px' }}>
          ⦁ Dados de cadastro. Quando você se cadastra no APL SP Play, você nos
          fornece informações como nome completo, e-mail e CPF.
        </p>

        <p style={{ textAlign: "left" }}>
          <b>⦁ Informações geradas quando você usa nossos serviços.</b>
          <br />
          Nós coletamos as seguintes informações geradas:
        </p>

        <p style={{ textAlign: "left", marginLeft: '10px' }}>
          ⦁ Registros de acesso. O APL SP Play coleta automaticamente registros
          de acesso a aplicação, que incluem o endereço IP, com data e hora,
          utilizado para acessar o APL SP Play. Esses dados são de coleta
          obrigatória, de acordo com a Lei 12.965/2014, mas somente serão
          fornecidos para terceiros com a sua autorização expressa ou por meio
          de demanda judicial.
          <br />
          <br />
          ⦁ Dados de uso. Nós coletamos informações sobre suas interações no APL
          SP Play, como sua navegação, as páginas ou outro conteúdo que você
          acessa ou cria, suas buscas, participações em pesquisas ou fóruns e
          outras ações.
          <br />
          <br />
          ⦁ Características do equipamento. Como a maioria das aplicações, para
          poder funcionar o APL SP Play coleta automaticamente dados sobre as
          características do seu aparelho, dentre as quais o seu sistema
          operacional, a versão deste, informações de hardware, o idioma, sinal
          de internet e bateria.
          <br />
          <br />
          ⦁ Comunicações com o APL SP Play. Quando você se comunica com o APL SP
          Play, coletamos informações sobre sua comunicação, incluindo metadados
          como data, IP e hora das comunicações e todo o seu conteúdo, assim
          como qualquer informação que você escolha fornecer.
          <br />
          <br />⦁ Cookies e tecnologias semelhantes. Nós utilizamos cookies, que
          são arquivos de texto gerados e armazenados no seu navegador ou
          aparelho por sites, aplicativos e anúncios online. Os cookies poderão
          ser utilizados para as seguintes finalidades: autenticar usuários,
          lembrar preferências e configurações do usuário, e somente o
          necessário para garantir a sua navegação.
        </p>

        <p style={{ textAlign: "left" }}>
          <b>0. COMO USAMOS SUAS INFORMAÇÕES</b>
        </p>

        <p style={{ textAlign: "left" }}>
          Não custa lembrar, prezamos muito pela sua privacidade. Por isso,
          todos os dados e informações sobre você são tratadas como
          confidenciais, e somente as usaremos para os fins aqui descritos e
          autorizados por você, principalmente para que você possa utilizar o
          APL SP Play de forma plena, visando sempre melhorar a sua experiência
          como usuário.
        </p>

        <p style={{ textAlign: "left" }}>
          <b>⦁ Usos autorizados</b>
          <br />
          Desta forma, poderemos utilizar seus dados para:
        </p>

        <p style={{ textAlign: "left", marginLeft: '10px' }}>
          ⦁ Permitir que você acesse e utilize todas as funcionalidades do APL
          SP Play;
          <br />
          <br />
          ⦁ Enviar a você mensagens a respeito de suporte ou serviço, como
          alertas, notificações e atualizações;
          <br />
          <br />
          ⦁ Nos comunicar com você sobre produtos, serviços, promoções,
          notícias, atualizações, eventos e outros assuntos que você possa ter
          interesse;
          <br />
          <br />
          ⦁ Analisar o tráfego dos usuários em nossas aplicações;
          <br />
          <br />
          ⦁ Personalizar o serviço para este adequar cada vez mais aos seus
          gostos e interesses;
          <br />
          <br />
          ⦁ Criarmos novos serviços, produtos e funcionalidades;
          <br />
          <br />
          ⦁ Detecção e prevenção de fraudes, spam e incidentes de segurança;
          <br />
          <br />
          ⦁ Verificar ou autenticar as informações fornecidas por você,
          inclusive comparando a dados coletados de outras fontes;
          <br />
          <br />
          ⦁ Para qualquer fim que você autorizar no momento da coleta de dados;
          <br />
          <br />
          ⦁ Cumprir obrigações legais.
          <br />
        </p>

        <p style={{ textAlign: "left" }}>
          Eventualmente, poderemos utilizar dados para finalidades não previstas
          nesta política de privacidade, mas estas estarão dentro das suas
          legítimas expectativas.{" "}
          <b>
            O eventual uso dos seus dados para finalidades que não cumpram com
            essa prerrogativa será feito mediante sua autorização prévia.
          </b>
        </p>

        <p style={{ textAlign: "left" }}>
          <b>0. Exclusão dos dados</b>
          <br />
          <br />
          Todos os dados coletados serão excluídos de nossos servidores quando
          você assim requisitar, por procedimento gratuito e facilitado, ou
          quando estes não forem mais necessários ou relevantes para lhe
          oferecermos os nossos serviços, salvo se houver qualquer outra razão
          para a sua manutenção, como eventual obrigação legal de retenção de
          dados ou necessidade de preservação destes para resguardo de direitos
          do APL SP Play.
          <br />
          <br />
          Nos casos em que você solicite a exclusão dos seus dados, ela será
          feita prontamente, na medida do que for possível
        </p>

        <p style={{ textAlign: "left" }}>
          <b>0. Monitoramento</b>
          <br />
          <br />O APL SP Play se reserva no direito de monitorar toda a
          plataforma, principalmente para assegurar que as regras descritas em{" "}
          <b>nosso</b> Termos de Uso estão sendo observadas, ou ainda se não há
          violação ou abuso das leis aplicáveis.
          <br />
        </p>

        <p style={{ textAlign: "left" }}>
          <b>0. Exclusão de usuário</b>
          <br />
          <br />
          O APL SP Play se reserva no direito de excluir determinado usuário,
          independente do tipo que for, caso a presente Política ou os Termos de
          Uso não sejam respeitados. Como prezamos pelo bom relacionamento com
          os usuários, reconhecemos que têm o direito de buscar entender os
          motivos e até contestá-los, o que pode ser feito pelo seguinte e-mail:{" "}
          <b>secretaria@apltimarilia.org.br</b>.
          <br />
        </p>

        <p style={{ textAlign: "left" }}>
          <b>0. COMPARTILHAMENTO DAS INFORMAÇÕES</b>
          <br />
          <br />
          Todos os dados, informações e conteúdos sobre você podem ser
          considerados ativos no caso de negociações em que o APL SP Play fizer
          parte. Portanto, nos reservamos no direito de, por exemplo, incluir
          seus dados dentre os ativos da empresa caso esta venha a ser vendida,
          adquirida ou fundida com outra.
          <b>
            {" "}
            Por meio desta Política você concorda e está ciente desta
            possibilidade
          </b>
          . O APL SP Play se reserva no direito de fornecer seus dados e
          informações sobre você, incluindo interações suas, caso seja
          requisitado judicialmente para tanto, ato necessário para que a
          empresa esteja em conformidade com as leis nacionais, ou caso você
          autorize expressamente.
          <br />
        </p>

        <p style={{ textAlign: "left" }}>
          <b>0. DIREITOS DOS TITULARES</b>
          <br />
          <br />
          Você sempre poderá optar em não divulgar seus dados para nós, mas
          tenha em mente que alguns desses dados podem ser necessários para
          utilizar as funcionalidades de nossas aplicações. Independente disso,
          você sempre possuirá direitos relativos à privacidade e à proteção dos
          seus dados pessoais.
          <br />
          <br />
          Dessa forma, abaixo resumimos todos os direitos que você tem sob as
          leis setoriais brasileiras relativas a proteção de dados e a Lei Geral
          de Proteção de Dados (“LGPD”), quais sejam:
          <br />
          <br />
          <b>⦁ Direito de acesso.</b> Este direito permite que você possa
          requisitar e receber uma cópia dos dados pessoais que possuímos sobre
          Você.
          <br />
          <br />
          <b>⦁ Direito de retificação.</b> Este direito permite que você, a
          qualquer momento, possa solicitar a correção e/ou retificação dos seus
          dados pessoais, caso identifique que alguns deles estão incorretos.
          Contudo, para ser efetivada essa correção, teremos que checar a
          validade dos dados que você nos fornece. Você pode retificar
          diretamente alguns dos seus dados pessoais através do e-mail xxxxx.
          <br />
          <br />
          <b>⦁ Direito de exclusão.</b> Este direito permite que você possa nos
          solicitar a exclusão dos dados pessoais que possuímos sobre você.
          Todos os dados coletados serão excluídos de nossos servidores quando
          você assim requisitar ou quando estes não forem mais necessários ou
          relevantes para lhe oferecermos nossos serviços, salvo se houver
          qualquer outra razão para a sua manutenção, como eventual obrigação
          legal de retenção de dados ou necessidade de preservação destes para
          resguardo de direitos da APL SP Play. Para alterar suas informações
          pessoais ou excluí-las do nosso banco de dados, basta enviar um e-mail
          para xxxxx.
          <br />
          <br />
          <b>⦁ Direito de oposição ao processamento.</b> Você também tem o
          direito de contestar onde e em que contexto estamos tratando seus
          dados pessoais para diferentes finalidades. Em determinadas situações,
          podemos demonstrar que temos motivos legítimos para tratar seus dados,
          os quais se sobrepõem aos seus direitos, caso, por exemplo, sejam
          essenciais para o fornecimento de nossas aplicações.
          <br />
          <br />
          <b>
            ⦁ Direito de solicitar anonimização, bloqueio ou eliminação.
          </b>{" "}
          Este direito permite que você nos peça para suspender o processamento
          de seus dados pessoais nos seguintes cenários: (a) se você quiser que
          nós estabeleçamos a precisão dos dados; (b) quando você precisar que
          sejam mantidos os dados mesmo se não precisarmos mais deles, conforme
          necessário, para estabelecer, exercer ou defender reivindicações
          legais; ou (c) se você se opôs ao uso de seus dados, mas nesta
          hipótese precisamos verificar se temos motivos legítimos para usá-los.
          <br />
          <br />
          <b>⦁ Direito à portabilidade.</b> Forneceremos a você, ou a terceiros
          que você escolheu, seus dados pessoais em formato estruturado e
          interoperável.
          <br />
          <br />
          <b>⦁ Direito de retirar o seu consentimento.</b> Você tem o direito de
          retirar o seu consentimento em relação aos termos desta Política de
          Privacidade. No entanto, isso não afetará a legalidade de qualquer
          processamento realizado anteriormente. Se você retirar o seu
          consentimento, talvez não possamos fornecer determinados serviços.
          <br />
          <br />
          <b>⦁ Direito a revisão de decisões automatizadas.</b> Você também tem
          o direito de solicitar a revisão de decisões tomadas unicamente com
          base em tratamento automatizado de seus dados pessoais que afetem seus
          interesses, incluídas as decisões destinadas a definição de perfis
          pessoais, profissionais, de consumo e de crédito e/ou os aspectos de
          sua personalidade.
          <br />
          <br />
          Talvez seja necessário solicitar informações específicas suas para nos
          ajudar a confirmar sua identidade e garantir seu direito de acessar
          seus dados pessoais (ou de exercer seus outros direitos). Esta é uma
          medida de segurança para garantir que os dados pessoais não sejam
          divulgados a qualquer pessoa que não tenha direito de recebê-los.
          Podemos também contatá-lo para obter mais informações em relação à sua
          solicitação, a fim de acelerar nossa resposta. Tentamos responder a
          todas as solicitações legítimas dentro de 5 dias úteis.
          Ocasionalmente, pode levar mais de 5 dias se sua solicitação for
          particularmente complexa ou se você tiver feito várias solicitações.
          Neste caso, iremos comunicá-lo e mantê-lo atualizado sobre o andamento
          da sua solicitação.
          <br />
          <br />
          Caso Você tenha alguma dúvida sobre essas questões e sobre como você
          pode exercer esses direitos, fique à vontade para entrar em contato
          conosco no e-mail <b>secretaria@apltimarilia.org.br</b>.
        </p>

        <p style={{ textAlign: "left" }}>
          <b>0. SEGURANÇA DAS INFORMAÇÕES</b>
          <br />
          <br />
          Todos os seus dados são confidenciais e somente as pessoas com as
          devidas autorizações terão acesso a eles. Qualquer uso destes estará
          de acordo com a presente Política. O APL SP Play empreenderá todos os
          esforços razoáveis de mercado para garantir a segurança dos nossos
          sistemas e dos seus dados. Nossos servidores estão localizados em
          diferentes locais para garantir estabilidade e segurança, e somente
          podem ser acessados por meio de canais de comunicação previamente
          autorizados.
          <br />
          <br />
          Todas as suas informações serão, sempre que possível, criptografadas,
          caso não inviabilizem o seu uso pela plataforma. A qualquer momento
          você poderá requisitar cópia dos seus dados armazenados em nossos
          sistemas. Manteremos os dados e informações somente até quando estas
          forem necessárias ou relevantes para as finalidades descritas nesta
          Política, ou em caso de períodos pré-determinados por lei, ou até
          quando estas forem necessárias para a manutenção de interesses
          legítimos do APL SP Play.
          <br />
          <br />
          O APL SP Play considera a sua privacidade algo extremamente importante
          e fará tudo que estiver ao alcance para protegê-la. Todavia, não temos
          como garantir que todos os dados e informações sobre você em nossa
          plataforma estarão livres de acessos não autorizados, principalmente
          caso haja compartilhamento indevido das credenciais necessárias para
          acessar o nosso aplicativo. Portanto, você é o único responsável por
          manter sua senha de acesso em local seguro e é vedado o
          compartilhamento desta com terceiros. Você se compromete a notificar o
          APL SP Play imediatamente, através de meio seguro, a respeito de
          qualquer uso não autorizado de sua conta, bem como o acesso não
          autorizado por terceiros a esta.
          <br />

        </p>

        <p style={{ textAlign: "left" }}>
          <b>0. ATUALIZAÇÕES DA POLÍTICA DE PRIVACIDADE</b>
          <br />
          <br />
          O APL SP Play se reserva no direito de alterar essa Política quantas
          vezes forem necessárias, visando fornecer a você mais segurança,
          conveniência, e melhorar cada vez mais a sua experiência. É por isso
          que é muito importante acessar nossa Política periodicamente. Para
          facilitar, indicamos no início do documento a data da última
          atualização. Caso sejam feitas alterações relevantes que ensejem novas
          autorizações suas, publicaremos uma nova política de privacidade,
          sujeita novamente ao seu consentimento.
          <br />

        </p>

        <p style={{ textAlign: "left" }}>
          <b>0. LEI APLICÁVEL</b>
          <br />
          <br />
          Este documento é regido e deve ser interpretado de acordo com as leis
          da República Federativa do Brasil. Fica eleito o Foro da Comarca de
          Marília, São Paulo, como o competente para dirimir quaisquer questões
          porventura oriundas do presente documento, com expressa renúncia a
          qualquer outro, por mais privilegiado que seja.
        </p>
    </ModalComponent>
  );
}
