import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { HiOutlineMail } from 'react-icons/hi';
import { AiFillLinkedin } from 'react-icons/ai';
import AvatarImg from '../../../../assets/images/no-image.png';
import Medal1 from '../../../assets/images/medal/medal-1.svg';
import Medal2 from '../../../assets/images/medal/medal-2.svg';
import Medal3 from '../../../assets/images/medal/medal-3.svg';
import Medal4 from '../../../assets/images/medal/medal-4.svg';
import Medal5 from '../../../assets/images/medal/medal-5.svg';

import {
  Dashboard,
  UserProfissionalProps,
} from '../../../../interfaces/Entidades';

interface cardProfile {
  professional: UserProfissionalProps;
  professionalData: Dashboard;
}

export default function CardProfile({
  professional,
  professionalData,
}: cardProfile) {
  const medalRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    function loadMedal() {
      if (professionalData.nivel === 1) {
        if (medalRef.current) {
          medalRef.current.src = Medal1;
        }
      } else if (professionalData.nivel === 2) {
        if (medalRef.current) {
          medalRef.current.src = Medal2;
        }
      } else if (professionalData.nivel === 3) {
        if (medalRef.current) {
          medalRef.current.src = Medal3;
        }
      } else if (professionalData.nivel === 4) {
        if (medalRef.current) {
          medalRef.current.src = Medal4;
        }
      } else if (professionalData.nivel === 5) {
        if (medalRef.current) {
          medalRef.current.src = Medal5;
        }
      }
    }

    loadMedal();
  }, [professionalData]);

  return (
    <>
      <div className="col-xl-12">
        <div className="card card-custom gutter-b">
          <div className="card-body">
            <div className="d-flex mb-9">
              <div className="flex-shrink-0 mr-7 mt-lg-0 mt-3">
                <div className="symbol symbol-50 symbol-lg-120">
                  <img
                    style={{ objectFit: 'cover' }}
                    src={professional.foto ? professional.foto : AvatarImg}
                    alt="Foto Profissional"
                  />
                </div>
              </div>

              <div className="flex-grow-1">
                <div className="d-flex justify-content-between flex-wrap mt-1">
                  <div className="d-flex mr-3">
                    <span className="text-dark-75 font-size-h5 font-weight-bold mr-3">
                      {professional.nome}
                    </span>
                    <Link to="/">
                      <i className="flaticon2-correct text-success font-size-h5" />
                    </Link>
                  </div>
                </div>

                <div className="d-flex flex-wrap justify-content-between mt-1">
                  <div className="d-flex flex-column flex-grow-1 pr-8">
                    <div className="d-flex flex-wrap mb-4">
                      <HiOutlineMail
                        className="text-dark-50 font-weight-bold "
                        size="20"
                        color="#6F95FF"
                      />

                      <a
                        href={`mailto:${professional.email}`}
                        className="text-dark-50 font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                        style={{ cursor: 'pointer' }}
                      >
                        {professional.email}
                      </a>

                      {!professional.linkedIn ? (
                        <></>
                      ) : (
                        <div>
                          <>
                            <AiFillLinkedin
                              className="text-dark-50 font-weight-bold "
                              size="20"
                              color="#6F95FF"
                            />
                            <a
                              target="_blank"
                              href={professional.linkedIn}
                              className="text-dark-50 font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                              style={{ cursor: 'pointer' }}
                              rel="noreferrer"
                            >
                              {professional.linkedIn.split('//')[1]}
                            </a>
                          </>
                        </div>
                      )}
                    </div>
                    <span className="font-weight-bold text-dark-50">
                      {professional.descricao}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="separator separator-solid" />

            <div className="d-flex align-items-center flex-wrap mt-8">
              <div className="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                <div className="d-flex flex-column text-dark-75">
                  <span className="font-weight-bolder font-size-h6">
                    Trilhas Concluídas
                  </span>
                  <span
                    className="font-weight-bolder font-size-h3"
                    style={{ textAlign: 'center' }}
                  >
                    {professionalData.trilhasAssistidas}
                  </span>
                </div>
              </div>

              <div className="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                <div className="d-flex flex-column text-dark-75">
                  <span className="font-weight-bolder font-size-h6">
                    Cursos Concluídos
                  </span>
                  <span
                    className="font-weight-bolder font-size-h3"
                    style={{ textAlign: 'center' }}
                  >
                    {professionalData.cursosAssistidos}
                  </span>
                </div>
              </div>

              <div className="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                <div className="d-flex flex-column text-dark-75">
                  <span className="font-weight-bolder font-size-h6">
                    Aulas Concluídas
                  </span>
                  <span
                    className="font-weight-bolder font-size-h3"
                    style={{ textAlign: 'center' }}
                  >
                    {professionalData.objetosAssistidos}
                  </span>
                </div>
              </div>

              <div className="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                <div className="d-flex flex-column text-dark-75">
                  <span className="font-weight-bolder font-size-h6">
                    Desafios Concluídos
                  </span>
                  <span
                    className="font-weight-bolder font-size-h3"
                    style={{ textAlign: 'center' }}
                  >
                    {professionalData.desafiosRespondidos}
                  </span>
                </div>
              </div>

              <div className="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                <div className="d-flex flex-column text-dark-75">
                  <span className="font-weight-bolder font-size-h6">
                    Avaliações Respondidas
                  </span>
                  <span
                    className="font-weight-bolder font-size-h3"
                    style={{ textAlign: 'center' }}
                  >
                    {professionalData.avaliacoesRespondidas}
                  </span>
                </div>
              </div>

              <div className="d-flex align-items-center flex-lg-fill mb-2 float-left">
                <span className="mr-4">
                  <i className="flaticon-network display-4 text-muted font-weight-bold" />
                </span>
                <div className="symbol-group symbol-hover">
                  <div
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}
                    className="symbol symbol-100 symbol-circle"
                    data-toggle="tooltip"
                    title="Nivel"
                  >
                    <img alt="" ref={medalRef} src={Medal1} />
                    <span className="font-weight-bolder font-size-h5">
                      Nivel {professionalData.nivel}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
