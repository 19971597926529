/* eslint-disable no-param-reassign */
import React, { MouseEventHandler } from 'react';
import Modal from '@material-ui/core/Modal';
import { FiX } from 'react-icons/fi';
import './styles.scss';

interface ModalComponentProps {
  closeButton: MouseEventHandler<SVGElement>;
  width?: number | string;
  height?: number | string;
  open: boolean;
  children: React.ReactNode;
  onClose?: {
    bivarianceHack(event: {}, reason: 'backdropClick' | 'escapeKeyDown'): void;
  }['bivarianceHack'];
}

export default function ModalComponent({
  onClose,
  width,
  height,
  open,
  closeButton,
  children,
}: ModalComponentProps) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={{ width, height }} className="ModalComponentContainer">
        <FiX
          color="#f53030"
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            margin: 10,
            cursor: 'pointer',
          }}
          size={20}
          onClick={closeButton}
        />
        {children}
      </div>
    </Modal>
  );
}
