/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React, { useState } from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { FiEdit, FiNavigation } from 'react-icons/fi';
import { IoMdTrash } from 'react-icons/io';
import { LinearProgress, Tooltip } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import { useAuth } from '../../../hooks/Auth';
import TableAdmin from '../../../components/Table';
import { AvaliacaoProps } from '../../../interfaces/Entidades';
import DeleteModal from '../../../components/Modal/DeleteModal';

interface AvaliacoesTableProps {
  evaluations: AvaliacaoProps[];
  setEvaluations: React.Dispatch<React.SetStateAction<AvaliacaoProps[]>>;
  loading: boolean;
}

const BlueTooltip = withStyles({
  tooltip: {
    fontSize: 12,
    color: '#fff',
    backgroundColor: '#6F95FF',
  },
})(Tooltip);

export default function AvaliacoesTable({
  evaluations,
  loading,
  setEvaluations,
}: AvaliacoesTableProps) {
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteId, setDeleteId] = useState(0);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      {loading ? (
        <div>
          <LinearProgress />
        </div>
      ) : (
        <>
          <DeleteModal
            color="#6F95FF"
            itens={evaluations}
            setItens={setEvaluations}
            name="a avaliação"
            routeName="avaliacao"
            open={deleteModalOpen}
            id={deleteId}
            closeButton={() => setDeleteModalOpen(false)}
            onClose={() => setDeleteModalOpen(false)}
          />
          <TableAdmin
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            ItemLenght={evaluations.length}
            TableHeadCells={['Módulo', 'Curso', 'Ações']}
          >
            {evaluations.length > 0 ? (
              evaluations
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((evaluation) => (
                  <TableRow key={evaluation.id}>
                    <TableCell
                      component="th"
                      scope="row"
                      align="left"
                      className="cluster-table-body-font"
                    >
                      {evaluation.unidadeAprendizagem.titulo}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      align="left"
                      className="cluster-table-body-font"
                    >
                      {evaluation.unidadeAprendizagem.curso.nome}
                    </TableCell>

                    <TableCell
                      align="left"
                      className="cluster-table-body-action"
                    >
                      <BlueTooltip title="Visualizar prova" placement="bottom">
                        <Link
                          className="link-footer-no-styled"
                          to={{
                            pathname: `/test/${evaluation.id}`,
                          }}
                        >
                          <FiNavigation
                            size={18}
                            color="#80808F"
                            style={{ cursor: 'pointer' }}
                            className="cluster-table-svg"
                            onMouseOver={({ currentTarget }) =>
                              (currentTarget.style.color = '#6F95FF')
                            }
                            onMouseOut={({ currentTarget }) =>
                              (currentTarget.style.color = '#80808F')
                            }
                          />
                        </Link>
                      </BlueTooltip>

                      <BlueTooltip title="Editar prova" placement="bottom">
                        <Link
                          className="link-footer-no-styled"
                          to={{
                            pathname: '/create/test',
                            state: {
                              avaliacao: evaluation,
                              unidadeId: evaluation.unidadeAprendizagemId,
                              NomeCurso:
                                evaluation.unidadeAprendizagem.curso.nome,
                              editMode: true,
                            },
                          }}
                        >
                          <FiEdit
                            size={18}
                            color="#80808F"
                            style={{ cursor: 'pointer', marginLeft: 15 }}
                            className="cluster-table-svg"
                            onMouseOver={({ currentTarget }) =>
                              (currentTarget.style.color = '#6F95FF')
                            }
                            onMouseOut={({ currentTarget }) =>
                              (currentTarget.style.color = '#80808F')
                            }
                          />
                        </Link>
                      </BlueTooltip>
                      <BlueTooltip title="Excluir prova" placement="bottom">
                        <Link to="#">
                          <IoMdTrash
                            onMouseOver={({ currentTarget }) =>
                              (currentTarget.style.color = '#6F95FF')
                            }
                            onMouseOut={({ currentTarget }) =>
                              (currentTarget.style.color = '#80808F')
                            }
                            onClick={() => {
                              setDeleteId(evaluation.id);
                              setDeleteModalOpen(true);
                            }}
                            size={18}
                            color="#80808F"
                            style={{ marginLeft: 15, cursor: 'pointer' }}
                          />
                        </Link>
                      </BlueTooltip>
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <h2 style={{ textAlign: 'center' }}>Não há itens disponíveis</h2>
            )}
          </TableAdmin>
        </>
      )}
    </>
  );
}
