import React, { useCallback, useRef, useState, useEffect } from 'react';
import { Form } from '@unform/web';
import { FiUser } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import toast from 'react-hot-toast';
import { GiModernCity } from 'react-icons/gi';
import { OptionTypeBase } from 'react-select';
import * as Yup from 'yup';

import { API } from '../../../../services/api';
import SelectInput from '../../../components/SelectInput';
import GetValidationErrors from '../../../../utils/getValidationErros';
import { useAuth } from '../../../../hooks/Auth';
import Input from '../../../components/Input';
import { Button } from '../../../../components/Button';
import ModalComponent from '../../../../components/Modal/ModalComponent';
import { EditModalProps } from '../../../../interfaces/components';
import {
  ClusterProps,
  TipoClusterProps,
} from '../../../../interfaces/Entidades';

export default function EditModalCluster({
  closeButton,
  item,
  open,
  setModalOpen,
  onClose,
  id,
}: EditModalProps) {
  const { token } = useAuth();
  const [inputLoading, setInputLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cluster, setCluster] = useState<ClusterProps>({} as ClusterProps);
  const [options, setOptions] = useState<OptionTypeBase[]>();
  const formRef = useRef<FormHandles>(null);

  const Submit = useCallback(
    async (data) => {
      setLoading(true);

      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          descricao: Yup.string().required('Nome obrigatório!'),
          cidade: Yup.string().required('Cidade obrigatória!'),
          tipoClusterId: Yup.number().required(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await API(token).put('/api/cluster', {
          descricao: data.descricao,
          cidade: data.cidade,
          tipoClusterId: data.tipoClusterId,
          id,
        });
        setLoading(false);

        toast.success('Cluster alterado com sucesso!');
        await setTimeout(() => {
          window.location.reload();
        }, 1000);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = GetValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          toast.error('Houve um erro ao editar o cluster, tente novamente! 😢');
        }

        setLoading(false);
      }
    },

    [token, id],
  );

  useEffect(() => {
    async function loadData() {
      setInputLoading(true);

      try {
        const response_tipo = await API(token).get('/api/tipocluster/odata');

        const optionsFormat: OptionTypeBase[] = [];
        response_tipo.data.value.forEach((tipoCluster: TipoClusterProps) => {
          optionsFormat.push({
            value: tipoCluster.id,
            label: tipoCluster.descricao,
          });
        });

        setOptions(optionsFormat);
        setInputLoading(false);
      } catch (err) {
        toast.error('Houve um erro ao carregar os dados! 😢');
        setInputLoading(false);
      }
    }

    loadData();
  }, [token, item]);

  return (
    <ModalComponent closeButton={closeButton} open={open} onClose={onClose}>
      <Form ref={formRef} placeholder="" className="createmodal-form-admin" onSubmit={Submit}>
        <h2 id="simple-modal-title">Editar cluster</h2>
        <Input
          name="descricao"
          maxLength={80}
          loading={inputLoading}
          defaultValue={item ? item.descricao : ''}
          icon={FiUser}
          type="text"
          placeholder={inputLoading ? 'Carregando...' : 'Nome'}
        />

        <Input
          name="cidade"
          maxLength={80}
          loading={inputLoading}
          defaultValue={item ? item.cidade : ''}
          icon={GiModernCity}
          type="text"
          placeholder={inputLoading ? 'Carregando...' : 'Cidade'}
        />

        <SelectInput
          loading
          options={options}
          isLoading={inputLoading}
          defaultValue={
            Object.keys(item).length > 0
              ? {
                  value: item.tipoClusterId,
                  label: item.tipoCluster.descricao,
                }
              : {}
          }
          name="tipoClusterId"
          placeholder="Selecione o tipo do cluster..."
        />

        <Button
          color="#802A00"
          text="Confirmar"
          type="submit"
          loading={loading}
        />
      </Form>
    </ModalComponent>
  );
}
