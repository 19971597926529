/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import './styles.scss';

interface TableProps {
  TableHeadCells: string[];
  children: React.ReactNode;
  ItemLenght?: number;
  page?: number;
  rowsPerPage?: number;
  handleChangePage?: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => void;
  handleChangeRowsPerPage?: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;
  hasPagination?: boolean;
}

const TableComponent = ({
  TableHeadCells,
  children,
  ItemLenght,
  page,
  rowsPerPage,
  hasPagination = true,
  handleChangePage,
  handleChangeRowsPerPage,
}: TableProps) => {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const stopBug = () => {};

  return (
    <>
      <TableContainer>
        <Table className="trail-table">
          <TableHead className="trail-table-head">
            <TableRow className="trail-table-head">
              {TableHeadCells.map((tableHead) => (
                <TableCell
                  key={tableHead}
                  align="left"
                  className="trail-table-head-font"
                >
                  {tableHead}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
      {hasPagination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={ItemLenght || 0}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} de ${count}`
          }
          labelRowsPerPage="Linhas"
          rowsPerPage={rowsPerPage || 0}
          page={page || 0}
          onPageChange={handleChangePage || stopBug}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </>
  );
};

export default TableComponent;
