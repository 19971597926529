import React, { useCallback, useRef, useState } from 'react';
import { FiLock, FiMail } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';

import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import toast from 'react-hot-toast';
import logoImg from '../../assets/images/apl.png';

import { Button } from '../../components/Button';
import Input from '../../components/Input';

import './styles.scss';
import GetValidationErrors from '../../../utils/getValidationErros';
import { useAuth } from '../../../hooks/Auth';

interface signInData {
  Email: string;
  Senha: string;
}

export function SignIn() {
  const { signIn } = useAuth();
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);

  const Submit = useCallback(
    async (data: signInData) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          Email: Yup.string()
            .required('Email obrigatório')
            .email('Digite um Email válido!'),
          Senha: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await signIn({ Email: data.Email, Senha: data.Senha });
        setLoading(false);

        toast.success('Login realizado!');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = GetValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          toast.error('Email ou senha inválidos!');
        }

        setLoading(false);
      }
    },
    [signIn],
  );

  return (
    <div id="page-signIn">
      <main>
        <Form ref={formRef} placeholder="" onSubmit={Submit}>
          <img
            src={logoImg}
            alt=""
            style={{
              objectFit: 'cover',
              width: '100%',
              height: '120px',
            }}
          />
          <h2>Faça seu Login</h2>
          <Input
            name="Email"
            maxLength={80}
            icon={FiMail}
            type="text"
            placeholder="E-mail"
          />
          <Input
            name="Senha"
            icon={FiLock}
            type="password"
            placeholder="Senha"
            maxLength={20}
          />
          <p>
            <Link to="/forgotpassword">Esqueceu sua senha?</Link>
          </p>
          <Button text="Entrar" type="submit" loading={loading} />
        </Form>
        <span>
          Não tem uma conta? <Link to="/Cadastro">Criar conta</Link>
        </span>
      </main>
    </div>
  );
}
