import React, { ChangeEvent, useCallback, useRef, useState } from 'react';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { url } from 'inspector';
import { ModalProps } from '../../../../../interfaces/components';
import { useAuth } from '../../../../../hooks/Auth';
import { API } from '../../../../../services/api';
import {
  MateriaisComplementaresProps,
  ObjetosAprendizagemProps,
} from '../../../../../interfaces/Entidades';
import GetValidationErrors from '../../../../../utils/getValidationErros';
import ModalComponent from '../../../../../components/Modal/ModalComponent';
import Input from '../../../../components/Input';
import TextAreaInput from '../../../../components/TextAreaInput';
import { Button } from '../../../../../components/Button';
import SwitchInput from '../../../../components/InputSwitch';

interface CreateCursoModalProps extends ModalProps {
  moduloId: number;
}

export default function CreateClassModal({
  moduloId,
  open,
  onClose,
  closeButton,
  itens,
  setItens,
  setModalOpen,
}: CreateCursoModalProps) {
  const { token } = useAuth();
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const [hasUrlVideo, setHasUrlVideo] = useState(true);

  const Submit = useCallback(
    async (data: ObjetosAprendizagemProps) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          titulo: Yup.string().required('Campo Obrigatório'),
          conteudo: Yup.string().required('Campo Obrigatório'),
          urlVideo: Yup.string(),
        });

        if (!hasUrlVideo) {
          data.urlVideo = undefined;
        }

        data.ordem = 0;

        itens.forEach((item) => {
          if (item.ordem > data.ordem) {
            data.ordem = item.ordem;
          }
        });

        data.ordem += 1;
        data.unidadeAprendizagemId = moduloId;

        await schema.validate(data, {
          abortEarly: false,
        });

        const response = await API(token).post<ObjetosAprendizagemProps>(
          '/api/objetoAprendizagem',
          data,
        );

        setLoading(false);

        data.id = response.data.id;

        setItens([...itens, data]);
        setModalOpen(false);

        toast.success('Aula criada com sucesso!');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = GetValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          toast.error('Houve um erro ao criar a aula, tente novamente!');
        }

        setLoading(false);
      }
    },

    [itens, moduloId, token, setItens, setModalOpen],
  );

  return (
    <ModalComponent
      closeButton={closeButton}
      open={open}
      onClose={onClose}
      width={1100}
    >
      <Form ref={formRef} placeholder="" className="createmodal-form-admin" onSubmit={Submit}>
        <h2 id="simple-modal-title">Adicionar Aula</h2>

        <Input
          name="titulo"
          maxLength={100}
          type="text"
          placeholder="Titulo da Aula"
        />

        <TextAreaInput
          name="conteudo"
          maxLength={300}
          placeholder="Descrição da Aula"
          style={{ marginBottom: 10 }}
        />

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'baseline',
          }}
        >
          <span style={{ marginRight: 10, fontSize: 16 }}>Possui Vídeo</span>
          <SwitchInput
            name="ativo"
            defaultChecked={hasUrlVideo}
            onClick={() => setHasUrlVideo(!hasUrlVideo)}
          />
        </div>

        {hasUrlVideo ? (
          <Input
            name="urlVideo"
            maxLength={300}
            type="text"
            placeholder="URL do vídeo"
          />
        ) : null}

        <Button
          color="#6F95FF"
          text="Confirmar"
          type="submit"
          loading={loading}
        />
      </Form>
    </ModalComponent>
  );
}
