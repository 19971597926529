/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React, {
  useState,
  useCallback,
  BaseSyntheticEvent,
  useEffect,
} from 'react';
import { Pagination } from '@material-ui/lab';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import CreateCursoModal from './CreateCursoModal';
import AvatarImg from '../../../assets/images/no-image.png';
import { API } from '../../../services/api';
import { useAuth } from '../../../hooks/Auth';
import DetailsPageComponent from '../../components/DetailsPage';
import { CursoProps } from '../../../interfaces/Entidades';

const CoursesPage = () => {
  const { token, user } = useAuth();
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [cursos, setCursos] = useState<CursoProps[]>([]);

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filteredCursos, setFilteredCursos] = useState<CursoProps[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(9);

  const handleFilter = useCallback(
    (text: string) => {
      const filterCursos = cursos.filter((curso) =>
        curso.nome.toUpperCase().includes(text.toUpperCase()),
      );
      if (filterCursos.length > 0) {
        setFilteredCursos(filterCursos);
      } else if (text === '') {
        setFilteredCursos(cursos);
      } else {
        setFilteredCursos([]);
      }

      const pagesCount = Math.ceil(filterCursos.length / rowsPerPage);
      setPageCount(pagesCount);
      if (pagesCount <= 1) {
        setPage(0);
      }
    },
    [cursos, rowsPerPage],
  );

  useEffect(() => {
    function setNewCursos() {
      setFilteredCursos(cursos);
      setPageCount(Math.ceil(cursos.length / rowsPerPage));
    }

    setNewCursos();
  }, [cursos, rowsPerPage]);

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      try {
        const response = await API(token).get(
          `/api/curso/odata?$select=nome,descricao,urlImagem,id&$filter=organizacaoId eq ${user.organizacao}`,
        );

        setPageCount(Math.ceil(response.data.value.length / rowsPerPage));
        setCursos(response.data.value);
        setFilteredCursos(response.data.value);
        setLoading(false);
      } catch (err) {
        toast.error('Houve um erro ao carregar os cursos, tente novamente! 😢');
      }
    }

    loadData();
  }, [token, rowsPerPage, user.organizacao]);

  return (
    <>
      <CreateCursoModal
        itens={cursos}
        open={createModalOpen}
        setModalOpen={setCreateModalOpen}
        setItens={setCursos}
        onClose={() => setCreateModalOpen(false)}
        closeButton={() => setCreateModalOpen(false)}
      />

      <DetailsPageComponent
        InputOnChange={(event: BaseSyntheticEvent) =>
          handleFilter(event.target.value)
        }
        hasCard={false}
        buttonHeaderText="Adicionar Curso"
        setCreateModalOpen={setCreateModalOpen}
        loading={loading}
        headerBack={false}
        headerTitle="Cursos"
      >
        {filteredCursos.length > 0 ? (
          filteredCursos
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((curso) => (
              <div
                key={curso.id}
                className="admin-trails-card"
                style={{ width: '31%' }}
              >
                <div className="admin-trails-card-header">
                  <div>
                    <img
                      src={curso.urlImagem ? curso.urlImagem : AvatarImg}
                      alt=""
                    />
                  </div>
                  <div
                    style={{
                      padding: 10,
                    }}
                  >
                    <h2>{curso.nome}</h2>
                  </div>
                </div>

                <div className="admin-trails-card-descriptions">
                  <span>{curso.descricao}</span>
                </div>

                <div className="admin-trails-card-footer">
                  <Link to={`/course/${curso.id}`}>
                    <span>Detalhes</span>
                  </Link>
                </div>
              </div>
            ))
        ) : (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <h2>Não foram encontrados itens</h2>
          </div>
        )}
        <div className="admin-trail-footer">
          <Pagination
            count={pageCount}
            page={page + 1}
            hideNextButton={pageCount < 2}
            hidePrevButton={pageCount < 2}
            onChange={(event: object, page_select: number) => {
              setPage(page_select - 1);
            }}
          />
        </div>
      </DetailsPageComponent>
    </>
  );
};

export default CoursesPage;
