import React, { useState, useEffect } from 'react';
import { TableCell, TableRow } from '@material-ui/core';

import { format, subDays } from 'date-fns';

// eslint-disable-next-line import/no-extraneous-dependencies
import ReactApexChart from 'react-apexcharts';
import TableComponent from '../../../components/Table';
import { Button } from '../../../components/Button';

const labels = [
  'Curso de Algoritmo',
  'Curso de HTML',
  'Curso de CSS',
  'Curso de Javascript',
  'Curso de Modelagem de Dados',
  'Curso de Mysql',
  'Curso de Figma',
  'Curso de PhotoShop',
  'Curso de Java',
  'Curso de PHP',
  'Curso de Asp.net Core',
  'Curso de Asp.net Core MVC',
  'Curso de WordPress',
  'Curso de React',
  'Curso de Angular 13',
  'Curso de Vue.js 3',
  'Curso de Python',
  'Curso de Python para Machine Learning',
  'Curso de Introdução ao desenvolvimento iOS',
  'Curso de Introdução ao desenvolvimento Android (Kotlin)',
  'Curso de Flutter',
  'Curso de React Native',
  'Curso de Linux',
  'Curso de Linguagem C',
  'Curso de Unity',
  'Curso de Ruby',
  'Curso de Git e GitHub',
  'Curso de Arduino',
  'Curso de ChatGPT: Como Usar Chat GPT',
  'Curso de Inteligência Artificial com Python',
  'Curso de Noje.JS',
  'Curso de Django 2.0',
  'Curso de Docker',
];

const ChartData: any = {
  options: {
    chart: {
      id: 'curso-chart',
      type: 'bar',
    },
    xaxis: {
      categories: labels,
      labels: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: '30px',
      },
    },
    colors: ['#802A00'],

    dataLabels: {
      enabled: true,
    },
  },
  series: [
    {
      name: 'Quantidade de Acessos por curso',
      data: [
        3289, 2422, 2122, 2991, 1723, 1771, 1133, 2013, 2489, 1754, 1887, 1432,
        876, 3656, 3229, 3238, 3265, 3266, 1952, 2234, 3379, 3576, 1452	, 1567,
        2310, 2144, 2983, 1601, 2434, 2798, 2515, 2087, 2515,
      ],
    },
  ],
};

const Chart2Data: any = {
  series: [
    {
      name: 'Quantidade de Acessos por trilha',
      data: [
        3289, 7535, 3494, 3146, 2489, 3319, 876, 10123, 6531, 11141, 1452, 1567,
        5873, 2144, 2983, 1601, 2798, 2515, 2527,
      ],
    },
  ],
  options: {
    chart: {
      type: 'bar',
      height: 350,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      },
    },
    colors: ['#802A00'],
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [
        'Trilha Introdução a Algoritmos',
        'Trilha Inicio do desenvolvimento WEB',
        'Trilha Banco de Dados',
        'Trilha Design',
        'Trilha Java',
        'Trilha de Asp.net Core',
        'Trilha WordPress',
        'Trilha Desenvolvimento WEB',
        'Trilha Python',
        'Trilha Desenvolvimento Mobile',
        'Trilha Sistema Operacional',
        'Trilha Linguagem C',
        'Trilha Unity',
        'Trilha Ruby',
        'Trilha Git',
        'Trilha Arduino',
        'Trilha Inteligência Artificial',
        'Trilha Noje.JS',
        'Trilha Docker',
      ],
      labels: {
        show: false,
      },
    },
  },
};

export default function Home() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [dates, setDates] = useState<string[]>([]);

  const valuesForCourse = [
    {
      trail: 'Trilha Introdução a Algoritmos',
      course: 'Curso de Algoritmos',
      qtd: 3289,
      lastUpdate: dates[5],
    },
    {
      trail: 'Trilha Inicio do desenvolvimento WEB',
      course: 'Curso de HTML',
      qtd: 2422,
      lastUpdate: dates[0],
    },
    {
      trail: 'Trilha Inicio do desenvolvimento WEB',
      course: 'Curso de CSS',
      qtd: 2122,
      lastUpdate: dates[0],
    },
    {
      trail: 'Trilha Inicio do desenvolvimento WEB',
      course: 'Curso de Javascript',
      qtd: 2991,
      lastUpdate: dates[0],
    },
    {
      trail: 'Trilha Banco de Dados',
      course: 'Curso de Modelagem de Dados',
      qtd: 1723,
      lastUpdate: dates[2],
    },
    {
      trail: 'Trilha Banco de Dados',
      course: 'Curso de Mysql',
      qtd: 1771,
      lastUpdate: dates[2],
    },
    {
      trail: 'Trilha Design',
      course: 'Curso de Figma',
      qtd: 1133,
      lastUpdate: dates[1],
    },
    {
      trail: 'Trilha Design',
      course: 'Curso de Photoshop',
      qtd: 2013,
      lastUpdate: dates[1],
    },
    {
      trail: 'Trilha Java',
      course: 'Curso de Java',
      qtd: 2489,
      lastUpdate: dates[0],
    },
    {
      trail: 'Trilha PHP',
      course: 'Curso de PHP',
      qtd: 1754,
      lastUpdate: dates[4],
    },
    {
      trail: 'Trilha de Asp.net Core',
      course: 'Curso de Asp.net Core',
      qtd: 1887,
      lastUpdate: dates[0],
    },
    {
      trail: 'Trilha de Asp.net Core',
      course: 'Curso de Asp.net Core MVC',
      qtd: 1432,
      lastUpdate: dates[0],
    },
    {
      trail: 'Trilha de WordPress',
      course: 'Curso de WordPress',
      qtd: 876,
      lastUpdate: dates[5],
    },
    {
      trail: 'Trilha de Desenvolvimento WEB',
      course: 'Curso de React',
      qtd: 3656,
      lastUpdate: dates[0],
    },
    {
      trail: 'Trilha de Desenvolvimento WEB',
      course: 'Curso de Angular 13',
      qtd: 3229,
      lastUpdate: dates[0],
    },
    {
      trail: 'Trilha de Desenvolvimento WEB',
      course: 'Curso de Vue.js 3',
      qtd: 3238,
      lastUpdate: dates[0],
    },
    {
      trail: 'Trilha Python',
      course: 'Curso de Python',
      qtd: 3265,
      lastUpdate: dates[1],
    },
    {
      trail: 'Trilha de Python',
      course: 'Curso de Python para Machine Learning',
      qtd: 3266,
      lastUpdate: dates[1],
    },
    {
      trail: 'Trilha de Desenvolvimento Mobile',
      course: 'Curso de Introdução ao desenvolvimento iOS',
      qtd: 1952,
      lastUpdate: dates[3],
    },
    {
      trail: 'Trilha de Desenvolvimento Mobile',
      course: 'Curso de Introdução ao desenvolvimento Android (Kotlin)',
      qtd: 2234,
      lastUpdate: dates[3],
    },
    {
      trail: 'Trilha de Desenvolvimento Mobile',
      course: 'Curso de Flutter',
      qtd: 3379,
      lastUpdate: dates[3],
    },
    {
      trail: 'Trilha de Desenvolvimento Mobile',
      course: 'Curso de React Native',
      qtd: 3576,
      lastUpdate: dates[3],
    },
    {
      trail: 'Trilha de Sistema Operacional',
      course: 'Curso de Linux',
      qtd: 1452,
      lastUpdate: dates[9],
    },
    {
      trail: 'Trilha de Linguagem C',
      course: 'Curso de Linguagem C',
      qtd: 1567,
      lastUpdate: dates[7],
    },
    {
      trail: 'Trilha de Unity',
      course: 'Curso de Unity',
      qtd: 2310,
      lastUpdate: dates[6],
    },
    {
      trail: 'Trilha de Ruby',
      course: 'Curso de Ruby',
      qtd: 2144,
      lastUpdate: dates[9],
    },
    {
      trail: 'Trilha de Git',
      course: 'Curso de Git e GitHub',
      qtd: 2983,
      lastUpdate: dates[1],
    },
    {
      trail: 'Trilha de Arduino',
      course: 'Curso de Arduino',
      qtd: 1601,
      lastUpdate: dates[8],
    },
    {
      trail: 'Trilha de ChatGPT',
      course: 'Curso de ChatGPT: Como Usar Chat GPT',
      qtd: 2434,
      lastUpdate: dates[5],
    },
    {
      trail: 'Trilha de Inteligência Artificial',
      course: 'Curso de Inteligência Artificial com Python',
      qtd: 2798,
      lastUpdate: dates[4],
    },
    {
      trail: 'Trilha de Noje.JS',
      course: 'Curso de Noje.JS',
      qtd: 2515,
      lastUpdate: dates[2],
    },
    {
      trail: 'Trilha de Django',
      course: 'Curso de Django 2.0',
      qtd: 2087,
      lastUpdate: dates[7],
    },
    {
      trail: 'Trilha de Docker',
      course: 'Curso de Docker',
      qtd: 2515,
      lastUpdate: dates[5],
    },
  ];

  const valuesForTrail = [
    {
      trail: 'Trilha Introdução a Algoritmos',
      qtd: 3289,
      lastUpdate: dates[5],
    },
    {
      trail: 'Trilha Inicio do desenvolvimento WEB',
      qtd: 7535,
      lastUpdate: dates[0],
    },
    {
      trail: 'Trilha Banco de Dados',
      qtd: 3494,
      lastUpdate: dates[2],
    },
    {
      trail: 'Trilha Design',
      qtd: 3146,
      lastUpdate: dates[1],
    },
    {
      trail: 'Trilha Java',
      qtd: 2489,
      lastUpdate: dates[0],
    },
    {
      trail: 'Trilha de Asp.net Core',
      qtd: 3319,
      lastUpdate: dates[0],
    },
    {
      trail: 'Trilha de WordPress',
      qtd: 876,
      lastUpdate: dates[5],
    },
    {
      trail: 'Trilha de Desenvolvimento WEB',
      qtd: 10123,
      lastUpdate: dates[0],
    },
    {
      trail: 'Trilha de Python',
      qtd: 6531,
      lastUpdate: dates[1],
    },
    {
      trail: 'Trilha de Desenvolvimento Mobile',
      qtd: 11141,
      lastUpdate: dates[3],
    },
    {
      trail: 'Trilha de Sistema Operacional',
      qtd: 1452,
      lastUpdate: dates[9],
    },
    {
      trail: 'Trilha de Linguagem C',
      qtd: 1567,
      lastUpdate: dates[7],
    },
    {
      trail: 'Trilha de Unity',
      qtd: 5873,
      lastUpdate: dates[6],
    },
    {
      trail: 'Trilha de Ruby',
      qtd: 2144,
      lastUpdate: dates[9],
    },
    {
      trail: 'Trilha de Git',
      qtd: 2983,
      lastUpdate: dates[1],
    },
    {
      trail: 'Trilha de Arduino',
      qtd: 1601,
      lastUpdate: dates[8],
    },
    {
      trail: 'Trilha de Inteligência Artificial',
      qtd: 2798,
      lastUpdate: dates[4],
    },
    {
      trail: 'Trilha de Noje.JS',
      qtd: 2515,
      lastUpdate: dates[2],
    },
    {
      trail: 'Trilha de Docker',
      qtd: 2527,
      lastUpdate: dates[5],
    },
  ];

  useEffect(() => {
    const generateDates = () => {
      const currentDate = new Date();
      const generatedDates: string[] = [];

      for (let i = 0; i < 10; i++) {
        const newDate = subDays(currentDate, i); // Subtrai os dias de 0 a 9
        const formattedDate = format(newDate, 'dd MMM yyyy');
        generatedDates.push(formattedDate);
      }

      setDates(generatedDates);
    };

    generateDates();
  }, []);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <div className="cluster-table-container">
        <div className="cluster-table-card">
          <div className="cluster-table-card-header">
            <h2 style={{ marginBottom: 30, color: '#802A00' }}>
              Quantidade de acesso por curso
            </h2>

            <Button color="#802A00" text="Atualizar Informação" />
          </div>

          <TableComponent
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            ItemLenght={valuesForCourse.length}
            TableHeadCells={[
              'Trilha',
              'Curso',
              'Quantidade de Usuários',
              'Último acesso',
            ]}
          >
            {valuesForCourse
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((value) => (
                <TableRow key={value.course}>
                  <TableCell
                    component="th"
                    scope="row"
                    align="left"
                    className="cluster-table-body-font"
                  >
                    {value.trail}
                  </TableCell>
                  <TableCell align="left" className="cluster-table-body-font">
                    {value.course}
                  </TableCell>
                  <TableCell align="left" className="cluster-table-body-font">
                    {value.qtd}
                  </TableCell>
                  <TableCell align="left" className="cluster-table-body-font">
                    {value.lastUpdate}
                  </TableCell>
                </TableRow>
              ))}
          </TableComponent>
        </div>
      </div>

      <div className="cluster-table-container">
        <div className="cluster-table-card">
          <div className="cluster-table-card-header">
            <h2 style={{ marginBottom: 30, color: '#802A00' }}>
              Quantidade de acesso por curso
            </h2>
          </div>

          <div id="chart">
            <ReactApexChart
              options={ChartData.options}
              series={ChartData.series}
              type="bar"
              width="100%"
              height={350}
            />
          </div>
        </div>
      </div>

      <div className="cluster-table-container">
        <div className="cluster-table-card">
          <div className="cluster-table-card-header">
            <h2 style={{ marginBottom: 30, color: '#802A00' }}>
              Quantidade de acesso por trilha
            </h2>

            <Button color="#802A00" text="Atualizar Informação" />
          </div>

          <TableComponent
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            ItemLenght={valuesForTrail.length}
            TableHeadCells={[
              'Trilha',
              'Quantidade de Usuários',
              'Último acesso',
            ]}
          >
            {valuesForTrail
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((value) => (
                <TableRow key={value.trail}>
                  <TableCell
                    component="th"
                    scope="row"
                    align="left"
                    className="cluster-table-body-font"
                  >
                    {value.trail}
                  </TableCell>
                  <TableCell align="left" className="cluster-table-body-font">
                    {value.qtd}
                  </TableCell>
                  <TableCell align="left" className="cluster-table-body-font">
                    {value.lastUpdate}
                  </TableCell>
                </TableRow>
              ))}
          </TableComponent>
        </div>
      </div>

      <div className="cluster-table-container">
        <div className="cluster-table-card">
          <div className="cluster-table-card-header">
            <h2 style={{ marginBottom: 30, color: '#802A00' }}>
              Quantidade de acesso por trilha
            </h2>
          </div>

          <div id="chart">
            <ReactApexChart
              options={Chart2Data.options}
              series={Chart2Data.series}
              type="bar"
              width="100%"
              height={350}
            />
          </div>
        </div>
      </div>
    </>
  );
}
