import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Rating, Skeleton } from '@material-ui/lab';
import { TrailProps } from '../../../../../interfaces/Entidades';

import '../styles.scss';

interface CardTitleAndLearnProps {
  trilha: TrailProps;
  loading: boolean;
}

export default function CardTitleAndLearn({
  trilha,
  loading,
}: CardTitleAndLearnProps) {
  return (
    <>
      <div className="col-xl-8">
        <div
          className="card card-custom bgi-no-repeat gutter-b card-stretch"
          style={{
            backgroundPosition: 'left top',
            backgroundSize: '20% auto',
            height: '33%',
          }}
        >
          <div className="card-body">
            <div className="font-weight-bolder font-size-h5 color">
              {loading ? <Skeleton variant="text" /> : trilha.nome}
            </div>
            <p className="font-weight-bolder font-size-h7 m-0 text-muted line-clamp">
              {loading ? (
                <Skeleton variant="text" width="20%" />
              ) : (
                trilha.resumo
              )}
            </p>

            {/* {loading ? (
              <Skeleton variant="text" width={100} />
            ) : (
              <Rating name="disabled" value={3} disabled />
            )} */}

            {/* <p className="text-dark-75 font-size-sm font-size-base m-0 text-muted">
              {loading ? (
                <Skeleton variant="text" width={300} />
              ) : (
                '(5.000 classificação) 10.000 alunos inscritos'
              )}
            </p> */}

            {loading ? (
              <Skeleton
                variant="text"
                height="56px"
                style={{ marginTop: '-7px' }}
              />
            ) : (
              <p className="text-dark-75 font-size-sm  font-size-base m-0 text-muted">
                Criado por{' '}
                <Link
                  to={{ pathname: `/details_company/${trilha.organizacaoId}` }}
                  className="font-weight-bolder"
                >
                  {trilha.organizacao.razaoSocial}
                </Link>
              </p>
            )}

            <div className="categoryContainer">
              {loading ? (
                <></>
              ) : (
                trilha.categorias.slice(0, 4).map((categoria) => (
                  <div className="categoryCard">
                    <span>{categoria.categoria.descricao}</span>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>

        <div
          className="card card-custom card-stretch gutter-b"
          style={{ height: '60%' }}
        >
          <div className="card-header border-0 py-5">
            {loading ? (
              <Skeleton variant="text" width="70%" />
            ) : (
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label font-weight-bolder text-dark">
                  O que você aprenderá
                </span>
              </h3>
            )}
          </div>
          <div className="card-body py-0">
            <div className="WhatWillLearnContainer">
              {!loading ? (
                trilha.resumoAprendizagem.split('[*]').map((resumo) => (
                  <>
                    <p className="font-weight-bold text-dark-75 text-hover-primary font-size-lg line-clamp">
                      ✔ {resumo}
                    </p>
                  </>
                ))
              ) : (
                <>
                  <div>
                    <Skeleton variant="text" width="100%" height={30} />
                    <Skeleton variant="text" width="100%" height={30} />
                    <Skeleton variant="text" width="100%" height={30} />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
