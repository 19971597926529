/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useEffect, useState } from 'react';

import { Skeleton } from '@material-ui/lab';
import toast from 'react-hot-toast';
import {
  ClusterProps,
  CompanyProps,
  tipoOrganizacaoProps,
} from '../../../../interfaces/Entidades';
import { useAuth } from '../../../../hooks/Auth';
import { API } from '../../../../services/api';
import { Button } from '../../../components/Button';
import { CardSearchCompany } from './components/cardCompany';

import '../style.scss';

export default function SearchCompany() {
  const [loadingCategoria, setLoadingCategoria] = useState(true);
  const [loadingCluster, setLoadingCluster] = useState(true);
  const [loadingEmpresa, setLoadingEmpresa] = useState(true);
  const { token } = useAuth();
  const [categories, setCategories] = useState<tipoOrganizacaoProps[]>([]);
  const [clusters, setClusters] = useState<ClusterProps[]>([]);
  const [empresas, setEmpresas] = useState<CompanyProps[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string[]>([]);
  const [selectedCluster, setSelectedCluster] = useState<string[]>([]);
  const [CompanyName, setCompanyName] = useState('');

  useEffect(() => {
    async function loadInitialData() {
      setLoadingEmpresa(true);
      setLoadingCategoria(true);
      setLoadingCluster(true);

      try {
        const response_cluster = await API(token).get(
          '/api/cluster/odata?$select=id,descricao',
        );

        const response_categoria = await API(token).get(
          '/api/tipoorganizacao/odata',
        );
        const response_empresa = await API(token).get(
          '/api/organizacao/search',
        );

        setClusters(response_cluster.data.value);
        setCategories(response_categoria.data.value);
        setEmpresas(response_empresa.data.value);
      } finally {
        setLoadingEmpresa(false);
        setLoadingCategoria(false);
        setLoadingCluster(false);
      }
    }

    loadInitialData();
  }, [token]);

  const handleFilter = useCallback(async () => {
    setLoadingEmpresa(true);

    try {
      let params: any = { $search: CompanyName };

      if (selectedCategory.length > 0 && selectedCluster.length <= 0) {
        let allCategories = '';

        selectedCategory.forEach(
          // eslint-disable-next-line no-return-assign
          (categoria, index) => {
            if (index === selectedCategory.length - 1) {
              allCategories += `${categoria}`;
            } else {
              allCategories += `${categoria},`;
            }
          },
        );

        params = {
          $filter: `tipoOrganizacaoId in (${allCategories})`,
          $search: CompanyName,
        };
      } else if (selectedCategory.length <= 0 && selectedCluster.length > 0) {
        let allClusters = '';

        selectedCluster.forEach(
          // eslint-disable-next-line no-return-assign
          (cluster, index) => {
            if (index === selectedCluster.length - 1) {
              allClusters += `${cluster}`;
            } else {
              allClusters += `${cluster},`;
            }
          },
        );

        params = {
          $filter: `clusterId in (${allClusters})`,
          $search: CompanyName,
        };
      } else if (selectedCluster.length > 0 && selectedCategory.length > 0) {
        let allClusters = '';

        selectedCluster.forEach(
          // eslint-disable-next-line no-return-assign
          (cluster, index) => {
            if (index === selectedCluster.length - 1) {
              allClusters += `${cluster}`;
            } else {
              allClusters += `${cluster},`;
            }
          },
        );

        let allCategories = '';

        selectedCategory.forEach(
          // eslint-disable-next-line no-return-assign
          (categoria, index) => {
            if (index === selectedCategory.length - 1) {
              allCategories += `${categoria}`;
            } else {
              allCategories += `${categoria},`;
            }
          },
        );

        params = {
          $filter: `clusterId in (${allClusters}) and tipoOrganizacaoId in (${allCategories})`,
          $search: CompanyName,
        };
      }

      const response = await API(token).get('/api/organizacao/search', {
        params,
      });

      if (CompanyName) {
        setEmpresas(response.data);
      } else {
        setEmpresas(response.data.value);
      }
    } catch (err) {
      toast.error('Houve um erro ao realizar a pesquisa! 😢');
    } finally {
      setLoadingEmpresa(false);
    }
  }, [token, selectedCategory, selectedCluster, CompanyName]);

  return (
    <>
      <div className="row">
        <div className="col-md-4" style={{ height: '100%' }}>
          {/* begin::Mixed Widget 16 */}
          <div className="card card-custom card-stretch gutter-b">
            {/* begin::Header */}
            <div className="row" />
            <div className="card-body">
              {/* begin::Form */}
              <div className="form-group mb-7">
                <label className="font-size-h3 font-weight-bolder text-dark mb-7">
                  Empresas para você
                </label>

                <input
                  type="text"
                  className="form-control h-40px"
                  placeholder="Procurar Empresas..."
                  onChange={(event) => setCompanyName(event.target.value)}
                />
              </div>

              <form id="form-teste">
                <div className="form-group mb-7">
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'baseline',
                    }}
                  >
                    <label className="font-size-h3 font-weight-bolder text-dark mb-7">
                      Clusters
                    </label>
                  </div>

                  <div className="radio-list">
                    {loadingCluster && (
                      <>
                        <Skeleton variant="rect" width="100%" />
                        <br />
                        <Skeleton variant="rect" width="100%" />
                        <br />
                        <Skeleton variant="rect" width="100%" />
                      </>
                    )}
                    {!loadingCluster && (
                      <>
                        {clusters.map((cluster, index) => (
                          <label
                            key={cluster.id}
                            className="customSearchCheckBox"
                            htmlFor={`cluster${index.toString()}`}
                          >
                            <input
                              type="checkbox"
                              name="cluster"
                              disabled={loadingCluster}
                              id={`cluster${index.toString()}`}
                              value={cluster.id}
                              onChange={(event) => {
                                if (!event.target.checked) {
                                  setSelectedCluster((oldValues) =>
                                    oldValues.filter(
                                      (value) => value !== event.target.value,
                                    ),
                                  );
                                } else {
                                  setSelectedCluster((oldValues) => [
                                    ...oldValues,
                                    event.target.value,
                                  ]);
                                }
                              }}
                            />
                            <span />
                            <div className="font-size-lg text-dark-75 font-weight-bold">
                              {cluster.descricao}
                            </div>
                          </label>
                        ))}
                      </>
                    )}
                  </div>
                </div>

                <div className="form-group mb-7">
                  <label className="font-size-h3 font-weight-bolder text-dark mb-7">
                    Categoria
                  </label>

                  <div className="radio-list">
                    {loadingCategoria && (
                      <>
                        <Skeleton variant="rect" width="100%" />
                        <br />
                        <Skeleton variant="rect" width="100%" />
                        <br />
                        <Skeleton variant="rect" width="100%" />
                      </>
                    )}
                    {!loadingCategoria && (
                      <>
                        {categories.map((categoria, index) => (
                          <label
                            key={categoria.id}
                            className="customSearchCheckBox"
                            htmlFor={`categoria${index.toString()}`}
                          >
                            <input
                              type="checkbox"
                              name="categoria"
                              value={categoria.id}
                              id={`categoria${index.toString()}`}
                              onChange={(event) => {
                                if (!event.target.checked) {
                                  setSelectedCategory((oldValues) =>
                                    oldValues.filter(
                                      (value) => value !== event.target.value,
                                    ),
                                  );
                                } else {
                                  setSelectedCategory((oldValues) => [
                                    ...oldValues,
                                    event.target.value,
                                  ]);
                                }
                              }}
                            />
                            <span />
                            <div className="font-size-lg text-dark-75 font-weight-bold">
                              {categoria.descricao}
                            </div>
                          </label>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </form>

              <Button
                text="Confirmar"
                type="submit"
                disabled={loadingEmpresa}
                className="btn btn-primary font-weight-bolder mr-2 px-8"
                onClick={() => handleFilter()}
              />
            </div>
          </div>
        </div>
        <CardSearchCompany empresas={empresas} loading={loadingEmpresa} />
      </div>
    </>
  );
}
