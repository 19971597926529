import React, { useCallback, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import ReactPlayer from 'react-player/lazy';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';
import { FiArrowLeft, FiInfo } from 'react-icons/fi';
import { useAuth } from '../../../hooks/Auth';
import {
  ClassProgress,
  DesafioProps,
  MateriaisComplementaresProps,
  ObjetosAprendizagemProps,
  UnidadeAprendizagemProps,
} from '../../../interfaces/Entidades';
import { API } from '../../../services/api';

import './styles.scss';

import { BackHeaderComponent } from '../../../components/BackHeader';
import PlaylistClass from './components/PlaylistClass';
import { Error401 } from '../../../ErrorPages/401';

interface ParamsProps {
  id: string;
}

export default function WatchClass() {
  const { token } = useAuth();
  const history = useHistory();
  const params = useParams<ParamsProps>();
  const [aula, setAula] = useState<ObjetosAprendizagemProps>(
    {} as ObjetosAprendizagemProps,
  );
  const [aulasCompletas, setAulasCompletas] = useState<number[]>([]);
  const [modulo, setModulo] = useState<UnidadeAprendizagemProps>(
    {} as UnidadeAprendizagemProps,
  );
  const [desafios, setDesafios] = useState<DesafioProps[]>([]);
  const [materiais, setMateriais] = useState<MateriaisComplementaresProps[]>(
    [],
  );
  const [blocked, setBlocked] = useState(false);
  const [loading, setLoading] = useState(true);

  function handleCompleteClass() {
    API(token).put('/api/progresso/AulaCompleta', {
      id: params.id,
    });
  }

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      setAula({} as ObjetosAprendizagemProps);
      try {
        const response_aula = await API(token).get<ObjetosAprendizagemProps>(
          `/api/objetoAprendizagem/${params.id}`,
        );

        if (!response_aula.data.inscrito) {
          throw new Error('Error401');
        }

        setAula(response_aula.data);
        setMateriais(response_aula.data.materiaisComplementares);

        const response_modulo = API(token).get<UnidadeAprendizagemProps>(
          `/api/unidadeAprendizagem/${response_aula.data.unidadeAprendizagemId}`,
        );

        const response_progress_aula = API(token).get<ClassProgress[]>(
          '/api/progresso/objetos',
        );

        const aulasCompletasArray: number[] = [];

        await Promise.all([response_modulo, response_progress_aula]).then(
          (results) => {
            setModulo(results[0].data);
            setDesafios(results[0].data.desafios);
            const filteredAulas = results[1].data.filter(
              (aulaRay) =>
                aulaRay.objetoAprendizagem.unidadeAprendizagemId ===
                results[0].data.id,
            );

            filteredAulas.forEach((item) => {
              if (item.finalizado) {
                aulasCompletasArray.push(item.objetoAprendizagemId);
              }
            });
          },
        );

        setAulasCompletas(aulasCompletasArray);

        setLoading(false);
        setBlocked(false);
      } catch (err: any) {
        if (err.message === 'Error401') {
          setBlocked(true);
          toast.error('Você não tem acesso a esta aula! 😓', {
            icon: <FiInfo color="#fff" size={35} />,
            position: 'top-right',
            style: {
              minWidth: 300,
              height: 50,
              color: '#fff',
              backgroundColor: '#3172b7',
            },
          });
        } else {
          toast.error(
            'Houve um erro ao carregar os dados da aula, tente novamente! 😢',
          );
        }
      }
    }
    loadData();
  }, [token, params.id]);

  if (blocked) {
    return <Error401 />;
  }

  return (
    <>
      {!loading && (
        <div className="admin-trail-header">
          <div
            role="none"
            className="admin-trailheader-divider"
            onClick={() =>
              history.push(`/learningUnit/${aula.unidadeAprendizagem.cursoId}`)
            }
          >
            <FiArrowLeft size={20} />
            <span>Voltar</span>
          </div>
        </div>
      )}
      <div className="Container">
        <div className="card card-custom gutter-b">
          <div className="card-body d-flex flex-column">
            <strong>
              {loading ? (
                <Skeleton
                  variant="rect"
                  width="40%"
                  style={{ borderRadius: 3 }}
                />
              ) : (
                aula?.titulo
              )}
            </strong>
            <p>
              {loading ? (
                <Skeleton
                  variant="rect"
                  width="40%"
                  style={{ borderRadius: 3, marginTop: 15 }}
                />
              ) : (
                `${modulo.titulo} | ${modulo.curso.nome}`
              )}
            </p>
          </div>
        </div>
        <div>
          {loading ? (
            <Skeleton
              variant="rect"
              width="100%"
              height="40vh"
              style={{ borderRadius: 3, marginBottom: 30 }}
            />
          ) : (
            <>
              {aula?.urlVideo == null ? (
                <div className="row">
                  <div className="col-xl" style={{ height: '36em' }}>
                    <div className="card card-custom card-stretch gutter-b">
                      <div className="card-body d-flex flex-column">
                        <strong>Descrição da Aula</strong>
                        <p>
                          {loading ? (
                            <Skeleton
                              variant="rect"
                              width="40%"
                              style={{ borderRadius: 3, marginTop: 15 }}
                            />
                          ) : (
                            aula?.conteudo
                          )}
                        </p>
                      </div>
                    </div>
                  </div>

                  <PlaylistClass
                    titleModule={modulo.titulo}
                    classes={modulo.objetosAprendizagens}
                    completedClasses={aulasCompletas}
                    activeClassId={aula.id}
                  />
                </div>
              ) : (
                <div className="row">
                  <div className="col-xl" style={{ height: '36em' }}>
                    <div className="card card-custom card-stretch gutter-b">
                      <ReactPlayer
                        url={aula?.urlVideo}
                        controls
                        width="100%"
                        height="100%"
                        config={{
                          file: { attributes: { controlsList: 'nodownload' } },
                        }}
                        style={{
                          alignSelf: 'center',
                          width: '100%',
                          height: '100%',
                          borderRadius: 15,
                        }}
                        onPlay={() => handleCompleteClass()}
                      />
                    </div>
                  </div>

                  <PlaylistClass
                    titleModule={modulo.titulo}
                    classes={modulo.objetosAprendizagens}
                    completedClasses={aulasCompletas}
                    activeClassId={aula.id}
                  />
                </div>
              )}
            </>
          )}
        </div>
        {aula?.urlVideo == null ? (
          ''
        ) : (
          <div className="card card-custom  gutter-b">
            <div className="card-body d-flex flex-column">
              {loading ? (
                <Skeleton variant="rect" width="40%" />
              ) : (
                <strong>Descrição da Aula</strong>
              )}

              <p>
                {loading ? (
                  <Skeleton
                    variant="rect"
                    width="40%"
                    style={{ borderRadius: 3, marginTop: 15 }}
                  />
                ) : (
                  aula?.conteudo
                )}
              </p>
            </div>
          </div>
        )}

        {materiais.length >= 1 ? (
          <div
            className="card card-custom card-stretch gutter-b"
            style={{ height: 300 }}
          >
            <div className="card-body d-flex flex-column">
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {loading ? (
                  <Skeleton variant="rect" width="40%" />
                ) : (
                  <strong>Materiais de Apoio</strong>
                )}
              </div>

              {loading ? (
                <Skeleton
                  variant="rect"
                  width="100%"
                  height={80}
                  style={{ borderRadius: 3, marginTop: 15 }}
                />
              ) : (
                <div
                  style={{
                    overflow: 'auto',
                    maxHeight: '500px',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {materiais &&
                    materiais.map((material) => (
                      <a
                        href={material.urlConteudo}
                        download
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          color: '#202020',
                          display: 'flex',
                          flexDirection: 'column',
                          background: '#F7F8FA',
                          marginTop: 15,
                          padding: 15,
                          justifyContent: 'center',
                          maxWidth: '99%',
                        }}
                      >
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <h4>{material.titulo}</h4>
                        </div>
                      </a>
                    ))}
                </div>
              )}
            </div>
          </div>
        ) : null}

        {desafios.length >= 1 ? (
          <div
            className="card card-custom card-stretch gutter-b"
            style={{ height: 300 }}
          >
            <div className="card-body d-flex flex-column">
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {loading ? (
                  <Skeleton variant="rect" width="40%" />
                ) : (
                  <strong>Desafios do Módulo</strong>
                )}
              </div>

              {loading ? (
                <Skeleton
                  variant="rect"
                  width="100%"
                  height={80}
                  style={{ borderRadius: 3, marginTop: 15 }}
                />
              ) : (
                <div style={{ overflowY: 'scroll' }}>
                  {desafios &&
                    desafios.map((desafio) => (
                      <Link
                        to={{
                          pathname: '/desafios',
                          state: { id: desafio.id },
                        }}
                        style={{
                          color: '#202020',
                          display: 'flex',
                          flexDirection: 'column',
                          background: '#F7F8FA',
                          marginTop: 15,
                          padding: 15,
                          justifyContent: 'center',
                        }}
                      >
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <h4>{desafio.titulo}</h4>
                        </div>

                        <p>{modulo?.titulo}</p>
                      </Link>
                    ))}
                </div>
              )}
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}
