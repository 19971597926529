import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import toast from 'react-hot-toast';
import { Rating, Skeleton } from '@material-ui/lab';
import { useAuth } from '../../../hooks/Auth';
import { API } from '../../../services/api';

import { TrailProps } from '../../../interfaces/Entidades';
import NoImage from '../../assets/images/no-image.png';

interface MyTrails {
  trilha: TrailProps;
  progresso: number;
}

export default function MyTrails() {
  const { token } = useAuth();
  const [trilha, setTrilha] = useState<MyTrails[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadTrail() {
      setLoading(true);
      setTrilha([]);
      try {
        const response = await API(token).get(
          `/api/trilha/minhas?$expand=trilha($expand=organizacao)`,
        );
        setTrilha(response.data);
        setLoading(false);
      } catch (err) {
        toast.error(
          'Houve um problema ao carregar seus dados, tente novamente! 😢',
        );
      }
    }

    loadTrail();
  }, [token]);

  if (loading) {
    return (
      <>
        <div className="row">
          <div className="col-xl-12">
            <div className="card card-custom card-stretch gutter-b">
              <div
                className="card-header border-0 py-5"
                style={{ marginBottom: -25, justifyContent: 'flex-start' }}
              >
                <Skeleton
                  variant="rect"
                  width={70}
                  height="110%"
                  style={{ borderRadius: 3 }}
                />

                <div style={{ marginLeft: 10 }}>
                  <Link
                    to="/"
                    style={{ display: 'block' }}
                    className="text-dark font-weight-bolder text-hover-primary font-size-h4 line-clamp-title"
                  >
                    <Skeleton variant="text" width="100%" height={25} />
                  </Link>

                  <p className="text-dark-75 font-size-sm  font-size-base m-0 text-muted">
                    <Skeleton variant="text" width={200} height="100%" />
                  </p>
                </div>
              </div>

              <div className="card-body d-flex flex-column">
                <p className="text-dark-50 font-weight-normal font-size-lg line-clamp">
                  <Skeleton
                    variant="text"
                    width="100%"
                    height={100}
                    style={{ marginTop: '-40px' }}
                  />
                </p>

                <Skeleton
                  variant="text"
                  width="100%"
                  height={50}
                  style={{ marginTop: '-30px' }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  if (trilha.length === 0) {
    return (
      <>
        <h2 style={{ textAlign: 'center' }}>Nenhuma Trilha encontrada</h2>
        <p style={{ textAlign: 'center' }}>
          <Link style={{ color: '#008C9E' }} to="/search_trail">
            Clique aqui
          </Link>{' '}
          para procurar uma Trilha
        </p>
      </>
    );
  }

  return (
    <>
      <div className="row">
        {trilha.map((trail) => (
          <div className="col-xl-12">
            <div className="card card-custom card-stretch gutter-b">
              <div
                className="card-header border-0 py-5"
                style={{ marginBottom: -25, justifyContent: 'flex-start' }}
              >
                <img
                  src={
                    trail.trilha.urlImagem ? trail.trilha.urlImagem : NoImage
                  }
                  alt="Logo do Curso"
                  style={{
                    width: '96px',
                    height: '100px',
                    objectFit: 'cover',
                    borderRadius: 6,
                  }}
                />

                <div style={{ marginLeft: 10 }}>
                  <Link
                    to={{ pathname: `/details_trail/${trail.trilha.id}` }}
                    style={{ display: 'block' }}
                    className="text-dark font-weight-bolder text-hover-primary font-size-h4 line-clamp-title"
                  >
                    {trail.trilha.nome}
                  </Link>

                  {/* <Rating name="disabled" value={3} disabled /> */}

                  <p className="text-dark-75 font-size-sm  font-size-base m-0 text-muted">
                    Criado por{' '}
                    <Link
                      to={{
                        pathname: `/details_company/${trail.trilha.organizacaoId}`,
                      }}
                      className="font-weight-bolder"
                      style={{ color: '#005f6b' }}
                    >
                      {trail.trilha.organizacao.razaoSocial}
                    </Link>
                  </p>
                </div>
              </div>

              <div className="card-body d-flex flex-column">
                <p className="text-dark-50 font-weight-normal font-size-lg line-clamp">
                  {trail.trilha.descricao}
                </p>
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <span className="text-muted mr-2 font-size-sm font-weight-bold">
                    {(trail.progresso * 100).toFixed(2)} %
                  </span>
                  <span className="text-muted font-size-sm font-weight-bold">
                    Progresso
                  </span>
                </div>
                <div className="progress progress-xs w-100">
                  <div
                    className="progress-bar bg-success"
                    role="progressbar"
                    style={{ width: `${trail.progresso * 100}%` }}
                    aria-valuenow={50}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
