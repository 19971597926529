import React, { InputHTMLAttributes, useEffect, useRef } from 'react';
import { useField } from '@unform/core';

import './style.scss';

interface InputProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  loading?: boolean;
}

const TextAreaInput = ({
  name,
  loading,
  ...props
}: InputProps): JSX.Element => {
  const InputRef = useRef<HTMLTextAreaElement>(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  if (error) {
    InputRef.current?.classList.add('textAreaInputError');
  } else {
    InputRef.current?.classList.remove('textAreaInputError');
  }

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: InputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <textarea
      className="TextAreaCompany"
      ref={InputRef}
      {...props}
      disabled={loading}
    />
  );
};

export default TextAreaInput;
