/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Skeleton } from '@material-ui/lab';
import React, { useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { UnidadeAprendizagemProps } from '../../../../interfaces/Entidades';

import '../style.scss';

interface IModulosProps {
  nameCurso: string | undefined;
  modulos: UnidadeAprendizagemProps[];
  isLoading: boolean;
  loadAulas: (id: any) => Promise<void>;
  setModuleId: React.Dispatch<React.SetStateAction<number>>;
}

export default function CardRecommendedCourse({
  nameCurso,
  modulos,
  isLoading,
  loadAulas,
  setModuleId,
}: IModulosProps) {
  const [loading, setLoading] = useState(false);
  const [activeCard, setActiveCard] = useState(0);

  const itensDesktop = () => {
    if (modulos) {
      if (modulos.length === 1) {
        return 2;
      }
      if (modulos.length === 2) {
        return 3;
      }
      if (modulos.length === 3) {
        return 4;
      }
      if (modulos.length >= 4) {
        return 4;
      }
      return 0;
    }
    return 0;
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: itensDesktop(),
      paritialVisibilityGutter: 0,
    },
    tablet: {
      breakpoint: { max: 1024, min: 667 },
      items: 3,
      paritialVisibilityGutter: 100,
    },
    mobile: {
      breakpoint: { max: 667, min: 0 },
      items: 2,
      paritialVisibilityGutter: 28,
    },
  };

  return (
    <>
      {isLoading ? (
        <Skeleton
          variant="rect"
          width="100%"
          height={60}
          style={{ borderRadius: 3, marginTop: 15, marginBottom: 15 }}
        />
      ) : (
        <div
          style={{
            height: 60,
            width: '100%',
            padding: 10,
            backgroundColor: 'transparent',
          }}
        >
          <h1 style={{ textAlign: 'center' }}>{nameCurso}</h1>
        </div>
      )}

      {isLoading ? (
        <Skeleton
          variant="rect"
          width="100%"
          height={100}
          style={{ borderRadius: 3, marginTop: 15, marginBottom: 15 }}
        />
      ) : (
        <>
          {modulos && (
            <Carousel
              ssr
              partialVisible
              responsive={responsive}
              itemClass="item-class"
            >
              {modulos.map((modulo, index) => (
                <div
                  key={modulo.id}
                  className={
                    index === activeCard
                      ? 'card card-custom card-stretch gutter-b activeModuleCard'
                      : 'card card-custom card-stretch gutter-b'
                  }
                  onClick={() => setActiveCard(index)}
                >
                  <div
                    style={{ cursor: 'pointer' }}
                    className="card-body d-flex flex-column"
                    onClick={async () => {
                      if (!loading) {
                        setLoading(true);
                        await loadAulas(modulo.id);
                        setLoading(false);
                      } else {
                        setLoading(true);
                      }
                      setModuleId(modulo.id);
                    }}
                  >
                    <span
                      className="font-weight-bolder font-size-h3 color"
                      style={{ textAlign: 'center' }}
                    >
                      {modulo.titulo}
                    </span>
                  </div>
                </div>
              ))}
            </Carousel>
          )}
        </>
      )}
    </>
  );
}
