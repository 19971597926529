import React from 'react';
import { RouteProps, Route as ReactDOMRoute, Redirect } from 'react-router-dom';
import { Error404 } from '../ErrorPages/404';
import { useAuth } from '../hooks/Auth';

interface routeProps extends RouteProps {
  isAdmin?: boolean;
  component: React.ComponentType;
}

export const Route = ({
  isAdmin = false,
  component: Component,
  ...rest
}: routeProps) => {
  const { user } = useAuth();

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) =>
        (isAdmin && user.role === 'Organizacao') || !isAdmin ? (
          <Component />
        ) : (
          <Error404 />
        )
      }
    />
  );
};
