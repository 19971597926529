import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import CreateModal from './CreateModal';

import CompanyTable from './CompanyTable';
import { Button } from '../../../components/Button';
import { useAuth } from '../../../hooks/Auth';
import { API } from '../../../services/api';
import { AdminUserClusterProps } from '../../../interfaces/Entidades';

const ClusterAdminUser = () => {
  const { token } = useAuth();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [adminUserCluster, setAdminUserCluster] = useState<
    AdminUserClusterProps[]
  >([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadUserAdminCluster() {
      setLoading(true);
      setAdminUserCluster([]);
      try {
        const response = await API(token).get(
          '/api/usuario/odata?$filter=clusterId+ne+null and inactive+ne+true',
        );

        setAdminUserCluster(response.data.value);
        setLoading(false);
      } catch (err) {
        toast.error(
          'Houve um problema ao listar os usuários, tente novamente!',
        );
      }
    }

    loadUserAdminCluster();
  }, [token]);

  return (
    <div className="cluster-table-container">
      <CreateModal
        itens={adminUserCluster}
        setItens={setAdminUserCluster}
        setModalOpen={setCreateModalOpen}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        closeButton={() => setCreateModalOpen(false)}
      />

      <div className="cluster-table-card">
        <div className="cluster-table-card-header">
          <h2 style={{ marginBottom: 30, color: '#802A00' }}>
            Conta Administradora do Cluster
          </h2>
          <Button
            color="#802A00"
            text="Adicionar Usuário"
            onClick={() => setCreateModalOpen(true)}
          />
        </div>
        <CompanyTable
          loading={loading}
          users={adminUserCluster}
          setUsers={setAdminUserCluster}
        />
      </div>
    </div>
  );
};

export default ClusterAdminUser;
