import React, { useEffect, useState } from 'react';

import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { LinearProgress } from '@material-ui/core';
import CardProfile from './components/cardProfile';
import CardCertficate from './components/cardCertificate';
import { useAuth } from '../../../hooks/Auth';
import { API } from '../../../services/api';
import {
  Dashboard,
  UserProfissionalProps,
} from '../../../interfaces/Entidades';
import { BackHeaderComponent } from '../../../components/BackHeader';
import CardProgressTrail from './components/CardProgressTrail';

interface routeParams {
  id: number;
}

export function ProfileProfessionalOrg() {
  const params = useParams();
  const { token } = useAuth();
  const [professional, setProfessional] = useState<UserProfissionalProps>(
    {} as UserProfissionalProps,
  );
  const [professionalData, setProfessionalData] = useState<Dashboard>(
    {} as Dashboard,
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadProfissional() {
      setLoading(true);
      const { id } = params as routeParams;

      try {
        const response = API(token).get(`/api/usuario/perfil/${id}`);
        const response_dashboard = API(token).get(
          `/api/usuario/dashboard/${id}`,
        );

        await Promise.all([response, response_dashboard]).then((results) => {
          setProfessional(results[0].data);
          setProfessionalData(results[1].data);
        });

        setLoading(false);
      } catch (err) {
        toast.error(
          'Houve um problema ao carregar os dados do profissional, tente novamente! 😢',
        );
      }
    }

    loadProfissional();
  }, [token, params]);

  if (loading) {
    return (
      <div style={{ height: 60, width: '100%' }}>
        <BackHeaderComponent />
        <LinearProgress color="primary" />
      </div>
    );
  }

  return (
    <>
      <div className="row">
        <BackHeaderComponent />
      </div>
      <div className="row">
        <CardProfile
          professionalData={professionalData}
          professional={professional}
        />
      </div>
      <div className="row">
        <div className="carrosel">
          <CardProgressTrail
            trilha={professional.inscricoes}
            loading={loading}
          />
        </div>
      </div>
      <div className="row">
        <CardCertficate trilha={professional.aprovadas} loading={loading} />
      </div>
    </>
  );
}
