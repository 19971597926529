/* eslint-disable no-param-reassign */
import React, { useCallback, useRef, useState, useEffect } from 'react';
import { Form } from '@unform/web';
import { FiImage, FiLock, FiMail, FiUser } from 'react-icons/fi';
import { AiOutlineIdcard } from 'react-icons/ai';
import { FormHandles } from '@unform/core';
import toast from 'react-hot-toast';
import { cpf } from 'cpf-cnpj-validator';

import { OptionTypeBase } from 'react-select';
import * as Yup from 'yup';

import { API } from '../../../../services/api';
import SelectInput from '../../../components/SelectInput';
import GetValidationErrors from '../../../../utils/getValidationErros';
import { useAuth } from '../../../../hooks/Auth';
import Input from '../../../components/Input';
import MaskInput from '../../../components/MaskInput';
import { Button } from '../../../../components/Button';
import ModalComponent from '../../../../components/Modal/ModalComponent';
import { ModalProps } from '../../../../interfaces/components';
import { ClusterProps } from '../../../../interfaces/Entidades';

export default function CreateModalAdminUserCompany({
  open,
  onClose,
  closeButton,
  itens,
  setItens,
  setModalOpen,
}: ModalProps) {
  const { token } = useAuth();
  const [inputLoading, setInputLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [optionsClusters, setOptionsClusters] = useState<OptionTypeBase[]>();
  const formRef = useRef<FormHandles>(null);

  const Submit = useCallback(
    async (data) => {
      setLoading(true);

      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          clusterId: Yup.number().required('Campo Obrigatório'),
          nome: Yup.string().required('Campo Obrigatório'),
          email: Yup.string().required('Campo Obrigatório'),
          cpf: Yup.string()
            .required('Campo obrigatório')
            .test('validacao-cpf', 'CPF Inválido', async (Cpf) => {
              if (Cpf === undefined) {
                return false;
              }
              const validation = cpf.isValid(Cpf);
              if (validation) {
                return true;
              }
              return false;
            }),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        data.administrador = true;
        const response = await API(token).post('/api/usuario/', data);
        setLoading(false);

        const findCluster = optionsClusters?.find(
          (item) => item.value === data.clusterId,
        );

        if (findCluster) {
          data.cluster = { descricao: findCluster.label };
          data.id = response.data.id;
        } else {
          console.log('fail');
        }

        setItens([...itens, data]);
        setModalOpen(false);
        toast.success('Usuário criado com sucesso!');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = GetValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          toast.error('Houve um erro ao criar o usuário, tente novamente! 😢');
        }

        setLoading(false);
      }
    },

    [token, itens, setItens, setModalOpen, optionsClusters],
  );

  useEffect(() => {
    async function loadData() {
      setInputLoading(true);
      setOptionsClusters([]);

      try {
        const response_clusters = await API(token).get(
          '/api/cluster/odata?$select=id,descricao',
        );
        const optionsFormatCluster: OptionTypeBase[] = [];
        response_clusters.data.value.forEach((cluster: ClusterProps) => {
          optionsFormatCluster.push({
            value: cluster.id,
            label: cluster.descricao,
          });
        });
        setOptionsClusters(optionsFormatCluster);

        setInputLoading(false);
      } catch (err) {
        toast.error('Houve um erro ao carregar os dados! 😢');
        setInputLoading(false);
      }
    }

    loadData();
  }, [token]);

  return (
    <ModalComponent closeButton={closeButton} open={open} onClose={onClose}>
      <Form ref={formRef} placeholder="" className="createmodal-form-admin" onSubmit={Submit}>
        <h2 id="simple-modal-title">Criar novo Usuário</h2>

        <Input
          name="nome"
          maxLength={60}
          icon={FiUser}
          type="text"
          placeholder="Nome"
        />
        <MaskInput
          mask="999.999.999-99"
          name="cpf"
          icon={AiOutlineIdcard}
          type="text"
          placeholder="Cpf"
        />
        <Input
          name="email"
          maxLength={60}
          icon={FiMail}
          type="text"
          placeholder="Email"
        />

        <SelectInput
          loading
          options={optionsClusters}
          isLoading={inputLoading}
          name="clusterId"
          placeholder="Selecione o cluster..."
        />

        <Button
          color="#802A00"
          text="Confirmar"
          type="submit"
          loading={loading}
        />
      </Form>
    </ModalComponent>
  );
}
