import React, { useCallback, useRef, useState } from 'react';
import { Form } from '@unform/web';

import { FormHandles } from '@unform/core';
import toast from 'react-hot-toast';

import * as Yup from 'yup';

import { API } from '../../../../../services/api';
import GetValidationErrors from '../../../../../utils/getValidationErros';
import { useAuth } from '../../../../../hooks/Auth';
import Input from '../../../../components/Input';
import { Button } from '../../../../../components/Button';
import ModalComponent from '../../../../../components/Modal/ModalComponent';
import { EditModalProps } from '../../../../../interfaces/components';

import TextAreaInput from '../../../../components/TextAreaInput';
import { ObjetosAprendizagemProps } from '../../../../../interfaces/Entidades';
import SwitchInput from '../../../../components/InputSwitch';

export default function EditClassModal({
  closeButton,
  item,
  open,
  setModalOpen,
  onClose,
  id,
}: EditModalProps) {
  const { token } = useAuth();
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const [inputLoading, setInputLoading] = useState(false);
  const [hasUrlVideo, setHasUrlVideo] = useState(true);

  const Submit = useCallback(
    async (data: ObjetosAprendizagemProps) => {
      setLoading(true);

      try {
        formRef.current?.setErrors({});

        if (!hasUrlVideo) {
          const schema = Yup.object().shape({
            titulo: Yup.string().required('Campo Obrigatório'),
            conteudo: Yup.string().required('Campo Obrigatório'),
          });

          data.urlVideo = undefined;

          await schema.validate(data, {
            abortEarly: false,
          });
        } else {
          const schema = Yup.object().shape({
            titulo: Yup.string().required('Campo Obrigatório'),
            conteudo: Yup.string().required('Campo Obrigatório'),
            urlVideo: Yup.string().required('Campo Obrigatório'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });
        }

        data.id = id;
        data.unidadeAprendizagemId = item.unidadeAprendizagemId;

        await API(token).put('/api/objetoAprendizagem', data);
        setLoading(false);
        setModalOpen(false);

        toast.success('Aula alterada com sucesso!');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = GetValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          toast.error('Houve um erro ao editar a Aula, tente novamente! 😢');
        }

        setLoading(false);
      }
    },

    [id, item.unidadeAprendizagemId, token, hasUrlVideo],
  );

  return (
    <ModalComponent closeButton={closeButton} open={open} onClose={onClose}>
      <Form ref={formRef} placeholder="" className="createmodal-form-admin" onSubmit={Submit}>
        <h2 id="simple-modal-title">Editar Aula</h2>

        <Input
          name="titulo"
          maxLength={100}
          loading={inputLoading}
          defaultValue={item ? item.titulo : ''}
          type="text"
          placeholder={inputLoading ? 'Carregando...' : 'Titulo da aula'}
        />

        <TextAreaInput
          name="conteudo"
          loading={inputLoading}
          defaultValue={item ? item.conteudo : ''}
          maxLength={300}
          placeholder={inputLoading ? 'Carregando...' : 'Descrição da aula'}
          style={{ marginBottom: 10 }}
        />

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'baseline',
          }}
        >
          <span style={{ marginRight: 10, fontSize: 16 }}>Possui Vídeo</span>
          <SwitchInput
            name="ativo"
            defaultChecked={hasUrlVideo}
            onClick={() => setHasUrlVideo(!hasUrlVideo)}
          />
        </div>

        {hasUrlVideo ? (
          <Input
            name="urlVideo"
            loading={inputLoading}
            defaultValue={item ? item.urlVideo : ''}
            maxLength={300}
            placeholder={inputLoading ? 'Carregando...' : 'Url da aula'}
          />
        ) : null}

        <Button
          color="#6F95FF"
          text="Confirmar"
          type="submit"
          loading={loading}
        />
      </Form>
    </ModalComponent>
  );
}
