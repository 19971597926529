import { Form } from '@unform/web';
import React, { useState, useCallback, useRef } from 'react';
import { FiCheckCircle, FiMail } from 'react-icons/fi';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { FormHandles } from '@unform/core';
import { Link } from 'react-router-dom';

import Input from '../../components/Input';
import logoImg from '../../assets/images/apl.png';
import Wave from '../../assets/images/wave.svg';

import './styles.scss';
import { Button } from '../../components/Button';

import GetValidationErrors from '../../../utils/getValidationErros';
import { API } from '../../../services/api';

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const formRef = useRef<FormHandles>(null);

  const Submit = useCallback(async (data) => {
    setLoading(true);

    formRef.current?.setErrors({});
    const schema = Yup.object().shape({
      Email: Yup.string()
        .required('Email obrigatório')
        .email('Digite um Email válido!'),
    });

    await schema.validate(data, {
      abortEarly: false,
    });

    const response = await API().post('/api/usuario/esqueciSenha', data);
    setLoading(false);
    setEmailSent(true);
  }, []);

  if (emailSent) {
    return (
      <div className="forgotPasswordContainer">
        <main className="main-forgotpassword-email">
          <FiCheckCircle
            color="#005F6B"
            size={80}
            style={{ marginBottom: 20 }}
          />
          <h1>Email enviado!</h1>
          <p>
            Se o email informado estiver associado a alguma conta <br />
            por favor verifique a caixa de entra e/ou o span <br /> e siga as
            instruções para realizar a troca de senha
          </p>

          <Link to="/">Voltar para login</Link>
        </main>
      </div>
    );
  }

  return (
    <div className="forgotPasswordContainer">
      <main className="main-forgotpassword">
        <Form ref={formRef} placeholder="" className="forgotPasswordForm" onSubmit={Submit}>
          <img
            src={logoImg}
            alt=""
            style={{
              objectFit: 'cover',
              width: '100%',
              height: '120px',
            }}
          />
          <h1>Esqueceu sua senha?</h1>
          <p>
            Por favor digite o endereço de email utilizado na criação da conta,
            nós enviaremos um email com as intruções para a realização da troca
            de senha
          </p>
          <Input
            name="Email"
            placeholder="Digite seu email"
            icon={FiMail}
            maxLength={80}
          />

          <Button text="Recuperar Senha" loading={loading} type="submit" />
          <Link to="/">Voltar para login</Link>
        </Form>
      </main>
    </div>
  );
};

export default ForgotPassword;
