import React from 'react';

import './styles.scss';

export function Error401() {
  return (
    <>
      <div id="main">
        <div className="fof">
          <h1 style={{ fontSize: '70px' }}>Acesso não autorizado</h1>
          <br />
          <span style={{ fontSize: '18px' }}>
            Desculpe, você não tem permissão para visualizar essa página
          </span>
        </div>
      </div>
    </>
  );
}
