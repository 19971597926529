import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';
import { VscFile } from 'react-icons/vsc';
import {
  AiOutlineFilePdf,
  AiOutlineFileImage,
  AiOutlineFileZip,
  AiOutlineFileWord,
  AiOutlineFileText,
} from 'react-icons/ai';
import { LinearProgress } from '@material-ui/core';
import { format } from 'date-fns';
import { useAuth } from '../../../hooks/Auth';
import {
  DesafioProps,
  RespostaDesafioProps,
} from '../../../interfaces/Entidades';
import { API } from '../../../services/api';
import { BackHeaderComponent } from '../../../components/BackHeader';
import { Button } from '../../components/Button';

import './styles.scss';

interface StateProps {
  id: number;
}

interface IFileProps {
  urlResposta: string;
  desafioId: number | undefined;
  arquivoResposta: string;
}

export default function Challenges() {
  const { token } = useAuth();
  const location = useLocation();
  const [desafio, setDesafio] = useState<DesafioProps>();
  const [loading, setLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [respDesafio, setRespDesafio] = useState<IFileProps>();
  const [wasAnswered, setWasAnswered] = useState(false);
  const [answerFile, setAnswerFile] = useState<RespostaDesafioProps>();
  const [nameFile, setNameFile] = useState('');
  const [fileExtension, setFileExtension] = useState('');

  const id = location.state as StateProps;

  function getFileExtension(fileName: string) {
    const extension = fileName.split('.');

    if (
      extension[extension.length - 1] !== undefined &&
      extension[extension.length - 1] !== ''
    ) {
      setFileExtension(extension[extension.length - 1]);
    }
  }

  function handleUploadFile(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files) {
      const data = new FormData();
      data.append('resposta', e.target.files[0]);

      setNameFile(e.target.files[0].name);
      getFileExtension(e.target.files[0].name);

      API(token)
        .post('/api/desafio/subirArquivoResposta', data)
        .then((response) => {
          if (e.target.files !== null) {
            setRespDesafio({
              urlResposta: response.data.destino,
              desafioId: desafio?.id,
              arquivoResposta: e.target.files[0].name,
            });
          }
        })
        .catch((error) => {
          toast.error('Houve um erro ao enviar a resposta,tente novamente! 😢');
        });
    }
  }

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      setNameFile('');
      setFileExtension('');

      try {
        await API(token).get(`/api/usuario/pontuacao`);

        const response = await API(token).get<DesafioProps>(
          `/api/desafio/${id.id}`,
        );
        setDesafio(response.data);

        await API(token)
          .get(`/api/desafio/meus?filter= desafioId eq ${id.id}`)
          .then((res) => {
            res.data.value.forEach((item: RespostaDesafioProps) => {
              if (item.desafioId === id.id) {
                setAnswerFile(item);
                setWasAnswered(true);

                if (item.arquivoResposta !== null) {
                  getFileExtension(item.arquivoResposta);
                }
              }
            });
          });

        setLoading(false);
      } catch (err) {
        toast.error('Houve um erro ao carregar o desafio, tente novamente!');
        setLoading(false);
      }
    }
    loadData();
  }, [token, id]);

  const SubmitDesafio = useCallback(async () => {
    setUploadLoading(true);

    await API(token).post('/api/desafio/responder', respDesafio);
    try {
      setUploadLoading(false);
      toast.success('Resposta enviada com sucesso!');
      await setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (err) {
      setUploadLoading(false);
      toast.error('Houve um erro ao enviar sua resposta, tente novamente!');
    }
  }, [token, respDesafio]);

  return (
    <div className="Container">
      <BackHeaderComponent />
      {loading ? (
        <Skeleton
          variant="rect"
          width="100%"
          height="60px"
          style={{ borderRadius: 3, marginBottom: 10 }}
        />
      ) : (
        <h1 style={{ textAlign: 'center' }}>
          Desafio do Módulo - {desafio?.unidadeAprendizagem.titulo}
        </h1>
      )}

      <div className="card card-custom gutter-b flex-row">
        <div className="card-body d-flex flex-column">
          <strong>
            {loading ? (
              <Skeleton
                variant="rect"
                width="60%"
                style={{ borderRadius: 3 }}
              />
            ) : (
              desafio?.titulo
            )}
          </strong>

          {loading ? (
            <Skeleton
              variant="rect"
              width="40%"
              style={{ borderRadius: 3, marginTop: 15 }}
            />
          ) : (
            <>
              <span style={{ fontWeight: 'bold' }}>Instruções</span>
              <p>{desafio?.conteudo}</p>
            </>
          )}

          {loading ? (
            <Skeleton
              variant="rect"
              width="40%"
              style={{ borderRadius: 3, marginTop: 15 }}
            />
          ) : (
            <>
              <span style={{ fontWeight: 'bold' }}>Meu trabalho</span>
              {wasAnswered ? (
                <a
                  href={`${answerFile?.urlResposta}`}
                  target="_blank"
                  download
                  rel="noopener noreferrer"
                  style={{
                    fontSize: 14,
                    height: '100%',
                    width: '70%',
                    background: '#f1f1f1',
                    padding: 10,
                    borderRadius: 4,
                  }}
                >
                  {fileExtension === 'pdf' && (
                    <AiOutlineFilePdf size={25} style={{ color: 'red' }} />
                  )}
                  {fileExtension === 'zip' && <AiOutlineFileZip size={25} />}
                  {fileExtension === 'txt' && <AiOutlineFileText size={25} />}
                  {fileExtension === 'png' && <AiOutlineFileImage size={25} />}
                  {fileExtension === 'docx' && (
                    <AiOutlineFileWord size={25} style={{ color: 'blue' }} />
                  )}

                  {answerFile?.arquivoResposta}
                </a>
              ) : (
                <div className="chooseFile">
                  <input
                    id="inputFile"
                    className="inputFile"
                    type="file"
                    onChange={(event) => handleUploadFile(event)}
                  />
                  <div
                    style={{
                      display: 'flex',
                      gap: 15,
                      alignItems: 'center',
                      width: '50%',
                      height: 'auto',
                    }}
                  >
                    <label htmlFor="inputFile">
                      <VscFile size={18} />
                      Anexo
                    </label>
                    <span>
                      {fileExtension === 'pdf' && (
                        <AiOutlineFilePdf size={25} style={{ color: 'red' }} />
                      )}
                      {fileExtension === 'zip' && (
                        <AiOutlineFileZip size={25} />
                      )}
                      {fileExtension === 'txt' && (
                        <AiOutlineFileText size={25} />
                      )}
                      {fileExtension === 'png' && (
                        <AiOutlineFileImage size={25} />
                      )}
                      {fileExtension === 'docx' && (
                        <AiOutlineFileWord
                          size={25}
                          style={{ color: 'blue' }}
                        />
                      )}
                      {nameFile}
                    </span>
                    <div />
                  </div>
                  {uploadLoading ? (
                    <LinearProgress
                      style={{
                        width: '40%',
                        marginLeft: 5,
                        height: 2.5,
                      }}
                    />
                  ) : null}
                </div>
              )}
            </>
          )}
        </div>
        <div
          className="card-body d-flex flex-column"
          style={{ alignItems: 'flex-end' }}
        >
          {loading ? (
            <></>
          ) : (
            <div style={{ display: 'flex', gap: 15 }}>
              {answerFile?.pontuacao === undefined ||
              answerFile?.pontuacao === 0 ? (
                <></>
              ) : (
                <>
                  <p>Pontos: {answerFile?.pontuacao}</p>
                </>
              )}
            </div>
          )}

          {wasAnswered ? (
            <>
              {loading ? (
                <Skeleton variant="rect" width={0} height={0} />
              ) : (
                <Button
                  style={{ height: '5rem', padding: 20 }}
                  text="Resposta enviada  ✔"
                  type="button"
                  disabled
                />
              )}
            </>
          ) : (
            <>
              {loading ? (
                <Skeleton variant="rect" width={0} height={0} />
              ) : (
                <>
                  {nameFile ? (
                    <Button
                      text="Enviar"
                      type="button"
                      onClick={SubmitDesafio}
                    />
                  ) : (
                    <Button text="Enviar" type="button" disabled />
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
      {answerFile?.dataCorrecao && (
        <div className="card card-custom gutter-b flex-row">
          <div className="card-body d-flex flex-column">
            <strong>
              {loading ? (
                <Skeleton
                  variant="rect"
                  width="40%"
                  style={{ borderRadius: 3 }}
                />
              ) : (
                'Correção do desafio:'
              )}
            </strong>

            {loading ? (
              <Skeleton
                variant="rect"
                width="40%"
                style={{ borderRadius: 3, marginTop: 15 }}
              />
            ) : (
              <>
                <span style={{ fontWeight: 'bold' }}>Mensagem</span>
                <p>{answerFile?.mensagem}</p>
              </>
            )}

            {loading ? (
              <Skeleton
                variant="rect"
                width="40%"
                style={{ borderRadius: 3, marginTop: 15 }}
              />
            ) : (
              <>
                {answerFile?.dataCorrecao && (
                  <span style={{ fontWeight: 500 }}>
                    {format(Date.parse(answerFile.dataCorrecao), 'dd/MM/yyyy')}
                  </span>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
