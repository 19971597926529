import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import CardInformation from './components/cardinformation';
import CardTitleAndLearn from './components/cardTitleAndLearn';
import CardLearningUnit from './components/cardlearningUnit';
import CardDescription from './components/cardDescription';
import { BackHeaderComponent } from '../../../../components/BackHeader';
import { CursoProps } from '../../../../interfaces/Entidades';
import { API } from '../../../../services/api';
import { useAuth } from '../../../../hooks/Auth';

interface ParamsProps {
  id: string;
}

const DetailsCourse: React.FC = () => {
  const routeParams = useParams();
  const { token } = useAuth();
  const [curso, setCurso] = useState<CursoProps>({} as CursoProps);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      const { id } = routeParams as ParamsProps;
      try {
        const response = await API(token).get(`/api/curso/${id}`);

        setCurso(response.data);
        setIsLoading(false);
      } catch {
        toast.error(
          'Ocorreu um erro ao recuperar os detalhes do curso. Tente novamente! 😢',
        );
      }
    }

    getData();
  }, [token, routeParams]);

  return (
    <>
      <div className="row">
        <BackHeaderComponent />
      </div>
      <div className="row">
        <CardTitleAndLearn loading={isLoading} curso={curso} />
        <CardInformation
          loading={isLoading}
          curso={curso}
          isSubscribed
          setIsSubscribed={setIsSubscribed}
        />
      </div>
      <div className="row" style={{ marginTop: '20px' }}>
        <CardLearningUnit loading={isLoading} curso={curso} />
      </div>
    </>
  );
};
export default DetailsCourse;
