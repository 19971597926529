import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import CreateModal from './CreateModal';

import CompanyTable from './CompanyTable';
import { Button } from '../../../components/Button';
import { AdminUserCompanyProps } from '../../../interfaces/Entidades';
import { useAuth } from '../../../hooks/Auth';
import { API } from '../../../services/api';

const CompanyAdminUser = () => {
  const { token } = useAuth();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [adminUserCompany, setAdminUserCompany] = useState<
    AdminUserCompanyProps[]
  >([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadUserAdminCompany() {
      setLoading(true);
      setAdminUserCompany([]);
      try {
        const response = await API(token).get(
          '/api/colaborador/odata?$filter=administrador eq true',
        );

        setAdminUserCompany(response.data.value);
        setLoading(false);
      } catch (err) {
        toast.error(
          'Houve um problema ao listar os usuários, tente novamente!',
        );
      }
    }

    loadUserAdminCompany();
  }, [token]);

  return (
    <div className="cluster-table-container">
      <CreateModal
        itens={adminUserCompany}
        setItens={setAdminUserCompany}
        setModalOpen={setCreateModalOpen}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        closeButton={() => setCreateModalOpen(false)}
      />

      <div className="cluster-table-card">
        <div className="cluster-table-card-header">
          <h2 style={{ marginBottom: 30, color: '#802A00' }}>
            Conta Administradora da Organização
          </h2>
          <Button
            color="#802A00"
            text="Adicionar Usuário"
            onClick={() => setCreateModalOpen(true)}
          />
        </div>
        <CompanyTable
          loading={loading}
          users={adminUserCompany}
          setUsers={setAdminUserCompany}
        />
      </div>
    </div>
  );
};

export default CompanyAdminUser;
