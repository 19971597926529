/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback } from 'react';

import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { AiOutlineProfile } from 'react-icons/ai';

import Avatar from '../../assets/images/user.png';
import { useAuth } from '../../hooks/Auth';
import { DropdownMenuProps } from '../../interfaces/components';

const HeaderDropdown = ({
  foto,
  nome,
  options,
  color,
  backgroundColor,
}: DropdownMenuProps) => {
  const { signOut, user } = useAuth();

  const SignOut = useCallback(async () => {
    await signOut();
  }, [signOut]);

  return (
    <>
      <div className="topbar-item">
        <Dropdown drop="down" alignRight>
          <Dropdown.Toggle className="btn btn-hover-transparent-white w-sm-auto d-flex align-items-center btn-lg px-2">
            <span className="symbol symbol-35">
              <span className="symbol-label font-size-h5 font-weight-bold text-white bg-white-o-30">
                {nome.substr(0, 2).toUpperCase()}
              </span>
            </span>
          </Dropdown.Toggle>

          <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
            <>
              <span>
                <div className="d-flex align-items-center p-8 rounded-top">
                  <div className="symbol symbol-md  mr-3 flex-shrink-0">
                    <img
                      src={foto || Avatar}
                      alt=""
                      style={{ objectFit: 'cover' }}
                    />
                  </div>
                  <div className="m-0 flex-grow-1 mr-3 font-size-h5">
                    <span style={{ color }}>{nome}</span>
                  </div>
                  <span
                    className="label  label-lg font-weight-bold label-inline"
                    style={{ color: '#fff', backgroundColor }}
                  >
                    Bem Vindo
                  </span>
                </div>
                <div className="separator separator-solid" />
              </span>
              <div className="navi navi-spacer-x-0 pt-5">
                {options.map((option) => {
                  if (option.isAdmin && user.role !== 'Organizacao') {
                    return <></>;
                  }
                  return (
                    <Link
                      key={option.id}
                      to={option.link}
                      className="navi-item px-8 cursor-pointer"
                    >
                      <div className="navi-link">
                        <div className="navi-icon mr-2">
                          <AiOutlineProfile color="#000" />
                        </div>
                        <div className="navi-text">
                          <div className="font-weight-bold cursor-pointer">
                            {option.titulo}
                          </div>
                          <div className="text-muted">{option.descricao}</div>
                        </div>
                      </div>
                    </Link>
                  );
                })}

                <div className="navi-separator mt-3" />
                <div className="navi-footer  px-8 >py-5">
                  <Link to="/" />
                  <button
                    type="button"
                    className="btn font-weight-bold"
                    onClick={SignOut}
                    style={{ backgroundColor, color: '#fff' }}
                  >
                    Sair
                  </button>
                </div>
              </div>
            </>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
};
export default HeaderDropdown;
