import React from 'react';
import Carousel from 'react-multi-carousel';
import { Link } from 'react-router-dom';
import 'react-multi-carousel/lib/styles.css';
import { Skeleton } from '@material-ui/lab';
import { MyTrailsProps } from '../../../../interfaces/Entidades';
import AvatarImg from '../../../assets/images/no-image.png';

interface CardProgressTrail {
  loading: boolean;
  trilha: MyTrailsProps[];
}

export default function CardProgressTrail({
  loading,
  trilha,
}: CardProgressTrail) {
  const itensDesktop = () => {
    if (trilha) {
      if (trilha.length === 1) {
        return 2;
      }
      if (trilha.length === 2) {
        return 3;
      }
      if (trilha.length === 3) {
        return 4;
      }
      if (trilha.length >= 4) {
        return 3;
      }
      return 0;
    }
    return 0;
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: itensDesktop(),
      paritialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      paritialVisibilityGutter: 30,
    },
  };

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%',
          marginBottom: 25,
        }}
      >
        <div
          style={{
            width: 300,
            marginRight: 10,
            backgroundColor: '#fff',
            padding: 20,
            borderRadius: 8,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Skeleton variant="rect" width="20%" height={40} />
            <Skeleton variant="text" width="80%" style={{ marginLeft: 10 }} />
          </div>
          <div style={{ marginTop: 10 }}>
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="80%" />
          </div>
        </div>
        <div
          style={{
            width: 300,
            marginRight: 10,
            backgroundColor: '#fff',
            padding: 20,
            borderRadius: 8,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Skeleton variant="rect" width="20%" height={40} />
            <Skeleton variant="text" width="80%" style={{ marginLeft: 10 }} />
          </div>
          <div style={{ marginTop: 10 }}>
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="80%" />
          </div>
        </div>
        <div
          style={{
            width: 300,
            marginRight: 10,
            backgroundColor: '#fff',
            padding: 20,
            borderRadius: 8,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Skeleton variant="rect" width="20%" height={40} />
            <Skeleton variant="text" width="80%" style={{ marginLeft: 10 }} />
          </div>
          <div style={{ marginTop: 10 }}>
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="80%" />
          </div>
        </div>
        <div
          style={{
            width: 300,
            marginRight: 10,
            backgroundColor: '#fff',
            padding: 20,
            borderRadius: 8,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Skeleton variant="rect" width="20%" height={40} />
            <Skeleton variant="text" width="80%" style={{ marginLeft: 10 }} />
          </div>
          <div style={{ marginTop: 10 }}>
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="80%" />
          </div>
        </div>
        <div
          style={{
            width: 300,
            marginRight: 10,
            backgroundColor: '#fff',
            padding: 20,
            borderRadius: 8,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Skeleton variant="rect" width="20%" height={40} />
            <Skeleton variant="text" width="80%" style={{ marginLeft: 10 }} />
          </div>
          <div style={{ marginTop: 10 }}>
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="80%" />
          </div>
        </div>
      </div>
    );
  }

  if (trilha.length === 0) {
    return <></>;
  }
  return (
    <>
      <h1 className="titleRecommended">Progressos de Trilhas</h1>
      <Carousel
        ssr
        partialVisible
        responsive={responsive}
        itemClass="item-class"
      >
        {trilha.map((trail) => (
          <div
            className="card card-custom card-stretch gutter-b"
            style={{
              cursor: 'pointer',
            }}
          >
            <div className="card-body d-flex flex-column">
              <div className="flex-grow-1 pb-5">
                <div className="d-flex align-items-center pr-2 mb-6">
                  <div className="symbol symbol-60">
                    <img
                      src={
                        trail.trilha.urlImagem
                          ? trail.trilha.urlImagem
                          : AvatarImg
                      }
                      alt="Foto da Trilha"
                      style={{
                        objectFit: 'cover',
                      }}
                    />
                  </div>
                  <Link
                    to={{ pathname: `/details_trail/${trail.trilha.id}` }}
                    className="font-weight-bolder font-size-h5 color font-weight-bolder line-clamp-title"
                    style={{ marginLeft: '5px' }}
                  >
                    {trail.trilha.nome}
                  </Link>
                </div>
                <div className="font-weight-bolder text-muted mt-2">
                  {trail.trilha.nome}
                </div>

                <p className="text-dark-75 font-size-sm  font-size-base m-0 text-muted line-clamp">
                  {trail.trilha.resumo}
                </p>
                <br />
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <span className="text-muted mr-2 font-size-sm font-weight-bold">
                    {(trail.progresso * 100).toFixed(2)} %
                  </span>
                  <span className="text-muted font-size-sm font-weight-bold">
                    Progresso
                  </span>
                </div>
                <div className="progress progress-xs w-100">
                  <div
                    className="progress-bar bg-success"
                    role="progressbar"
                    style={{ width: `${trail.progresso * 100}%` }}
                    aria-valuenow={50}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </>
  );
}
