/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React, { useState } from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { FiEdit } from 'react-icons/fi';
import { IoMdTrash } from 'react-icons/io';
import LinearProgress from '@material-ui/core/LinearProgress';
import EditModal from './EditModal';
import DeleteModal from '../../../components/Modal/DeleteModal';
import TableComponent from '../../../components/Table';
import { ClusterProps } from '../../../interfaces/Entidades';

interface ClusterTableProps {
  clusters: ClusterProps[];
  setClusters: React.Dispatch<React.SetStateAction<ClusterProps[]>>;
  loading: boolean;
}

export default function ClustersTable({
  clusters,
  loading,
  setClusters,
}: ClusterTableProps) {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [editItem, setEditItem] = useState<ClusterProps>({} as ClusterProps);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <EditModal
        id={editItem.id}
        setModalOpen={setEditModalOpen}
        item={editItem}
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        closeButton={() => setEditModalOpen(false)}
      />

      <DeleteModal
        itens={clusters}
        setItens={setClusters}
        color="#802A00"
        name="o cluster"
        routeName="cluster"
        id={editItem.id}
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        closeButton={() => setDeleteModalOpen(false)}
      />

      {loading ? (
        <div>
          <LinearProgress />
        </div>
      ) : (
        <TableComponent
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          ItemLenght={clusters.length}
          TableHeadCells={['Nome', 'Cidade', 'Tipo Cluster', 'Ações']}
        >
          {clusters
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((cluster) => (
              <TableRow key={cluster.id}>
                <TableCell
                  component="th"
                  scope="row"
                  align="left"
                  className="cluster-table-body-font"
                >
                  {cluster.descricao}
                </TableCell>
                <TableCell align="left" className="cluster-table-body-font">
                  {cluster.cidade}
                </TableCell>
                <TableCell align="left" className="cluster-table-body-font">
                  {cluster.tipoCluster.descricao}
                </TableCell>
                <TableCell align="left" className="cluster-table-body-action">
                  <FiEdit
                    onClick={async () => {
                      await setEditItem(cluster);
                      await setEditModalOpen(true);
                    }}
                    size={25}
                    color="#80808F"
                    style={{ cursor: 'pointer' }}
                    className="cluster-table-svg"
                    onMouseOver={({ currentTarget }) =>
                      (currentTarget.style.color = '#802A00')
                    }
                    onMouseOut={({ currentTarget }) =>
                      (currentTarget.style.color = '#80808F')
                    }
                  />
                  <IoMdTrash
                    onClick={() => {
                      setEditItem(cluster);
                      setDeleteModalOpen(true);
                    }}
                    onMouseOver={({ currentTarget }) =>
                      (currentTarget.style.color = '#802A00')
                    }
                    onMouseOut={({ currentTarget }) =>
                      (currentTarget.style.color = '#80808F')
                    }
                    size={25}
                    color="#80808F"
                    style={{ marginLeft: 15, cursor: 'pointer' }}
                    className="cluster-table-svg"
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableComponent>
      )}
    </>
  );
}
