import React from 'react';

import '../../../styles/component.scss';

import { Route, Switch } from 'react-router-dom';

import AdminClusterHome from '../Home';
import { Error404 } from '../../../ErrorPages/404';
import ClusterCompanyPage from '../Company';
import PageContainer from '../../../components/PageContainer';
import { ItensProps, DropDownOptionsProps, OptionsProps } from '../../../interfaces/components';
import { HeaderAdmin } from '../../../components/HeaderAdmin';
import HeaderMobile from '../../../components/HeaderMobile';
import Tutorials from '../Tutorials';
import CompanyAdminUser from '../CompanyAdminUser';

const links: ItensProps[] = [
  { id: 1, link: '/', nome: 'Home' },
  { id: 3, link: '/organizacao', nome: 'Organização' },
  { id: 4, link: '/organizacao_users', nome: 'Administrador Organização' },
];

const options: OptionsProps[] = [
  {
    id: 1,
    titulo: 'Organização',
    itens: [
      { id: 2, link: '/organizacao', nome: 'Organização' },
      { id: 3, link: '/organizacao_users', nome: 'Admin Organização' },
    ],
  },
];

const dropdownOptions: DropDownOptionsProps[] = [
  {
    id: 1,
    titulo: 'Tutorial Organização',
    descricao: 'Tutoriais do Sistema',
    isAdmin: false,
    link: '/tutorials',
  },
];

const ContainerAdminCluster: React.FC = () => (
  <>
    <PageContainer
      HeaderOptions={options}
      backgroundColor="#668036"
      Header={HeaderAdmin}
      HeaderMobile={HeaderMobile}
      headerDropDownOptions={dropdownOptions}
      color="#668036"
      links={links}
      options={[]}
    >
      <Switch>
        <Route path="/" exact component={ClusterCompanyPage} />
        <Route path="/organizacao" exact component={ClusterCompanyPage} />
        <Route path="/tutorials" exact component={Tutorials} />
        <Route path="/organizacao_users" component={CompanyAdminUser} />
        <Route component={Error404} />
      </Switch>
    </PageContainer>
  </>
);
export default ContainerAdminCluster;
