/* eslint-disable no-param-reassign */
import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  ChangeEvent,
} from 'react';
import { Form } from '@unform/web';
import { FiImage, FiLock, FiMail, FiUser, FiCamera } from 'react-icons/fi';
import { RiLinkedinBoxLine } from 'react-icons/ri';
import { FormHandles } from '@unform/core';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { API } from '../../../../../services/api';
import GetValidationErrors from '../../../../../utils/getValidationErros';
import { useAuth } from '../../../../../hooks/Auth';
import Input from '../../../../components/Input';
import { Button } from '../../../../components/Button';
import { EditModalProps } from '../../../../../interfaces/components';
import ModalComponent from '../../../../../components/Modal/ModalComponent';
import { UserProfissionalProps } from '../../../../../interfaces/Entidades';
import TextAreaInput from '../../../../components/TextAreaInput';
import AvatarImg from '../../../../../assets/images/no-image.png';
import './style.scss';

export default function EditModalUserAdminCompany({
  id,
  onClose,
  closeButton,
  item,
  open,
  setModalOpen,
}: EditModalProps) {
  const { token, user, updateUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);

  const avatarRef = useRef<HTMLImageElement>(null);
  const fileRef = useRef<HTMLInputElement>(null);

  const Submit = useCallback(
    async (data) => {
      setLoading(true);

      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          curriculo: Yup.string(),
          linkedIn: Yup.string().url('Deve conter um link'),
          nome: Yup.string().required('Campo Obrigatório'),
          email: Yup.string()
            .required('Campo Obrigatório')
            .email('Email inválido'),
          cpf: Yup.string(),
          foto: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        data.aceitaColetaDados = true;

        await API(token).put(`/api/usuario/profissional`, data);
        setLoading(false);

        if (
          fileRef.current &&
          fileRef.current.files &&
          fileRef.current.files.length > 0
        ) {
          const formData = new FormData();
          formData.append('foto', fileRef.current.files[0]);

          const upload_response = await API(token).put(
            '/api/usuario/imagem',
            formData,
          );
          data.foto = upload_response.data.url;

          updateUser(upload_response.data.url);
        }

        await setTimeout(() => {
          window.location.reload();
        }, 1000);
        toast.success('Usuário alterado com sucesso!');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = GetValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          toast.error('Houve um erro ao editar o usuário, tente novamente! 😢');
        }

        setLoading(false);
      }
    },

    [token],
  );

  const handleFotoChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      if (avatarRef.current) {
        avatarRef.current.src = URL.createObjectURL(e.target.files[0]);
      }
    }
  }, []);

  return (
    <ModalComponent closeButton={closeButton} open={open} onClose={onClose}>
      <Form ref={formRef} placeholder="" className="createmodal-form-admin" onSubmit={Submit}>
        <h2 id="simple-modal-title">Editar usuário</h2>

        <div className="AvatarInput">
          <img ref={avatarRef} src={item?.foto || AvatarImg} alt="" />
          <label htmlFor="foto" style={{ backgroundColor: '#008C9E' }}>
            <FiCamera />

            <input
              type="file"
              id="foto"
              ref={fileRef}
              name="foto"
              accept="image/*"
              onChange={(event) => handleFotoChange(event)}
            />
          </label>
        </div>

        <Input
          name="nome"
          maxLength={60}
          type="text"
          icon={FiUser}
          defaultValue={item.nome}
          placeholder="Nome"
          disabled
        />

        <Input
          name="email"
          maxLength={80}
          defaultValue={item.email}
          icon={FiMail}
          type="text"
          placeholder="Email"
          disabled
        />

        <Input
          name="linkedIn"
          maxLength={80}
          defaultValue={item.linkedIn}
          icon={RiLinkedinBoxLine}
          type="text"
          placeholder="Linkedin"
        />

        <TextAreaInput
          name="descricao"
          maxLength={300}
          defaultValue={item.descricao}
          placeholder="Descrição"
          style={{ overflow: 'hidden' }}
        />

        <Button
          color="#005F6B"
          text="Confirmar"
          type="submit"
          loading={loading}
        />
      </Form>
    </ModalComponent>
  );
}
