/* eslint-disable no-return-assign */
import React, {
  InputHTMLAttributes,
  RefObject,
  useEffect,
  useRef,
} from 'react';
import { useField } from '@unform/core';

interface Props {
  name: string;
  label?: string;
  options: {
    id: string;
    value: string;
    label: string;
  }[];
}

type RefInputEl = RefObject<HTMLInputElement[]>;

type InputProps = InputHTMLAttributes<HTMLInputElement> & Props;

export default function InputRadio({
  name,
  label,
  options,
  ...rest
}: InputProps) {
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const { fieldName, registerField, defaultValue = '', error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs,
      getValue: (refs: RefInputEl) => {
        if (refs.current) {
          const findValue = refs.current.find((input) => input?.checked)?.value;
          if (findValue) {
            return findValue;
          }
        }
        return '';
      },
      setValue: (refs: RefInputEl, id: string) => {
        if (refs.current) {
          const inputRef = refs.current.find((ref) => ref.id === id);
          if (inputRef) inputRef.checked = true;
        }
      },
      clearValue: (refs: RefInputEl) => {
        if (refs.current) {
          const inputRef = refs.current.find((ref) => ref.checked === true);
          if (inputRef) inputRef.checked = false;
        }
      },
    });
  }, [fieldName, registerField]);

  return (
    <>
      {options.map((option, index) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            marginLeft: 15,
          }}
          key={option.id}
        >
          <input
            type="radio"
            ref={(ref) => {
              if (ref) {
                inputRefs.current[index] = ref;
              }
            }}
            id={option.id}
            name={name}
            defaultChecked={defaultValue === option.id}
            value={option.value}
            {...rest}
          />

          <label
            style={{
              marginRight: 6,
              marginLeft: 2,
              color: error ? 'red' : '#000',
            }}
            htmlFor={option.id}
            key={option.id}
          >
            {option.label}
          </label>
        </div>
      ))}
    </>
  );
}
