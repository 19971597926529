import React from 'react';
import { Link } from 'react-router-dom';

import { format } from 'date-fns';
import { Skeleton } from '@material-ui/lab';
import { AllEvaluations } from '../../../../interfaces/Entidades';

interface TableAssessment {
  loading: boolean;
  evaluations: AllEvaluations[];
}

export default function tableAssessment({
  loading,
  evaluations,
}: TableAssessment) {
  if (loading) {
    return (
      <>
        <div className="col-sm-12" style={{ height: '100%' }}>
          <div
            className="card card-custom card-stretch gutter-b"
            style={{ height: '66%' }}
          >
            <div className="card-header border-0 py-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label font-weight-bolder text-dark">
                  <Skeleton
                    variant="text"
                    width={200}
                    height={30}
                    style={{ marginTop: '-10px' }}
                  />
                </span>
              </h3>
            </div>

            <div className="card-body py-0">
              <Skeleton
                variant="text"
                width="100%"
                height="60px"
                style={{ marginTop: '-35px' }}
              />
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="col-sm-12" style={{ height: '100%' }}>
        <div
          className="card card-custom card-stretch gutter-b"
          style={{ height: '66%' }}
        >
          <div className="card-header border-0 py-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label font-weight-bolder text-dark">
                Provas Realizadas
              </span>
            </h3>
          </div>

          <div className="card-body py-0">
            <div className="table-responsive">
              <table
                className="table table-head-custom table-vertical-center"
                id="kt_advance_table_widget_1"
              >
                <thead>
                  <tr className="text-left">
                    <th style={{ minWidth: '250px' }}>Avaliaçao</th>
                    <th style={{ minWidth: '250px' }}>Modulo</th>
                    <th style={{ minWidth: '200px' }}>Data Submissão</th>
                    <th style={{ minWidth: '150px' }}>Pontuação</th>
                  </tr>
                </thead>
                <tbody>
                  {evaluations.length > 0 ? (
                    evaluations.map((evaluation) => (
                      <tr>
                        <td className="pl-0">
                          <span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
                            {evaluation.avaliacao.descricao}
                          </span>
                        </td>
                        <td>
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {evaluation.avaliacao.unidadeAprendizagem.titulo}
                          </span>
                        </td>
                        <td className="pl-0">
                          <span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
                            {format(
                              Date.parse(evaluation.dataSubmissao),
                              'dd/MM/yyyy',
                            )}
                          </span>
                        </td>
                        <td>
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {(evaluation.pontuacao * 100).toFixed(2)}
                          </span>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <></>
                    // <h2 style={{ textAlign: 'center' }}>
                    //   Não há itens disponíveis
                    // </h2>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
