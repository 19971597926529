/* eslint-disable no-nested-ternary */
import React from 'react';

import { Skeleton } from '@material-ui/lab';
import { CompanyProps } from '../../../../../interfaces/Entidades';

interface cardWork {
  company: CompanyProps;
  loading: boolean;
}

export default function cardWork({ company, loading }: cardWork) {
  if (company.trabalheConosco === '') {
    return <></>;
  }
  return (
    <>
      <div className="cardLearningUnit">
        <div
          className="card card-custom card-stretch gutter-b"
          style={{ height: '66%' }}
        >
          <div className="card-header border-0 py-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label font-weight-bolder text-dark">
                {loading ? (
                  <Skeleton variant="text" width={150} height={30} />
                ) : (
                  'Trabalhe Conosco'
                )}
              </span>
            </h3>
          </div>
          <div className="card-body py-0">
            <p className="font-weight-bold text-dark-75 font-size-lg">
              {loading ? (
                <Skeleton
                  variant="text"
                  width="100%"
                  height={100}
                  style={{ marginTop: '-30px' }}
                />
              ) : company.trabalheConosco ? (
                company.trabalheConosco
              ) : (
                'Não há descrição'
              )}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
