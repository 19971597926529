import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => (
  <>
    {/* begin::Footer */}
    <div className="footer bg-white py-4 d-flex flex-lg-column">
      {/* begin::Container */}
      <div className="container d-flex flex-column flex-md-row align-items-center justify-content-between">
        {/* begin::Copyright */}
        <div className="text-dark order-2 order-md-1">
          <span className="text-muted font-weight-bold mr-2">2022©</span>
          <Link
            to="/"
            target="_blank"
            className="text-dark-75 text-hover-primary"
          >
            APL SP PLAY
          </Link>
        </div>
        {/* end::Copyright */}
      </div>
      {/* end::Container */}
    </div>
    {/* end::Footer */}
  </>
);
export default Footer;
