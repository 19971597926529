/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { FiCamera } from 'react-icons/fi';
import { API } from '../../../services/api';
import GetValidationErrors from '../../../utils/getValidationErros';
import { useAuth } from '../../../hooks/Auth';

import Input from '../../components/Input';
import TextAreaInput from '../../components/TextAreaInput';
import MaskInput from '../../components/MaskInput';
import { CompanyProps } from '../../../interfaces/Entidades';
import { Button } from '../../../components/Button';
import AvatarImg from '../../../assets/images/no-image.png';

interface cepProps {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
}

export default function CompanyPage() {
  const { token, user } = useAuth();
  const [inputLoading, setInputLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cep, setCep] = useState('');
  const [telResponsavel, setTelResponsavel] = useState('');
  const [clusterId, setclusterId] = useState('');
  const [tipoOrganizacaoId, setTipoOrganizacaoId] = useState('');
  const [telefone, setTelefone] = useState('');
  const [empresa, setEmpresa] = useState<CompanyProps>();
  const formRef = useRef<FormHandles>(null);
  const avatarRef = useRef<HTMLImageElement>(null);
  const fileRef = useRef<HTMLInputElement>(null);

  const Submit = useCallback(
    async (data) => {
      setLoading(true);

      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          razaoSocial: Yup.string().required('Campo Obrigatório'),
          nomeFantasia: Yup.string().required('Campo Obrigatório'),
          tipoLogradouro: Yup.string().required('Campo Obrigatório'),
          logradouro: Yup.string().required('Campo Obrigatório'),
          numero: Yup.string().required('Campo Obrigatório'),
          complemento: Yup.string(),
          bairro: Yup.string().required('Campo Obrigatório'),
          cidade: Yup.string().required('Campo Obrigatório'),
          uf: Yup.string().required('Campo Obrigatório'),
          cep: Yup.string().required('Campo Obrigatório'),
          telefone: Yup.string().required('Campo Obrigatório'),
          email: Yup.string().required('Campo Obrigatório').email(),
          urlSite: Yup.string().required('Campo Obrigatório'),
          nomeResponsavel: Yup.string().required('Campo Obrigatório'),
          telefoneResponsavel: Yup.string().required('Campo Obrigatório'),
          logotipo: Yup.string(),
          urlFacebook: Yup.string(),
          urlInstagram: Yup.string(),
          urlLinkedIn: Yup.string(),
          urlYoutube: Yup.string(),
          descricao: Yup.string(),
          trabalheConosco: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        data.logotipo = empresa?.logotipo;

        if (
          fileRef.current &&
          fileRef.current.files &&
          fileRef.current.files.length > 0
        ) {
          const formData = new FormData();
          formData.append('avatar', fileRef.current.files[0]);

          const upload_response = await API(token).post(
            '/api/trilha/UploadFiles/avatar',
            formData,
          );

          data.logotipo = upload_response.data.avatar;
        }

        data.tipoOrganizacaoId = tipoOrganizacaoId;
        data.clusterId = clusterId;
        data.ativo = true;
        await API(token).put(`/api/organizacao`, data);
        setLoading(false);

        toast.success('Dados Alterados com Sucesso!');
        await setTimeout(() => {
          window.location.reload();
        }, 1000);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = GetValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          toast.error('Houve um erro ao criar o empresa, tente novamente!');
        }

        setLoading(false);
      }
    },

    [clusterId, tipoOrganizacaoId, token, empresa?.logotipo],
  );

  useEffect(() => {
    async function loadData() {
      setInputLoading(true);

      try {
        const response_dados = await API(token).get(
          `api/organizacao/${user.organizacao}`,
        );

        setclusterId(response_dados.data.clusterId);
        setTipoOrganizacaoId(response_dados.data.tipoOrganizacaoId);
        setEmpresa(response_dados.data);
        setTelResponsavel(response_dados.data.telefoneResponsavel);
        setCep(response_dados.data.cep);
        setTelefone(response_dados.data.telefone);
        setInputLoading(false);
      } catch (err) {
        toast.error('Houve um erro ao carregar os dados da empresa! 😢');
        setInputLoading(false);
      }
    }

    loadData();
  }, [token, user.organizacao]);

  const handleAvatarChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      if (avatarRef.current) {
        avatarRef.current.src = URL.createObjectURL(e.target.files[0]);
      }
    }
  }, []);

  return (
    <>
      <Form ref={formRef} placeholder="" className="form" onSubmit={Submit}>
        <div
          className="card card-custom card-stretch"
          style={{ marginTop: '28px' }}
        >
          <div className="card-header py-3">
            <div className="card-title align-items-start flex-column">
              <h3
                className="card-label font-weight-bolder"
                style={{ color: '#413B6B' }}
              >
                Perfil da empresa
              </h3>
              <span className="text-muted font-weight-bold font-size-sm mt-1">
                Visualizar e editar perfil
              </span>
            </div>
            <div className="card-toolbar">
              <Button
                color="#6F95FF"
                className="btn btn-success mr-2"
                text="Salvar Alterações"
                type="submit"
                loading={loading}
                style={{ background: '#5C65C0', borderColor: '#5C65C0' }}
              />
            </div>
          </div>

          <div className="card-body">
            <div className="row">
              <label className="col-xl-3" />
              <div className="col-lg-9 col-xl-6">
                <h5
                  className="font-weight-bold mb-6"
                  style={{ color: '#413B6B' }}
                >
                  Informações da Empresa
                </h5>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">Logo</label>
              <div className="AvatarInput">
                <img
                  ref={avatarRef}
                  src={empresa?.logotipo || AvatarImg}
                  alt=""
                />
                <label htmlFor="avatar">
                  <FiCamera />

                  <input
                    type="file"
                    id="avatar"
                    ref={fileRef}
                    name="logotipo"
                    accept="image/*"
                    onChange={(event) => handleAvatarChange(event)}
                  />
                </label>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Nome da empresa
              </label>
              <div className="col-lg-9 col-xl-6">
                <Input
                  name="razaoSocial"
                  maxLength={60}
                  type="text"
                  defaultValue={empresa?.razaoSocial}
                  placeholder={inputLoading ? 'Carregando...' : undefined}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Nome Fantasia
              </label>
              <div className="col-lg-9 col-xl-6">
                <Input
                  name="nomeFantasia"
                  maxLength={60}
                  type="text"
                  defaultValue={empresa?.nomeFantasia}
                  placeholder={inputLoading ? 'Carregando...' : undefined}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">Email</label>
              <div className="col-lg-9 col-xl-6">
                <Input
                  name="email"
                  maxLength={80}
                  type="text"
                  defaultValue={empresa?.email}
                  placeholder={inputLoading ? 'Carregando...' : undefined}
                />
              </div>
            </div>

            <div className="row">
              <label className="col-xl-3" />
              <div className="col-lg-9 col-xl-6">
                <h5
                  className="font-weight-bold mt-10 mb-6"
                  style={{ color: '#413B6B' }}
                >
                  Informações Contato
                </h5>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Nome do Responsável
              </label>
              <div className="col-lg-9 col-xl-6">
                <Input
                  name="nomeResponsavel"
                  maxLength={80}
                  type="text"
                  defaultValue={empresa?.nomeResponsavel}
                  placeholder={inputLoading ? 'Carregando...' : undefined}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Telefone Responsável
              </label>
              <div className="col-lg-9 col-xl-6">
                <MaskInput
                  mask={telResponsavel ? '(99) 99999-9999' : ''}
                  name="telefoneResponsavel"
                  defaultValue={empresa?.telefoneResponsavel}
                  type="text"
                  placeholder={inputLoading ? 'Carregando...' : undefined}
                  style={{ paddingLeft: '0px' }}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Telefone Empresa
              </label>
              <div className="col-lg-9 col-xl-6">
                <MaskInput
                  mask={telefone ? '(99) 9999-9999' : ''}
                  name="telefone"
                  defaultValue={empresa?.telefone}
                  type="text"
                  placeholder={inputLoading ? 'Carregando...' : undefined}
                  style={{ paddingLeft: '0px' }}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Link do Site
              </label>
              <div className="col-lg-9 col-xl-6">
                <Input
                  name="urlSite"
                  maxLength={80}
                  defaultValue={empresa?.urlSite}
                  type="text"
                  placeholder={inputLoading ? 'Carregando...' : undefined}
                />
              </div>
            </div>

            <div className="row">
              <label className="col-xl-3" />
              <div className="col-lg-9 col-xl-6">
                <h5
                  className="font-weight-bold mt-10 mb-6"
                  style={{ color: '#413B6B' }}
                >
                  Endereço
                </h5>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">CEP</label>
              <div className="col-lg-9 col-xl-6">
                <MaskInput
                  mask={cep ? '99999-999' : ''}
                  name="cep"
                  defaultValue={empresa?.cep}
                  type="text"
                  placeholder={inputLoading ? 'Carregando...' : undefined}
                  style={{ paddingLeft: '0px' }}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Logradouro
              </label>
              <div className="col-lg-9 col-xl-6">
                <Input
                  name="logradouro"
                  defaultValue={empresa?.logradouro}
                  maxLength={80}
                  type="text"
                  placeholder={inputLoading ? 'Carregando...' : undefined}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">Número</label>
              <div className="col-lg-9 col-xl-6">
                <Input
                  name="numero"
                  maxLength={80}
                  defaultValue={empresa?.numero}
                  type="text"
                  placeholder={inputLoading ? 'Carregando...' : undefined}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Complemento
              </label>
              <div className="col-lg-9 col-xl-6">
                <Input
                  name="complemento"
                  defaultValue={empresa?.complemento}
                  maxLength={80}
                  type="text"
                  placeholder={inputLoading ? 'Carregando...' : undefined}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">Bairro</label>
              <div className="col-lg-9 col-xl-6">
                <Input
                  name="bairro"
                  defaultValue={empresa?.bairro}
                  maxLength={80}
                  type="text"
                  placeholder={inputLoading ? 'Carregando...' : undefined}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">Cidade</label>
              <div className="col-lg-9 col-xl-6">
                <Input
                  name="cidade"
                  maxLength={80}
                  defaultValue={empresa?.cidade}
                  type="text"
                  placeholder={inputLoading ? 'Carregando...' : undefined}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">Estado</label>
              <div className="col-lg-9 col-xl-6">
                <Input
                  name="uf"
                  maxLength={80}
                  defaultValue={empresa?.uf}
                  type="text"
                  placeholder={inputLoading ? 'Carregando...' : undefined}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Tipo Logradouro
              </label>
              <div className="col-lg-9 col-xl-6">
                <Input
                  name="tipoLogradouro"
                  maxLength={80}
                  defaultValue={empresa?.tipoLogradouro}
                  type="text"
                  placeholder={inputLoading ? 'Carregando...' : undefined}
                />
              </div>
            </div>

            <div className="row">
              <label className="col-xl-3" />
              <div className="col-lg-9 col-xl-6">
                <h5
                  className="font-weight-bold mt-10 mb-6"
                  style={{ color: '#413B6B' }}
                >
                  Rede Socais
                </h5>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Link Facebook
              </label>
              <div className="col-lg-9 col-xl-6">
                <Input
                  name="urlFacebook"
                  maxLength={80}
                  defaultValue={empresa?.urlFacebook}
                  type="text"
                  placeholder={inputLoading ? 'Carregando...' : undefined}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Link Instagram
              </label>
              <div className="col-lg-9 col-xl-6">
                <Input
                  name="urlInstagram"
                  maxLength={80}
                  defaultValue={empresa?.urlInstagram}
                  type="text"
                  placeholder={inputLoading ? 'Carregando...' : undefined}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Link Linkedin
              </label>
              <div className="col-lg-9 col-xl-6">
                <Input
                  name="urlLinkedIn"
                  maxLength={80}
                  defaultValue={empresa?.urlLinkedIn}
                  type="text"
                  placeholder={inputLoading ? 'Carregando...' : undefined}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Link Youtube
              </label>
              <div className="col-lg-9 col-xl-6">
                <Input
                  name="urlYoutube"
                  maxLength={80}
                  defaultValue={empresa?.urlYoutube}
                  type="text"
                  placeholder={inputLoading ? 'Carregando...' : undefined}
                />
              </div>
            </div>

            <div className="row">
              <label className="col-xl-3" />
              <div className="col-lg-9 col-xl-6">
                <h5
                  className="font-weight-bold mt-10 mb-6"
                  style={{ color: '#413B6B' }}
                >
                  Descrições
                </h5>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Descrição da Empresa
              </label>
              <div className="col-lg-9 col-xl-6">
                <TextAreaInput
                  name="descricao"
                  maxLength={400}
                  style={{ maxHeight: 180, height: 180 }}
                  defaultValue={empresa?.descricao}
                  type="text"
                  placeholder={inputLoading ? 'Carregando...' : undefined}
                />

                <span className="form-text text-muted">
                  Descrição da empresa para pagina principal da organização
                </span>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Trabalhe Conosco
              </label>
              <div className="col-lg-9 col-xl-6">
                <TextAreaInput
                  name="trabalheConosco"
                  maxLength={1000}
                  defaultValue={empresa?.trabalheConosco}
                  style={{ maxHeight: 130, height: 130 }}
                  type="text"
                  placeholder={inputLoading ? 'Carregando...' : undefined}
                />
                <span className="form-text text-muted">
                  Descrição trabalhe conosco da empresa para pagina principal da
                  organização
                </span>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
}
