/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
import { useCallback, useEffect, useState } from 'react';

import { Skeleton } from '@material-ui/lab';
import toast from 'react-hot-toast';
import { useAuth } from '../../../../hooks/Auth';
import { CategoriasProps } from '../../../../interfaces/CategoryProps';
import { CompanyProps, TrailProps } from '../../../../interfaces/Entidades';
import { API } from '../../../../services/api';
import { Button } from '../../../components/Button';
import CardTrail from './components/cardTrail';

import '../style.scss';

export default function SearchTrail() {
  const [loadingCategoria, setLoadingCategoria] = useState(true);
  const [loadingTrilha, setLoadingTrilha] = useState(true);
  const [loadingEmpresa, setLoadingEmpresa] = useState(true);
  const { token, user } = useAuth();
  const [categories, setCategories] = useState<CategoriasProps[]>([]);
  const [empresas, setEmpresas] = useState<CompanyProps[]>([]);
  const [trilhas, setTrilhas] = useState<TrailProps[]>([]);
  const [selectedCategorys, setSelectedCategorys] = useState<string[]>([]);
  const [selectedCompanys, setSelectedCompanys] = useState<string[]>([]);
  const [TrailName, setTrailName] = useState('');

  useEffect(() => {
    async function loadInitialData() {
      setLoadingEmpresa(true);
      setLoadingCategoria(true);
      setLoadingTrilha(true);
      setSelectedCategorys([]);
      setSelectedCompanys([]);

      try {
        let response_trilha = {
          data: {
            value: [],
          },
        };

        if (user.organizacao === '') {
          response_trilha = await API(token).get(
            '/api/trilha/search?$filter=exclusiva eq false and ativa eq true',
          );
        } else {
          response_trilha = await API(token).get(
            `/api/trilha/search?$filter=ativa eq true and ((exclusiva eq true and organizacaoId eq ${user.organizacao}) or exclusiva eq false)`,
          );
        }

        // const response_cluster = await API(token).get(
        //   '/api/cluster/odata?$select=id,descricao',
        // );

        const response_categoria = await API(token).get('/api/categoria/odata');
        const response_empresa = await API(token).get(
          '/api/organizacao/search',
          { params: { $select: 'razaoSocial,id' } },
        );

        // setClusters(response_cluster.data.value);
        setCategories(response_categoria.data.value);
        setTrilhas(response_trilha.data.value);
        setEmpresas(response_empresa.data.value);
      } catch (err) {
        console.log(err);
        toast.error('Ocorreu um erro ao listar as trilhas,tente novamente! 😢');
        setCategories([]);
        setTrilhas([]);
        setEmpresas([]);
      } finally {
        setLoadingEmpresa(false);
        setLoadingCategoria(false);
        setLoadingTrilha(false);
      }
    }

    loadInitialData();
  }, [token, user]);

  // const handleClusterChange = useCallback(
  //   async (clusterId) => {
  //     setLoadingEmpresa(true);
  //     const response = await API(token).get('/api/organizacao/odata', {
  //       params: { $filter: `clusterId eq ${clusterId}` },
  //     });

  //     setEmpresas(response.data.value);
  //     setLoadingEmpresa(false);
  //   },
  //   [token],
  // );

  const handleFilter = useCallback(async () => {
    setLoadingTrilha(true);

    try {
      let params: any = { };

      if (user.organizacao === '') {
        params = {
          $filter: `exclusiva eq false and ativa eq true`,
          $search: TrailName,
        };
      } else {
        params = {
          $filter: `ativa eq true and ((exclusiva eq true and organizacaoId eq ${user.organizacao}) or exclusiva eq false)`,
          $search: TrailName,
        };
      }

      if (selectedCategorys.length > 0 && selectedCompanys.length <= 0) {
        let allCategories = '';

        selectedCategorys.forEach(
          // eslint-disable-next-line no-return-assign
          (categoria, index) => {
            if (index === selectedCategorys.length - 1) {
              allCategories += `${categoria}`;
            } else {
              allCategories += `${categoria},`;
            }
          },
        );

        if (user.organizacao === '') {
          params = {
            $filter: `categorias/any(c:c/categoriaId in (${allCategories})) and exclusiva eq false and ativa eq true`,
            $search: TrailName,
          };
        } else {
          params = {
            $filter: `categorias/any(c:c/categoriaId in (${allCategories})) ativa eq true and ((exclusiva eq true and organizacaoId eq ${user.organizacao}) or exclusiva eq false)`,
            $search: TrailName,
          };
        }
      } else if (selectedCategorys.length <= 0 && selectedCompanys.length > 0) {
        let allCompanys = '';

        selectedCompanys.forEach(
          // eslint-disable-next-line no-return-assign
          (company, index) => {
            if (index === selectedCompanys.length - 1) {
              allCompanys += `${company}`;
            } else {
              allCompanys += `${company},`;
            }
          },
        );

        if (user.organizacao === '') {
          params = {
            $filter: `organizacaoId in (${allCompanys}) and exclusiva eq false and ativa eq true`,
            $search: TrailName,
          };
        } else {
          params = {
            $filter: `organizacaoId in (${allCompanys}) ativa eq true and ((exclusiva eq true and organizacaoId eq ${user.organizacao}) or exclusiva eq false)`,
            $search: TrailName,
          };
        }

      } else if (selectedCompanys.length > 0 && selectedCategorys.length > 0) {
        let allCategories = '';

        selectedCategorys.forEach(
          // eslint-disable-next-line no-return-assign
          (categoria, index) => {
            if (index === selectedCategorys.length - 1) {
              allCategories += `${categoria}`;
            } else {
              allCategories += `${categoria},`;
            }
          },
        );

        let allCompanys = '';

        selectedCompanys.forEach(
          // eslint-disable-next-line no-return-assign
          (company, index) => {
            if (index === selectedCompanys.length - 1) {
              allCompanys += `${company}`;
            } else {
              allCompanys += `${company},`;
            }
          },
        );

        if (user.organizacao === '') {
          params = {
            $filter: `categorias/any(c:c/categoriaId in (${allCategories})) and organizacaoId in (${allCompanys}) and exclusiva eq false and ativa eq true`,
            $search: TrailName,
          };
        } else {
          params = {
            $filter: `categorias/any(c:c/categoriaId in (${allCategories})) and organizacaoId in (${allCompanys}) ativa eq true and ((exclusiva eq true and organizacaoId eq ${user.organizacao}) or exclusiva eq false)`,
            $search: TrailName,
          };
        }
      }

      const response = await API(token).get('/api/trilha/search', {
        params,
      });

      if (TrailName) {
        setTrilhas(response.data);
      } else {
        setTrilhas(response.data.value);
      }
    } catch (err) {
      toast.error('Houve um erro ao realizar a pesquisa! 😢');
    } finally {
      setLoadingTrilha(false);
    }
  }, [token, selectedCategorys, selectedCompanys, TrailName, user]);

  return (
    <>
      <div className="row">
        <div className="col-md-4" style={{ height: '100%' }}>
          {/* begin::Mixed Widget 16 */}
          <div className="card card-custom card-stretch gutter-b">
            {/* begin::Header */}
            <div className="row" />
            <div className="card-body">
              {/* begin::Form */}
              <div className="form-group mb-7">
                <label className="font-size-h3 font-weight-bolder text-dark mb-7">
                  Trilhas para você
                </label>
                <input
                  type="text"
                  className="form-control h-40px"
                  placeholder="Procurar Trilhas.."
                  onChange={(event) => setTrailName(event.target.value)}
                />
              </div>

              {/* <form>
                <div className="form-group mb-7">
                  <label className="font-size-h3 font-weight-bolder text-dark mb-7">
                    Clusters
                  </label>

                  <div className="radio-list">
                    {loadingCluster && (
                      <>
                        <Skeleton variant="rect" width="100%" />
                        <br />
                        <Skeleton variant="rect" width="100%" />
                        <br />
                        <Skeleton variant="rect" width="100%" />
                      </>
                    )}
                    {!loadingCluster && (
                      <>
                        {clusters.map((cluster, index) => (
                          <div>
                            <input
                              type="radio"
                              name="cluster"
                              disabled={loadingEmpresa}
                              id={`cluster${index.toString()}`}
                              onChange={() => handleClusterChange(cluster.id)}
                            />
                            <label htmlFor={`cluster${index.toString()}`}>
                              {cluster.descricao}
                            </label>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </form> */}

              <form>
                <div className="form-group mb-7">
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'baseline',
                    }}
                  >
                    <label className="font-size-h3 font-weight-bolder text-dark mb-7">
                      Categorias
                    </label>
                  </div>

                  <div className="radio-list">
                    {loadingCategoria && (
                      <>
                        <Skeleton variant="rect" width="100%" />
                        <br />
                        <Skeleton variant="rect" width="100%" />
                        <br />
                        <Skeleton variant="rect" width="100%" />
                      </>
                    )}
                    {!loadingCategoria && (
                      <>
                        {categories.map((categoria, index) => (
                          <label
                            key={categoria.id}
                            className="customSearchCheckBox"
                            htmlFor={`categoria${index.toString()}`}
                          >
                            <input
                              type="checkbox"
                              name="categoria"
                              value={categoria.id}
                              id={`categoria${index.toString()}`}
                              onChange={(event) => {
                                if (!event.target.checked) {
                                  setSelectedCategorys((oldValues) =>
                                    oldValues.filter(
                                      (value) => value !== event.target.value,
                                    ),
                                  );
                                } else {
                                  setSelectedCategorys((oldValues) => [
                                    ...oldValues,
                                    event.target.value,
                                  ]);
                                }
                              }}
                            />
                            <span />
                            <div className="font-size-lg text-dark-75 font-weight-bold">
                              {categoria.descricao}
                            </div>
                          </label>
                        ))}
                      </>
                    )}
                  </div>
                </div>

                <div className="form-group mb-7">
                  <label className="font-size-h3 font-weight-bolder text-dark mb-7">
                    Empresas
                  </label>

                  <div className="radio-list">
                    {loadingEmpresa && (
                      <>
                        <Skeleton variant="rect" width="100%" />
                        <br />
                        <Skeleton variant="rect" width="100%" />
                        <br />
                        <Skeleton variant="rect" width="100%" />
                      </>
                    )}
                    {!loadingEmpresa && (
                      <>
                        {empresas.map((empresa, index) => (
                          <label
                            key={empresa.id}
                            className="customSearchCheckBox"
                            htmlFor={`empresa${index.toString()}`}
                          >
                            <input
                              type="checkbox"
                              name="empresa"
                              value={empresa.id}
                              id={`empresa${index.toString()}`}
                              onChange={(event) => {
                                if (!event.target.checked) {
                                  setSelectedCompanys((oldValues) =>
                                    oldValues.filter(
                                      (value) => value !== event.target.value,
                                    ),
                                  );
                                } else {
                                  setSelectedCompanys((oldValues) => [
                                    ...oldValues,
                                    event.target.value,
                                  ]);
                                }
                              }}
                            />
                            <span />
                            <div className="font-size-lg text-dark-75 font-weight-bold">
                              {empresa.razaoSocial}
                            </div>
                          </label>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </form>
              <Button
                text="Confirmar"
                type="submit"
                disabled={loadingTrilha}
                className="btn btn-primary font-weight-bolder mr-2 px-8"
                onClick={() => handleFilter()}
              />
            </div>
          </div>
        </div>
        <CardTrail trilhas={trilhas} loading={loadingTrilha} />
      </div>
    </>
  );
}
