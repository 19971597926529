import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { IconType } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';

import './style.scss';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '../../../components/Tooltip';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  value?: string;
}

const SwitchInput = ({ name, value, ...props }: InputProps): JSX.Element => {
  const InputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const defaultChecked = defaultValue === value;

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: InputRef,
      getValue: (ref) => ref.current.checked,
      clearValue: (ref) => {
        ref.current.checked = defaultChecked;
      },
      setValue: (ref, Value) => {
        ref.current.checked = Value;
      },
    });
  }, [defaultValue, fieldName, registerField, defaultChecked]);

  return (
    <label className="SwitchInputOrg">
      <input type="checkbox" ref={InputRef} {...props} />
      <span className="sliderRoundOrg" />
    </label>
  );
};

export default SwitchInput;
