import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { IconType } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';

import './style.scss';
import Tooltip from '../../../components/Tooltip';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: IconType;
  loading?: boolean;
}

const Input = ({ name, icon, loading, ...props }: InputProps): JSX.Element => {
  const ContainerRef = useRef<HTMLDivElement>(null);
  const [iconActive, setIconActive] = useState('');
  const InputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const Icon = icon;

  const HandleFocus = useCallback(() => {
    ContainerRef.current?.classList.add('InputContainerAdminActive');
    setIconActive('iconAdminActive');
  }, []);

  if (error) {
    ContainerRef.current?.classList.add('InputContainerAdminError');
  } else {
    ContainerRef.current?.classList.remove('InputContainerAdminError');
  }

  const handleBlur = useCallback(() => {
    ContainerRef.current?.classList.remove('InputContainerAdminActive');

    if (InputRef.current?.value) {
      setIconActive('iconAdminActive');
    } else {
      setIconActive('');
    }
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: InputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <div ref={ContainerRef} className="InputContainer">
      {Icon && <Icon className={iconActive} size={20} />}
      <input
        ref={InputRef}
        {...props}
        onFocus={HandleFocus}
        onBlur={handleBlur}
        disabled={loading}
      />
      {error && (
        <Tooltip title={error}>
          <FiAlertCircle color="#802A00" size={20} />
        </Tooltip>
      )}
    </div>
  );
};

Input.defaultProps = {
  icon: undefined,
};

export default Input;
