import { Collapse, List, ListItem, ListItemText } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import React, { useState } from 'react';
import { AiOutlineLink, AiOutlinePlayCircle } from 'react-icons/ai';
import { BsBook } from 'react-icons/bs';
import { FiPlayCircle } from 'react-icons/fi';
import { MdDashboard } from 'react-icons/md';

interface CollapseComponentProps {
  titulo: string;
  itens: any[];
  type?: string;
}

export default function CollapseComponent({
  titulo,
  itens,
  type = 'modulo',
}: CollapseComponentProps) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <ListItem
        button
        onClick={() => setOpen(!open)}
        style={{ height: 50, marginBottom: 15 }}
      >
        <ListItemText>
          <div>
            <p
              className="font-weight-bold text-dark-75 text-hover-primary font-size-lg line-clamp"
              style={{ marginTop: '15px' }}
            >
              {titulo}
            </p>
          </div>
        </ListItemText>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {itens.map((item) => (
            <ListItem>
              <div>
                {type === 'video' ? (
                  <FiPlayCircle
                    style={{
                      height: '15',
                      width: '15',
                      marginTop: '-4px',
                    }}
                  />
                ) : (
                  <MdDashboard
                    style={{
                      height: '15',
                      width: '15',
                      marginTop: '-4px',
                    }}
                  />
                )}
              </div>

              <ListItemText>
                <p
                  className="text-dark-75 font-size-lg mb-1 line-clamp"
                  style={{ marginLeft: '5px' }}
                >
                  {item.titulo}
                </p>
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
}
