/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import { LinearProgress } from '@material-ui/core';
import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { FiArrowLeft, FiSearch } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Button } from '../../../components/Button';
import { InfoCard } from './components/cardInfo';
import './styles.scss';

interface MenuButtonOptions {
  option: string;
  setModalOpen?: Dispatch<SetStateAction<boolean>>;
  action?: () => void;
}
interface DetailsPageProps {
  loading: boolean;
  children: React.ReactNode;
  headerTitle?: string;
  headerBack: boolean;
  buttonHeaderText?: string;
  hasCard: boolean;
  hasHeaderButton?: boolean;
  hasSearch?: boolean;
  cardNome?: string;
  hasMenuButton?: boolean;
  menuButtonOptions?: MenuButtonOptions[];
  cardImage?: string;
  cardInscritos?: number;
  hasHeaderOnly?: boolean;
  cardHasButtons?: boolean;
  cardFormados?: number;
  cardEditButtonText?: string;
  cardDeleteButtonText?: string;
  setCreateModalOpen?: Dispatch<SetStateAction<boolean>>;
  setEditModalOpen?: Dispatch<SetStateAction<boolean>>;
  setDeleteModalOpen?: Dispatch<SetStateAction<boolean>>;
  InputOnChange?: React.ChangeEventHandler<HTMLInputElement>;
}

const DetailsPageComponent = ({
  buttonHeaderText,
  cardDeleteButtonText,
  cardEditButtonText,
  cardFormados,
  cardHasButtons,
  children,
  cardInscritos,
  cardImage,
  cardNome,
  hasSearch = true,
  hasCard,
  hasHeaderButton = true,
  InputOnChange,
  hasMenuButton,
  menuButtonOptions,
  headerBack,
  loading,
  hasHeaderOnly,
  setCreateModalOpen,
  setEditModalOpen,
  headerTitle,
  setDeleteModalOpen,
}: DetailsPageProps) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (hasHeaderOnly) {
    return (
      <div className="row" style={{ padding: 20 }}>
        <div className="admin-trail-header">
          {headerBack ? (
            <div
              className="admin-trailheader-divider"
              onClick={() => history.goBack()}
            >
              <FiArrowLeft size={20} />
              <span>Voltar</span>
            </div>
          ) : (
            <h2>{headerTitle}</h2>
          )}

          <div className="admin-trail-header-end">
            {!loading &&
              hasHeaderButton &&
              (hasMenuButton ? (
                <>
                  <Button
                    color="#6F95FF"
                    text={buttonHeaderText || ''}
                    onClick={handleClick}
                  />
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    {menuButtonOptions?.map((option) => (
                      <MenuItem
                        key={option.option}
                        onClick={() => {
                          setAnchorEl(null);
                          if (option.setModalOpen) {
                            option.setModalOpen(true);
                          } else if (option.action) {
                            option.action();
                          }
                        }}
                      >
                        {option.option}
                      </MenuItem>
                    ))}
                  </Menu>
                </>
              ) : (
                <Button
                  color="#6F95FF"
                  text={buttonHeaderText || ''}
                  onClick={() => {
                    if (setCreateModalOpen) {
                      setCreateModalOpen(true);
                    }
                  }}
                />
              ))}
            {hasSearch && (
              <div className="admin-trail-header-search">
                <input type="text" onChange={InputOnChange} />
                <FiSearch size={20} color="black" />
              </div>
            )}
          </div>
        </div>

        {loading ? (
          <div style={{ height: 60, width: '100%' }}>
            <LinearProgress color="primary" />
          </div>
        ) : (
          <>{children}</>
        )}
      </div>
    );
  }

  return (
    <>
      <div className="row" style={{ padding: 20 }}>
        <div className="admin-trail-header">
          {headerBack ? (
            <div
              className="admin-trailheader-divider"
              onClick={() => history.goBack()}
            >
              <FiArrowLeft size={20} />
              <span>Voltar</span>
            </div>
          ) : (
            <h2>{headerTitle}</h2>
          )}

          <div className="admin-trail-header-end">
            {!loading &&
              hasHeaderButton &&
              (hasMenuButton ? (
                <>
                  <Button
                    color="#6F95FF"
                    text={buttonHeaderText || ''}
                    onClick={handleClick}
                  />
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    {menuButtonOptions?.map((option) => (
                      <MenuItem
                        key={option.option}
                        onClick={() => {
                          setAnchorEl(null);
                          if (option.setModalOpen) {
                            option.setModalOpen(true);
                          } else if (option.action) {
                            option.action();
                          }
                        }}
                      >
                        {option.option}
                      </MenuItem>
                    ))}
                  </Menu>
                </>
              ) : (
                <Button
                  color="#6F95FF"
                  text={buttonHeaderText || ''}
                  onClick={() => {
                    if (setCreateModalOpen) {
                      setCreateModalOpen(true);
                    }
                  }}
                />
              ))}
            {hasSearch && (
              <div className="admin-trail-header-search">
                <input type="text" onChange={InputOnChange} />
                <FiSearch size={20} color="black" />
              </div>
            )}
          </div>
        </div>
      </div>

      {loading ? (
        <div style={{ height: 60, width: '100%' }}>
          <LinearProgress color="primary" />
        </div>
      ) : (
        <>
          <div className="row" style={{ padding: 20 }}>
            {hasCard && (
              <InfoCard
                cardImage={cardImage}
                cardHasButtons={cardHasButtons}
                setEditModalOpen={setEditModalOpen}
                setDeleteModalOpen={setDeleteModalOpen}
                cardNome={cardNome}
                cardInscritos={cardInscritos || 0}
                cardEditButtonText={cardEditButtonText}
                cardDeleteButtonText={cardDeleteButtonText}
                cardFormados={cardFormados || 0}
              />
            )}
            <div className="admin-trails-container">{children}</div>
          </div>
        </>
      )}
    </>
  );
};

export default DetailsPageComponent;
