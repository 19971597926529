import React, { useCallback, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FiUser } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { useAuth } from '../../../../../hooks/Auth';
import { API } from '../../../../../services/api';
import GetValidationErrors from '../../../../../utils/getValidationErros';
import Input from '../../../../components/Input';
import TextAreaInput from '../../../../components/TextAreaInput';
import { Button } from '../../../../../components/Button';
import ModalComponent from '../../../../../components/Modal/ModalComponent';
import { ModalProps } from '../../../../../interfaces/components';

interface CreateCursoModalProps extends ModalProps {
  cursoId: number;
}

export default function CreateCursoModal({
  onClose,
  cursoId,
  closeButton,
  itens,
  open,
  setItens,
  setModalOpen,
}: CreateCursoModalProps) {
  const { token } = useAuth();
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);

  const Submit = useCallback(
    async (data) => {
      setLoading(true);

      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          titulo: Yup.string().required('Campo Obrigatório'),
          conteudo: Yup.string().required('Campo Obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        data.ordem = 0;

        itens.forEach((item) => {
          if (item.ordem > data.ordem) {
            data.ordem = item.ordem;
          }
        });

        data.ordem += 1;
        data.cursoId = cursoId;

        const response = await API(token).post(
          '/api/unidadeAprendizagem',
          data,
        );
        setLoading(false);

        data.id = response.data.id;

        setItens([...itens, data]);
        setModalOpen(false);
        toast.success('Módulo criado com sucesso!');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = GetValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          toast.error('Houve um erro ao criar o módulo, tente novamente!');
        }

        setLoading(false);
      }
    },

    [token, cursoId, setItens, itens, setModalOpen],
  );

  return (
    <ModalComponent open={open} onClose={onClose} closeButton={closeButton}>
      <Form ref={formRef} placeholder="" className="createmodal-form-admin" onSubmit={Submit}>
        <h2 id="simple-modal-title">Criar novo módulo</h2>

        <Input
          name="titulo"
          maxLength={60}
          icon={FiUser}
          type="text"
          placeholder="Nome"
        />
        <TextAreaInput
          name="conteudo"
          maxLength={300}
          placeholder="Descrição"
        />

        <Button
          color="#6F95FF"
          text="Confirmar"
          type="submit"
          loading={loading}
        />
      </Form>
    </ModalComponent>
  );
}
