/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {useState} from 'react';
import Logo from '../../../assets/images/apl.png';
import Modal from './modal';
import './styles.scss';

import AdicionarCursoDentroDaTrilha from './assets/adicionarCursoDentroDaTrilha.gif';
import AdicionarCursoExistenteNaTrilha from './assets/adicionarCursoExistenteNaTrilha.gif';
import CadastrarTrilha from './assets/cadastrarTrilha.gif';
import DeletarCursoDaTrilha from './assets/deletarCursoDaTrilha.gif';
import EditarTrilha from './assets/editarTrilha.gif';
import DeletarTriilha from './assets/deletarTrilha.gif';

import CadastrarCurso from './assets/cadastrarCurso.gif';
import CriarModuloParaCurso from './assets/criarModuloParaCurso.gif';
import DeletarModuloCurso from './assets/deletarModuloCurso.gif';
import EditarCurso from './assets/editarCurso.gif';
import DeletarCurso from './assets/deletarCurso.gif';

import AdicionarQuestaoNaAvaliacao from './assets/adicionarQuestaoNaAvaliacao.gif';
import AdicionarAvaliaçãoModulo from './assets/adicionarAvaliaçãoModulo.gif';
import EditarModulo from './assets/editarModulo.gif';
import AlterarOrdemModulo from './assets/alterarOrdemModulo.gif';
import RemoverQuestao from './assets/removerQuestao.gif';

import AdicionarDesafio from './assets/adicionarDesafio.gif';
import AdicionarMaterialDeApoio from './assets/adicionarMaterialDeApoio.gif';
import AlterarOrdemDaAula from './assets/alterarOrdemDaAula.gif';
import CadastrarAula from './assets/cadastrarAula.gif';
import DeletarAula from './assets/deletarAula.gif';
import EditarAula from './assets/editarAula.gif';
import EditarDesafio from './assets/editarDesafio.gif';
import ExcluirDesafio from './assets/excluirDesafio.gif';
import RemoverMaterialDeApoio from './assets/removerMaterialDeApoio.gif';

import EditarAvaliacao from './assets/editarAvaliacao.gif';
import ExcluirAvaliacao from './assets/excluirAvaliacao.gif';
import VisualizarAvaliacao from './assets/visualizarAvaliacao.gif';


import CadastrarAdministrador from './assets/cadastrarAdministrador.gif';
import DeletarColaborador from './assets/deletarColaborador.gif';
import EditarColaborador from './assets/editarColaborador.gif';

import EditarPerfilEmpresa from './assets/editarPerfilEmpresa.gif';

import VisualizarProfissionalnscritoNaTrilha from './assets/visualizarProfissionalnscritoNaTrilha.gif';

export default function Tutorials() {
  const [modalOpen, setModalOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [gif, setGif] = useState('');
  const [hasDescription, setHasDescription] = useState(false);
  const [description, setDescription] = useState('');

  const setValue = ((id, name, video, verifica, descricao) => {
    setModalOpen(true);
    setTitle(name)
    setGif(video)
    setHasDescription(verifica)
    setDescription(descricao)
  })

  const setModal = ((id, name, video, verifica, descricao) => {
    switch (id) {
      case 1:
        setValue(id, name, video, verifica, descricao);
        break;
      case 2:
        setValue(id, name, video, verifica, descricao);
        break;
      case 3:
        setValue(id, name, video, verifica, descricao);
        break;
      case 4:
        setValue(id, name, video, verifica, descricao);
        break;
      case 5:
        setValue(id, name, video, verifica, descricao);
        break;
      case 6:
        setValue(id, name, video, verifica, descricao);
        break;
      case 7:
        setValue(id, name, video, verifica, descricao);
        break;
      case 8:
        setValue(id, name, video, verifica, descricao);
        break;
      case 9:
        setValue(id, name, video, verifica, descricao);
        break;
      case 10:
        setValue(id, name, video, verifica, descricao);
        break;
      case 11:
        setValue(id, name, video, verifica, descricao);
        break;
      case 12:
        setValue(id, name, video, verifica, descricao);
        break;
      case 13:
        setValue(id, name, video, verifica, descricao);
        break;
      case 14:
        setValue(id, name, video, verifica, descricao);
        break;
      case 15:
        setValue(id, name, video, verifica, descricao);
        break;
      case 16:
        setValue(id, name, video, verifica, descricao);
        break;
      case 17:
        setValue(id, name, video, verifica, descricao);
        break;
      case 18:
        setValue(id, name, video, verifica, descricao);
        break;
      case 19:
        setValue(id, name, video, verifica, descricao);
        break;
      case 20:
        setValue(id, name, video, verifica, descricao);
        break;
      case 21:
        setValue(id, name, video, verifica, descricao);
        break;
      case 22:
        setValue(id, name, video, verifica, descricao);
        break;
      case 23:
        setValue(id, name, video, verifica, descricao);
        break;
      case 24:
        setValue(id, name, video, verifica, descricao);
        break;
      case 25:
        setValue(id, name, video, verifica, descricao);
        break;
      case 26:
        setValue(id, name, video, verifica, descricao);
        break;
      case 27:
        setValue(id, name, video, verifica, descricao);
        break;
      case 28:
        setValue(id, name, video, verifica, descricao);
        break;
      case 29:
        setValue(id, name, video, verifica, descricao);
        break;
      case 30:
        setValue(id, name, video, verifica, descricao);
        break;
      case 31:
        setValue(id, name, video, verifica, descricao);
        break;
      case 32:
        setValue(id, name, video, verifica, descricao);
        break;
      case 33:
        setValue(id, name, video, verifica, descricao);
        break;
      default:
        console.log(`Não foi encontrado o ID: ${id}`);
    }
  })

  return (
    <>
      <Modal
        open={modalOpen}
        gif={gif}
        title={title}
        hasDescription={hasDescription}
        description={description}
        setModalOpen={setModalOpen}
        onClose={() => setModalOpen(false)}
        closeButton={() => setModalOpen(false)}
      />
      <h1>Painel Organização</h1>

      <div className="card-alert">
        <span>💡</span>
        <span style={{marginLeft: "8px", fontSize: "13px"}}>
        Bem-vindo à central de ajuda da APL SP play! Estamos aqui para responder às suas perguntas.
        Não consegue encontrar o que procura? Envie uma nota à nossa equipe de suporte
        em secretaria@apltimarilia.org.br
        </span>
      </div>

      <div style={{display: "grid", marginTop: '10px'}}>
        <a href="#trilha" className="link-tutorials-organization">Trilhas</a>
        <a href="#cursos" className="link-tutorials-organization">Cursos</a>
        <a href="#aulas" className="link-tutorials-organization">Aulas</a>
        <a href="#modulos" className="link-tutorials-organization">Módulos</a>
        <a href="#administradores" className="link-tutorials-organization">Administradores</a>
        <a href="#perfil" className="link-tutorials-organization">Perfil Empresa</a>
      </div>

      <div id="trilha">
        <h2 style={{marginTop: "32px"}}>Trilhas</h2>
        <div className="container-card">
          <div className="card-tutorials" style={{marginLeft: 0}}>
            <a
              onClick={async () => {
                await setModal(1,'📘 Cadastrar Trilha', `${CadastrarTrilha}`, true, "Para o cadastro da Trilha é necessário o preenchimento de todos os campos");
              }}
              >

              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📘 Cadastrar Trilha
              </div>
            </a>
          </div>
          <div className="card-tutorials">
            <a
            onClick={async () => {
              await setModal(2,'📘 Editar e Inativar Trilha', `${EditarTrilha}`, false, '');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📘 Editar e Inativar Trilha
              </div>
            </a>
          </div>
          <div className="card-tutorials">
            <a
              onClick={async () => {
                await setModal(3,'📘 Adicionar curso já existente', `${AdicionarCursoExistenteNaTrilha}`, false, '');
              }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📘 Adicionar curso já existente
              </div>
            </a>
          </div>
          <div className="card-tutorials" style={{marginRight: 0}}>
            <a
            onClick={async () => {
              await setModal(4,'📘 Profissionais inscrito na Trilha', `${VisualizarProfissionalnscritoNaTrilha}`, false, '');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📘 Profissionais inscrito na Trilha
              </div>
            </a>
          </div>
        </div>
        <div className="container-card" >
          <div className="card-tutorials" style={{marginLeft: 0}}>
            <a
            onClick={async () => {
              await setModal(5,'📘 Remover curso da Trilha', `${DeletarCursoDaTrilha}`, false, '');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📘 Remover curso da Trilha
              </div>
            </a>
          </div>
          <div className="card-tutorials">
            <a
            onClick={async () => {
              await setModal(6,'📘 Deletar Trilha', `${DeletarTriilha}`, false, '');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📘 Deletar Trilha
              </div>
            </a>
          </div>
          <div className="card-tutorials">
            <a
             onClick={async () => {
              await setModal(7,'📘 Adicionar curso dentro da Trilha', `${AdicionarCursoDentroDaTrilha}`, false, '');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📘 Adicionar curso dentro da Trilha
              </div>
            </a>
          </div>
        </div>
      </div>

      <div id="cursos">
        <h2 style={{marginTop: "32px"}}>Cursos</h2>
        <div className="container-card" >
          <div className="card-tutorials" style={{marginLeft: 0}}>
            <a
            onClick={async () => {
              await setModal(8,'📘 Cadastrar Curso', `${CadastrarCurso}`, true, 'Para o cadastro do Curso é necessário o preenchimento de todos os campos');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📘 Cadastrar Curso
              </div>
            </a>
          </div>
          <div className="card-tutorials">
            <a
             onClick={async () => {
              await setModal(9,'📘 Editar Curso', `${EditarCurso}`, false, '');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📘 Editar Curso
              </div>
            </a>
          </div>
          <div className="card-tutorials">
            <a
            onClick={async () => {
              await setModal(10,'📘 Deletar Curso', `${DeletarCurso}`, false, '');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📘 Deletar Curso
              </div>
            </a>
          </div>
          <div className="card-tutorials" style={{marginRight: 0}}>
            <a
            onClick={async () => {
              await setModal(11,'📘 Cadastrar módulo para Curso', `${CriarModuloParaCurso}`, true, 'Para o cadastro do módulo é necessário o preenchimento de todos os campos');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📘 Cadastrar módulo para Curso
              </div>
            </a>
          </div>
        </div>
        <div className="container-card" >
          <div className="card-tutorials" style={{marginLeft: 0}}>
            <a
             onClick={async () => {
              await setModal(12,'📘 Alterar ordem módulo', `${AlterarOrdemModulo}`, false, '');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📘 Alterar ordem módulo
              </div>
            </a>
          </div>
          <div className="card-tutorials">
            <a
            onClick={async () => {
              await setModal(13,'📘 Deletar Módulo do Curso', `${DeletarModuloCurso}`, false, '');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📘 Deletar Módulo do Curso
              </div>
            </a>
          </div>
        </div>
      </div>

      <div id="modulos">
        <h2 style={{marginTop: "32px"}}>Módulos</h2>
        <div className="container-card" >
          <div className="card-tutorials" style={{marginLeft: 0}}>
            <a
            onClick={async () => {
              await setModal(14,'📘 Editar Módulo', `${EditarModulo}`, false, '');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📘 Editar Módulo
              </div>
            </a>
          </div>
          <div className="card-tutorials">
            <a
            onClick={async () => {
              await setModal(15,'📘 Adicionar Avaliação no Módulo', `${AdicionarAvaliaçãoModulo}`, false, '');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📘 Adicionar Avaliação no Módulo
              </div>
            </a>
          </div>
          <div className="card-tutorials">
            <a
             onClick={async () => {
              await setModal(16,'📘 Adicionar questão na Avaliação', `${AdicionarQuestaoNaAvaliacao}`, true, 'É possível cadastrar apenas 5 alternativa por questão');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📘 Adicionar questão na Avaliação
              </div>
            </a>
          </div>
          <div className="card-tutorials" style={{marginRight: 0}}>
            <a
            onClick={async () => {
              await setModal(17,'📘 Remover questão da Avaliação', `${RemoverQuestao}`, false, '');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📘 Remover questão da Avaliação
              </div>
            </a>
          </div>
        </div>
      </div>

      <div id="aulas">
        <h2 style={{marginTop: "32px"}}>Aulas</h2>
        <div className="container-card" >
          <div className="card-tutorials" style={{marginLeft: 0}}>
            <a
            onClick={async () => {
              await setModal(18,'📘 Cadastrar Aula', `${CadastrarAula}`, true, 'Para o cadastro da aula é necessário o preenchimento de todos os campos, a aula pode ser criada sem vídeo apenas com uma descrição');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📘 Cadastrar Aula
              </div>
            </a>
          </div>
          <div className="card-tutorials">
            <a
            onClick={async () => {
              await setModal(19,'📘 Editar Aula', `${EditarAula}`, false, '');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📘 Editar Aula
              </div>
            </a>
          </div>
          <div className="card-tutorials">
            <a
             onClick={async () => {
              await setModal(20,'📘 Deletar Aula', `${DeletarAula}`, false, '');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📘 Deletar Aula
              </div>
            </a>
          </div>
          <div className="card-tutorials" style={{marginRight: 0}}>
            <a
            onClick={async () => {
              await setModal(21,'📘 Alterar ordem da Aula', `${AlterarOrdemDaAula}`, false, '');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📘 Alterar ordem da Aula
              </div>
            </a>
          </div>
        </div>
        <div className="container-card" >
          <div className="card-tutorials" style={{marginLeft: 0}}>
            <a
             onClick={async () => {
              await setModal(22,'📘 Adicionar Material de Apoio', `${AdicionarMaterialDeApoio}`, false, '');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📘 Adicionar Material de Apoio
              </div>
            </a>
          </div>
          <div className="card-tutorials">
            <a
            onClick={async () => {
              await setModal(23,'📘 Remover Material de Apoio', `${RemoverMaterialDeApoio}`, false, '');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📘 Remover Material de Apoio
              </div>
            </a>
          </div>
          <div className="card-tutorials">
            <a
            onClick={async () => {
              await setModal(24,'📘 Adicionar Desafio', `${AdicionarDesafio}`, false, '');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📘 Adicionar Desafio
              </div>
            </a>
          </div>
          <div className="card-tutorials">
            <a
            onClick={async () => {
              await setModal(25,'📘 Editar Desafio', `${EditarDesafio}`, false, '');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📘 Editar Desafio
              </div>
            </a>
          </div>
        </div>
        <div className="container-card" >
          <div className="card-tutorials" style={{marginLeft: 0}}>
            <a
            onClick={async () => {
              await setModal(26,'📘 Remover Desafio', `${ExcluirDesafio}`, false, '');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📘 Remover Desafio
              </div>
            </a>
          </div>
        </div>
      </div>

      <div id="avaliacao">
        <h2 style={{marginTop: "32px"}}>Avaliação</h2>
        <div className="container-card" >
          <div className="card-tutorials" style={{marginLeft: 0}}>
            <a
            onClick={async () => {
              await setModal(27,'📘 Editar Módulo', `${VisualizarAvaliacao}`, false, '');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📘 Visualizar Avaliação
              </div>
            </a>
          </div>
          <div className="card-tutorials">
            <a
            onClick={async () => {
              await setModal(28,'📘 Adicionar Avaliação no Módulo', `${EditarAvaliacao}`, false, '');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📘 Editar Avaliação
              </div>
            </a>
          </div>
          <div className="card-tutorials">
            <a
             onClick={async () => {
              await setModal(29,'📘 Adicionar questão na Avaliação', `${ExcluirAvaliacao}`, false, '');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📘 Remover Avaliação
              </div>
            </a>
          </div>
        </div>
      </div>

      <div id="administradores">
        <h2 style={{marginTop: "32px"}}>Administradores</h2>
        <div className="container-card" >
          <div className="card-tutorials" style={{marginLeft: 0}}>
            <a
            onClick={async () => {
              await setModal(30,'📘 Cadastrar Administrador', `${CadastrarAdministrador}`, true, 'Para o cadastro de um colaborador é necessário o preenchimento de todos os campos');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📘 Cadastrar Administrador
              </div>
            </a>
          </div>
          <div className="card-tutorials">
            <a
            onClick={async () => {
              await setModal(31,'📘 Editar Administrador', `${EditarColaborador}`, false, '');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📘 Editar Administrador
              </div>
            </a>
          </div>
          <div className="card-tutorials">
            <a
            onClick={async () => {
              await setModal(32,'📘 Deletar Administrador', `${DeletarColaborador}`, false, '');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📘 Deletar Administrador
              </div>
            </a>
          </div>
        </div>
      </div>

      <div id="perfil">
        <h2 style={{marginTop: "32px"}}>Perfil Empresa</h2>
        <div className="container-card" >
          <div className="card-tutorials" style={{marginLeft: 0}}>
            <a
            onClick={async () => {
              await setModal(33,'📘 Editar Perfil da Empresa', `${EditarPerfilEmpresa}`, false, '');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📘 Editar Perfil da Empresa
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
