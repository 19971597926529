/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { Link, useLocation } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';
import ReactPlayer from 'react-player/lazy';
import { FiEdit } from 'react-icons/fi';
import { IoMdTrash } from 'react-icons/io';
import {
  AiOutlineFileImage,
  AiOutlineFilePdf,
  AiOutlineFileText,
  AiOutlineFileWord,
  AiOutlineFileZip,
} from 'react-icons/ai';
import { updateClassExpression } from 'typescript';
import { useAuth } from '../../../hooks/Auth';
import { API } from '../../../services/api';

import {
  DesafioProps,
  MateriaisComplementaresProps,
  ObjetosAprendizagemProps,
  UnidadeAprendizagemProps,
} from '../../../interfaces/Entidades';
import CreateDesafios from './components/CriarDesafios';
import { Button } from '../../../Profissional/components/Button';
import DeleteModal from '../../../components/Modal/DeleteModal';
import EditDesafioModal from './components/EditDesafioModal';
import { BackHeaderComponent } from '../../../components/BackHeader';
import AdicionarMaterialModal from './components/AdicionarMateriaisModal';

interface StateProps {
  id: number;
}

interface IMateriais {
  materiaisApoio: string;
}

const LessonsDetailPage = () => {
  const location = useLocation();
  const { token } = useAuth();
  const [editAula, setEditAula] = useState<ObjetosAprendizagemProps>(
    {} as ObjetosAprendizagemProps,
  );
  const [editItem, setEditItem] = useState<DesafioProps>({} as DesafioProps);
  const [deleteItem, setDeleteItem] = useState<any>(0);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [createDesafiosOpen, setCreateDesafiosOpen] = useState(false);
  const [createMateriaisOpen, setCreateMateriaisOpen] = useState(false);
  const [aula, setAula] = useState<ObjetosAprendizagemProps>(
    {} as ObjetosAprendizagemProps,
  );
  const [modulo, setModulo] = useState<UnidadeAprendizagemProps>();
  const [desafios, setDesafios] = useState<DesafioProps[]>([]);
  const [materiais, setMateriais] = useState<MateriaisComplementaresProps[]>(
    [],
  );
  const [pontuacaoDesafio, setPontuacaoDesafio] = useState<number>(0);

  // const [fileExtension, setFileExtension] = useState('');
  const id = location.state as StateProps;

  async function updateClass(materiaisArray: MateriaisComplementaresProps[]) {
    try {
      const dataToSet: ObjetosAprendizagemProps = {
        id: aula.id,
        titulo: aula.titulo,
        conteudo: aula.conteudo,
        ordem: aula.ordem,
        unidadeAprendizagemId: aula.unidadeAprendizagemId,
        unidadeAprendizagem: aula.unidadeAprendizagem,
        materiaisComplementares: materiaisArray,
        urlVideo: aula.urlVideo,
        inscrito: aula.inscrito,
      };

      await API(token).put<ObjetosAprendizagemProps>(
        '/api/objetoAprendizagem',
        dataToSet,
      );
    } catch (err) {
      toast.error('Houve um erro ao excluir o material!');
    }
  }

  function handleRemoveItem(title: string) {
    if (title !== '') {
      materiais.splice(
        materiais.findIndex((item) => item.titulo === title),
        1,
      );
    }

    const materiaisArray: MateriaisComplementaresProps[] = [];
    materiais?.forEach((material) => {
      materiaisArray.push(material);
    });
    setMateriais(materiaisArray);
    updateClass(materiaisArray);
  }

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      setAula({} as ObjetosAprendizagemProps);
      setDesafios([]);

      try {
        const response_aula = await API(token).get<ObjetosAprendizagemProps>(
          `/api/objetoAprendizagem/${id.id}`,
        );
        setAula(response_aula.data);
        setMateriais(response_aula.data.materiaisComplementares);

        const response_modulo = await API(token).get<UnidadeAprendizagemProps>(
          `/api/unidadeAprendizagem/${response_aula.data.unidadeAprendizagemId}`,
        );
        setDesafios(response_modulo.data.desafios);
        setModulo(response_modulo.data);

        const response_configuracao = await API(token).get('/api/configuracao');
        setPontuacaoDesafio(response_configuracao.data.pontuacaoDesafio);

        setLoading(false);
      } catch (err) {
        toast.error(
          'Houve um erro ao carregar os dados da aula, tente novamente!',
        );
        setLoading(false);
      }
    }
    loadData();
  }, [token, id]);

  return (
    <>
      <BackHeaderComponent />

      <DeleteModal
        itens={desafios}
        setItens={setDesafios}
        color="#6F95FF"
        name="o desafio"
        routeName="desafio"
        id={deleteItem?.id}
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        closeButton={() => setDeleteModalOpen(false)}
      />

      <AdicionarMaterialModal
        item={aula}
        aulaId={aula.id}
        itens={materiais}
        setItens={setMateriais}
        setModalOpen={setCreateMateriaisOpen}
        open={createMateriaisOpen}
        onClose={() => setCreateMateriaisOpen(false)}
        closeButton={() => setCreateMateriaisOpen(false)}
      />

      <CreateDesafios
        pontuacaoDesafio={pontuacaoDesafio}
        setItens={setDesafios}
        itens={desafios}
        moduloId={aula.unidadeAprendizagemId}
        setModalOpen={setCreateDesafiosOpen}
        open={createDesafiosOpen}
        onClose={() => setCreateDesafiosOpen(false)}
        closeButton={() => setCreateDesafiosOpen(false)}
      />

      <EditDesafioModal
        pontuacaoDesafio={pontuacaoDesafio}
        item={editItem}
        id={editItem.id}
        setModalOpen={setEditModalOpen}
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        closeButton={() => setEditModalOpen(false)}
      />

      <div className="Container">
        <div className="card card-custom gutter-b">
          <div className="card-body d-flex flex-column">
            <strong style={{ color: '#5C65C0' }}>
              {loading ? (
                <Skeleton
                  variant="rect"
                  width="40%"
                  style={{ borderRadius: 3 }}
                />
              ) : (
                aula?.titulo
              )}
            </strong>
            <p>
              {loading ? (
                <Skeleton
                  variant="rect"
                  width="40%"
                  style={{ borderRadius: 3, marginTop: 15 }}
                />
              ) : (
                `${modulo?.titulo} | ${modulo?.curso.nome}`
              )}
            </p>
          </div>
        </div>
        <div className="card card-custom  gutter-b">
          {loading ? (
            <Skeleton
              variant="rect"
              width="100%"
              height="40vh"
              style={{ borderRadius: 3 }}
            />
          ) : (
            <>
              {aula?.urlVideo === null ? (
                ''
              ) : (
                <ReactPlayer
                  url={aula?.urlVideo}
                  width="100%"
                  height="60vh"
                  controls
                  config={{
                    file: { attributes: { controlsList: 'nodownload' } },
                  }}
                />
              )}
            </>
          )}
        </div>
        <div className="card card-custom  gutter-b">
          <div className="card-body d-flex flex-column">
            {loading ? (
              <Skeleton
                variant="rect"
                width="40%"
                height={20}
                style={{ borderRadius: 3, marginTop: 15 }}
              />
            ) : (
              <strong style={{ color: '#5C65C0' }}>Descrição da Aula</strong>
            )}

            <p>
              {loading ? (
                <Skeleton
                  variant="rect"
                  width="100%"
                  height={50}
                  style={{ borderRadius: 3, marginTop: 15 }}
                />
              ) : (
                aula?.conteudo
              )}
            </p>
          </div>
        </div>

        <div
          className="card card-custom card-stretch gutter-b"
          style={{ height: 300 }}
        >
          <div className="card-body d-flex flex-column">
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {loading ? (
                <Skeleton
                  variant="rect"
                  width="100%"
                  height={80}
                  style={{ borderRadius: 3, marginTop: 15 }}
                />
              ) : (
                <strong style={{ color: '#5C65C0' }}>Materiais de Apoio</strong>
              )}

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: 15,
                }}
              >
                {loading ? (
                  <Skeleton variant="rect" width={0} height={0} />
                ) : (
                  <Button
                    text="Adicionar"
                    style={{ marginTop: 0, background: '#6F95FF' }}
                    onClick={async () => {
                      if (aula !== undefined) {
                        await setEditAula(aula);
                        await setCreateMateriaisOpen(true);
                      }
                    }}
                  />
                )}
              </div>
            </div>
            {loading ? (
              <Skeleton
                variant="rect"
                width="100%"
                height={80}
                style={{ borderRadius: 3, marginTop: 15 }}
              />
            ) : (
              <div
                style={{
                  overflow: 'auto',
                  maxHeight: '500px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {materiais &&
                  materiais.map((material) => (
                    <div
                      key={material.titulo}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        background: '#F7F8FA',
                        marginTop: 15,
                        padding: 30,
                        justifyContent: 'space-between',
                        maxWidth: '99%',
                      }}
                    >
                      <a
                        href={material.urlConteudo}
                        download
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: '#202020' }}
                      >
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <h4>{material.titulo}</h4>
                        </div>
                      </a>
                      <IoMdTrash
                        onClick={() => handleRemoveItem(material.titulo)}
                        onMouseOver={({ currentTarget }) =>
                          (currentTarget.style.color = '#6F95FF')
                        }
                        onMouseOut={({ currentTarget }) =>
                          (currentTarget.style.color = '#80808F')
                        }
                        size={25}
                        color="#80808F"
                        style={{ marginLeft: 15, cursor: 'pointer' }}
                        className="cluster-table-svg"
                      />
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>

        <div
          className="card card-custom card-stretch gutter-b"
          style={{ height: 300 }}
        >
          <div className="card-body d-flex flex-column">
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {loading ? (
                <Skeleton
                  variant="rect"
                  width="40%"
                  height={20}
                  style={{ borderRadius: 3, marginTop: 15 }}
                />
              ) : (
                <strong style={{ color: '#5C65C0' }}>Desafios do Módulo</strong>
              )}

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: 15,
                }}
              >
                {loading ? (
                  <Skeleton variant="rect" width={0} height={0} />
                ) : (
                  <Button
                    text="Criar"
                    style={{ marginTop: 0, background: '#6F95FF' }}
                    onClick={async () => {
                      if (aula !== undefined) {
                        await setEditAula(aula);
                        await setCreateDesafiosOpen(true);
                      }
                    }}
                  />
                )}
              </div>
            </div>

            {loading ? (
              <Skeleton
                variant="rect"
                width="100%"
                height={80}
                style={{ borderRadius: 3, marginTop: 15 }}
              />
            ) : (
              <div style={{ overflowY: 'scroll' }}>
                {desafios &&
                  desafios.map((desafio) => (
                    <div
                      key={desafio.id}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        background: '#F7F8FA',
                        marginTop: 15,
                        padding: 15,
                        justifyContent: 'center',
                      }}
                    >
                      <Link
                        style={{ color: '#272729' }}
                        to={{
                          pathname: '/corrigirDesafio',
                          state: { id: desafio.id },
                        }}
                      >
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <h4>{desafio.titulo}</h4>
                        </div>

                        <p>{modulo?.titulo}</p>
                      </Link>

                      <div
                        style={{
                          width: 60,
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <FiEdit
                          onClick={async () => {
                            await setEditItem(desafio);
                            await setEditModalOpen(true);
                          }}
                          size={20}
                          color="#80808F"
                          style={{ cursor: 'pointer' }}
                          className="cluster-table-svg"
                          onMouseOver={({ currentTarget }) =>
                            (currentTarget.style.color = '#6F95FF')
                          }
                          onMouseOut={({ currentTarget }) =>
                            (currentTarget.style.color = '#80808F')
                          }
                        />
                        <IoMdTrash
                          onClick={async () => {
                            await setDeleteItem(desafio);
                            await setDeleteModalOpen(true);
                          }}
                          onMouseOver={({ currentTarget }) =>
                            (currentTarget.style.color = '#6F95FF')
                          }
                          onMouseOut={({ currentTarget }) =>
                            (currentTarget.style.color = '#80808F')
                          }
                          size={25}
                          color="#80808F"
                          style={{ marginLeft: 15, cursor: 'pointer' }}
                          className="cluster-table-svg"
                        />
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LessonsDetailPage;
