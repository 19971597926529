/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {useState} from 'react';
import Logo from '../../../assets/images/apl.png';
import Modal from './modal';
import './styles.scss';

import CadastrarCluster from './assets/cadastrarCluster.gif';
import DeletarCluster from './assets/deletarCluster.gif';
import EditarCluster from './assets/editarCluster.gif';

import CadastroTipoCluster from './assets/cadastroTipoCluster.gif';
import DeletarTipoCluster from './assets/deletarTipoCluster.gif';
import EditarTipoCluster from './assets/editarTipoCluster.gif';

import CadastroContaCluster from './assets/cadastroContaCluster.gif';
import DeletarContaCluster from './assets/deletarContaCluster.gif';
import EditarContaCluster from './assets/editarContaCluster.gif';

import CadastrarOrganizacao from './assets/cadastrarOrganizacao.gif';
import DeletarOrganizacao from './assets/deletarOrganizacao.gif';
import EditarOrganizacao from './assets/editarOrganizacao.gif';

import CadastrarTipoOrganizacao from './assets/cadastrarTipoOrganizacao.gif';
import DeletarTipoOrganizacao from './assets/deletarTipoOrganizacao.gif';
import EditarTipoOrganizacao from './assets/editarTipoOrganizacao.gif';

import CadastrarContaOrganizacao from './assets/cadastrarContaOrganizacao.gif';
import DeletarContaOrganizacao from './assets/deletarContaOrganizacao.gif';
import EditarContaOrganizacao from './assets/editarContaOrganizacao.gif';

import CadastrarCategoriaTrilhaCurso from './assets/cadastrarCategoriaTrilhaCurso.gif';
import DeletarCategoriaTrilhaCurso from './assets/deletarCategoriaTrilhaCurso.gif';
import EditarCategoriaTrilhaCurso from './assets/editarCategoriaTrilhaCurso.gif';

import ConfiguracaoSistema from './assets/configuracaoSistema.gif';

export default function Tutorials() {
  const [modalOpen, setModalOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [gif, setGif] = useState('');
  const [hasDescription, setHasDescription] = useState(false);
  const [description, setDescription] = useState('');
  const [hasDescriptionSecond, setHasDescriptionSecond] = useState(false);

  const setValue = ((id, name, video, verifica, descricao) => {
    setModalOpen(true);
    setTitle(name)
    setGif(video)
    setHasDescription(verifica)
    setDescription(descricao)
  })

  const setModal = ((id, name, video, verifica, descricao) => {
    switch (id) {
      case 1:
        setValue(id, name, video, verifica, descricao);
        setHasDescriptionSecond(false)
        break;
      case 2:
        setValue(id, name, video, verifica, descricao);
        setHasDescriptionSecond(false)
        break;
      case 3:
        setValue(id, name, video, verifica, descricao);
        setHasDescriptionSecond(false)
        break;
      case 4:
        setValue(id, name, video, verifica, descricao);
        setHasDescriptionSecond(false)
        break;
      case 5:
        setValue(id, name, video, verifica, descricao);
        setHasDescriptionSecond(false)
        break;
      case 6:
        setValue(id, name, video, verifica, descricao);
        setHasDescriptionSecond(false)
        break;
      case 7:
        setValue(id, name, video, verifica, descricao);
        setHasDescriptionSecond(false)
        break;
      case 8:
        setValue(id, name, video, verifica, descricao);
        setHasDescriptionSecond(false)
        break;
      case 9:
        setValue(id, name, video, verifica, descricao);
        setHasDescriptionSecond(false)
        break;
      case 10:
        setValue(id, name, video, verifica, descricao);
        setHasDescriptionSecond(false)
        break;
      case 11:
        setValue(id, name, video, verifica, descricao);
        setHasDescriptionSecond(false)
        break;
      case 12:
        setValue(id, name, video, verifica, descricao);
        setHasDescriptionSecond(false)
        break;
      case 13:
        setValue(id, name, video, verifica, descricao);
        setHasDescriptionSecond(false)
        break;
      case 14:
        setValue(id, name, video, verifica, descricao);
        setHasDescriptionSecond(false)
        break;
      case 15:
        setValue(id, name, video, verifica, descricao);
        setHasDescriptionSecond(false)
        break;
      case 16:
        setValue(id, name, video, verifica, descricao);
        setHasDescriptionSecond(false)
        break;
      case 17:
        setValue(id, name, video, verifica, descricao);
        setHasDescriptionSecond(false)
        break;
      case 18:
        setValue(id, name, video, verifica, descricao);
        setHasDescriptionSecond(false)
        break;
      case 19:
        setValue(id, name, video, verifica, descricao);
        setHasDescriptionSecond(false)
        break;
      case 20:
        setValue(id, name, video, verifica, descricao);
        setHasDescriptionSecond(false)
        break;
      case 21:
        setValue(id, name, video, verifica, descricao);
        setHasDescriptionSecond(false)
        break;
      case 22:
        setValue(id, name, video, verifica, descricao);
        setHasDescriptionSecond(true)
        break;
      default:
        console.log(`Não foi encontrado o ID: ${id}`);
    }
  })

  return (
    <>
      <Modal
        open={modalOpen}
        gif={gif}
        title={title}
        hasDescription={hasDescription}
        description={description}
        hasDescriptionSecond={hasDescriptionSecond}
        setModalOpen={setModalOpen}
        onClose={() => setModalOpen(false)}
        closeButton={() => setModalOpen(false)}
      />
      <h1>Painel Administrativo</h1>

      <div className="card-alert">
        <span>💡</span>
        <span style={{marginLeft: "8px", fontSize: "13px"}}>
        Bem-vindo à central de ajuda da APL SP play! Estamos aqui para responder às suas perguntas.
        Não consegue encontrar o que procura? Envie uma nota à nossa equipe de suporte
        em secretaria@apltimarilia.org.br
        </span>
      </div>

      <div style={{display: "grid", marginTop: '10px'}}>
        <a href="#cluster" className="link-tutorials-administrador">Clusters</a>
        <a href="#tiposCluster" className="link-tutorials-administrador">Tipos Cluster</a>
        <a href="#administradorCluster" className="link-tutorials-administrador">Conta Administrador Cluster</a>
        <a href="#organizacao" className="link-tutorials-administrador">Organização</a>
        <a href="#tipoOrganizacao" className="link-tutorials-administrador">Tipo Organização</a>
        <a href="#administradorOrganizacao" className="link-tutorials-administrador">Conta Administrador da Organização</a>
        <a href="#categoria" className="link-tutorials-administrador">Categorias de Trilha e Curso</a>
        <a href="#pontuacao" className="link-tutorials-administrador">Configuração de Pontuação</a>
      </div>

      <div id="cluster">
        <h2 style={{marginTop: "32px"}}>Clusters</h2>
        <div className="container-card" >
          <div className="card-tutorials" style={{marginLeft: 0}}>
            <a
            onClick={async () => {
              await setModal(1,'📕 Cadastrar Cluster', `${CadastrarCluster}`, false, '');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
              📕 Cadastrar Cluster
              </div>
            </a>
          </div>
          <div className="card-tutorials" style={{marginLeft: 0}}>
            <a
            onClick={async () => {
              await setModal(2,'📕 Editar Cluster', `${EditarCluster}`, false, '');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📕 Editar Cluster
              </div>
            </a>
          </div>
          <div className="card-tutorials" style={{marginLeft: 0}}>
            <a
            onClick={async () => {
              await setModal(3,'📕 Deletar Cluster', `${DeletarCluster}`, false, '');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📕 Deletar Cluster
              </div>
            </a>
          </div>
        </div>
      </div>

      <div id="tiposCluster">
        <h2 style={{marginTop: "32px"}}>Tipo Cluster</h2>
        <div style={{display: "flex", marginTop: '10px', marginBottom: '10px'}}>
          <div className="highlighter" />
          <span style={{marginLeft: "8px", fontSize: "13px"}}>Os tipos de cluster vão ser utilizados na criação de um Cluster! 😉</span>
        </div>

        <div className="container-card" >
          <div className="card-tutorials" style={{marginLeft: 0}}>
            <a
            onClick={async () => {
              await setModal(4,'📕 Cadastrar Tipo Cluster', `${CadastroTipoCluster}`, false, '');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📕 Cadastrar Tipo Cluster
              </div>
            </a>
          </div>
          <div className="card-tutorials" style={{marginLeft: 0}}>
            <a
            onClick={async () => {
              await setModal(5,'📕 Editar Tipo Cluster', `${EditarTipoCluster}`, false, '');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📕 Editar Tipo Cluster
              </div>
            </a>
          </div>
          <div className="card-tutorials" style={{marginLeft: 0}}>
            <a
            onClick={async () => {
              await setModal(6,'📕 Deletar Tipo Cluster', `${DeletarTipoCluster}`, false, '');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📕 Deletar Tipo Cluster
              </div>
            </a>
          </div>
        </div>
      </div>

      <div id="administradorCluster">
        <h2 style={{marginTop: "32px"}}>Conta Administrador Cluster</h2>
        <div style={{display: "flex", marginTop: '10px', marginBottom: '10px'}}>
          <div className="highlighter" />
          <span style={{marginLeft: "8px", fontSize: "13px"}}> A conta administradora do cluster será responsável por garantir o acesso do cluster no sistema! 😉</span>
        </div>

        <div className="container-card" >
          <div className="card-tutorials" style={{marginLeft: 0}}>
            <a
            onClick={async () => {
              await setModal(7,'📕 Cadastrar Administrador Cluster', `${CadastroContaCluster}`, false, '');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📕 Cadastrar Administrador Cluster
              </div>
            </a>
          </div>
          <div className="card-tutorials" style={{marginLeft: 0}}>
            <a
            onClick={async () => {
              await setModal(8,'📕 Editar Administrador Cluster', `${EditarContaCluster}`, false, '');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📕 Editar Administrador Cluster
              </div>
            </a>
          </div>
          <div className="card-tutorials" style={{marginLeft: 0}}>
            <a
            onClick={async () => {
              await setModal(9,'📕 Deletar Administrador Cluster', `${DeletarContaCluster}`, false, '');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📕 Deletar Administrador Cluster
              </div>
            </a>
          </div>
        </div>
      </div>

      <div id="organizacao">
        <h2 style={{marginTop: "32px"}}>Organização</h2>

        <div className="container-card" >
          <div className="card-tutorials" style={{marginLeft: 0}}>
            <a
            onClick={async () => {
              await setModal(10,'📕 Cadastrar Organização', `${CadastrarOrganizacao}`, true, 'Para o cadastro da organização é necessário o preenchimento de todos os campos, menos complemento que é opcional');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📕 Cadastrar Organização
              </div>
            </a>
          </div>
          <div className="card-tutorials" style={{marginLeft: 0}}>
            <a
            onClick={async () => {
              await setModal(11,'📕 Editar Organização', `${EditarOrganizacao}`, false, '');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📕 Editar Organização
              </div>
            </a>
          </div>
          <div className="card-tutorials" style={{marginLeft: 0}}>
            <a
            onClick={async () => {
              await setModal(12,'📕 Deletar Organização', `${DeletarOrganizacao}`, false, '');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📕 Deletar Organização
              </div>
            </a>
          </div>
        </div>
      </div>

      <div id="tipoOrganizacao">
        <h2 style={{marginTop: "32px"}}>Tipo Organização</h2>
        <div style={{display: "flex", marginTop: '10px', marginBottom: '10px'}}>
          <div className="highlighter" />
          <span style={{marginLeft: "8px", fontSize: "13px"}}>Os tipos de organização vão ser utilizados na criação de uma Organização! 😉</span>
        </div>

        <div className="container-card" >
          <div className="card-tutorials" style={{marginLeft: 0}}>
            <a
            onClick={async () => {
              await setModal(13,'📕 Cadastrar Tipo Organização', `${CadastrarTipoOrganizacao}`, false, '');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📕 Cadastrar Tipo Organização
              </div>
            </a>
          </div>
          <div className="card-tutorials" style={{marginLeft: 0}}>
            <a
            onClick={async () => {
              await setModal(14,'📕 Editar Tipo Organização', `${EditarTipoOrganizacao}`, false, '');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📕 Editar Tipo Organização
              </div>
            </a>
          </div>
          <div className="card-tutorials" style={{marginLeft: 0}}>
            <a
            onClick={async () => {
              await setModal(15,'📕 Deletar Tipo Organização', `${DeletarTipoOrganizacao}`, false, '');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📕 Deletar Tipo Organização
              </div>
            </a>
          </div>
        </div>
      </div>

      <div id="administradorOrganizacao">
        <h2 style={{marginTop: "32px"}}>Conta Administrador da Organização</h2>
        <div style={{display: "flex", marginTop: '10px', marginBottom: '10px'}}>
          <div className="highlighter" />
          <span style={{marginLeft: "8px", fontSize: "13px"}}>A conta administradora da organização será responsável por garantir o acesso da organização no sistema! 😉</span>
        </div>

        <div className="container-card" >
          <div className="card-tutorials" style={{marginLeft: 0}}>
            <a
            onClick={async () => {
              await setModal(16,'📕 Cadastrar Conta Organização', `${CadastrarContaOrganizacao}`, true, 'Para o cadastro da conta administradora da organização é necessário o preenchimento de todos os campos');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📕 Cadastrar Conta Organização
              </div>
            </a>
          </div>
          <div className="card-tutorials" style={{marginLeft: 0}}>
            <a
            onClick={async () => {
              await setModal(17,'📕 Editar Conta Organização', `${EditarContaOrganizacao}`, false, '');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📕 Editar Conta Organização
              </div>
            </a>
          </div>
          <div className="card-tutorials" style={{marginLeft: 0}}>
            <a
            onClick={async () => {
              await setModal(18,'📕 Deletar Conta Organização', `${DeletarContaOrganizacao}`, false, '');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📕 Deletar Conta Organização
              </div>
            </a>
          </div>
        </div>
      </div>

      <div id="categoria">
        <h2 style={{marginTop: "32px"}}>Categorias de Trilha e Curso</h2>
        <div style={{display: "flex", marginTop: '10px', marginBottom: '10px'}}>
          <div className="highlighter" />
          <span style={{marginLeft: "8px", fontSize: "13px"}}>As categorias serão utilizadas na hora da criação da Trilha e Curso! 😉</span>
        </div>

        <div className="container-card" >
          <div className="card-tutorials" style={{marginLeft: 0}}>
            <a
            onClick={async () => {
              await setModal(19,'📕 Cadastrar Categoria Trilha e Curso', `${CadastrarCategoriaTrilhaCurso}`, false, '');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📕 Cadastrar Categoria Trilha e Curso
              </div>
            </a>
          </div>
          <div className="card-tutorials" style={{marginLeft: 0}}>
            <a
            onClick={async () => {
              await setModal(20,'📕 Editar Categoria Trilha e Curso', `${EditarCategoriaTrilhaCurso}`, false, '');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📕 Editar Categoria Trilha e Curso
              </div>
            </a>
          </div>
          <div className="card-tutorials" style={{marginLeft: 0}}>
            <a
            onClick={async () => {
              await setModal(21,'📕 Deletar Categoria Trilha e Curso', `${DeletarCategoriaTrilhaCurso}`, false, '');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📕 Deletar Categoria Trilha e Curso
              </div>
            </a>
          </div>
        </div>
      </div>

      <div id="pontuacao">
        <h2 style={{marginTop: "32px"}}>Configuração de Pontuação</h2>

        <div className="container-card" >
          <div className="card-tutorials" style={{marginLeft: 0}}>
            <a
            onClick={async () => {
              await setModal(22,'📕 Configuração de Pontuação', `${ConfiguracaoSistema}`, true, 'Se o profissional já possui pontos, a alteração de alguma configuração não influenciará nos pontos atuais do profissional, apenas em novos ganhos.');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📕 Configuração de Pontuação
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
