/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-param-reassign */
import React from "react";
import { ModalTutorialsProps } from "../../../../interfaces/components";
import ModalComponent from "../../../../components/Modal/ModalComponent";
import './styles.scss';

export default function Modal({
  onClose,
  closeButton,
  open,
  setModalOpen,
  hasDescription,
  description,
  title,
  gif,
}: ModalTutorialsProps) {
  return (
    <ModalComponent closeButton={closeButton} open={open} onClose={onClose}>
      <h2 id="simple-modal-title">
        {title}
      </h2>
      <img alt="gif" src={gif} style={{width: "100%", objectFit: "cover", padding: 3, marginTop: '10px', borderRadius: '10px'}}/>

      {hasDescription ?
        <div className="card-alert">
          <span>💡</span>
          <span style={{marginLeft: "8px", fontSize: "13px"}}>
          {description}
          </span>
        </div>
      :
        <div />
      }
    </ModalComponent>
  );
}
