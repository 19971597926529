import { CircularProgress } from '@material-ui/core';
import { HistoryOutlined } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import React, { useState, useEffect, useCallback } from 'react';
import toast from 'react-hot-toast';
import { Link, useHistory } from 'react-router-dom';
import { useAuth } from '../../../../../hooks/Auth';
import { MyTrailsProps, TrailProps } from '../../../../../interfaces/Entidades';
import { API } from '../../../../../services/api';

import NoImage from '../../../../assets/images/no-image.png';
import { Button } from '../../../../components/Button';

interface InformationCardProps {
  trilha: TrailProps;
  loading: boolean;
  isSubscribed: boolean;
  setIsSubscribed: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function InformationCard({
  trilha,
  loading,
  isSubscribed,
  setIsSubscribed,
}: InformationCardProps) {
  const { token } = useAuth();
  const [isSubscribing, setIsSubscribing] = useState<boolean>(false);

  const history = useHistory();

  const handleSubscribe = useCallback(async () => {
    setIsSubscribing(true);
    try {
      await API(token).post('/api/trilha/inscrever', { trilhaId: trilha.id });
      setIsSubscribing(false);
      setIsSubscribed(true);
      toast.success('Inscrição realizada com sucesso! 😄');
    } catch (err) {
      toast.error(
        'Houve um erro ao se inscrever na trilha, tente novamente! 😢',
      );
      setIsSubscribing(false);
    }
  }, [token, trilha.id, setIsSubscribed]);

  return (
    <>
      <div className="col-xl-4">
        <div
          className="card card-custom card-stretch gutter-b"
          style={{ height: '98%' }}
        >
          <div className="card-header border-0 pt-5">
            {loading ? (
              <></>
            ) : (
              <div className="card-title">
                <div className="card-label">
                  <div className="font-weight-bolder">
                    Informações da Trilha
                  </div>
                </div>
              </div>
            )}

            <div style={{ width: '100%', height: 200 }}>
              {loading ? (
                <Skeleton
                  variant="rect"
                  width="100%"
                  height={200}
                  style={{ borderRadius: 6 }}
                />
              ) : (
                <img
                  alt=""
                  src={trilha.urlImagem ? trilha.urlImagem : NoImage}
                  style={{
                    width: '100%',
                    height: 200,
                    borderRadius: 6,
                    objectFit: 'cover',
                  }}
                />
              )}
            </div>
          </div>

          <div className="card-body d-flex flex-column">
            <div
              className="card-body pt-1"
              style={{
                fontSize: 14,
                overflowY: 'auto',
                maxHeight: 300,
              }}
            >
              {loading ? (
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Skeleton variant="text" width="100%" height="25px" />
                    <Skeleton variant="text" width="90%" height="25px" />
                    <Skeleton variant="text" width="80%" height="25px" />
                    <Skeleton variant="text" width="70%" height="25px" />
                  </div>
                </>
              ) : (
                trilha.descricao
              )}
            </div>

            <div className="rightSide-buttons">
              {loading ? (
                <></>
              ) : (
                <>
                  {isSubscribed && (
                    <Button
                      onClick={() =>
                        history.push('/list_coursesTrail', {
                          trilha: { ...trilha },
                        })
                      }
                      text="Cursar Trilha"
                    />
                  )}
                </>
              )}

              {loading ? (
                <></>
              ) : (
                <>
                  {isSubscribed ? (
                    <Button text="Já inscrito ✔" disabled />
                  ) : (
                    <Button
                      text="Inscreva-se"
                      onClick={handleSubscribe}
                      loading={isSubscribing}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
