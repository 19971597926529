/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  LinearProgress,
  TableRow,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import toast from 'react-hot-toast';
import { FiArrowLeft, FiInfo } from 'react-icons/fi';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import { Form } from '@unform/web';
import { number } from 'yup';
import { string } from 'yup/lib/locale';
import { Button } from '../../../components/Button';
import { useAuth } from '../../../hooks/Auth';
import {
  AvaliacaoProps,
  CourseModuloEvaluation,
} from '../../../interfaces/Entidades';
import { API } from '../../../services/api';
import TextAreaInput from '../../components/TextAreaInput';
import InputRadio from '../../components/InputRadio';

import './styles.scss';

interface ParamsProps {
  id: number;
}

interface RespostaProps {
  questaoId: number;
  alternativa: string;
}

interface AvaliacaoRespostaProps {
  avaliacaoId: number;
  respostas: RespostaProps[];
}

const AvaliacaoPage = () => {
  const routeParams = useParams();
  const history = useHistory();
  const { token } = useAuth();
  const [loading, setLoading] = useState(true);
  const [backDropOpen, setBackDropOpen] = useState(false);
  const [avaliacao, setAvaliacao] = useState<AvaliacaoProps>(
    {} as AvaliacaoProps,
  );
  const [courseModule, setCourseModule] = useState<CourseModuloEvaluation>(
    {} as CourseModuloEvaluation,
  );

  const Submit = useCallback(
    async (data) => {
      setBackDropOpen(true);

      try {
        const resposta: RespostaProps[] = [];
        // eslint-disable-next-line array-callback-return
        Object.keys(data).map((key, index) => {
          resposta.push({
            questaoId: parseInt(key, 10),
            alternativa: data[key],
          });
        });

        const dataToPost = {
          avaliacaoId: avaliacao.id,
          respostas: resposta,
        };

        await API(token).post('/api/avaliacao/responder', dataToPost);
        toast.success('Avaliação respondida com sucesso!');
        setBackDropOpen(false);
        history.push('/');
      } catch (err) {
        toast.error(
          'Houve um erro ao responder a avaliação, tente novamente! 😢',
        );
      }
      setLoading(false);
    },
    [token, avaliacao.id, history],
  );

  useEffect(() => {
    async function loadAvaliacaoData() {
      setLoading(true);
      try {
        const { id } = routeParams as ParamsProps;

        const response_resp_evaluation = await API(token).get(
          `/api/resposta/odata?$expand=avaliacao&$filter=avaliacao/unidadeAprendizagemId eq ${id}`,
        );

        if (response_resp_evaluation.data.value.length > 0) {
          throw new Error('Error404');
        }

        const response = await API(token).get(`/api/avaliacao/byUnidade/${id}`);

        const response_course_module = await API(token).get(
          `/api/unidadeAprendizagem/odata?$filter=id eq ${id}&$expand=curso($expand=organizacao($select=razaoSocial); $select=nome)`,
        );

        setCourseModule(response_course_module.data.value[0]);

        setAvaliacao(response.data);
        setLoading(false);
      } catch (err: any) {
        if (err.message === 'Error404') {
          toast.success('Já respondeu está avaliação! 😀');
        } else if (err.response.status === 422) {
          toast.error(
            'Ainda não completou o módulo para realizar a avaliação! 😉',
            {
              icon: <FiInfo color="#fff" size={35} />,
              position: 'top-right',
              style: {
                minWidth: 300,
                height: 50,
                color: '#fff',
                backgroundColor: '#3172b7',
              },
            },
          );
        } else {
          toast.error('Houve um erro ao carregar a prova, tente novamente! 😢');
        }
      }
    }
    loadAvaliacaoData();
  }, [routeParams, token, history]);

  if (loading) {
    return (
      <>
        <div className="row" style={{ padding: 10 }}>
          <div className="avaliacao-top">
            <div
              className="admin-trailheader-divider"
              onClick={() => history.goBack()}
            >
              <FiArrowLeft size={20} />
              <span>Voltar</span>
            </div>
          </div>
          <div className="avaliacao-header">
            <h1>
              <Skeleton variant="text" width="40%" height={30} />
            </h1>

            <h4>
              <Skeleton variant="text" width="20%" height={20} />
            </h4>
            <span>
              <Skeleton
                variant="text"
                width="100%"
                height={100}
                style={{ marginTop: -20 }}
              />
            </span>
          </div>
          <div style={{ width: '100%' }}>
            <div className="questions-container">
              <span>
                <Skeleton variant="text" width="20%" height={30} />
              </span>
              <Skeleton
                variant="text"
                width="100%"
                height={100}
                style={{ marginTop: -10 }}
              />
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="row" style={{ padding: 10 }}>
      <div className="avaliacao-top">
        <div
          className="admin-trailheader-divider"
          onClick={() => history.goBack()}
        >
          <FiArrowLeft size={20} />
          <span>Voltar</span>
        </div>
      </div>
      <div className="avaliacao-header">
        <h1 className="text-dark font-weight-bolder text-hover-primary font-size-h4 line-clamp-title">
          Prova de Certificação
        </h1>
        <span>
          {courseModule.curso.nome} | {courseModule.titulo}{' '}
        </span>

        <p className="text-dark-75 font-size-sm  font-size-base m-0 text-muted">
          Criado por{' '}
          <span className="font-weight-bolder" style={{ color: '#005f6b' }}>
            {courseModule.curso.organizacao.razaoSocial}
          </span>
        </p>

        <span>{avaliacao.descricao}</span>
      </div>
      <Form placeholder="" onSubmit={Submit} style={{ width: '100%' }}>
        {avaliacao.questoes.map((questao, index) => (
          <>
            <div className="questions-container">
              <div key={questao.conteudo} className="question-card">
                <div className="create-question-card-title">
                  <span>
                    {index + 1} - {questao.conteudo}
                  </span>
                </div>
                <div className="question-card-alternatives">
                  <InputRadio
                    type="radio"
                    name={questao.id ? questao.id.toString() : ''}
                    options={questao.alternativas}
                    style={{ marginTop: -7, color: '#008C9E' }}
                  />
                </div>
              </div>
            </div>
          </>
        ))}
        <div style={{ float: 'left' }}>
          <Button
            color="#005F6B"
            text="Confirmar"
            type="submit"
            loading={loading}
          />
        </div>
      </Form>
      <Backdrop style={{ color: '#fff', zIndex: 999 }} open={backDropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default AvaliacaoPage;
