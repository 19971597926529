import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Form } from '@unform/web';
import { FiCamera, FiImage, FiUser } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import toast from 'react-hot-toast';
import * as Yup from 'yup';

import { Autocomplete } from '@material-ui/lab';
import { Chip, TextField } from '@material-ui/core';
import { Button } from '../../../../../components/Button';
import { API } from '../../../../../services/api';
import GetValidationErrors from '../../../../../utils/getValidationErros';
import { useAuth } from '../../../../../hooks/Auth';
import Input from '../../../../components/Input';
import AvatarImg from '../../../../../assets/images/no-image.png';
import TextAreaInput from '../../../../components/TextAreaInput';
import ModalComponent from '../../../../../components/Modal/ModalComponent';
import { EditModalProps } from '../../../../../interfaces/components';
import {
  CategoriasProps,
  CategoriaSubmitProp,
} from '../../../../../interfaces/CategoryProps';
import { CategoryGetProps } from '../../../../../interfaces/Entidades';

export default function EditCursoModal({
  onClose,
  id,
  closeButton,
  item,
  open,
  setModalOpen,
}: EditModalProps) {
  const { token } = useAuth();
  const [loading, setLoading] = useState(false);
  const [resumoAprendizagem, setResumoAprendizagem] = useState<string[]>([]);
  const [categorias, setCategorias] = useState<CategoriasProps[]>([]);
  const [defaultCategoriaValue, setDefaultCategoriaValue] = useState<
    CategoriasProps[]
  >([]);
  const [categoriasArray, setCategoriasArray] = useState<CategoriasProps[]>([]);
  const formRef = useRef<FormHandles>(null);
  const avatarRef = useRef<HTMLImageElement>(null);
  const fileRef = useRef<HTMLInputElement>(null);

  const Submit = useCallback(
    async (data) => {
      setLoading(true);

      try {
        formRef.current?.setErrors({});
        if (resumoAprendizagem.length > 10) {
          throw new Error('resumoAprendizagemExceed');
        }
        const schema = Yup.object().shape({
          nome: Yup.string().required('Campo Obrigatório'),
          descricao: Yup.string().required('Campo Obrigatório'),
          resumo: Yup.string().required('Campo Obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        data.urlImagem = item.urlImagem;

        if (
          fileRef.current &&
          fileRef.current.files &&
          fileRef.current.files.length > 0
        ) {
          const formData = new FormData();
          formData.append('avatar', fileRef.current.files[0]);

          const upload_response = await API(token).post(
            '/api/trilha/UploadFiles/avatar',
            formData,
          );

          data.urlImagem = upload_response.data.avatar;
        }

        data.id = id;
        let resumoAprendizagemString = '';
        resumoAprendizagem.forEach((resumo, index) => {
          if (index === resumoAprendizagem.length - 1) {
            resumoAprendizagemString += `${resumo}`;
          } else {
            resumoAprendizagemString += `${resumo}[*]`;
          }
        });
        data.resumoAprendizagem = resumoAprendizagemString;

        const categoriaIds: CategoriaSubmitProp[] = [];
        categoriasArray.forEach((categoria) => {
          categoriaIds.push({ categoriaId: categoria.id });
        });

        data.categorias = categoriaIds;

        await API(token).put('/api/curso', data);
        setLoading(false);

        toast.success('Curso alterado com sucesso!');
        await setTimeout(() => {
          window.location.reload();
        }, 1000);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = GetValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else if (
          err instanceof Error &&
          err.message === 'resumoAprendizagemExceed'
        ) {
          toast.error('Máximo de 10 itens para o que voce aprenderá!');
        } else {
          toast.error('Houve um erro ao alterar o curso, tente novamente!');
        }

        setLoading(false);
      }
    },

    [token, id, resumoAprendizagem, categoriasArray, item.urlImagem],
  );

  const handleAvatarChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      if (avatarRef.current) {
        avatarRef.current.src = URL.createObjectURL(e.target.files[0]);
      }
    }
  }, []);

  useEffect(() => {
    async function setData() {
      const response = await API(token).get('/api/categoria/odata');
      setCategorias(response.data.value);
      if (item.resumoAprendizagem) {
        setResumoAprendizagem(item.resumoAprendizagem.split('[*]'));
      }

      if (item.categorias) {
        const itemsCome = item.categorias as CategoryGetProps[];
        const value: CategoriasProps[] = itemsCome.map((cat) => ({
          descricao: cat.categoria.descricao,
          id: cat.categoriaId,
        }));

        setCategoriasArray(value);
        setDefaultCategoriaValue(value);
      }
    }

    setData();
  }, [item, token]);

  return (
    <ModalComponent open={open} onClose={onClose} closeButton={closeButton}>
      <h2 id="simple-modal-title">Editar curso</h2>
      <Form ref={formRef} placeholder="" className="createmodal-form-admin" onSubmit={Submit}>
        <div className="AvatarInput">
          <img
            ref={avatarRef}
            src={item.urlImagem ? item.urlImagem : AvatarImg}
            alt=""
          />
          <label htmlFor="avatar">
            <FiCamera />

            <input
              type="file"
              id="avatar"
              ref={fileRef}
              name="urlImagem"
              accept="image/*"
              onChange={(event) => handleAvatarChange(event)}
            />
          </label>
        </div>

        <Input
          name="nome"
          maxLength={100}
          icon={FiUser}
          type="text"
          defaultValue={item.nome}
          placeholder="Nome"
        />

        <TextAreaInput
          name="resumo"
          maxLength={200}
          defaultValue={item.resumo}
          style={{ height: 110, maxHeight: 110 }}
          placeholder="Descrição breve"
        />

        <TextAreaInput
          name="descricao"
          defaultValue={item.descricao}
          maxLength={1000}
          style={{ maxHeight: 300, minHeight: 180 }}
          placeholder="Descricao"
        />

        <Autocomplete
          multiple
          options={[]}
          freeSolo
          onChange={(event, value) => {
            setResumoAprendizagem(value);
          }}
          defaultValue={
            item.resumoAprendizagem ? item.resumoAprendizagem.split('[*]') : ''
          }
          style={{ marginTop: 10 }}
          limitTags={5}
          className="AutoCompleteStyle"
          renderTags={(value: string[], getTagProps) =>
            value.map((option: string, index: number) => (
              <Chip
                style={{ backgroundColor: '#6f95ff', color: '#fff' }}
                variant="default"
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const newInputProps = params.InputProps as any;
            newInputProps.disableUnderline = true;
            // eslint-disable-next-line @typescript-eslint/ban-types
            params.InputProps = newInputProps;
            return (
              <TextField
                required={resumoAprendizagem.length === 0}
                label="O que o usuário aprenderá"
                name="resumoAprendizagem"
                className="TextFieldStyle"
                {...params}
                variant="standard"
                placeholder="Pressione enter para adicionar um item"
              />
            );
          }}
        />

        <Autocomplete
          multiple
          noOptionsText="Não há categorias disponíveis!"
          options={categorias}
          onChange={(event, value) => {
            setCategoriasArray(value);
          }}
          defaultValue={defaultCategoriaValue}
          limitTags={3}
          style={{
            marginTop: 10,
            backgroundColor: '#f2f3f7',
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index: number) => (
              <Chip
                style={{ backgroundColor: '#6f95ff', color: '#fff' }}
                variant="default"
                label={option.descricao}
                {...getTagProps({ index })}
              />
            ))
          }
          getOptionLabel={(option) => option.descricao}
          renderInput={(params) => (
            <TextField
              required={categoriasArray.length === 0}
              label="Categorias"
              name="resumoAprendizagem"
              className="TextFieldStyle"
              {...params}
              variant="outlined"
              placeholder="Adicionar categorias"
            />
          )}
        />

        <Button
          color="#6F95FF"
          text="Confirmar"
          type="submit"
          loading={loading}
        />
      </Form>
    </ModalComponent>
  );
}
