import React, { useCallback, useEffect, useState } from 'react';
import {
  Backdrop,
  CircularProgress,
  TableCell,
  TableRow,
} from '@material-ui/core';
import toast from 'react-hot-toast';
import { FiArrowDown, FiArrowUp } from 'react-icons/fi';
import { useLocation, useHistory } from 'react-router-dom';
import DetailsPageComponent from '../../components/DetailsPage';
import TableComponent from '../../../components/Table';
import { API } from '../../../services/api';
import { useAuth } from '../../../hooks/Auth';
import {
  CursoProps,
  UnidadeAprendizagemProps,
} from '../../../interfaces/Entidades';
import { Error404 } from '../../../ErrorPages/404';

interface OrderPageProps {
  Itens: any[];
  Type: 'UnidadeAprendizagem' | 'ObjetoAprendizagem';
  HeaderTitle: string;
  FatherEntity: any;
}

const OrderPage = () => {
  const location = useLocation();
  const history = useHistory();
  const { token } = useAuth();
  const [backDropOpen, setBackDropOpen] = useState(false);
  const [error, setError] = useState(true);
  const [params, setParams] = useState<Omit<OrderPageProps, 'Itens'>>(
    {} as OrderPageProps,
  );
  const [itens, setItens] = useState<any[]>([]);

  useEffect(() => {
    function loadItens() {
      if (!location.state) {
        setError(true);
      } else {
        const { FatherEntity, HeaderTitle, Itens, Type } =
          location.state as OrderPageProps;
        setItens([...Itens]);
        setParams({ FatherEntity, HeaderTitle, Type });
        setError(false);
      }
    }

    loadItens();
  }, [location.state]);

  const changeOrdem = (type: 'up' | 'down', index: number) => {
    const newItens = [...itens];
    if (type === 'up') {
      const aux = newItens[index].ordem;
      newItens[index].ordem = newItens[index - 1].ordem;
      newItens[index - 1].ordem = aux;
    } else {
      const aux = newItens[index].ordem;
      newItens[index].ordem = newItens[index + 1].ordem;
      newItens[index + 1].ordem = aux;
    }
    newItens.sort((a, b) => a.ordem - b.ordem);
    setItens([...newItens]);
  };

  const saveChanges = useCallback(async () => {
    setBackDropOpen(true);
    try {
      if (params.Type === 'ObjetoAprendizagem') {
        const testEntity = params.FatherEntity as UnidadeAprendizagemProps;
        const dataToPut = {
          id: testEntity.id,
          cursoId: testEntity.cursoId || 0,
          titulo: testEntity.titulo,
          conteudo: testEntity.conteudo,
          ordem: testEntity.ordem,
          objetosAprendizagens: itens,
        };

        await API(token).put('/api/unidadeAprendizagem', dataToPut);
      } else {
        const testEntity = params.FatherEntity as CursoProps;
        const dataToPut = {
          id: testEntity.id,
          urlImagem: testEntity.urlImagem,
          organizacaoId: testEntity.organizacaoId || 0,
          nome: testEntity.nome,
          descricao: testEntity.descricao,
          unidades: itens,
        };

        await API(token).put('/api/curso', dataToPut);
      }

      toast.success('Alterações salvas com sucesso!');
      setTimeout(() => {
        history.goBack();
      }, 2000);
      setBackDropOpen(false);
    } catch (err) {
      toast.error('Houve um erro ao salvar as alterações, tente novamente! 😢');
      setBackDropOpen(false);
    }
  }, [token, params, itens, history]);

  if (error) {
    return <Error404 />;
  }

  return (
    <>
      <DetailsPageComponent
        buttonHeaderText="Opções"
        hasSearch={false}
        hasMenuButton
        menuButtonOptions={[
          {
            option: 'Salvar Alterações',
            action: saveChanges,
          },
          {
            option: 'Cancelar',
            action: () => history.goBack(),
          },
        ]}
        hasCard={false}
        loading={false}
        headerBack={false}
        headerTitle={params.HeaderTitle}
      >
        <div className="cluster-table-container" style={{ margin: 0 }}>
          <div className="cluster-table-card">
            <TableComponent
              hasPagination={false}
              TableHeadCells={['Nome', 'Ordem', 'Ações']}
            >
              {itens.length > 0 ? (
                itens.map((item, index) => (
                  <TableRow key={item.id}>
                    <TableCell
                      component="th"
                      scope="row"
                      align="left"
                      className="cluster-table-body-font"
                    >
                      {item.titulo}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      align="left"
                      className="cluster-table-body-font"
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      align="left"
                      className="cluster-table-body-font"
                    >
                      {index !== 0 && (
                        <FiArrowUp
                          size={20}
                          color="green"
                          style={{ cursor: 'pointer' }}
                          onClick={() => changeOrdem('up', index)}
                        />
                      )}

                      {index !== itens.length - 1 && (
                        <FiArrowDown
                          size={20}
                          color="red"
                          style={{ cursor: 'pointer' }}
                          onClick={() => changeOrdem('down', index)}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <h2>Não foram encontrados itens</h2>
                </div>
              )}
            </TableComponent>
          </div>
        </div>
      </DetailsPageComponent>
      <Backdrop style={{ color: '#fff', zIndex: 999 }} open={backDropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default OrderPage;
