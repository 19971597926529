import React from 'react';

import { Skeleton } from '@material-ui/lab';
import { Avatar } from '@material-ui/core';
import { CompanyProps } from '../../../../../interfaces/Entidades';
import NoImg from '../../../../assets/images/no-image.png';

interface CardTitleCompany {
  company: CompanyProps;
  loading: boolean;
}

export default function CardTitleCompany({
  company,
  loading,
}: CardTitleCompany) {
  return (
    <>
      <div className="cardLearningUnit">
        <div
          className="card card-custom card-stretch gutter-b"
          style={{ height: '66%' }}
        >
          <div className="card-header border-0 py-5">
            <div className="company-header">
              {loading ? (
                <Skeleton
                  variant="rect"
                  width={70}
                  height="110%"
                  style={{ borderRadius: 3 }}
                />
              ) : (
                <img
                  src={company.logotipo || NoImg}
                  alt="Logo Empresa"
                  style={{
                    maxWidth: '30%',
                    maxHeight: '10rem',
                    objectFit: 'cover',
                    borderRadius: 6,
                  }}
                />
              )}

              <div className="company-name">
                <span className="title-company">
                  {loading ? (
                    <Skeleton variant="text" width="100%" height={25} />
                  ) : (
                    company?.razaoSocial
                  )}
                </span>

                <p className="font-weight-bolder font-size-h7 m-0 text-muted line-clamp">
                  {loading ? (
                    <Skeleton variant="text" width={200} height="100%" />
                  ) : (
                    `${company?.cidade} - ${company?.uf}`
                  )}
                </p>
                <p className="font-weight-bolder font-size-h7 m-0 text-muted line-clamp">
                  {loading ? (
                    <Skeleton variant="text" width={200} height="100%" />
                  ) : (
                    company.cluster.descricao
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="card-body py-0">
            <p
              className="font-weight-bold text-dark-75 font-size-lg"
              style={{ marginTop: '-30px' }}
            >
              {loading ? (
                <Skeleton
                  variant="text"
                  width="100%"
                  height={100}
                  style={{ marginTop: '-40px' }}
                />
              ) : !company.descricao ? (
                <>
                  <p className="font-weight-bolder font-size-h7 m-0 text-muted line-clamp">
                    Essa empresa não tem descrição
                  </p>
                </>
              ) : (
                company.descricao
              )}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
