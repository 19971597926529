import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { UserAprovadasProps } from '../../../../interfaces/Entidades';
import AvatarImg from '../../../assets/images/no-image.png';
import { API } from '../../../../services/api';
import { useAuth } from '../../../../hooks/Auth';

interface CardCertificate {
  loading: boolean;
  trilha: UserAprovadasProps[];
}

export default function CardCertificate({ loading, trilha }: CardCertificate) {
  const { token } = useAuth();
  const tagARef = useRef<HTMLAnchorElement>(null);
  const [url, setUrl] = useState('');

  async function Download(id:number, trailName: string) {
    const retorno = await API(token).get(`/api/trilha/certificado/${id}`, { responseType: 'arraybuffer'}).then((e) => {

        const blob = new Blob([e.data], { type: 'application/pdf' });
        const downloadURL = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = `Certificado - ${trailName}.pdf`;
        link.click();

    }).catch(error => {
        console.log(error);
    });

}

  if (loading) {
    return <></>;
  }

  if (trilha.length === 0) {
    return (
      <div className="col-lg-12">
        <div
          className="card card-custom card-stretch gutter-b"
          style={{
            height: '100%',
            overflowY: 'scroll',
            paddingBottom: '10px',
          }}
        >
          <div className="card-header border-0">
            <h3 className="card-title font-weight-bolder text-dark">
              Certificados
            </h3>
          </div>

          <div className="card-body pt-0">
            <h1 className="font-size-lg" style={{ textAlign: 'center' }}>
              Profissional não possui certificados
            </h1>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="col-lg-12">
        <div
          className="card card-custom card-stretch gutter-b"
          style={{
            height: '100%',
            overflowY: 'scroll',
            paddingBottom: '10px',
          }}
        >
          <div className="card-header border-0">
            <h3 className="card-title font-weight-bolder text-dark">
              Certificados
            </h3>
          </div>

          <div className="card-body pt-0">
            {trilha.map((trail) => (
              <div
                className="d-flex align-items-center mb-9  rounded p-5"
                style={{ backgroundColor: '#F7F8FA' }}
              >
                <span className="svg-icon svg-icon-warning mr-5">
                  <span className="svg-icon svg-icon-lg">
                    <img
                      src={trail.trilhaImagem ? trail.trilhaImagem : AvatarImg}
                      alt=""
                      style={{
                        width: '68px',
                        height: '45px',
                        borderRadius: '10px',
                        objectFit: 'cover',
                      }}
                    />
                  </span>
                </span>

                <div className="d-flex flex-column flex-grow-1 mr-2">
                  <button
                    onClick={() => {
                      Download(trail.trilhaId, trail.trilhaNome)
                    }}
                    type="button"
                    style={{border: "none", backgroundColor: "transparent", textAlign: "start", color: "#005F6B"}}
                    className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                  >
                    Certificado da trilha - {trail.trilhaNome}
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
