import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import jwt_decode from 'jwt-decode';
import { API } from '../services/api';
import { ReactFCProps } from '../interfaces/Children';

interface SignInCredentials {
  Email: string;
  Senha: string;
}

interface tokenPayload {
  email: string;
  Id: string;
  organizacao: any;
  foto: string;
  role: 'Admin' | 'Cluster' | 'Organizacao' | 'Colaborador' | 'Profissional';
  cluster: string | undefined;
  nbf: number;
  exp: number;
  iat: number;
}

interface SignInResponse {
  accessToken: string;
  username: string;
  organizacao: number;
}

interface userProps {
  username: string;
  email: string;
  organizacao: any;
  foto: string;
  Id: string;
  role: 'Admin' | 'Cluster' | 'Organizacao' | 'Colaborador' | 'Profissional';
  cluster: string | undefined;
}

interface AuthState {
  user: userProps;
  accessToken: string;
}

interface AuthContextData {
  user: userProps;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): Promise<void>;
  updateUser(foto: string): void;
  token: string;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider = ({ children }: ReactFCProps) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@APL:accessToken');
    const user = localStorage.getItem('@APL:user');

    if (token && user) {
      return { accessToken: token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ Email, Senha }: SignInCredentials) => {
    const response = await API().post<SignInResponse>('/api/usuario/login', {
      Email,
      Senha,
    });

    const { accessToken, username } = response.data;

    const payload: tokenPayload = jwt_decode(accessToken);

    const userData: userProps = {
      email: payload.email,
      Id: payload.Id,
      foto: payload.foto,
      role: payload.role,
      organizacao: payload.organizacao,
      cluster: payload.cluster,
      username,
    };

    localStorage.setItem('@APL:accessToken', accessToken);
    localStorage.setItem('@APL:user', JSON.stringify(userData));

    setData({ accessToken, user: userData });
  }, []);

  const signOut = useCallback(async () => {
    localStorage.removeItem('@APL:accessToken');
    localStorage.removeItem('@APL:user');
    setData({} as AuthState);
  }, []);

  const updateUser = useCallback(
    (foto: string) => {
      const oldUser = localStorage.getItem('@APL:user');
      if (oldUser !== null) {
        const newUser = JSON.parse(oldUser);
        newUser.foto = foto;
        setData({
          user: newUser,
          accessToken: data.accessToken,
        });

        localStorage.setItem('@APL:user', JSON.stringify(newUser));
      }
    },
    [data.accessToken],
  );

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        token: data.accessToken,
        signIn,
        signOut,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth deve ser usado com AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
