/* eslint-disable no-nested-ternary */
import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Skeleton } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import { RecommendationOrg } from '../../../../interfaces/Entidades';
import AvatarImg from '../../../assets/images/no-image.png';

interface CardRecommendedCompany {
  loading: boolean;
  recommendationOrg: RecommendationOrg[];
}

export default function CardRecommendedCompany({
  loading,
  recommendationOrg,
}: CardRecommendedCompany) {
  const itensDesktop = () => {
    if (recommendationOrg) {
      if (recommendationOrg.length === 1) {
        return 2;
      }
      if (recommendationOrg.length === 2) {
        return 3;
      }
      if (recommendationOrg.length === 3) {
        return 4;
      }
      if (recommendationOrg.length >= 4) {
        return 3;
      }
      return 0;
    }
    return 0;
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: itensDesktop(),
      paritialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      paritialVisibilityGutter: 30,
    },
  };

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%',
          marginBottom: 25,
        }}
      >
        <div
          style={{
            width: 300,
            marginRight: 10,
            backgroundColor: '#fff',
            padding: 20,
            borderRadius: 8,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Skeleton variant="rect" width="20%" height={40} />
            <Skeleton variant="text" width="80%" style={{ marginLeft: 10 }} />
          </div>
          <div style={{ marginTop: 10 }}>
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="80%" />
          </div>
        </div>
        <div
          style={{
            width: 300,
            marginRight: 10,
            backgroundColor: '#fff',
            padding: 20,
            borderRadius: 8,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Skeleton variant="rect" width="20%" height={40} />
            <Skeleton variant="text" width="80%" style={{ marginLeft: 10 }} />
          </div>
          <div style={{ marginTop: 10 }}>
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="80%" />
          </div>
        </div>
        <div
          style={{
            width: 300,
            marginRight: 10,
            backgroundColor: '#fff',
            padding: 20,
            borderRadius: 8,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Skeleton variant="rect" width="20%" height={40} />
            <Skeleton variant="text" width="80%" style={{ marginLeft: 10 }} />
          </div>
          <div style={{ marginTop: 10 }}>
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="80%" />
          </div>
        </div>
        <div
          style={{
            width: 300,
            marginRight: 10,
            backgroundColor: '#fff',
            padding: 20,
            borderRadius: 8,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Skeleton variant="rect" width="20%" height={40} />
            <Skeleton variant="text" width="80%" style={{ marginLeft: 10 }} />
          </div>
          <div style={{ marginTop: 10 }}>
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="80%" />
          </div>
        </div>
        <div
          style={{
            width: 300,
            marginRight: 10,
            backgroundColor: '#fff',
            padding: 20,
            borderRadius: 8,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Skeleton variant="rect" width="20%" height={40} />
            <Skeleton variant="text" width="80%" style={{ marginLeft: 10 }} />
          </div>
          <div style={{ marginTop: 10 }}>
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="80%" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <h1 className="titleRecommended">Empresas recomendadas para você</h1>
      <Carousel
        ssr
        partialVisible
        responsive={responsive}
        containerClass="teste"
        itemClass="item-class"
      >
        {recommendationOrg.map((company) => (
          <div
            className="card card-custom card-stretch gutter-b"
            style={{
              cursor: 'pointer',
            }}
          >
            <div className="card-body d-flex flex-column">
              <div className="flex-grow-1 pb-5">
                <div className="d-flex align-items-center pr-2 mb-6">
                  <div className="symbol symbol-60">
                    <img
                      src={company.logotipo ? company.logotipo : AvatarImg}
                      alt="Foto da Trilha"
                      style={{
                        objectFit: 'cover',
                      }}
                    />
                  </div>
                  <Link
                    to={{ pathname: `/details_company/${company.id}` }}
                    className="font-weight-bolder font-size-h5 color font-weight-bolder line-clamp-title"
                    style={{ marginLeft: '5px' }}
                  >
                    {loading ? (
                      <Skeleton variant="text" width={200} height="100%" />
                    ) : (
                      company.nomeFantasia
                    )}
                  </Link>
                </div>

                <p className="text-dark-75 font-size-sm  font-size-base m-0 text-muted line-clamp">
                  {loading ? (
                    <Skeleton variant="text" width={200} height="100%" />
                  ) : company.descricao ? (
                    company.descricao
                  ) : (
                    'Essa empresa não tem descrição'
                  )}
                </p>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </>
  );
}
