import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  ChangeEvent,
} from 'react';
import { Form } from '@unform/web';
import {
  FiHome,
  FiLink,
  FiMail,
  FiMap,
  FiMapPin,
  FiPhone,
  FiUser,
  FiCamera,
} from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import toast from 'react-hot-toast';

import { OptionTypeBase } from 'react-select';
import * as Yup from 'yup';

import { API } from '../../../../services/api';
import SelectInput from '../../../components/SelectInput';
import GetValidationErrors from '../../../../utils/getValidationErros';
import { useAuth } from '../../../../hooks/Auth';
import Input from '../../../components/Input';
import MaskInput from '../../../components/MaskInput';
import { Button } from '../../../../components/Button';
import ModalComponent from '../../../../components/Modal/ModalComponent';
import { EditModalProps } from '../../../../interfaces/components';
import AvatarImg from '../../../assets/images/no-image.png';
import { tipoOrganizacaoProps } from '../../../../interfaces/Entidades';
import SwitchInputCluster from '../../../components/InputSwitch';

export default function EditModalCompany({
  id,
  onClose,
  closeButton,
  item,
  open,
  setModalOpen,
}: EditModalProps) {
  const { token, user } = useAuth();
  const [inputLoading, setInputLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [optionsTipoOrg, setOptionsTipoOrg] = useState<OptionTypeBase[]>();
  const formRef = useRef<FormHandles>(null);
  const avatarRef = useRef<HTMLImageElement>(null);
  const fileRef = useRef<HTMLInputElement>(null);

  const Submit = useCallback(
    async (data) => {
      setLoading(true);

      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          razaoSocial: Yup.string().required('Campo Obrigatório'),
          nomeFantasia: Yup.string().required('Campo Obrigatório'),
          tipoLogradouro: Yup.string().required('Campo Obrigatório'),
          logradouro: Yup.string().required('Campo Obrigatório'),
          numero: Yup.string().required('Campo Obrigatório'),
          complemento: Yup.string(),
          bairro: Yup.string().required('Campo Obrigatório'),
          cidade: Yup.string().required('Campo Obrigatório'),
          uf: Yup.string().required('Campo Obrigatório'),
          cep: Yup.string().required('Campo Obrigatório'),
          telefone: Yup.string().required('Campo Obrigatório'),
          email: Yup.string().required('Campo Obrigatório').email(),
          urlSite: Yup.string().required('Campo Obrigatório'),
          nomeResponsavel: Yup.string().required('Campo Obrigatório'),
          telefoneResponsavel: Yup.string().required('Campo Obrigatório'),
          tipoOrganizacaoId: Yup.number().required('Campo Obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        data.id = id;
        data.clusterId = user.cluster;
        data.logotipo = item.logotipo;

        if (
          fileRef.current &&
          fileRef.current.files &&
          fileRef.current.files.length > 0
        ) {
          const formData = new FormData();
          formData.append('foto', fileRef.current.files[0]);

          const upload_response = await API(token).post(
            '/api/organizacao/imagem',
            formData,
          );

          data.logotipo = upload_response.data.url;
        }

        await API(token).put(`/api/organizacao`, data);
        setLoading(false);

        await setTimeout(() => {
          window.location.reload();
        }, 1000);
        toast.success('Organização alterada com sucesso!');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = GetValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          toast.error(
            'Houve um erro ao alterar a organização, tente novamente! 😢',
          );
        }

        setLoading(false);
      }
    },

    [token, id, user.cluster, item.logotipo],
  );

  const handleAvatarChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      if (avatarRef.current) {
        avatarRef.current.src = URL.createObjectURL(e.target.files[0]);
      }
    }
  }, []);

  useEffect(() => {
    async function loadData() {
      setInputLoading(true);
      setOptionsTipoOrg([]);

      const response_tipoOrg = await API(token).get(
        '/api/tipoorganizacao/odata',
      );

      const optionsFormatTipoOrg: OptionTypeBase[] = [];

      response_tipoOrg.data.value.forEach(
        (tipoEmpresa: tipoOrganizacaoProps) => {
          optionsFormatTipoOrg.push({
            value: tipoEmpresa.id,
            label: tipoEmpresa.descricao,
          });
        },
      );

      setOptionsTipoOrg(optionsFormatTipoOrg);

      setInputLoading(false);
    }

    loadData();
  }, [token, id]);

  return (
    <ModalComponent
      closeButton={closeButton}
      open={open}
      onClose={onClose}
      height="90%"
    >
      <Form ref={formRef} placeholder="" className="createmodal-form-admin" onSubmit={Submit}>
        <h2 id="simple-modal-title">Editar Organização</h2>

        <div className="AvatarInput">
          <img ref={avatarRef} src={item.logotipo || AvatarImg} alt="" />
          <label htmlFor="avatar" style={{ backgroundColor: '#7A9940' }}>
            <FiCamera />

            <input
              type="file"
              id="avatar"
              ref={fileRef}
              name="urlImagem"
              accept="image/*"
              onChange={(event) => handleAvatarChange(event)}
            />
          </label>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'baseline',
          }}
        >
          <span style={{ marginRight: 10, fontSize: 16 }}>Ativa</span>
          <SwitchInputCluster name="ativo" defaultChecked={item.ativo} />
        </div>

        <Input
          name="razaoSocial"
          maxLength={60}
          icon={FiUser}
          type="text"
          defaultValue={item?.razaoSocial}
          placeholder="Razão Social"
        />
        <Input
          name="nomeFantasia"
          maxLength={60}
          icon={FiUser}
          type="text"
          defaultValue={item?.nomeFantasia}
          placeholder="Nome Fantasia"
        />
        <Input
          name="email"
          maxLength={80}
          icon={FiMail}
          type="text"
          defaultValue={item?.email}
          placeholder="Email"
        />

        <Input
          name="nomeResponsavel"
          maxLength={80}
          icon={FiUser}
          type="text"
          defaultValue={item?.nomeResponsavel}
          placeholder="Nome Responsável"
        />
        <MaskInput
          mask="(99) 99999-9999"
          name="telefoneResponsavel"
          defaultValue={item?.telefoneResponsavel}
          icon={FiPhone}
          type="text"
          placeholder="Telefone Responsável"
        />
        <MaskInput
          mask="(99) 9999-9999"
          name="telefone"
          icon={FiPhone}
          defaultValue={item?.telefone}
          type="text"
          placeholder="Telefone"
        />
        <Input
          name="urlSite"
          maxLength={100}
          icon={FiLink}
          defaultValue={item?.urlSite}
          type="text"
          placeholder="Link do Site"
        />

        <MaskInput
          mask="99999-999"
          name="cep"
          defaultValue={item?.cep}
          icon={FiMap}
          type="text"
          placeholder="CEP"
        />
        <Input
          name="logradouro"
          defaultValue={item?.logradouro}
          maxLength={60}
          icon={FiHome}
          type="text"
          placeholder="Logradouro"
        />
        <Input
          name="numero"
          maxLength={20}
          defaultValue={item?.numero}
          icon={FiMapPin}
          type="text"
          placeholder="Numero"
        />
        <Input
          name="complemento"
          defaultValue={item?.complemento}
          maxLength={20}
          icon={FiMapPin}
          type="text"
          placeholder="Complemento"
        />

        <Input
          name="bairro"
          defaultValue={item?.bairro}
          maxLength={60}
          icon={FiHome}
          type="text"
          placeholder="Bairro"
        />
        <Input
          name="cidade"
          maxLength={60}
          icon={FiHome}
          defaultValue={item?.cidade}
          type="text"
          placeholder="Cidade"
        />
        <Input
          name="uf"
          maxLength={2}
          defaultValue={item?.uf}
          icon={FiMapPin}
          type="text"
          placeholder="UF"
        />
        <Input
          name="tipoLogradouro"
          maxLength={20}
          defaultValue={item?.tipoLogradouro}
          icon={FiMapPin}
          type="text"
          placeholder="Tipo Logradouro"
        />

        <div className="createCompanyDivFormSelects">
          <SelectInput
            loading
            options={optionsTipoOrg}
            isLoading={inputLoading}
            defaultValue={
              Object.keys(item).length > 0
                ? {
                    value: item.tipoOrganizacaoId,
                    label: item.tipoOrganizacao.descricao,
                  }
                : {}
            }
            name="tipoOrganizacaoId"
            placeholder="Selecione o tipo da empresa..."
          />
        </div>

        <div className="button-modal">
          <Button
            color="#7A9940"
            height={40}
            width="100%"
            text="Confirmar"
            type="submit"
            loading={loading}
          />
        </div>
      </Form>
    </ModalComponent>
  );
}
