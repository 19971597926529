import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { AxiosResponse } from 'axios';
import { FiArrowLeft, FiInfo } from 'react-icons/fi';
import CardModules from './components/cardModules';
import CardInfo from './components/cardInfo';
import CardClass from './components/cardModuleClass';
import {
  CursoProps,
  ObjetosAprendizagemProps,
  UnidadeAprendizagemProps,
  MyDesafio,
  CoursesProgress,
  UserProfissionalProps,
  RespostaDesafioProps,
} from '../../../interfaces/Entidades';
import { useAuth } from '../../../hooks/Auth';
import { API } from '../../../services/api';
import { Error401 } from '../../../ErrorPages/401';

interface ParamsProps {
  id: string;
}

export default function LearningUnit() {
  const history = useHistory();
  const params = useParams<ParamsProps>();
  const { token, user } = useAuth();
  const [blocked, setBlocked] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [moduleId, setModuleId] = useState(0);
  const [moduleName, setModuleName] = useState<string>();
  const [classLoading, setClassLoading] = useState<boolean>(false);
  const [modulos, setModulos] = useState<UnidadeAprendizagemProps[]>([]);
  const [aulas, setAulas] = useState<ObjetosAprendizagemProps[]>([]);
  const [desafios, setDesafios] = useState<RespostaDesafioProps[]>([]);
  const [hasEvaluation, setHasEvaluation] = useState(false);
  const [cursoProgress, setCursoProgress] = useState<CoursesProgress>(
    {} as CoursesProgress,
  );
  const [curso, setCurso] = useState<CursoProps>({} as CursoProps);
  const [profissional, setProfissional] = useState<UserProfissionalProps>(
    {} as UserProfissionalProps,
  );

  const filterDesafios = useCallback(
    (desafiosArray: RespostaDesafioProps[], moduloId: number) => {
      const filteredArray = desafiosArray.filter(
        (desafio) => desafio.desafio.unidadeAprendizagemId === moduloId,
      );
      return filteredArray;
    },
    [],
  );

  const LoadAulas = useCallback(
    async (moduloId) => {
      setClassLoading(true);

      const response_modulo = await API(token).get(
        `/api/unidadeAprendizagem/odata?$select=titulo&$filter=id eq ${moduloId}`,
      );
      setModuleName(response_modulo.data.value[0].titulo);

      const response = await API(token).get(
        `/api/objetoAprendizagem/odata?$filter=unidadeAprendizagemId eq ${moduloId}`,
      );

      const response_meusDesafios = await API(token).get(`/api/desafio/meus`);

      setDesafios(filterDesafios(response_meusDesafios.data.value, moduloId));

      const data = response.data.value as ObjetosAprendizagemProps[];

      const hasAvaliacao = modulos.find((modulo) => modulo.id === moduloId);

      if (hasAvaliacao) {
        setHasEvaluation(hasAvaliacao.possuiAvaliacao);
      }
      setAulas(data);
      setClassLoading(false);
    },
    [modulos, token, filterDesafios],
  );

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      setModulos([]);
      setAulas([]);

      try {
        const promise_array: Promise<AxiosResponse<any>>[] = [];
        // Pegar dados do curso + modulos e aulas
        const response_curso = await API(token).get<CursoProps>(
          `/api/curso/${params.id}`,
        );

        if (!response_curso.data.inscrito) {
          throw new Error('Error401');
        }

        setCurso(response_curso.data);
        setModulos(response_curso.data.unidades);
        setModuleId(response_curso.data.unidades[0].id);
        setModuleName(response_curso.data.unidades[0].titulo);
        setHasEvaluation(response_curso.data.unidades[0].possuiAvaliacao);
        setAulas(response_curso.data.unidades[0].objetosAprendizagens);

        // Pegar dados do profissional
        const response_profissional = API(token).get(
          `/api/usuario/perfil/${user.Id}`,
        );

        // Pegar dados do progresso/inicio do curso/modulos concluidos
        const response_progress = API(token).get(
          `/api/progresso/odata?$filter=cursoId eq ${params.id} and profissionalId eq ${user.Id}`,
        );

        const response_meusDesafios = API(token).get(`/api/desafio/meus`);

        promise_array.push(
          response_profissional,
          response_progress,
          response_meusDesafios,
        );

        await Promise.all(promise_array).then((results) => {
          setProfissional(results[0].data);
          setCursoProgress(results[1].data.value[0]);
          setDesafios(
            filterDesafios(
              results[2].data.value,
              response_curso.data.unidades[0].id,
            ),
          );
        });

        setLoading(false);
        setBlocked(false);
      } catch (err: any) {
        console.log(err);
        if (err.message === 'Error401') {
          setBlocked(true);
          toast.error('Você não tem acesso a este curso! 😓', {
            icon: <FiInfo color="#fff" size={35} />,
            position: 'top-right',
            style: {
              minWidth: 300,
              height: 50,
              color: '#fff',
              backgroundColor: '#3172b7',
            },
          });
        } else {
          toast.error(
            'Houve um erro ao carregar os dados, tente novamente! 😥',
          );
        }
      }
    }
    loadData();
  }, [token, params.id, user.Id, filterDesafios]);

  if (blocked) {
    return <Error401 />;
  }

  return (
    <>
      <div className="admin-trail-header">
        <div
          role="none"
          className="admin-trailheader-divider"
          onClick={() => history.push('/mytrails')}
          onKeyDown={() => history.push('/mytrails')}
        >
          <FiArrowLeft size={20} />
          <span>Voltar</span>
        </div>
      </div>
      <div className="row">
        <div className="carrosel">
          <CardModules
            setModuleId={setModuleId}
            loadAulas={LoadAulas}
            isLoading={loading}
            nameCurso={curso?.nome}
            modulos={modulos}
          />
        </div>
      </div>

      <div className="row">
        <CardInfo
          moduleId={moduleId}
          modulos={modulos}
          loading={loading}
          profissional={profissional}
          cursoProgress={cursoProgress}
          hasEvaluation={hasEvaluation}
          desafios={desafios}
        />
        {loading ? (
          <></>
        ) : (
          <CardClass
            aulas={aulas}
            isLoading={classLoading}
            moduleName={moduleName}
          />
        )}
      </div>
    </>
  );
}
