import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import toast from 'react-hot-toast';

import * as Yup from 'yup';
import { useAuth } from '../../../../hooks/Auth';
import {
  DesafioProps,
  RespostaDesafioProps,
} from '../../../../interfaces/Entidades';
import { API } from '../../../../services/api';
import GetValidationErrors from '../../../../utils/getValidationErros';
import ModalComponent from '../../../../components/Modal/ModalComponent';
import TextAreaInput from '../../../components/TextAreaInput';
import { Button } from '../../../../components/Button';
import Input from '../../../components/Input';
import { EditModalProps } from '../../../../interfaces/components';

export default function CreateCorrecaoModal({
  closeButton,
  item,
  open,
  setModalOpen,
  onClose,
  id,
}: EditModalProps) {
  const { token } = useAuth();
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);

  const Submit = useCallback(
    async (data: RespostaDesafioProps) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          pontuacao: Yup.number().required('Campo Obrigatório'),
          mensagem: Yup.string().required('Campo Obrigatório'),
        });

        data.id = id;

        await schema.validate(data, {
          abortEarly: false,
        });

        await API(token).put<RespostaDesafioProps>(
          '/api/desafio/Corrigir',
          data,
        );

        setLoading(false);

        // data.id = response.data.id;

        setModalOpen(false);

        toast.success('Correção enviada com sucesso!');
        await setTimeout(() => {
          window.location.reload();
        }, 1000);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = GetValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          toast.error('Houve um erro ao enviar a correção, tente novamente!');
        }

        setLoading(false);
      }
    },

    [token, id],
  );

  return (
    <ModalComponent closeButton={closeButton} open={open} onClose={onClose}>
      <Form ref={formRef} placeholder="" className="createmodal-form-admin" onSubmit={Submit}>
        <h2 id="simple-modal-title">Correção do Desafio</h2>

        <Input
          name="pontuacao"
          max={item ? item.desafio?.pontuacao : ''}
          min={0}
          type="number"
          placeholder={
            item ? `Pontuação máxima: ${item.desafio?.pontuacao}` : ''
          }
        />

        <TextAreaInput name="mensagem" maxLength={400} placeholder="Mensagem" />

        <Button
          color="#6F95FF"
          text="Confirmar"
          type="submit"
          loading={loading}
        />
      </Form>
    </ModalComponent>
  );
}
