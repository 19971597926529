/* eslint-disable react/react-in-jsx-scope */
import { Redirect, Route, Switch } from 'react-router-dom';
import ContainerAdmin from '../Admin/pages/Container';
import ContainerAdminCluster from '../AdminCluster/pages/Container';
import ContainerAdminCompany from '../AdminCompany/pages/Container';
import { Error404 } from '../ErrorPages/404';
import ContainerProfessional from '../Profissional/Pages/Container';
import ForgotPassword from '../Profissional/Pages/ForgotPassword';
import ResetPassword from '../Profissional/Pages/ResetPassword';
import { SignIn } from '../Profissional/Pages/SignIn';
import { SignUp } from '../Profissional/Pages/SignUp';
import { useAuth } from '../hooks/Auth';

export const Routes = () => {
  const { user } = useAuth();

  if (!user) {
    return (
      <Switch>
        <Route path="/" exact component={SignIn} />
        <Route path="/cadastro" component={SignUp} />

        <Route path="/forgotpassword" component={ForgotPassword} />
        <Route path="/redefinirSenha" component={ResetPassword} />
        <Route
          render={({ location }) => (
            <Redirect
              to={{
                pathname: '/',
                state: { from: location },
              }}
            />
          )}
        />
      </Switch>
    );
  }
  if (user && user.role === 'Admin') {
    return <ContainerAdmin />;
  }

  if (user && user.role === 'Cluster') {
    return <ContainerAdminCluster />;
  }

  if (user && (user.role === 'Organizacao' || user.role === 'Colaborador')) {
    return <ContainerAdminCompany />;
  }

  if (user && user.role === 'Profissional') {
    return <ContainerProfessional />;
  }
  return <Route component={Error404} />;
};
