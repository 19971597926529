/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Pagination } from '@material-ui/lab';
import React, {
  useState,
  useEffect,
  BaseSyntheticEvent,
  useCallback,
} from 'react';
import toast from 'react-hot-toast';
import { Link, useParams, useHistory } from 'react-router-dom';
import { FiCheck, FiDelete, FiEdit, FiPlus, FiTrash } from 'react-icons/fi';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import AvatarImg from '../../assets/images/avatar.jpg';
import { useAuth } from '../../../hooks/Auth';
import { API } from '../../../services/api';
import DetailsPageComponent from '../../components/DetailsPage';
import CreateCursoModal from './components/CreateModuleModal';
import EditCursoModal from './components/EditCourseModal';
import {
  CursoProps,
  UnidadeAprendizagemProps,
} from '../../../interfaces/Entidades';
import EditModuleModal from './components/EditModuleModal';
import DeleteModal from '../../../components/Modal/DeleteModal';
import { CourseCard } from './components/CourseCard';

interface StateProps {
  id: string;
}

const BlueTooltip = withStyles({
  tooltip: {
    fontSize: 12,
    color: '#fff',
    backgroundColor: '#6F95FF',
  },
})(Tooltip);

const CourseDetailPage = () => {
  const params = useParams<StateProps>();
  const history = useHistory();
  const { token } = useAuth();
  const [curso, setCurso] = useState<CursoProps>({} as CursoProps);
  const [unidadesAprendizagem, setUnidadesAprendizagem] = useState<
    UnidadeAprendizagemProps[]
  >([]);
  const [filteredUnidadesAprendizagem, setFilteredUnidadesAprendizagem] =
    useState<UnidadeAprendizagemProps[]>([]);
  const [loading, setLoading] = useState(true);
  const [createModuleModalOpen, setCreateModuleModalOpen] = useState(false);
  const [editCursoModalOpen, setEditCursoModalOpen] = useState(false);
  const [editModuleModalOpen, setEditModuleModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteModuleModalOpen, setDeleteModuleModalOpen] = useState(false);
  const [editModule, setEditModule] = useState<UnidadeAprendizagemProps>(
    {} as UnidadeAprendizagemProps,
  );
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [formados, setFormados] = useState(0);

  const handleFilter = useCallback(
    (text: string) => {
      const filterUnidadesAprendizagem = unidadesAprendizagem.filter((module) =>
        module.titulo.toUpperCase().includes(text.toUpperCase()),
      );
      if (filterUnidadesAprendizagem.length > 0) {
        setFilteredUnidadesAprendizagem(filterUnidadesAprendizagem);
      } else if (text === '') {
        setFilteredUnidadesAprendizagem(unidadesAprendizagem);
      } else {
        setFilteredUnidadesAprendizagem([]);
      }

      const pagesCount = Math.ceil(
        filterUnidadesAprendizagem.length / rowsPerPage,
      );
      setPageCount(pagesCount);
      if (pagesCount <= 1) {
        setPage(0);
      }
    },
    [unidadesAprendizagem, rowsPerPage],
  );

  useEffect(() => {
    function setNewData() {
      const sortModules = unidadesAprendizagem.sort(
        (a, b) => a.ordem - b.ordem,
      );

      setUnidadesAprendizagem(sortModules);
      setFilteredUnidadesAprendizagem(sortModules);
      setPageCount(Math.ceil(sortModules.length / rowsPerPage));
    }

    setNewData();
  }, [unidadesAprendizagem, rowsPerPage]);

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      setCurso({} as CursoProps);
      try {
        const response = await API(token).get<CursoProps>(
          `/api/curso/${params.id}`,
        );

        const curso_formados = await API(token).get(
          `/api/progresso/odata?$filter=cursoId eq ${params.id} and concluido eq true&$count=true`,
        );

        // eslint-disable-next-line no-underscore-dangle
        setFormados(curso_formados.data._count);
        const cursos = response.data;
        cursos.unidades = cursos.unidades.sort((a, b) => a.ordem - b.ordem);

        setCurso(response.data);
        setUnidadesAprendizagem(response.data.unidades);
        setFilteredUnidadesAprendizagem(response.data.unidades);
        setPageCount(Math.ceil(response.data.unidades.length / rowsPerPage));
        setLoading(false);
      } catch (err) {
        toast.error(
          'Houve um erro ao carregar os dados do curso, tente novamente! 😢',
        );
      }
    }

    loadData();
  }, [token, params.id, rowsPerPage]);

  return (
    <>
      <CreateCursoModal
        open={createModuleModalOpen}
        setModalOpen={setCreateModuleModalOpen}
        itens={unidadesAprendizagem}
        setItens={setUnidadesAprendizagem}
        cursoId={curso.id}
        onClose={() => setCreateModuleModalOpen(false)}
        closeButton={() => setCreateModuleModalOpen(false)}
      />

      <EditCursoModal
        id={curso.id}
        open={editCursoModalOpen}
        setModalOpen={setEditCursoModalOpen}
        item={curso}
        onClose={() => setEditCursoModalOpen(false)}
        closeButton={() => setEditCursoModalOpen(false)}
      />

      <DeleteModal
        open={deleteModalOpen}
        id={curso.id}
        color="#6F95FF"
        name="o curso"
        routeName="curso"
        onClose={() => setDeleteModalOpen(false)}
        closeButton={() => setDeleteModalOpen(false)}
      />

      <EditModuleModal
        id={editModule.id}
        open={editModuleModalOpen}
        setModalOpen={setEditModuleModalOpen}
        item={editModule}
        onClose={() => setEditModuleModalOpen(false)}
        closeButton={() => setEditModuleModalOpen(false)}
      />

      <DeleteModal
        open={deleteModuleModalOpen}
        id={editModule.id}
        color="#6F95FF"
        itens={unidadesAprendizagem}
        setItens={setUnidadesAprendizagem}
        name="o módulo"
        routeName="unidadeAprendizagem"
        onClose={() => setDeleteModuleModalOpen(false)}
        closeButton={() => setDeleteModuleModalOpen(false)}
      />

      <Breadcrumbs aria-label="breadcrumb">
        <div color="inherit">
          Listagem de Trilhas
        </div>

        <div color="inherit">
          Cursos da Trilha
        </div>

        <Typography color="textPrimary">
          Módulos do Curso
        </Typography>

       </Breadcrumbs>

      <DetailsPageComponent
        InputOnChange={(event: BaseSyntheticEvent) =>
          handleFilter(event.target.value)
        }
        buttonHeaderText="Opções"
        hasMenuButton
        menuButtonOptions={[
          {
            option: 'Adicionar Módulo',
            setModalOpen: setCreateModuleModalOpen,
          },
          {
            option: 'Alterar ordem',
            action: () =>
              history.push('/order', {
                FatherEntity: curso,
                HeaderTitle: 'Ordenar Módulos do Curso',
                Itens: unidadesAprendizagem,
                Type: 'UnidadeAprendizagem',
              }),
          },
        ]}
        hasCard={false}
        hasHeaderOnly
        setCreateModalOpen={setCreateModuleModalOpen}
        loading={loading}
        headerBack
        headerTitle="Trilha"
      >
        <>
          <CourseCard
            curso={curso}
            setEditModalOpen={setEditCursoModalOpen}
            setDeleteModalOpen={setDeleteModalOpen}
            qtdeFormados={formados}
          />
          <div className="admin-trails-container">
            <div style={{ width: '100%', marginTop: -10 }}>
              <h4 style={{ textAlign: 'center' }}>
                <span className="text-muted" style={{ fontSize: '16px' }}>
                  Módulos do curso:{' '}
                </span>
                {curso.nome}
              </h4>
            </div>
            {filteredUnidadesAprendizagem.length > 0 ? (
              filteredUnidadesAprendizagem
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((module, index) => (
                  <div key={module.id} className="admin-trails-card">
                    <div className="admin-trails-card-header">
                      <div
                        style={{
                          padding: 10,
                          width: '100%',
                        }}
                      >
                        <span
                          style={{
                            textAlign: 'start',
                            fontSize: 20,
                            fontWeight: 'bold',
                          }}
                          className="line-clamp"
                        >
                          {index + 1} - {module.titulo}
                        </span>
                      </div>
                    </div>

                    <div className="admin-trails-card-descriptions">
                      <span>{module.conteudo}</span>
                    </div>

                    <div className="admin-trails-card-footer">
                      <BlueTooltip
                        title={
                          module.possuiAvaliacao
                            ? 'Módulo já possui avaliação'
                            : 'Adicionar avaliação'
                        }
                        placement="bottom"
                      >
                        {!module.possuiAvaliacao ? (
                          <Link
                            className="link-footer-no-styled"
                            to={{
                              pathname: '/create/test',
                              state: {
                                unidadeId: module.id,
                                NomeCurso: curso.nome,
                              },
                            }}
                          >
                            <FiPlus
                              size={20}
                              color="#6f95ff"
                              style={{ cursor: 'pointer' }}
                            />
                          </Link>
                        ) : (
                          <div>
                            <FiCheck size={20} color="green" />
                          </div>
                        )}
                      </BlueTooltip>
                      <BlueTooltip title="Editar módulo" placement="bottom">
                        <div>
                          <FiEdit
                            size={20}
                            onClick={() => {
                              setEditModule(module);
                              setEditModuleModalOpen(true);
                            }}
                            color="#6f95ff"
                            style={{ cursor: 'pointer' }}
                          />
                        </div>
                      </BlueTooltip>
                      <BlueTooltip title="Deletar módulo" placement="bottom">
                        <div>
                          <FiTrash
                            size={20}
                            onClick={() => {
                              setEditModule(module);
                              setDeleteModuleModalOpen(true);
                            }}
                            color="#6f95ff"
                            style={{ cursor: 'pointer', marginRight: 15 }}
                          />
                        </div>
                      </BlueTooltip>

                      <Link
                        to={{
                          pathname: '/module_details',
                          state: { id: module.id },
                        }}
                      >
                        <span>Detalhes</span>
                      </Link>
                    </div>
                  </div>
                ))
            ) : (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <h2>Não foram encontrados itens</h2>
              </div>
            )}
            <div className="admin-trail-footer">
              <Pagination
                count={pageCount}
                page={page + 1}
                hideNextButton={pageCount < 2}
                hidePrevButton={pageCount < 2}
                onChange={(event: object, page_select: number) => {
                  setPage(page_select - 1);
                }}
              />
            </div>
          </div>
        </>
      </DetailsPageComponent>
    </>
  );
};

export default CourseDetailPage;
