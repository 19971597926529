import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Toasts } from './components/Toaster';
import AppProvider from './hooks';
import { Routes } from './routes';

import './styles/global.scss';

function App() {
  return (
    <>
      <AppProvider>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </AppProvider>

      <Toasts />
    </>
  );
}

export default App;
