import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { url } from 'inspector';
import { LinearProgress } from '@material-ui/core';
import { ModalProps } from '../../../../../interfaces/components';
import { useAuth } from '../../../../../hooks/Auth';
import { API } from '../../../../../services/api';
import {
  MateriaisComplementaresProps,
  ObjetosAprendizagemProps,
} from '../../../../../interfaces/Entidades';
import GetValidationErrors from '../../../../../utils/getValidationErros';
import ModalComponent from '../../../../../components/Modal/ModalComponent';
import Input from '../../../../components/Input';
import TextAreaInput from '../../../../components/TextAreaInput';
import { Button } from '../../../../../components/Button';
import SwitchInput from '../../../../components/InputSwitch';

interface CreateCursoModalProps extends ModalProps {
  aulaId: number;
  item: ObjetosAprendizagemProps;
}

interface MateriaisProps {
  titulo: string;
  urlConteudo: string;
  ordem: number;
}

export default function AdicionarMaterialModal({
  aulaId,
  item,
  open,
  onClose,
  closeButton,
  itens,
  setItens,
  setModalOpen,
}: CreateCursoModalProps) {
  const { token } = useAuth();
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const [hasUrlExternal, setHasUrlExternal] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [newMaterial, setNewMaterial] = useState<MateriaisProps>(
    {} as MateriaisProps,
  );

  async function handleUploadFile(e: ChangeEvent<HTMLInputElement>) {
    setUploadLoading(true);
    if (e.target.files) {
      const data = new FormData();
      data.append('resposta', e.target.files[0]);

      const nameFile = e.target?.files[0].name;

      if (nameFile !== null) {
        try {
          const response = await API(token).post(
            '/api/trilha/UploadFiles/MateriaisComplementares',
            data,
          );

          const material = {
            titulo: nameFile,
            urlConteudo: response.data.resposta,
            ordem: 0,
          };

          setNewMaterial(material);
          setUploadLoading(false);
        } catch (err) {
          toast.error('Ocorreu um erro no upload! 😢');
          setUploadLoading(false);
        }
      }
    }
  }

  const Submit = useCallback(
    async (data) => {
      setLoading(true);
      let material = {} as MateriaisProps;

      try {
        formRef.current?.setErrors({});
        if (hasUrlExternal) {
          const schema = Yup.object().shape({
            tituloMaterial: Yup.string().required('Campo Obrigatório'),
            urlConteudo: Yup.string().required('Campo Obrigatório'),
          });

          data.id = aulaId;
          data.titulo = item.titulo;
          data.conteudo = item.conteudo;
          data.unidadeAprendizagemId = item.unidadeAprendizagemId;
          data.urlVideo = item.urlVideo;

          material = {
            titulo: data.tituloMaterial,
            urlConteudo: data.urlConteudo,
            ordem: 0,
          };

          const newArrayMaterial: MateriaisComplementaresProps[] =
            itens.concat(material);

          await schema.validate(data, {
            abortEarly: false,
          });

          data.materiaisComplementares = newArrayMaterial;
        } else {
          data.id = aulaId;
          data.titulo = item.titulo;
          data.conteudo = item.conteudo;
          data.unidadeAprendizagemId = item.unidadeAprendizagemId;
          data.urlVideo = item.urlVideo;
          data.urlConteudo = newMaterial.urlConteudo;

          const newArrayMaterial: MateriaisComplementaresProps[] =
            itens.concat(newMaterial);

          material = newMaterial;

          data.materiaisComplementares = newArrayMaterial;
        }

        await API(token).put<ObjetosAprendizagemProps>(
          '/api/objetoAprendizagem',
          data,
        );

        toast.success('Material adicionado com sucesso!');
        setLoading(false);
        setModalOpen(false);
        setItens([...itens, material]);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = GetValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          toast.error('Houve um erro ao adicionar material, tente novamente!');
        }

        setLoading(false);
      }
    },

    [
      hasUrlExternal,
      setModalOpen,
      setItens,
      itens,
      aulaId,
      item.titulo,
      item.conteudo,
      item.unidadeAprendizagemId,
      item.urlVideo,
      newMaterial,
    ],
  );

  return (
    <ModalComponent
      closeButton={closeButton}
      open={open}
      onClose={onClose}
      width={1100}
    >
      <Form ref={formRef} placeholder="" className="createmodal-form-admin" onSubmit={Submit}>
        <h2 id="simple-modal-title">Materiais de Apoio</h2>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'baseline',
          }}
        >
          <span style={{ marginRight: 10, fontSize: 16 }}>Possui Vídeo</span>
          <SwitchInput
            name="ativo"
            defaultChecked={hasUrlExternal}
            onClick={() => setHasUrlExternal(!hasUrlExternal)}
          />
        </div>

        {hasUrlExternal ? (
          <>
            <Input
              name="tituloMaterial"
              maxLength={100}
              type="text"
              placeholder="Titulo do Material"
            />
            <Input name="urlConteudo" type="text" placeholder="Link Externo" />

            {uploadLoading ? (
              <LinearProgress
                style={{
                  width: '100%',
                  marginTop: 15,
                  height: 4,
                }}
              />
            ) : null}
          </>
        ) : (
          <>
            <Input
              name="urlConteudo"
              type="file"
              onChange={(event) => handleUploadFile(event)}
            />
            {uploadLoading ? (
              <LinearProgress
                style={{
                  width: '100%',
                  marginTop: 15,
                  height: 4,
                }}
              />
            ) : null}
          </>
        )}

        <Button
          color="#6F95FF"
          text="Confirmar"
          type="submit"
          loading={loading}
          disabled={uploadLoading}
        />
      </Form>
    </ModalComponent>
  );
}
