/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Skeleton } from '@material-ui/lab';
import CardInformation from './components/cardinformation';
import CardTitleAndLearn from './components/cardTitleAndLearn';
import CardLearningUnit from './components/cardlearningUnit';
import CardDescription from './components/cardDescription';
import { BackHeaderComponent } from '../../../../components/BackHeader';
import { useAuth } from '../../../../hooks/Auth';
import { API } from '../../../../services/api';
import {
  CompanyProps,
  MyTrailsProps,
  TrailProps,
  UnidadeAprendizagemProps,
} from '../../../../interfaces/Entidades';
import CarouselTrailCourse from './components/CarouselCourse';

interface ParamsProps {
  id: string;
}

export default function DetailsTrail() {
  const routeParams = useParams();
  const [trail, setTrail] = useState<TrailProps>({} as TrailProps);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { token } = useAuth();

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      const { id } = routeParams as ParamsProps;
      try {
        const response = await API(token).get(`/api/trilha/${id}`);
        const response_myTrails = await API(token).get<MyTrailsProps[]>(
          '/api/trilha/minhas',
        );

        const findSubscribe = response_myTrails.data.find(
          (trilha) => trilha.trilhaId === parseInt(id, 10),
        );

        if (findSubscribe) {
          setIsSubscribed(true);
        } else {
          setIsSubscribed(false);
        }
        setTrail(response.data);
        setIsLoading(false);
      } catch {
        toast.error(
          'Ocorreu um erro ao recuperar os detalhes da Trilha. Tente novamente! 😢',
        );
      }
    }

    getData();
  }, [token, routeParams]);

  return (
    <>
      <div className="row">
        <BackHeaderComponent />
      </div>
      <div className="row">
        <CardTitleAndLearn loading={isLoading} trilha={trail} />

        <CardInformation
          setIsSubscribed={setIsSubscribed}
          isSubscribed={isSubscribed}
          loading={isLoading}
          trilha={trail}
        />
      </div>
      <div className="row" style={{ marginTop: '20px' }}>
        <CardLearningUnit loading={isLoading} trilha={trail} />
      </div>
      {!isLoading && trail.cursos.length > 0 && (
        <div className="row">
          <div className="carrosel">
            <h1 className="titleRecommended">
              {isLoading ? '' : 'Cursos da trilha'}
            </h1>
            <CarouselTrailCourse loading={isLoading} cursos={trail.cursos} />
          </div>
        </div>
      )}
    </>
  );
}
