import axios, { AxiosInstance } from 'axios';

export function API_PUBLIC(): AxiosInstance {
  return axios.create({
    baseURL:
      process.env.REACT_APP_NODE_ENV === 'development'
        ? process.env.REACT_APP_API_LOCAL
        : process.env.REACT_APP_API_PROD,
  });
}
