/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {useState} from 'react';
import Logo from '../../../assets/images/apl.png';
import Modal from './modal';
import './styles.scss';

import CadastrarConta from './assets/cadastrarConta.gif';
import DeletarConta from './assets/deletarConta.gif';
import EditarConta from './assets/editarConta.gif';

import CadastrarOrganizacao from './assets/cadastrarOrganizacao.gif';
import DeletarOrgannizacao from './assets/deletarOrgannizacao.gif';
import EditarOrganizacao from './assets/editarOrganizacao.gif';

export default function Tutorials() {
  const [modalOpen, setModalOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [gif, setGif] = useState('');
  const [hasDescription, setHasDescription] = useState(false);
  const [description, setDescription] = useState('');

  const setValue = ((id, name, video, verifica, descricao) => {
    setModalOpen(true);
    setTitle(name)
    setGif(video)
    setHasDescription(verifica)
    setDescription(descricao)
  })

  const setModal = ((id, name, video, verifica, descricao) => {
    switch (id) {
      case 1:
        setValue(id, name, video, verifica, descricao);
        break;
      case 2:
        setValue(id, name, video, verifica, descricao);
        break;
      case 3:
        setValue(id, name, video, verifica, descricao);
        break;
      case 4:
        setValue(id, name, video, verifica, descricao);
        break;
      case 5:
        setValue(id, name, video, verifica, descricao);
        break;
      case 6:
        setValue(id, name, video, verifica, descricao);
        break;
      default:
        console.log(`Não foi encontrado o ID: ${id}`);
    }
  })

  return (
    <>
      <Modal
        open={modalOpen}
        gif={gif}
        title={title}
        hasDescription={hasDescription}
        description={description}
        setModalOpen={setModalOpen}
        onClose={() => setModalOpen(false)}
        closeButton={() => setModalOpen(false)}
      />
      <h1>Painel Cluster</h1>

      <div className="card-alert">
        <span>💡</span>
        <span style={{marginLeft: "8px", fontSize: "13px"}}>
        Bem-vindo à central de ajuda da APL SP play! Estamos aqui para responder às suas perguntas.
        Não consegue encontrar o que procura? Envie uma nota à nossa equipe de suporte
        em secretaria@apltimarilia.org.br
        </span>
      </div>

      <div style={{display: "grid", marginTop: '10px'}}>
        <a href="#organizacao" className="link-tutorials">Organização</a>
        <a href="#conta" className="link-tutorials">Conta Administrador da Organização</a>
      </div>

      <div id="organizacao">
        <h2 style={{marginTop: "32px"}}>Organização</h2>
        <div className="container-card" >
          <div className="card-tutorials" style={{marginLeft: 0}}>
            <a
            onClick={async () => {
              await setModal(1,'📗 Cadastrar Organização', `${CadastrarOrganizacao}`, true, 'Para o cadastro da organização é necessário o preenchimento de todos os campos, menos complemento que é opcional');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📗 Cadastrar Organização
              </div>
            </a>
          </div>
          <div className="card-tutorials" style={{marginLeft: 0}}>
            <a
            onClick={async () => {
              await setModal(2,'📗 Editar Organização', `${EditarOrganizacao}`, false, '');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📗 Editar Organização
              </div>
            </a>
          </div>
          <div className="card-tutorials" style={{marginLeft: 0}}>
            <a
            onClick={async () => {
              await setModal(3,'📗 Deletar Organização', `${DeletarOrgannizacao}`, false, '');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📗 Deletar Organização
              </div>
            </a>
          </div>
        </div>
      </div>

      <div id="conta">
        <h2 style={{marginTop: "32px"}}>Conta Administrador da Organização</h2>
        <div style={{display: "flex", marginTop: '10px', marginBottom: '10px'}}>
          <div className="highlighter" />
          <span style={{marginLeft: "8px", fontSize: "13px"}}>A conta administradora da organização será responsável por garantir o acesso da organização no sistema! 😉</span>
        </div>

        <div className="container-card" >
          <div className="card-tutorials" style={{marginLeft: 0}}>
            <a
            onClick={async () => {
              await setModal(4,'📗 Cadastrar Conta Administrador', `${CadastrarConta}`, true, 'Para o cadastro da conta administradora da organização é necessário o preenchimento de todos os campos');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📗 Cadastrar Conta Administrador
              </div>
            </a>
          </div>
          <div className="card-tutorials" style={{marginLeft: 0}}>
            <a
            onClick={async () => {
              await setModal(5,'📗 Editar Conta Administrador', `${EditarConta}`, false, '');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📗 Editar Conta Administrador
              </div>
            </a>
          </div>
          <div className="card-tutorials" style={{marginLeft: 0}}>
            <a
            onClick={async () => {
              await setModal(6,'📗 Deletar Conta Administrador', `${DeletarConta}`, false, '');
            }}
            >
              <img alt="APL SP PLAY" src={Logo} className="image"/>
              <div className="title-card">
                📗 Deletar Conta Administrador
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
