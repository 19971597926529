/* eslint-disable no-return-assign */
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';
import { format } from 'date-fns';
import { LinearProgress, TableCell, TableRow } from '@material-ui/core';
import { FiCheck } from 'react-icons/fi';
import { IoMdDownload } from 'react-icons/io';

import './styles.scss';

import CreateCorrecaoModal from './CreateCorrecaoModal';
import { useAuth } from '../../../hooks/Auth';
import {
  DesafioProps,
  RespostaDesafioProps,
} from '../../../interfaces/Entidades';
import TableComponent from '../../../components/Table';
import { BackHeaderComponent } from '../../../components/BackHeader';
import { API } from '../../../services/api';

interface StateProps {
  id: number;
}

export default function CorrigirDesafios() {
  const { token } = useAuth();
  const location = useLocation();
  const [desafio, setDesafio] = useState<DesafioProps>();
  const [respostas, setRespostas] = useState<RespostaDesafioProps[]>([]);
  const [loading, setLoading] = useState(false);
  const id = location.state as StateProps;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [correcaoModalOpen, setCorrecaoModalOpen] = useState(false);
  const [correcaoItem, setCorrecaoItem] = useState<RespostaDesafioProps>(
    {} as RespostaDesafioProps,
  );

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      setRespostas([]);

      try {
        const response = await API(token).get<DesafioProps>(
          `/api/desafio/${id.id}`,
        );

        await API(token)
          .get(`/api/desafio/respostas/?$filter=desafioId eq ${id.id}`)
          .then((res) => {
            setRespostas(res.data.value);
          });
        setDesafio(response.data);
        setLoading(false);
      } catch (err) {
        toast.error('Houve um erro ao carregar o desafio, tente novamente!');
        setLoading(false);
      }
    }
    loadData();
  }, [token, id]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      {desafio?.id && (
        <CreateCorrecaoModal
          item={correcaoItem}
          id={correcaoItem.id}
          setModalOpen={setCorrecaoModalOpen}
          open={correcaoModalOpen}
          onClose={() => setCorrecaoModalOpen(false)}
          closeButton={() => setCorrecaoModalOpen(false)}
        />
      )}
      <div className="Container">
        <BackHeaderComponent />
        <h1 style={{ marginBottom: 25 }}>Respostas enviadas</h1>
        <div className="card card-custom gutter-b flex-row">
          <div className="card-body d-flex flex-column">
            <strong style={{ color: '#5C65C0' }}>
              {loading ? (
                <Skeleton
                  variant="rect"
                  width="40%"
                  style={{ borderRadius: 3 }}
                />
              ) : (
                desafio?.titulo
              )}
            </strong>

            {loading ? (
              <Skeleton
                variant="rect"
                width="40%"
                style={{ borderRadius: 3, marginTop: 15 }}
              />
            ) : (
              <>
                <span style={{ fontWeight: 'bold' }}>Instruções</span>
                <p>{desafio?.conteudo}</p>
              </>
            )}
          </div>
        </div>
        <div className="cluster-table-container">
          <div className="cluster-table-card">
            {loading ? (
              <div>
                <LinearProgress />
              </div>
            ) : (
              <TableComponent
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                ItemLenght={respostas.length}
                TableHeadCells={[
                  'Nome',
                  'Desafio',
                  'Pontuação',
                  'Data de Correção',
                  'Ações',
                ]}
              >
                {respostas
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((resposta) => (
                    <TableRow key={resposta.id}>
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        className="cluster-table-body-font"
                      >
                        {resposta.profissional.nome}
                      </TableCell>
                      <TableCell
                        align="left"
                        className="cluster-table-body-font"
                      >
                        {resposta.desafio.titulo}
                      </TableCell>
                      <TableCell
                        align="left"
                        className="cluster-table-body-font"
                      >
                        {resposta.pontuacao}
                      </TableCell>
                      <TableCell
                        align="left"
                        className="cluster-table-body-font"
                      >
                        {resposta.dataCorrecao &&
                          format(
                            Date.parse(resposta.dataCorrecao),
                            'dd/MM/yyyy',
                          )}
                      </TableCell>
                      <TableCell
                        align="left"
                        className="cluster-table-body-action"
                      >
                        <FiCheck
                          onClick={async () => {
                            if (resposta !== undefined) {
                              await setCorrecaoItem(resposta);
                              await setCorrecaoModalOpen(true);
                            }
                          }}
                          size={25}
                          color="#80808F"
                          style={{ cursor: 'pointer' }}
                          className="cluster-table-svg"
                          onMouseOver={({ currentTarget }) =>
                            (currentTarget.style.color = '#5C65C0')
                          }
                          onMouseOut={({ currentTarget }) =>
                            (currentTarget.style.color = '#80808F')
                          }
                        />

                        <a
                          href={`${resposta.urlResposta}`}
                          target="_blank"
                          download
                          rel="noopener noreferrer"
                        >
                          <IoMdDownload
                            onMouseOver={({ currentTarget }) =>
                              (currentTarget.style.color = '#5C65C0')
                            }
                            onMouseOut={({ currentTarget }) =>
                              (currentTarget.style.color = '#80808F')
                            }
                            size={25}
                            color="#80808F"
                            style={{ marginLeft: 15, cursor: 'pointer' }}
                            className="cluster-table-svg"
                            type="download"
                          />
                        </a>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableComponent>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
