import React, { useCallback, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FiUser } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import toast from 'react-hot-toast';
import * as Yup from 'yup';

import { Button } from '../../../../../components/Button';
import { API } from '../../../../../services/api';
import GetValidationErrors from '../../../../../utils/getValidationErros';
import { useAuth } from '../../../../../hooks/Auth';
import Input from '../../../../components/Input';
import TextAreaInput from '../../../../components/TextAreaInput';
import ModalComponent from '../../../../../components/Modal/ModalComponent';
import { EditModalProps } from '../../../../../interfaces/components';

export default function EditModuleModal({
  onClose,
  id,
  closeButton,
  item,
  open,
  setModalOpen,
}: EditModalProps) {
  const { token } = useAuth();
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);

  const Submit = useCallback(
    async (data) => {
      setLoading(true);

      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          titulo: Yup.string().required('Campo Obrigatório'),
          conteudo: Yup.string().required('Campo Obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        data.id = id;
        data.cursoId = item.cursoId;
        data.ordem = item.ordem;
        data.possuiAvaliacao = item.possuiAvaliacao;

        await API(token).put('/api/unidadeAprendizagem', data);
        setLoading(false);

        toast.success('Módulo alterado com sucesso!');
        await setTimeout(() => {
          window.location.reload();
        }, 1000);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = GetValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          toast.error('Houve um erro ao alterar o módulo, tente novamente!');
        }

        setLoading(false);
      }
    },

    [token, id, item.cursoId, item.ordem],
  );

  return (
    <ModalComponent open={open} onClose={onClose} closeButton={closeButton}>
      <h2 id="simple-modal-title">Editar módulo</h2>
      <Form ref={formRef} placeholder="" className="createmodal-form-admin" onSubmit={Submit}>
        <Input
          name="titulo"
          maxLength={100}
          icon={FiUser}
          type="text"
          defaultValue={item.titulo}
          placeholder="Nome"
        />

        <TextAreaInput
          name="conteudo"
          maxLength={400}
          defaultValue={item.conteudo}
          style={{ height: 150, maxHeight: 200 }}
          placeholder="Descrição"
        />

        <Button
          color="#6F95FF"
          text="Confirmar"
          type="submit"
          loading={loading}
        />
      </Form>
    </ModalComponent>
  );
}
