/* eslint-disable no-param-reassign */
import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  ChangeEvent,
} from 'react';
import { Form } from '@unform/web';
import { FiImage, FiLock, FiMail, FiUser, FiCamera } from 'react-icons/fi';
import { RiLinkedinBoxLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import Input from '../../../../components/Input';
import { Button } from '../../../../components/Button';
import { EditModalProps } from '../../../../../interfaces/components';
import ModalComponent from '../../../../../components/Modal/ModalComponent';
import TextAreaInput from '../../../../components/TextAreaInput';
import AvatarImg from '../../../../../assets/images/no-image.png';

import Medal1 from '../../../../assets/images/medal/medal-1.svg';
import Medal2 from '../../../../assets/images/medal/medal-2.svg';
import Medal3 from '../../../../assets/images/medal/medal-3.svg';
import Medal4 from '../../../../assets/images/medal/medal-4.svg';
import Medal5 from '../../../../assets/images/medal/medal-5.svg';

export default function EditModalUserAdminCompany({
  id,
  onClose,
  closeButton,
  item,
  open,
  setModalOpen,
}: EditModalProps) {
  const [loading, setLoading] = useState(false);

  return (
    <ModalComponent closeButton={closeButton} open={open} onClose={onClose}>
      <h2 id="simple-modal-title">Classificações</h2>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            width: '16%',
            textAlign: 'center',
            marginRight: 5,
          }}
        >
          <img
            alt=""
            src={Medal1}
            style={{
              display: 'block',
              height: '120px',
              width: '100%',
            }}
          />
          <span>Nível 1</span>
        </div>
        <div
          style={{
            width: '16%',
            textAlign: 'center',
            marginRight: 5,
          }}
        >
          <img
            alt=""
            src={Medal2}
            style={{
              display: 'block',
              height: '120px',
              width: '100%',
            }}
          />
          <span>Nível 2</span>
        </div>
        <div
          style={{
            width: '16%',
            textAlign: 'center',
            marginRight: 5,
          }}
        >
          <img
            alt=""
            src={Medal3}
            style={{
              display: 'block',
              height: '120px',
              width: '100%',
            }}
          />
          <span>Nível 3</span>
        </div>
        <div
          style={{
            width: '16%',
            textAlign: 'center',
            marginRight: 5,
          }}
        >
          <img
            alt=""
            src={Medal4}
            style={{
              display: 'block',
              height: '120px',
              width: '100%',
            }}
          />
          <span>Nível 4</span>
        </div>
        <div
          style={{
            width: '16%',
            textAlign: 'center',
            marginRight: 5,
          }}
        >
          <img
            alt=""
            src={Medal5}
            style={{
              display: 'block',
              height: '120px',
              width: '100%',
            }}
          />
          <span>Nível 5</span>
        </div>
      </div>
      <div style={{ marginTop: '30px', width: '100%', textAlign: 'center' }}>
        <p>
          As conquistas estão relacionadas ao avanço do profissional nas
          trilhas. Conforme você completa as trilhas, você vai avançando nos
          níveis e, assim, ganhando medalhas informando a quantidade de
          experiência que possui. Para você ganhar mais pontos e ser chamado nas
          melhores organizações basta concluir trilhas, cursos e as provas de
          certificação. Fique ligado também nos desafios, eles dão experiência e
          podem ser um diferencial para você! 😉
          <br />
          Dica Extra: Procure uma{' '}
          <Link
            to="/search_trail"
            style={{ fontSize: '14px', color: '#005F6B' }}
          >
            Trilha
          </Link>{' '}
          e Edite seu{' '}
          <Link to="/profile" style={{ fontSize: '14px', color: '#005F6B' }}>
            perfil{' '}
          </Link>
          agora mesmo!
        </p>
      </div>
    </ModalComponent>
  );
}
