import React, { ReactNode } from 'react';

import './style.scss';

interface TooltipProps {
  title: string;
  children: ReactNode;
}

const Tooltip = ({ title, children }: TooltipProps): JSX.Element => (
  <div className="TooltipContainer">
    {children}
    <span>{title}</span>
  </div>
);

export default Tooltip;
