import React from 'react';
import { useHistory } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
import './styles.scss';

export function BackHeaderComponent() {
  const history = useHistory();
  return (
    <div className="admin-trail-header">
      <div
        role="none"
        className="admin-trailheader-divider"
        onClick={() => history.goBack()}
        onKeyDown={() => history.goBack()}
      >
        <FiArrowLeft size={20} />
        <span>Voltar</span>
      </div>
    </div>
  );
}
