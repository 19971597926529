import axios, { AxiosInstance } from 'axios';
import toast from 'react-hot-toast';

export function API(token?: string): AxiosInstance {
  const api = axios.create({
    baseURL:
      process.env.REACT_APP_NODE_ENV === 'development'
        ? process.env.REACT_APP_API_LOCAL
        : process.env.REACT_APP_API_PROD,
    headers: { Authorization: `Bearer ${token}` },
  });

  api.interceptors.request.use(async (config) => {
    const get_token = await localStorage.getItem('@APL:accessToken');

    // eslint-disable-next-line no-param-reassign
    if (config.headers) {
      config.headers.Authorization = `Bearer ${get_token}`;
    }

    return config;
  });

  api.interceptors.response.use(
    (response) => response,
    async (err) => {
      if (err.response && err.response.status === 401) {
        await localStorage.removeItem('@APL:accessToken');
        await localStorage.removeItem('@APL:user');
        toast.error(
          'Ocorreu um erro, você será redirecionado para o login! 😉',
          {
            position: 'top-right',
            style: {
              minWidth: 300,
              height: 50,
              color: '#fff',
              backgroundColor: '#3172b7',
            },
          },
        );
        await setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
      return Promise.reject(err);
    },
  );

  return api;
}
