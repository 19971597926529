import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { FiEdit, FiTrash, FiArrowLeft } from 'react-icons/fi';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { useLocation, useHistory } from 'react-router-dom';
import { Button } from '../../../components/Button';
import TextAreaInput from '../../components/TextAreaInput';

import './styles.scss';
import CreateQuestionModal from './components/CreateQuestionModal';
import {
  AvaliacaoProps,
  AvaliacaoQuestionProps,
} from '../../../interfaces/Entidades';
import { API } from '../../../services/api';
import { useAuth } from '../../../hooks/Auth';
import GetValidationErrors from '../../../utils/getValidationErros';
import { Error404 } from '../../../ErrorPages/404';

interface StateProps {
  unidadeId: number;
  NomeCurso: string;
  avaliacao?: AvaliacaoProps;
  editMode: boolean;
}

const CreateAvaliacaoPage = () => {
  const location = useLocation();
  const history = useHistory();
  const { token } = useAuth();
  const [modeId, setModeId] = useState(-1);
  const [evalDescricao, setEvalDescricao] = useState('');
  const [questions, setQuestions] = useState<AvaliacaoQuestionProps[]>([]);
  const [UnidadeId, setUnidadeId] = useState(0);
  const [nomeCurso, setNomeCurso] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasParams, setHasParams] = useState(false);
  const [createQuestionModalOpen, setCreateQuestionModalOpen] = useState(false);
  const formRef = useRef<FormHandles>(null);

  useEffect(() => {
    function loadParams() {
      if (location.state) {
        const {
          unidadeId,
          NomeCurso,
          editMode = false,
          avaliacao,
        } = location.state as StateProps;
        setUnidadeId(unidadeId);
        setNomeCurso(NomeCurso);
        if (editMode && avaliacao) {
          setModeId(avaliacao.id);
          setEvalDescricao(avaliacao.descricao);
          setQuestions(avaliacao.questoes);
        } else {
          setModeId(-1);
          setQuestions([]);
        }
        setHasParams(true);
      }
    }

    loadParams();
  }, [location.state]);

  const removeQuestion = useCallback(
    (conteudo) => {
      const questionsFiltered = questions.filter(
        (question) => question.conteudo !== conteudo,
      );

      setQuestions(questionsFiltered);
    },
    [questions],
  );

  const Submit = useCallback(
    async (data) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          descricao: Yup.string().required('Campo Obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (questions.length <= 0) {
          throw new Error('No questions');
        }

        if (modeId <= -1) {
          const dataToPost = {
            descricao: data.descricao,
            unidadeAprendizagemId: UnidadeId,
            questoes: questions,
          };
          const response = await API(token).post('/api/avaliacao', dataToPost);
          toast.success('Avaliação criada com sucesso!');
          history.push(`/test/${response.data.id}`);
        } else {
          const dataToPost = {
            descricao: data.descricao,
            unidadeAprendizagemId: UnidadeId,
            questoes: questions,
            id: modeId,
          };
          const response = await API(token).put('/api/avaliacao', dataToPost);
          toast.success('Avaliação alterada com sucesso!');
          history.push(`/test/${response.data.id}`);
        }

        setLoading(false);
      } catch (err: any) {
        if (err instanceof Yup.ValidationError) {
          const errors = GetValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else if (err.message === 'No questions') {
          toast.error('Não há questões!');
        } else {
          toast.error(
            'Houve um erro ao criar a avaliação, tente novamente! 😢',
          );
        }
        setLoading(false);
      }
    },
    [token, questions, UnidadeId, history, modeId],
  );

  if (!hasParams) {
    return <Error404 />;
  }

  return (
    <>
      <CreateQuestionModal
        setModalOpen={setCreateQuestionModalOpen}
        open={createQuestionModalOpen}
        itens={questions}
        setItens={setQuestions}
        onClose={() => setCreateQuestionModalOpen(false)}
        closeButton={() => setCreateQuestionModalOpen(false)}
      />

      <div className="row" style={{ padding: 10 }}>
        <div className="avaliacao-top">
          <div
            className="admin-trailheader-divider"
            onClick={() => history.goBack()}
          >
            <FiArrowLeft size={20} />
            <span>Voltar</span>
          </div>
        </div>
        <Form
          placeholder=""
          ref={formRef}
          className="create-avaliacao-form"
          onSubmit={(data) => Submit(data)}
        >
          <div className="avaliacao-header" style={{ maxHeight: 350 }}>
            <div className="create-avaliacao-divider">
              <h1>Prova de Certificação</h1>
              <Button
                type="submit"
                text="Salvar Alterações"
                color="#6F95FF"
                loading={loading}
              />
            </div>

            <div className="avaliacao-header-lines">
              <h4>Curso:</h4>
              <span>{nomeCurso}</span>
            </div>
            <div style={{ marginTop: 12 }}>
              <h4>Descrição da Prova:</h4>
              <TextAreaInput
                name="descricao"
                defaultValue={evalDescricao || ''}
                placeholder="Digite a descrição da prova..."
                maxLength={400}
                style={{ maxHeight: 120, height: 120 }}
              />
            </div>
          </div>
          <div className="create-questions-container">
            <div className="create-avaliacao-divider">
              <h2>Questões</h2>
              <Button
                type="button"
                text="Adicionar Questão"
                onClick={() => setCreateQuestionModalOpen(true)}
                color="#6F95FF"
              />
            </div>
          </div>
          {questions.map((question) => (
            <div key={question.conteudo} className="create-question-card">
              <div className="create-question-card-title">
                <span>{question.conteudo}</span>
              </div>
              <div className="question-card-alternatives">
                {question.alternativas.map((alternativa) => (
                  <div
                    key={alternativa.conteudo}
                    className={
                      alternativa.correta
                        ? 'question-input question-correct'
                        : 'question-input'
                    }
                  >
                    <input
                      type="radio"
                      name=""
                      id="a"
                      checked={alternativa.correta}
                      disabled
                    />
                    <label htmlFor="a">{alternativa.conteudo}</label>
                  </div>
                ))}
              </div>
              <div className="question-footer">
                <span>Peso: {question.peso}</span>
                <FiTrash
                  size={20}
                  color="#f53030"
                  onClick={() => removeQuestion(question.conteudo)}
                />
              </div>
            </div>
          ))}
        </Form>
      </div>
    </>
  );
};

export default CreateAvaliacaoPage;
