import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import toast from 'react-hot-toast';
import CardRecommendedTrail from './components/cardRecommendedtrail';
import CardWork from './components/cardWork';
import CardTitleCompany from './components/cardTitleCompany';
import CardSocial from './components/cardSocial';
import { useAuth } from '../../../../hooks/Auth';
import { API } from '../../../../services/api';
import { CompanyProps, TrailProps } from '../../../../interfaces/Entidades';
import { Error404 } from '../../../../ErrorPages/404';
import { BackHeaderComponent } from '../../../../components/BackHeader';

interface ParamsProps {
  id: number;
}

export default function DetailsCompany() {
  const { token } = useAuth();
  const routeParams = useParams();
  const [company, setCompany] = useState<CompanyProps>({} as CompanyProps);
  const [trilha, setTrilha] = useState<TrailProps[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      const { id } = routeParams as ParamsProps;
      try {
        const response_dados = await API(token).get(`api/organizacao/${id}`);
        const response_trilha = await API(token).get(
          `/api/trilha/search?$filter=organizacaoId eq ${id}`,
        );
        setCompany(response_dados.data);
        setTrilha(response_trilha.data.value);
        setLoading(false);
      } catch (err) {
        toast.error('Houve um erro ao carregar os dados da empresa! 😢');
      }
    }

    loadData();
  }, [token, routeParams]);
  if (!company) {
    return <Error404 />;
  }
  return (
    <>
      <div className="row">
        <BackHeaderComponent />
      </div>
      <div className="row">
        <CardTitleCompany loading={loading} company={company} />
      </div>
      <div className="row">
        <CardWork loading={loading} company={company} />
      </div>
      <div className="row">
        <div className="carrosel">
          <CardRecommendedTrail
            loading={loading}
            company={company}
            trilha={trilha}
          />
        </div>
      </div>
      <div className="row">
        <CardSocial loading={loading} company={company} />
      </div>
    </>
  );
}
