import React from 'react';

import './styles.scss';

export const Error404: React.FC = () => (
  <>
    <div id="main">
      <div className="fof">
        <h1 style={{ fontSize: '70px' }}>Erro 404</h1>
        <br />
        <span style={{ fontSize: '18px' }}>
          Desculpe não conseguimos encontrar essa página
        </span>
      </div>
    </div>
  </>
);
