import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import CreateModal from './CreateModal';

import ClustersTable from './clustersTable';
import { Button } from '../../../components/Button';

import { ClusterProps } from '../../../interfaces/Entidades';
import { API } from '../../../services/api';
import { useAuth } from '../../../hooks/Auth';

const ClusterPage = () => {
  const { token } = useAuth();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [clusters, setClusters] = useState<ClusterProps[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadCluster() {
      setLoading(true);
      setClusters([]);
      try {
        const response = await API(token).get('/api/cluster/odata');

        setClusters(response.data.value);
        setLoading(false);
      } catch (err) {
        toast.error(
          'Houve um problema ao listar os Clusters, tente novamente! 😢',
        );
      }
    }

    loadCluster();
  }, [token]);

  return (
    <div className="cluster-table-container">
      <CreateModal
        setModalOpen={setCreateModalOpen}
        itens={clusters}
        setItens={setClusters}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        closeButton={() => setCreateModalOpen(false)}
      />

      <div className="cluster-table-card">
        <div className="cluster-table-card-header">
          <h2 style={{ marginBottom: 30, color: '#802A00' }}>Clusters</h2>
          <Button
            color="#802A00"
            text="Adicionar Cluster"
            onClick={() => setCreateModalOpen(true)}
          />
        </div>
        <ClustersTable
          setClusters={setClusters}
          clusters={clusters}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default ClusterPage;
