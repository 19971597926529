/* eslint-disable no-param-reassign */
import React, { useCallback, useRef, useState } from 'react';
import { Form } from '@unform/web';
import {
  FiMail,
  FiUser,
} from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { AiOutlineIdcard } from 'react-icons/ai';
import { cpf } from 'cpf-cnpj-validator';
import { MdOutlineLocationCity } from 'react-icons/md';
import { useAuth } from '../../../../../hooks/Auth';
import Input from '../../../../components/Input';
import { API } from '../../../../../services/api';
import GetValidationErrors from '../../../../../utils/getValidationErros';
import MaskInput from '../../../../components/MaskInput';
import { EditModalProps } from '../../../../../interfaces/components';
import ModalComponent from '../../../../../components/Modal/ModalComponent';
import { Button } from '../../../../../components/Button';

export default function EditColaboradorProfissionalModal({
  onClose,
  closeButton,
  item,
  id,
  open,
  setModalOpen,
}: EditModalProps) {
  const { token, user } = useAuth();
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);

  const id_org = user.organizacao;

  const Submit = useCallback(
    async (data) => {
      setLoading(true);

      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          nome: Yup.string().required('Campo Obrigatório'),
          email: Yup.string().required('Campo Obrigatório').email(),
          cpf: Yup.string()
            .required('Campo obrigatório')
            .test('validacao-cpf', 'CPF Inválido', async (Cpf) => {
              if (Cpf === undefined) {
                return false;
              }
              const validation = cpf.isValid(Cpf);
              if (validation) {
                return true;
              }
              return false;
            }),
          bairro: Yup.string().required('Bairro obrigatório'),
          cidade: Yup.string().required('Cidade obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        data.id = id;
        data.aceitaColetaDados = true;
        data.organizacaoId = id_org;
        data.profissionalEmpresa = true;

        const response = await API(token).put('api/usuario/profissional', data);
        setLoading(false);
        await setTimeout(() => {
          window.location.reload();
        }, 1000);
        toast.success('Colaborador alterado com sucesso!');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = GetValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          toast.error(
            'Houve um erro ao alterar o colaborador, tente novamente!',
          );
        }

        setLoading(false);
      }
    },

    [token, id],
  );

  return (
    <ModalComponent closeButton={closeButton} open={open} onClose={onClose}>
      <Form ref={formRef} placeholder="" className="createmodal-form-admin" onSubmit={Submit}>
        <h2 id="simple-modal-title">Editar colaborador</h2>
        <Input
          type="text"
          maxLength={80}
          name="nome"
          defaultValue={item.nome}
          icon={FiUser}
          placeholder="Nome"
        />

        <Input
          type="text"
          maxLength={80}
          name="email"
          defaultValue={item.email}
          icon={FiMail}
          placeholder="Email"
        />

        <Input
          type="text"
          maxLength={80}
          name="cidade"
          defaultValue={item.cidade}
          icon={MdOutlineLocationCity}
          placeholder="Cidade"
        />

        <Input
          type="text"
          maxLength={80}
          name="bairro"
          defaultValue={item.bairro}
          icon={MdOutlineLocationCity}
          placeholder="Bairro"
        />

        <MaskInput
          mask="999.999.999-99"
          type="text"
          name="cpf"
          defaultValue={item.cpf}
          placeholder="Cpf"
          icon={AiOutlineIdcard}
        />

        <Button
          color="#6F95FF"
          text="Confirmar"
          type="submit"
          loading={loading}
        />
      </Form>
    </ModalComponent>
  );
}
