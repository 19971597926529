import { CircularProgress } from '@material-ui/core';

import React, { ButtonHTMLAttributes } from 'react';

import './styles.scss';

interface buttonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  color: string;
  loading?: boolean;
  height?: string | number;
  width?: string | number;
}

export function Button({
  type,
  text,
  color,
  height,
  width,
  loading = false,
  ...props
}: buttonProps) {
  if (loading) {
    return (
      <button
        type={type}
        style={{ backgroundColor: color, height, width }}
        disabled
        className="CustomButtonComponent"
        {...props}
      >
        <CircularProgress color="inherit" size={20} />
      </button>
    );
  }

  return (
    <button
      type={type}
      style={{ backgroundColor: color, height, width }}
      className="CustomButtonComponent"
      {...props}
    >
      {text}
    </button>
  );
}
