/* eslint-disable no-return-assign */
import {
  LinearProgress,
  TableCell,
  TableRow,
  Tooltip,
  TableContainer,
  TableSortLabel,
  Table,
  TableBody,
  TableHead,
  TablePagination,
} from '@material-ui/core';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import {
  FiArrowUp,
  FiArrowUpCircle,
  FiNavigation,
  FiSearch,
} from 'react-icons/fi';
import toast from 'react-hot-toast';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import TableComponent from '../../../components/Table';

import './styles.scss';
import { BackHeaderComponent } from '../../../components/BackHeader';
import { API } from '../../../services/api';
import { useAuth } from '../../../hooks/Auth';
import { TrilhaInscricoes } from '../../../interfaces/Entidades';

interface StateProps {
  id: number;
}

const BlueTooltip = withStyles({
  tooltip: {
    fontSize: 12,
    color: '#fff',
    backgroundColor: '#6F95FF',
  },
})(Tooltip);

export function TrailDashboardPage() {
  const params = useParams();
  const { token } = useAuth();
  const [page, setPage] = useState(0);
  const [activeLabel, setActiveLabel] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(true);
  const [inscritos, setInscritos] = useState<TrilhaInscricoes[]>([]);
  const [filteredInscritos, setfilteredInscritos] = useState<
    TrilhaInscricoes[]
  >([]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      try {
        const { id } = params as StateProps;
        const response = await API(token).get('/api/trilha/inscricoes', {
          params: { $filter: `trilhaId eq ${id}`, $orderBy: 'progresso desc' },
        });

        setInscritos(response.data.value);
        setfilteredInscritos(response.data.value);
        setLoading(false);
      } catch (err) {
        toast.error('Houve um erro ao carregar os dados, tente novamente! 😢');
      }
    }

    loadData();
  }, [token, params]);

  const handleInputFilter = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const filteredArray = inscritos.filter((inscrito) =>
        inscrito.profissional.nome
          .toUpperCase()
          .includes(event.target.value.toUpperCase()),
      );
      if (filteredArray.length > 0) {
        setfilteredInscritos([...filteredArray]);
      } else if (event.target.value === '') {
        setfilteredInscritos([...inscritos]);
      } else {
        setfilteredInscritos([]);
      }

      setPage(0);
    },
    [inscritos],
  );

  const handleFilter = useCallback(
    (columnName, orderBy) => {
      let newSortOrder = orderBy;
      if (columnName === activeLabel) {
        newSortOrder = orderBy === 'asc' ? 'desc' : 'asc';
        setSortOrder(newSortOrder);
      }

      setActiveLabel(columnName);
      filteredInscritos.sort((a, b) => {
        if (a.progresso < b.progresso) {
          return newSortOrder === 'asc' ? -1 : 1;
        }
        if (a.progresso > b.progresso) {
          return newSortOrder === 'asc' ? 1 : -1;
        }
        return 0;
      });
    },
    [filteredInscritos, activeLabel],
  );

  if (loading) {
    return (
      <div style={{ height: 60, width: '100%' }}>
        <BackHeaderComponent />
        <LinearProgress color="primary" />
      </div>
    );
  }

  return (
    <>

      <Breadcrumbs aria-label="breadcrumb">
        <div color="inherit">
          Listagem de Trilhas
        </div>

        <div color="inherit">
        Cursos da Trilha
        </div>

          <Typography color="textPrimary">
            {filteredInscritos[0].trilha.nome}
          </Typography>

       </Breadcrumbs>

      <BackHeaderComponent />

      <div
        style={{
          alignSelf: 'flex-start',
          display: 'flex',
          justifyContent: 'flex-start',
        }}
      >
        <h1
          style={{
            width: '100%',
            marginLeft: '20px',
          }}
        >
          Profissionais Inscritos na Trilha
        </h1>
        <div
          style={{
            width: '100%',
            alignSelf: 'flex-end',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            backgroundColor: 'transparent',
            height: 40,
          }}
        >
          <div
            style={{
              width: 240,
              alignSelf: 'flex-end',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              backgroundColor: '#fff',
              height: 40,
              padding: 10,
              borderRadius: 10,
            }}
          >
            <input
              type="text"
              style={{
                flex: 1,
                border: 'none',
                outline: 'none',
                padding: 10,
                backgroundColor: 'transparent',
              }}
              onChange={(text) => handleInputFilter(text)}
            />

            <FiSearch size={20} color="black" />
          </div>
        </div>
      </div>
      <div className="TrailDashboardContainer">
        <div className="cluster-table-container">
          <div className="cluster-table-card">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left" className="trail-table-head-font">
                      Nome
                    </TableCell>
                    <TableCell align="left" className="trail-table-head-font">
                      <TableSortLabel
                        active={activeLabel === 'Progresso'}
                        direction={sortOrder === 'asc' ? 'asc' : 'desc'}
                        onClick={() => handleFilter('Progresso', sortOrder)}
                      >
                        Progresso
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="left" className="trail-table-head-font">
                      Ações
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredInscritos.length > 0 &&
                    filteredInscritos
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage,
                      )
                      .map((inscrito) => (
                        <TableRow key={inscrito.profissionalId}>
                          <TableCell
                            component="th"
                            scope="row"
                            align="left"
                            className="cluster-table-body-font "
                          >
                            {inscrito.profissional.nome}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="left"
                            className="cluster-table-body-font"
                          >
                            {(inscrito.progresso * 100).toFixed(2)}%
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="left"
                            className="cluster-table-body-font"
                          >
                            <BlueTooltip
                              title="Visualizar profissional"
                              placement="bottom"
                            >
                              <Link
                                className="link-footer-no-styled"
                                to={{
                                  pathname: `/profissional/${inscrito.profissionalId}`,
                                }}
                              >
                                <FiNavigation
                                  size={18}
                                  color="#80808F"
                                  style={{ cursor: 'pointer' }}
                                  className="cluster-table-svg"
                                  onMouseOver={({ currentTarget }) =>
                                    (currentTarget.style.color = '#6F95FF')
                                  }
                                  onMouseOut={({ currentTarget }) =>
                                    (currentTarget.style.color = '#80808F')
                                  }
                                />
                              </Link>
                            </BlueTooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                  {filteredInscritos.length <= 0 && (
                    <h2 style={{ textAlign: 'center' }}>
                      Profissional não encontrado
                    </h2>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={inscritos.length || 0}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count}`
              }
              labelRowsPerPage="Linhas"
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
    </>
  );
}
