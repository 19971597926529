/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { HiOutlineMail } from 'react-icons/hi';
import { AiFillLinkedin } from 'react-icons/ai';
import { MdModeEdit } from 'react-icons/md';
import { Skeleton } from '@material-ui/lab';
import AvatarImg from '../../../../assets/images/no-image.png';
import EditColaboradorModal from './EditModal';

import Medal1 from '../../../assets/images/medal/medal-1.svg';
import Medal2 from '../../../assets/images/medal/medal-2.svg';
import Medal3 from '../../../assets/images/medal/medal-3.svg';
import Medal4 from '../../../assets/images/medal/medal-4.svg';
import Medal5 from '../../../assets/images/medal/medal-5.svg';

import {
  UserProfissionalProps,
  Dashboard,
} from '../../../../interfaces/Entidades';

interface cardProfile {
  loading: boolean;
  professional: UserProfissionalProps;
  editModalOpen: boolean;
  setEditModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  dashboard: Dashboard;
}

export default function cardProfile({
  loading,
  professional,
  editModalOpen,
  setEditModalOpen,
  dashboard,
}: cardProfile) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const medalRef = useRef<HTMLImageElement>(null);
  const handleMedalDashboard = () => {
    if (dashboard.nivel) {
      if (dashboard.nivel === 1) {
        if (medalRef.current) {
          medalRef.current.src = Medal1;
        }
      }
      if (dashboard.nivel === 2) {
        if (medalRef.current) {
          medalRef.current.src = Medal2;
        }
      }
      if (dashboard.nivel === 3) {
        if (medalRef.current) {
          medalRef.current.src = Medal3;
        }
      }
      if (dashboard.nivel === 4) {
        if (medalRef.current) {
          medalRef.current.src = Medal4;
        }
      }
      if (dashboard.nivel === 5) {
        if (medalRef.current) {
          medalRef.current.src = Medal5;
        }
      }
    } else if (medalRef.current) {
      medalRef.current.src = Medal1;
    }
  };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    async function loadData() {
      handleMedalDashboard();
    }
    loadData();
  }, [handleMedalDashboard]);

  return (
    <>
      <EditColaboradorModal
        id={professional.id}
        item={professional}
        open={editModalOpen}
        setModalOpen={setEditModalOpen}
        onClose={() => setEditModalOpen(false)}
        closeButton={() => setEditModalOpen(false)}
      />
      <div className="col-xl-12">
        <div className="card card-custom gutter-b">
          <div className="card-body">
            <div className="d-flex mb-9">
              <div className="flex-shrink-0 mr-7 mt-lg-0 mt-3">
                {loading ? (
                  <Skeleton
                    variant="text"
                    width="100px"
                    height="217px"
                    style={{ borderRadius: 3, marginTop: -43 }}
                  />
                ) : (
                  <div className="symbol symbol-50 symbol-lg-120">
                    <img
                      style={{ objectFit: 'cover' }}
                      src={professional.foto ? professional.foto : AvatarImg}
                      alt="Foto Profissional"
                    />
                  </div>
                )}
              </div>

              <div className="flex-grow-1">
                <div className="d-flex justify-content-between flex-wrap mt-1">
                  <div className="d-flex mr-3">
                    <span className="text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3">
                      {loading ? (
                        <Skeleton variant="text" width="200px" height={30} />
                      ) : (
                        professional.nome
                      )}
                    </span>
                    <Link to="/">
                      <i className="flaticon2-correct text-success font-size-h5" />
                    </Link>
                  </div>
                  <div className="my-lg-0 my-3">
                    {loading ? (
                      <Skeleton variant="text" width="0px" height={0} />
                    ) : (
                      <MdModeEdit
                        size="25"
                        style={{ cursor: 'pointer' }}
                        color="#005F6B"
                        onClick={async () => {
                          await setEditModalOpen(true);
                        }}
                      />
                    )}
                  </div>
                </div>

                <div className="d-flex flex-wrap justify-content-between mt-1">
                  <div className="d-flex flex-column flex-grow-1 pr-8">
                    <div className="d-flex flex-wrap mb-4">
                      {loading ? (
                        <Skeleton variant="text" width="0px" height={0} />
                      ) : (
                        <HiOutlineMail
                          className="text-dark-50 text-hover-primary font-weight-bold "
                          size="20"
                        />
                      )}
                      <a
                        href={`mailto:${professional.email}`}
                        className="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                        style={{ cursor: 'pointer' }}
                      >
                        {loading ? (
                          <Skeleton variant="text" width="100px" height={25} />
                        ) : (
                          professional.email
                        )}
                      </a>

                      {!professional.linkedIn ? (
                        <></>
                      ) : (
                        <div>
                          {loading ? (
                            <Skeleton variant="text" width="0px" height={0} />
                          ) : (
                            <>
                              <AiFillLinkedin
                                className="text-dark-50 text-hover-primary font-weight-bold "
                                size="20"
                              />
                              <a
                                target="_blank"
                                href={professional.linkedIn}
                                className="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                                style={{ cursor: 'pointer' }}
                                rel="noreferrer"
                              >
                                {professional.linkedIn.split('//')[1]}
                              </a>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                    <span className="font-weight-bold text-dark-50">
                      {loading ? (
                        <Skeleton
                          variant="text"
                          width="100%"
                          height={100}
                          style={{ marginTop: '-20px' }}
                        />
                      ) : (
                        professional.descricao
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {loading ? (
              <Skeleton variant="text" width="0" height={0} />
            ) : (
              <>
                <div className="separator separator-solid mobile-profile" />

                <div className="d-flex align-items-center flex-wrap mt-8 mobile-profile">
                  <div className="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                    <div className="d-flex flex-column text-dark-75">
                      <span className="font-weight-bolder font-size-h6">
                        Trilhas Concluídas
                      </span>
                      <span
                        className="font-weight-bolder font-size-h3"
                        style={{ textAlign: 'center' }}
                      >
                        {dashboard.trilhasAssistidas}
                      </span>
                    </div>
                  </div>

                  <div className="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                    <div className="d-flex flex-column text-dark-75">
                      <span className="font-weight-bolder font-size-h6">
                        Cursos Concluídos
                      </span>
                      <span
                        className="font-weight-bolder font-size-h3"
                        style={{ textAlign: 'center' }}
                      >
                        {dashboard.cursosAssistidos}
                      </span>
                    </div>
                  </div>

                  <div className="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                    <div className="d-flex flex-column text-dark-75">
                      <span className="font-weight-bolder font-size-h6">
                        Aulas Concluídas
                      </span>
                      <span
                        className="font-weight-bolder font-size-h3"
                        style={{ textAlign: 'center' }}
                      >
                        {dashboard.objetosAssistidos}
                      </span>
                    </div>
                  </div>

                  <div className="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                    <div className="d-flex flex-column text-dark-75">
                      <span className="font-weight-bolder font-size-h6">
                        Desafios Concluídos
                      </span>
                      <span
                        className="font-weight-bolder font-size-h3"
                        style={{ textAlign: 'center' }}
                      >
                        {dashboard.desafiosRespondidos}
                      </span>
                    </div>
                  </div>

                  <div className="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                    <div className="d-flex flex-column text-dark-75">
                      <span className="font-weight-bolder font-size-h6">
                        Avaliações Respondidas
                      </span>
                      <span
                        className="font-weight-bolder font-size-h3"
                        style={{ textAlign: 'center' }}
                      >
                        {dashboard.avaliacoesRespondidas}
                      </span>
                    </div>
                  </div>

                  <div className="d-flex align-items-center flex-lg-fill mb-2 float-left">
                    <span className="mr-4">
                      <i className="flaticon-network display-4 text-muted font-weight-bold" />
                    </span>
                    <div className="symbol-group symbol-hover">
                      <div
                        style={{
                          alignItems: 'center',
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                        }}
                        className="symbol symbol-100 symbol-circle"
                        data-toggle="tooltip"
                        title="Mark Stone"
                      >
                        <img alt="" ref={medalRef} />
                        <span className="font-weight-bolder font-size-h5">
                          Nivel {dashboard.nivel}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
