/* eslint-disable react/react-in-jsx-scope */
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { Skeleton } from '@material-ui/lab';
import AvatarImg from '../../../../../assets/images/no-image.png';
import { CompanyProps, TrailProps } from '../../../../../interfaces/Entidades';

interface CardRecommendedTrail {
  company: CompanyProps;
  loading: boolean;
  trilha: TrailProps[];
}

export default function CardRecommendedTrail({
  company,
  loading,
  trilha,
}: CardRecommendedTrail) {
  const itensDesktop = () => {
    if (trilha) {
      if (trilha.length === 1) {
        return 2;
      }
      if (trilha.length === 2) {
        return 3;
      }
      if (trilha.length === 3) {
        return 4;
      }
      if (trilha.length >= 4) {
        return 3;
      }
      return 0;
    }
    return 0;
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: itensDesktop(),
      paritialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      paritialVisibilityGutter: 30,
    },
  };
  if (trilha.length === 0) {
    return <></>;
  }
  return (
    <>
      {loading ? (
        <Skeleton variant="text" width={0} height="0%" />
      ) : (
        <h1 className="titleRecommended">Trilhas da Empresa</h1>
      )}

      <Carousel
        ssr
        partialVisible
        responsive={responsive}
        itemClass="item-class"
      >
        {trilha.map((trail) => (
          <div
            className="card card-custom card-stretch gutter-b"
            style={{
              cursor: 'pointer',
            }}
          >
            <div className="card-body d-flex flex-column">
              <div className="flex-grow-1 pb-5">
                <div className="d-flex align-items-center pr-2 mb-6">
                  <div className="symbol symbol-60">
                    <img
                      src={trail.urlImagem ? trail.urlImagem : AvatarImg}
                      alt="Foto da Trilha"
                      style={{
                        objectFit: 'cover',
                      }}
                    />
                  </div>
                  <span
                    className="font-weight-bolder font-size-h5 color font-weight-bolder line-clamp-title"
                    style={{ marginLeft: '5px' }}
                  >
                    {loading ? (
                      <Skeleton variant="text" width={200} height="100%" />
                    ) : (
                      trail.nome
                    )}
                  </span>
                </div>
                <div className="font-weight-bolder text-muted mt-2">
                  {loading ? (
                    <Skeleton variant="text" width={50} height="100%" />
                  ) : (
                    trail.organizacao.razaoSocial
                  )}
                </div>

                <p className="text-dark-75 font-size-sm  font-size-base m-0 text-muted line-clamp">
                  {loading ? (
                    <Skeleton variant="text" width={200} height="100%" />
                  ) : (
                    trail.organizacao.descricao
                  )}
                </p>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </>
  );
}
