/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-param-reassign */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Form } from '@unform/web';
import { FiMinus, FiPlus, FiType } from 'react-icons/fi';
import { FaWeightHanging } from 'react-icons/fa';
import { FormHandles } from '@unform/core';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import Input from '../../../../components/Input';
import GetValidationErrors from '../../../../../utils/getValidationErros';
import { ModalProps } from '../../../../../interfaces/components';
import ModalComponent from '../../../../../components/Modal/ModalComponent';
import { Button } from '../../../../../components/Button';
import InputRadio from '../../../../components/InputRadio';

import './styles.scss';
import {
  AvaliacaoAlternativasProps,
  AvaliacaoQuestionProps,
} from '../../../../../interfaces/Entidades';
import TextAreaInput from '../../../../components/TextAreaInput';

interface AlternativaProps {
  id: string;
  label: string;
  value: string;
}

export default function CreateQuestionModal({
  onClose,
  closeButton,
  itens,
  open,
  setModalOpen,
  setItens,
}: ModalProps) {
  const [alternativas, setAlternativas] = useState<AlternativaProps[]>([
    { id: '0', label: '1', value: '0' },
    { id: '1', label: '2', value: '1' },
  ]);
  const formRef = useRef<FormHandles>(null);
  const identificacao = useMemo(() => ['a', 'b', 'c', 'd', 'e'], []);

  useEffect(() => {
    function setAlternativasDefault() {
      setAlternativas([
        { id: '0', label: '1', value: '0' },
        { id: '1', label: '2', value: '1' },
      ]);
    }

    setAlternativasDefault();
  }, [open]);

  const Submit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          conteudo: Yup.string().required('Campo Obrigatório'),
          peso: Yup.string().required('Necessário ter um peso'),
          correta: Yup.string().required('Campo Obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const dataToSet: AvaliacaoQuestionProps = {
          ordem: 1,
          peso: parseInt(data.peso, 10),
          conteudo: data.conteudo,
          alternativas: data.alternativas.map(
            (conteudo: string, index: number) => {
              if (index.toString() === data.correta) {
                return {
                  identificacao: identificacao[index],
                  conteudo,
                  correta: true,
                } as AvaliacaoAlternativasProps;
              }

              return {
                identificacao: identificacao[index],
                conteudo,
              } as AvaliacaoAlternativasProps;
            },
          ),
        };

        setItens([...itens, dataToSet]);
        setModalOpen(false);
        toast.success('Questão adicionada com sucesso!');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = GetValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          toast.error(
            'Houve um erro ao adicionar a questão, tente novamente! 😢',
          );
        }
      }
    },
    [setItens, itens, setModalOpen, identificacao],
  );

  const addQuestion = useCallback(() => {
    const nextValue =
      parseInt(alternativas[alternativas.length - 1].id, 10) + 1;

    setAlternativas([
      ...alternativas,
      {
        id: nextValue.toString(),
        label: (nextValue + 1).toString(),
        value: nextValue.toString(),
      },
    ]);
  }, [alternativas]);

  const removeQuestion = useCallback(() => {
    setAlternativas(
      alternativas.filter(
        (alternativa) =>
          alternativa.id !== alternativas[alternativas.length - 1].id,
      ),
    );
  }, [alternativas]);

  return (
    <ModalComponent closeButton={closeButton} open={open} onClose={onClose}>
      <Form ref={formRef} placeholder="" initialData={{ correta: 1 }} onSubmit={Submit}>
        <h2>Adicionar Questão</h2>

        <TextAreaInput
          type="text"
          maxLength={500}
          name="conteudo"
          placeholder="Titulo da questão"
          style={{ maxHeight: 180, height: 120, marginBottom: 10 }}
        />

        <Input
          type="number"
          min={1}
          max={5}
          name="peso"
          defaultValue={1}
          icon={FaWeightHanging}
          placeholder="Peso"
        />

        <h5 style={{ marginTop: 15 }}>Alternativas</h5>

        <div className="create-question-modal-radio-divider ">
          <span>Selecionar correta:</span>
          <InputRadio
            style={{ height: 40 }}
            options={alternativas}
            name="correta"
          />
        </div>

        {alternativas.map((alternativa, index) => (
          <div
            key={alternativa.id}
            className="create-question-modal-input-divider"
          >
            <Input
              type="text"
              maxLength={80}
              required
              name={`alternativas.${alternativa.id}`}
              icon={FiType}
              placeholder="Titulo da alternativa"
            />
            {index === alternativas.length - 1 &&
              alternativas.length - 1 !== 1 && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flex: 1,
                  }}
                >
                  <FiMinus
                    size={20}
                    color="red"
                    style={{ cursor: 'pointer' }}
                    onClick={removeQuestion}
                  />
                </div>
              )}
          </div>
        ))}

        {alternativas.length < 5 && (
          <div className="AddMoreQuestionDiv" onClick={addQuestion}>
            <FiPlus size={23} />
          </div>
        )}

        <Button color="#6F95FF" text="Confirmar" type="submit" />
      </Form>
    </ModalComponent>
  );
}
