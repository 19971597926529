/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Form } from '@unform/web';
import toast from 'react-hot-toast';
import { LinearProgress } from '@material-ui/core';
import { format } from 'date-fns';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { API } from '../../../services/api';
import { useAuth } from '../../../hooks/Auth';
import { AdminConfigProps } from '../../../interfaces/ConfigProps';
import Input from '../../components/Input';
import { Button } from '../../../components/Button';

import './styles.scss';
import GetValidationErrors from '../../../utils/getValidationErros';

const ConfigAdminPage = () => {
  const formRef = useRef<FormHandles>(null);
  const { token } = useAuth();
  const [config, setConfig] = useState<AdminConfigProps>(
    {} as AdminConfigProps,
  );
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);

  const Submit = useCallback(
    async (data) => {
      try {
        setButtonLoading(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          pontuacaoDesafio: Yup.string().required('Campo Obrigatório'),
          pontuacaoAvaliacao: Yup.string().required('Campo Obrigatório'),
          pontuacaoTrilhaConcluida: Yup.string().required('Campo Obrigatório'),
          pontuacaoCursoConcluido: Yup.string().required('Campo Obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        await API(token).put('/api/configuracao', data);
        toast.success('Configurações alteradas com sucesso! 😄', {
          duration: 3000,
        });

        await setTimeout(() => {
          window.location.reload();
        }, 1000);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = GetValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          toast.error(
            'Houve um erro ao alterar as configurações, por favor tente novamente! 😢',
          );
        }
      } finally {
        setButtonLoading(false);
      }
    },
    [token],
  );

  useEffect(() => {
    async function loadConfig() {
      setLoading(true);
      try {
        const response = await API(token).get('/api/configuracao');

        setConfig(response.data);
        setLoading(false);
      } catch (err) {
        toast.error(
          'Houve um problema ao carregar as configurações, tente novamente! 😢',
        );
      }
    }

    loadConfig();
  }, [token]);

  return (
    <div className="ConfigPageAdminContainer">
      <div className="ConfigPageAdminHeader">
        <h2 style={{ color: '#802A00' }}>Configurações do sistema</h2>
        {loading ? (
          <></>
        ) : (
          <Button
            text="Salvar alterações"
            type="submit"
            form="config-form"
            color="#802A00"
            loading={buttonLoading}
          />
        )}
      </div>
      {loading ? (
        <LinearProgress style={{ width: '100%', margin: 10 }} />
      ) : (
        <>
          <Form
            ref={formRef}
            placeholder=""
            onSubmit={Submit}
            id="config-form"
            className="ConfigPageAdminForm"
          >
            <div className="ConfigInputContainer">
              <label htmlFor="pontuacaoDesafio">
                Pontos por Desafio enviado:
              </label>
              <Input
                name="pontuacaoDesafio"
                id="pontuacaoDesafio"
                defaultValue={config.pontuacaoDesafio || 0}
              />
            </div>

            <div className="ConfigInputContainer">
              <label htmlFor="pontuacaoAvaliacao">
                Pontos por Avaliação respondida:
              </label>
              <Input
                name="pontuacaoAvaliacao"
                id="pontuacaoAvaliacao"
                defaultValue={config.pontuacaoAvaliacao || 0}
              />
            </div>

            <div className="ConfigInputContainer">
              <label htmlFor="pontuacaoTrilhaConcluida">
                Pontos por Trilha concluída:
              </label>
              <Input
                name="pontuacaoTrilhaConcluida"
                id="pontuacaoTrilhaConcluida"
                defaultValue={config.pontuacaoTrilhaConcluida || 0}
              />
            </div>

            <div className="ConfigInputContainer">
              <label htmlFor="pontuacaoCursoConcluido">
                Pontos por Curso concluído:
              </label>
              <Input
                name="pontuacaoCursoConcluido"
                id="pontuacaoCursoConcluido"
                defaultValue={config.pontuacaoCursoConcluido || 0}
              />
            </div>
          </Form>
          <div className="ConfigPageAdminFooter">
            <span>
              {config.updatedAt
                ? `Atualizado em ${format(
                    Date.parse(config.updatedAt),
                    'dd/MM/yyyy',
                  )}`
                : 'Não atualizado'}
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default ConfigAdminPage;
