import React, { useEffect, useRef } from 'react';
import { format } from 'date-fns';
import { UserAprovadasProps } from '../../../../interfaces/Entidades';
import AvatarImg from '../../../assets/images/no-image.png';
import { API } from '../../../../services/api';
import { useAuth } from '../../../../hooks/Auth';

interface CardCertificate {
  loading: boolean;
  trilha: UserAprovadasProps[];
}

export default function CardCertificate({ loading, trilha }: CardCertificate) {
  const { token } = useAuth();

  useEffect(() => {
    async function teste() {
      const response = await API(token).get(
        'https://apl-web-api.azurewebsites.net/api/trilha/certificado/19',
      );
    }

    teste();
  }, [token]);

  if (loading) {
    return <></>;
  }
  return (
    <>
      <div className="col-lg-12">
        <div
          className="card card-custom card-stretch gutter-b"
          style={{
            height: '100%',
            overflowY: 'scroll',
            paddingBottom: '10px',
          }}
        >
          <div className="card-header border-0">
            <h3 className="card-title font-weight-bolder text-dark">
              Certificados
            </h3>
          </div>

          {trilha.length <= 0 && (
            <div className="card-body pt-0">
              <h1 className="font-size-lg" style={{ textAlign: 'center' }}>
                Profissional não possui certificados
              </h1>
            </div>
          )}
          {trilha.length > 0 && (
            <div className="card-body pt-0">
              {trilha.map((trail) => (
                <div
                  className="d-flex align-items-center mb-9  rounded p-5"
                  style={{ backgroundColor: '#F7F8FA' }}
                >
                  <span className="svg-icon svg-icon-warning mr-5">
                    <span className="svg-icon svg-icon-lg">
                      <img
                        src={
                          trail.trilhaImagem ? trail.trilhaImagem : AvatarImg
                        }
                        alt=""
                        style={{
                          width: '68px',
                          height: '45px',
                          borderRadius: '10px',
                          objectFit: 'cover',
                        }}
                      />
                    </span>
                  </span>

                  <div className="d-flex flex-column flex-grow-1 mr-2">
                    <a
                      href={trail.certificadoUrl}
                      download
                      target="_blank"
                      className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                      rel="noreferrer"
                    >
                      {trail.trilhaNome}
                    </a>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
