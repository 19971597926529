import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Form } from '@unform/web';
import { FiCamera, FiImage, FiUser } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import { Autocomplete } from '@material-ui/lab';
import { Chip, TextField } from '@material-ui/core';
import SwitchInput from '../../../components/InputSwitch';
import { API } from '../../../../services/api';
import GetValidationErrors from '../../../../utils/getValidationErros';
import { useAuth } from '../../../../hooks/Auth';
import Input from '../../../components/Input';
import TextAreaInput from '../../../components/TextAreaInput';
import { Button } from '../../../../components/Button';
import ModalComponent from '../../../../components/Modal/ModalComponent';
import { ModalProps } from '../../../../interfaces/components';
import AvatarImg from '../../../../assets/images/no-image.png';

import './styles.scss';

interface CategoriaSubmitProp {
  categoriaId: number;
}

interface CategoriasProps {
  id: number;
  descricao: string;
}

export default function CreateTrailModal({
  onClose,
  closeButton,
  itens,
  open,
  setItens,
  setModalOpen,
}: ModalProps) {
  const { token } = useAuth();
  const [loading, setLoading] = useState(false);
  const [sliderValue, setSliderValue] = useState<number | number[]>(30);
  const [categorias, setCategorias] = useState<CategoriasProps[]>([]);
  const [resumoAprendizagem, setResumoAprendizagem] = useState<string[]>([]);
  const [categoriasArray, setCategoriasArray] = useState<CategoriasProps[]>([]);
  const formRef = useRef<FormHandles>(null);
  const avatarRef = useRef<HTMLImageElement>(null);
  const fileRef = useRef<HTMLInputElement>(null);

  const [trilhaExclusiva, setTrilhaExclusiva] = useState(false);
  const handleChecked = (e) => {
    setTrilhaExclusiva(e.target.checked);
  }

  useEffect(() => {
    async function loadData() {
      try {
        const response = await API(token).get('/api/categoria/odata');
        setCategorias(response.data.value);
      } catch (err) {
        toast.error('Houve um error ao listar as categorias! 😢');
      }
    }

    loadData();
  }, [token]);

  const Submit = useCallback(
    async (data) => {
      setLoading(true);

      try {
        formRef.current?.setErrors({});
        if (resumoAprendizagem.length > 10) {
          throw new Error('resumoAprendizagemExceed');
        }
        const schema = Yup.object().shape({
          nome: Yup.string().required('Campo Obrigatório'),
          descricao: Yup.string().required('Campo Obrigatório'),
          resumo: Yup.string().required('Campo Obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (
          fileRef.current &&
          fileRef.current.files &&
          fileRef.current.files.length > 0
        ) {
          const formData = new FormData();
          formData.append('avatar', fileRef.current.files[0]);

          const upload_response = await API(token).post(
            '/api/trilha/UploadFiles/avatar',
            formData,
          );

          data.urlImagem = upload_response.data.avatar;
        }

        data.porcentagemMinima = sliderValue;
        let resumoAprendizagemString = '';
        resumoAprendizagem.forEach((resumo, index) => {
          if (index === resumoAprendizagem.length - 1) {
            resumoAprendizagemString += `${resumo}`;
          } else {
            resumoAprendizagemString += `${resumo}[*]`;
          }
        });
        data.resumoAprendizagem = resumoAprendizagemString;
        data.exclusiva = trilhaExclusiva;

        const categoriaIds: CategoriaSubmitProp[] = [];
        categoriasArray.forEach((categoria) => {
          categoriaIds.push({ categoriaId: categoria.id });
        });

        data.categorias = categoriaIds;
        const response = await API(token).post('/api/trilha', data);
        setLoading(false);

        data.id = response.data.id;
        setItens([...itens, data]);
        toast.success('Trilha criada com sucesso!');
        setModalOpen(false);
      } catch (err: any) {
        if (err instanceof Yup.ValidationError) {
          const errors = GetValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else if (
          err instanceof Error &&
          err.message === 'resumoAprendizagemExceed'
        ) {
          toast.error('Máximo de 10 itens para o que voce aprenderá!');
        } else {
          toast.error('Houve um erro ao criar a trilha, tente novamente! 😢');
        }

        setLoading(false);
      }
    },
    [
      token,
      itens,
      setItens,
      setModalOpen,
      resumoAprendizagem,
      sliderValue,
      categoriasArray,
      trilhaExclusiva
    ],
  );

  const handleAvatarChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      if (avatarRef.current) {
        avatarRef.current.src = URL.createObjectURL(e.target.files[0]);
      }
    }
  }, []);

  return (
    <ModalComponent open={open} closeButton={closeButton} onClose={onClose}>
      <Form ref={formRef} placeholder="" className="createmodal-form-admin" onSubmit={Submit}>
        <h2 id="simple-modal-title">Criar nova trilha</h2>

        <div className="AvatarInput">
          <img ref={avatarRef} src={AvatarImg} alt="" />
          <label htmlFor="avatar">
            <FiCamera />

            <input
              type="file"
              id="avatar"
              ref={fileRef}
              name="urlImagem"
              accept="image/*"
              onChange={(event) => handleAvatarChange(event)}
            />
          </label>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'baseline',
          }}
        >
          <span style={{ marginRight: 10, fontSize: 16 }}>Trilha Exclusiva</span>
          <SwitchInput name="exclusiva" checked={trilhaExclusiva} onChange={handleChecked} />
        </div>

        <Input
          name="nome"
          maxLength={100}
          icon={FiUser}
          type="text"
          placeholder="Nome"
        />

        <Autocomplete
          multiple
          options={[]}
          freeSolo
          onChange={(event, value) => {
            setResumoAprendizagem(value);
          }}
          limitTags={5}
          className="AutoCompleteStyle"
          renderTags={(value: string[], getTagProps) =>
            value.map((option: string, index: number) => (
              <Chip
                style={{ backgroundColor: '#6f95ff', color: '#fff' }}
                variant="default"
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const newInputProps = params.InputProps as any;
            newInputProps.disableUnderline = true;
            // eslint-disable-next-line @typescript-eslint/ban-types
            params.InputProps = newInputProps;
            return (
              <TextField
                required={resumoAprendizagem.length === 0}
                label="O que o usuário aprenderá"
                name="resumoAprendizagem"
                className="TextFieldStyle"
                {...params}
                variant="standard"
                placeholder="Pressione enter para adicionar um item"
              />
            );
          }}
        />

        <TextAreaInput
          name="resumo"
          maxLength={200}
          style={{ height: 110, maxHeight: 110 }}
          type="text"
          placeholder="Descrição breve"
        />

        <TextAreaInput
          name="descricao"
          style={{ maxHeight: 300, minHeight: 180 }}
          maxLength={1000}
          placeholder="Descrição"
        />

        <Autocomplete
          multiple
          noOptionsText="Não há categorias disponíveis!"
          options={categorias}
          onChange={(event, value) => {
            setCategoriasArray(value);
          }}
          limitTags={3}
          style={{
            marginTop: 10,
            backgroundColor: '#f2f3f7',
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index: number) => (
              <Chip
                style={{ backgroundColor: '#6f95ff', color: '#fff' }}
                variant="default"
                label={option.descricao}
                {...getTagProps({ index })}
              />
            ))
          }
          getOptionLabel={(option) => option.descricao}
          renderInput={(params) => (
            <TextField
              required={categoriasArray.length === 0}
              label="Categorias"
              name="resumoAprendizagem"
              className="TextFieldStyle"
              {...params}
              variant="outlined"
              placeholder="Adicionar categorias"
            />
          )}
        />

        <div style={{ margin: 10 }}>
          <Typography
            style={{ fontFamily: 'Poppins', fontWeight: 600 }}
            id="sliderLabel"
            gutterBottom
          >
            Porcentagem Mínima para o aluno ser aprovado nas provas:
          </Typography>
          <Slider
            name="porcentagemMinima"
            defaultValue={30}
            getAriaValueText={(value) => `${value}%`}
            aria-labelledby="sliderLabel"
            onChange={(event, value) => {
              setSliderValue(value);
            }}
            style={{ color: '#6F95FF' }}
            step={5}
            marks
            min={10}
            max={100}
            valueLabelDisplay="auto"
          />
        </div>

        <Button
          color="#6F95FF"
          text="Confirmar"
          type="submit"
          loading={loading}
        />
      </Form>
    </ModalComponent>
  );
}
