/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';

import { TrailProps } from '../../../../../interfaces/Entidades';

interface CardTrailProps {
  trilhas: TrailProps[];
  loading: boolean;
}

export default function CardTrail({ trilhas, loading }: CardTrailProps) {
  return (
    <>
      <div className="continuar">
        {/* begin::Mixed Widget 16 */}

        {loading ? (
          <div className="card card-custom card-stretch gutter-b">
            {/* begin::Header */}
            <div className="card-body">
              <div className="font-weight-bolder font-size-h5 color">
                <Skeleton variant="text" animation="wave" />
              </div>
              <div className="font-weight-bolder text-muted mt-2">
                <Skeleton variant="text" animation="wave" />
              </div>
              <p className="text-dark-75 font-size-sm  font-size-base m-0 text-muted line-clamp">
                <Skeleton variant="text" animation="wave" />
              </p>
            </div>
            {/* end::Body */}
          </div>
        ) : trilhas?.length === 0 ? (
          <div className="card card-custom card-stretch gutter-b">
            {/* begin::Header */}
            <div className="card-body">
              <div className="font-weight-bolder font-size-h5 color">
                Nenhuma trilha econtrada
              </div>
            </div>
            {/* end::Body */}
          </div>
        ) : (
          trilhas.map((x) => (
            <div className="card card-custom card-stretch gutter-b" key={x.id}>
              {/* begin::Header */}
              <div className="card-body">
                <div className="font-weight-bolder font-size-h5 color">
                  {x.nome}
                </div>
                <div className="font-weight-bolder text-muted mt-2">
                  {x.organizacao.razaoSocial}
                </div>
                <p className="text-dark-75 font-size-sm  font-size-base m-0 text-muted line-clamp">
                  {x.descricao}
                </p>

                <Link
                  to={{ pathname: `/details_trail/${x.id}` }}
                  className="btn btn-primary font-weight-bolder mt-2 px-8"
                >
                  Saiba Mais
                </Link>
              </div>
              {/* end::Body */}
            </div>
          ))
        )}
      </div>
    </>
  );
}
